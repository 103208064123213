import {Col, Row} from "../../../common";
import React, {useState} from "react";
import {useFormikContext} from "formik";
import Filters from "../../../../configs/Filters";
import {Colors, Metrics} from "../../../config";
import {Spin, Tooltip} from "antd";
import {CheckCircleOutlined} from "@material-ui/icons";

const mock = {
    "applicationId" : 123237,
    "desiredPrincipal" : 10000.0,
    "installmentsDueDay" : 7,
    "maxInstallmentValue" : 4143.0,
    "minInstallmentValue" : 50.0,
    "maxTerm" : 24,
    "maxTotalValue" : 7738.0,
    "monthlyInterestRate" : 3.95,
    "suggestions" : [ {
        "annualEcdRate" : 59.33,
        "annualInterestRate" : 59.18,
        "fee" : 0.0,
        "installmentValue" : 569.08,
        "installmentsDueDay" : 7,
        "loanDate" : "2022-10-02",
        "loanValue" : 7738.0,
        "monthlyEcdRate" : 3.96,
        "monthlyInterestRate" : 3.95,
        "numberOfInstallments" : 21,
        "onetimeFineRate" : 2,
        "taxes" : 224.14
    }, {
        "annualEcdRate" : 59.73,
        "annualInterestRate" : 59.18,
        "fee" : 0.0,
        "installmentValue" : 1505.17,
        "installmentsDueDay" : 7,
        "loanDate" : "2022-10-02",
        "loanValue" : 7738.0,
        "monthlyEcdRate" : 3.98,
        "monthlyInterestRate" : 3.95,
        "numberOfInstallments" : 6,
        "onetimeFineRate" : 2,
        "taxes" : 104.74
    }, {
        "annualEcdRate" : 59.47,
        "annualInterestRate" : 59.18,
        "fee" : 0.0,
        "installmentValue" : 846.47,
        "installmentsDueDay" : 7,
        "loanDate" : "2022-10-02",
        "loanValue" : 7738.0,
        "monthlyEcdRate" : 3.97,
        "monthlyInterestRate" : 3.95,
        "numberOfInstallments" : 12,
        "onetimeFineRate" : 2,
        "taxes" : 171.45
    }, {
        "annualEcdRate" : 59.36,
        "annualInterestRate" : 59.18,
        "fee" : 0.0,
        "installmentValue" : 630.09,
        "installmentsDueDay" : 7,
        "loanDate" : "2022-10-02",
        "loanValue" : 7738.0,
        "monthlyEcdRate" : 3.96,
        "monthlyInterestRate" : 3.95,
        "numberOfInstallments" : 18,
        "onetimeFineRate" : 2,
        "taxes" : 212.55
    }, {
        "annualEcdRate" : 59.32,
        "annualInterestRate" : 59.18,
        "fee" : 0.0,
        "installmentValue" : 523.97,
        "installmentsDueDay" : 7,
        "loanDate" : "2022-10-02",
        "loanValue" : 7738.0,
        "monthlyEcdRate" : 3.96,
        "monthlyInterestRate" : 3.95,
        "numberOfInstallments" : 24,
        "onetimeFineRate" : 2,
        "taxes" : 232.7
    }, {
        "annualEcdRate" : 59.4,
        "annualInterestRate" : 59.18,
        "fee" : 0.0,
        "installmentValue" : 716.33,
        "installmentsDueDay" : 7,
        "loanDate" : "2022-10-02",
        "loanValue" : 7738.0,
        "monthlyEcdRate" : 3.96,
        "monthlyInterestRate" : 3.95,
        "numberOfInstallments" : 15,
        "onetimeFineRate" : 2,
        "taxes" : 196.17
    }, {
        "annualEcdRate" : 59.51,
        "annualInterestRate" : 59.18,
        "fee" : 0.0,
        "installmentValue" : 1064.38,
        "installmentsDueDay" : 7,
        "loanDate" : "2022-10-02",
        "loanValue" : 7738.0,
        "monthlyEcdRate" : 3.97,
        "monthlyInterestRate" : 3.95,
        "numberOfInstallments" : 9,
        "onetimeFineRate" : 2,
        "taxes" : 137.43
    } ],
    "firstInstallmentDueDate" : "2022-11-07"
}
const Offers = ({ isFetching, data }: any) => {
    const { values, setFieldValue }: any = useFormikContext();

    if (isFetching) {
        return <Col style={{justifyContent: 'center', alignItems: 'center'}}>
            <Spin />
            <span>Por favor aguarde, estamos buscando as melhores ofertas. Esta busca pode demorar um pouco...</span>
        </Col>;
    }
    return (
        <Col>
            <h3>Opções de financiamento disponíveis para você</h3>
            <Col style={{alignItems: 'center'}}>
                <span style={{marginRight: Metrics.spacingSM}}>Valor solicitado:</span>
                <span style={{fontSize: Metrics.fontSize.lg, marginBottom: Metrics.spacingSM}}>{Filters.convertMoneyTextMask((data || mock)?.desiredPrincipal)}</span>
            </Col>
            {(data || mock)?.suggestions.map((s: any) => (
                <Row
                    key={s.installmentValue}
                    onClick={() => setFieldValue('selectedOffer', {...(data || mock), ...s})}
                     style={{
                         backgroundColor: s.installmentValue === values.selectedOffer?.installmentValue ? 'rgba(189,153,127, 0.2)' : 'unset',
                         border: `2px solid ${s.installmentValue === values.selectedOffer?.installmentValue ? Colors.primary : '#cacaca'}`,
                         borderRadius: Metrics.defaultRadius,
                         padding: Metrics.spacingMD,
                         marginBlock: Metrics.spacingMD,
                         position: 'relative',
                         cursor: 'pointer'
                }}>
                    <Row style={{alignItems: 'center'}}>
                        <span style={{fontSize: Metrics.fontSize.xxxsm, marginRight: Metrics.spacingSM}}>{`${s.numberOfInstallments}x de `}</span>
                        <span style={{fontSize: Metrics.fontSize.xsm, color: Colors.primaryDark}}>{`${Filters.convertMoneyTextMask(s?.installmentValue || 0)}`}</span>
                        {s.installmentValue === values.selectedOffer?.installmentValue &&
                            <CheckCircleOutlined
                                style={{
                                    top: -10,
                                    right: -10,
                                    color: Colors.primary,
                                    position: 'absolute',
                                    backgroundColor: Colors.white,
                                    borderRadius: '100%'
                                }} />
                        }
                    </Row>
                </Row>
            ))}
        </Col>
    );
};

export default Offers;
