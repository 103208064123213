import api from "./api";
import {unFormat} from "../../configs/utils/MasksUtil";
import moment from "moment";

function selectFirstDate(firstPayment: any, dueDay: any): any {
    if (!firstPayment) {
        const data = moment().set('date', dueDay);
        if (data.isBefore(moment())) {
            return data.add(1, 'month').toDate();
        } else {
            return data.toDate();
        }
    }
    return firstPayment;
}

function unformatUser(user: any): any {
    try {
        if (user.cpf) {
            user.cpf = unFormat(user.cpf);
        }
        if (user.address) {
            if (user.address.postalCode && user.address.postalCode.toString().length === 9) {
                user.address.postalCode = user.address.postalCode.toString().replace('-', '');
            }
        }
    } catch (e) {
        console.log(e);
    }
    return user;
}

export const downloadContractFileRequest = async (param: any, data: any) => {
    return api.post(`/contract/contract-pdf`, data,{
        responseType: "blob",
    });
};

export const hireServiceInternalRequest = async (param: any, req: any) => {
    const data = {
        ...req.installment,
        dueDay: req.dueDay,
        startPayment: selectFirstDate(req.startPayment, req.dueDay),
        ...(req.type ? {
            type: req.type,
            taxStartPayment: req.taxStartPayment,
            taxPaymentChoice: req.taxPaymentChoice,
            discountCoupon: req.discountCoupon
        } : [])
    }
    return api.post(`api/user-procedures/final`, data);
};

export const hireServiceRequest = async (param: any, req: any) => {
    const data = {
        simulationOption: {
            ...req.installment,
            dueDay: req.dueDay,
            startPayment: selectFirstDate(req.startPayment, req.dueDay),
            ...(req.type ? {
                type: req.type,
                taxStartPayment: req.taxStartPayment,
                taxPaymentChoice: req.taxPaymentChoice,
                discountCoupon: req.discountCoupon
            } : [])
        },
        ...(req.user ? {user: unformatUser(req.user)} : []),
        sellerId: req.sellerId
    }
    return api.post(`/simulation/complete`, data);
};
export const sendContractByEmailRequest = async (param: any, data: any) => {
    return api.post(`/contract/contract-email`, data);
};

export const simulationInstallmentOptionsRequest = async (param: any, data: any) => {
    return api.post(`/simulation`, data);
};

export const findMaxInstallmentsRequest = async () => {
    return api.get(`/api/installments/last`);
};

export const findCouponByCode = async (param: any, code: string) => {
    return api.get(`/api/coupon/validate/${code}`);
};

export const findDefaultProcedureRequest = async (params: any) => {
    return api.get(`/api/procedures/default`, {params: {type: params}});
};

