import React, {useEffect} from "react";
import {Formik, useFormikContext} from "formik";
import {Form} from "antd";
import {Btn, Divider, FormInputText, Row} from "../../../common";
import FormCheckbox from "../../../common/form/FormCheckbox";
import {Metrics} from "../../../../configs";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
};

const OnChangeTrigger = () => {
    const {values, handleSubmit}: any = useFormikContext();
    useEffect(() => handleSubmit(values), [values, handleSubmit]);
    return (<div />)
}

export const DreamFilter = ({setFilter, filter, isFetching}: any) => {
  return (
      <Formik
          initialValues={filter}
          onSubmit={setFilter}
          enableReinitialize>
          {({values, setFieldValue}: any) => (
              <Form>
                  <FormInputText name='name' value={values.name} placeholder='Cliente' autoFocus />
                  <FormCheckbox
                      formStyle={{marginTop: Metrics.spacingSM}}
                      name="active"
                      label="Somente clientes ativos"
                      onChange={(c: boolean) => c ? setFieldValue('suspended', false) : () => {}}
                  />
                  <FormCheckbox
                      formStyle={{marginTop: Metrics.spacingSM}}
                      name="suspended"
                      label="Somente clientes com contratos suspensos"
                      onChange={(c: boolean) => c ? setFieldValue('active', false) : () => {}}
                  />
                  <FormCheckbox formStyle={{marginTop: Metrics.spacingSM}} name="overdue" label="Somente clientes inadimplentes"/>
                  <Row style={styles.btnRow}>
                      <Btn
                          onClick={() => {
                              setFieldValue('name', '');
                              setFieldValue('active', false);
                              setFieldValue('suspended', false);
                              setFieldValue('overdue', false);
                          }}
                          disabled={isFetching}
                          type='primary'>
                          Limpar filtro
                      </Btn>
                  </Row>
                <Divider />
                  <OnChangeTrigger />
              </Form>
          )}
      </Formik>
  );
};
