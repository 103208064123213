import React, { createContext, useContext } from 'react';

import useAuth from './hooks/useAuth';

const Context = createContext({});

function AuthProvider({ children }) {
  const {
    authenticated,
    isFetching,
    user,
    isLoginVisible,
    setIsLoginVisible,
    hasAnyAuthority,
    acceptedLgpd,
    handleAcceptLgpd,
    handleLogin,
    handleLoginGoogle,
    refreshAccount,
    handleLogout,
  } = useAuth();

  return (
    <Context.Provider
      value={{
          authenticated,
          isFetching,
          user,
          isLoginVisible,
          setIsLoginVisible,
          hasAnyAuthority,
          acceptedLgpd,
          handleAcceptLgpd,
          handleLogin,
          handleLoginGoogle,
          refreshAccount,
          handleLogout,
      }}>
      {children}
    </Context.Provider>
  );
}

export const useAuthentication = () => {
  const {
      authenticated,
      isFetching,
      user,
      isLoginVisible,
      setIsLoginVisible,
      hasAnyAuthority,
      acceptedLgpd,
      handleAcceptLgpd,
      handleLogin,
      handleLoginGoogle,
      refreshAccount,
      handleLogout,
  } = useContext(Context);
  return {
      authenticated,
      isFetching,
      user,
      isLoginVisible,
      setIsLoginVisible,
      hasAnyAuthority,
      acceptedLgpd,
      handleAcceptLgpd,
      handleLogin,
      handleLoginGoogle,
      refreshAccount,
      handleLogout,
  };
};

export { Context as AuthContext, AuthProvider };
