import React from "react";
import {GenericList} from "../../../components";
import {deleteWorkgroupRequest, findWorkgroupsRequest} from "../../../services/workgroup";

export const Workgroup = () => {
  return (
      <GenericList
          title={'Grupos'}
          newPath={'workgroups/create'}
          editPath={'workgroups/edit'}
          providerFind={findWorkgroupsRequest}
          providerDelete={deleteWorkgroupRequest}
          columns={[
              {title: 'Nome', key: 'name'}
          ]}
      />
  );
};
