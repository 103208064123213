import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    account: Yup.string()
        .required('O campo conta é obrigatório.'),
    accountType: Yup.string()
        .required('O campo tipo de conta é obrigatório.'),
    agency: Yup.string()
        .required('O campo agência é obrigatório.'),
    bank: Yup.string()
        .required('O campo banco é obrigatório.'),
});

export default ValidationSchema;
