import api from "./api";

export const authenticateRequest = async (data: any) => {
    return api.post('api/authenticate', data);
};

export const accountRequest = async () => {
    return api.get('api/account');
};

export const authenticateGoogleRequest = async (params: any, data: any) => {
    return api.post('api/authenticate/google', data);
};

export const authenticateFacebookRequest = async (params: any, data: any) => {
    return api.post('api/authenticate/facebook', data);
};

export const changePasswordRequest = async (params: any, data: any) => {
    return api.post('api/account/reset-password/finish', data);
};

export const recoverPasswordRequest = async (params: any, data: any) => {
    return api.post('api/account/reset-password/init', data, {
        headers: {
            "Content-Type": "plain/text",
    }})
};
