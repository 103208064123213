import React from "react";
import validationSchema from './ValidationSchema';
import {Form} from 'antd';
import {Formik} from "formik";
import {Btn, FormDropdown, FormInputText, Row} from "../../../../common";
import {Metrics} from "../../../../config";
import {useHistory} from "react-router";
import Whitespace from "../../../../common/whitespace";
import {INSTALLMENT_TYPES} from "../util/INSTALLMENT_TYPES";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'}
};

const InstallmentForm = ({onSubmit, initialValues, isFetching}: any) => {
    const {goBack} = useHistory();
    return (
        <div>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {({ handleSubmit, values }) => (
                    <Form>
                        <FormInputText label="Valor" name="value" value={values.value} />
                        <FormInputText label="Taxa Administrativa" name="taxAdm" value={values.taxAdm} />
                        <FormDropdown
                            label="Tipo"
                            value={values.type}
                            name="type"
                            list={Object.keys(INSTALLMENT_TYPES)
                                .map( pt => ({label: INSTALLMENT_TYPES[pt], value: pt}))
                            }
                        />
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={goBack}
                                style={styles.btn}>Voltar</Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={() => handleSubmit()}
                                disabled={isFetching}
                                type='primary'
                                style={styles.btn}>
                                Salvar
                            </Btn>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default InstallmentForm;
