const store = (key: any, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // saving error
  }
};

const get = (key: any) => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
    return value;
  } catch {
    return null;
  }
};

const clear = (key: any) => {
  localStorage.removeItem(key);
};

const setUser = (user: any) => {
  store('sili#user', user);
};

const clearUser = () => {
  localStorage.removeItem('sili#user');
};

const getUser = () => {
  const user = get('sili#user');
  return user;
};

const setToken = (token: any) => {
  store('sili#token', token);
};

const getToken = () => {
  const token = get('sili#token');
  return token;
};

const clearToken = () => {
  clear('sili#token');
};

const acceptLgpd = () => {
  store('sili#lgpd', true);
};

const getLgpd = () => {
  return get('sili#lgpd');
};

export default {
  store,
  get,
  getToken,
  clearToken,
  setToken,
  acceptLgpd,
  getLgpd,
  setUser,
  getUser,
  clearUser,
  clear,
};
