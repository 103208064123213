import React, {useState} from 'react';
import {
    ActionItem,
    ActionItemContainer,
    Animated,
    Container,
    ContainerIcons,
    HelpOutlineIcon,
    SettingsIcon
} from './styles'
import {useAuthentication} from "../../context/AuthContext";
import {Colors} from "../../config";

const ActionIconsDrawer = () => {
    // const themeContext = useContext(ThemeContext);
    const { handleLogout } = useAuthentication();
    const [show, setShow] = useState(false);
    const displayActions = (display: boolean) => {
        setShow(display);
    };
    const logoutAction = () => {
        handleLogout();
        displayActions(false);
    };

    return (
        <>
            <Container>
                <ContainerIcons>
                    <SettingsIcon onClick={() => displayActions(!show)}/>
                </ContainerIcons>
                <div style={{
                    position: 'absolute',
                    overflow: 'hidden',
                    marginLeft: '29px',
                    width: show ? 100 : 1,
                    height: 100,
                    transition: 'all 0.4s ease'
                }}>
                    <Animated animationIn="fadeInLeftBig" animationOut="fadeOutLeftBig" isVisible={show}
                              animationInDuration={500}
                              animationOutDuration={4000} animateOnMount={false}>
                        <ActionItemContainer>
                            {/*<ActionItem>Perfil</ActionItem>*/}
                            <ActionItem onClick={logoutAction}>Sair</ActionItem>
                        </ActionItemContainer>
                    </Animated>
                </div>
            </Container>
        </>
    )
};

export default ActionIconsDrawer;
