import React from 'react';
import "./styles.css";
import {TableStyled} from './styles';

const Table = ({children, pagination, data, handleFind, rowKey = (rec: any) => rec.id, isFetching, expandedRowRender, ...rest}: any) => {
  return (
      <TableStyled
          {...rest}
          expandedRowRender={expandedRowRender}
          loading={isFetching}
          pagination={pagination ? {
            ...pagination,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '15', '20']
          } : false}
          rowKey={rowKey}
          onChange={handleFind}
          dataSource={data}>
        { children }
      </TableStyled>
  );
}

export default Table;
