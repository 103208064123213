import api from "./api";

export const findProfessionalRequest = async (param: any, data: any) => {
    const response: any = await api.get(`/api/professionals/${param}`, data);
    return new Promise((resolve: any) => {
        resolve(
            {...response,
                data: {
                    ...response.data,
                    address: {
                        ...response.data.address,
                        postalCode: response.data.address.postal_code
                    }
                }
            }
        );
    });
};

export const findProfessionalsRequest = (param: any, data: any) => {
    return api.get(`/api/professionals`, {params: data});
};

export const createProfessionalRequest = (param: any, data: any) => {
    return api.post(`/api/professionals`, data);
};

export const updateProfessionalRequest = (param: any, data: any) => {
    return api.put(`/api/professionals`, data);
};

export const deleteProfessionalRequest = (param: any, data: any) => {
    return api.delete(`/api/professionals/${data}`);
};
