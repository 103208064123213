import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {useAuthentication} from "../../context/AuthContext";

interface PrivateRouteProps {
    hasAnyAuthorities?: string[]
}

interface PrivateRoute extends RouteProps, PrivateRouteProps {
}

const PrivateRoute = ({component, path, exact, hasAnyAuthorities}: PrivateRoute) => {
    const { authenticated, hasAnyAuthority }: any = useAuthentication();
    const isAuthorized: boolean = hasAnyAuthority(hasAnyAuthorities || []);
    if (!authenticated) {
        return <Redirect to="/home"/>
    }
    if (!isAuthorized) {
        return <Redirect to="/sistema"/>
    }

    return (
        <Route component={component} path={path} exact={exact}/>
    );
};

export default PrivateRoute;
