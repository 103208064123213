import React from "react";
import {Btn, Col, FormInputText, Row, Whitespace} from "../../common";
import {Colors, Metrics} from "../../config";
import partnerImg from "../../../assets/images/partner.png";
import useFetch from "../../hooks/useFetch";
import validationSchema from "./ValidationSchema";
import {Form} from "antd";
import {MaskUtils} from "../../../util/MasksUtil";
import {Formik} from "formik";
import {showErrorToast, showSuccessToast} from "../../../configs/toast";
import {useHistory} from "react-router";
import {sendPartnershipDataRequest} from "../../services/partner";

const styles = {
    container: {
        alignItems: 'center', padding: `75px ${Metrics.spacingXLG}`,
        height: '100%',
        position: 'relative'
    },
    bg: {
        filter: 'blur(3px) opacity(0.4)',
        position: 'absolute' as const,
        width: '100%',
        top: 0
    },
    title: {
        color: Colors.primary,
        textAlign: 'center' as const
    },
    text: {
        fontSize: Metrics.fontSize.xxsm,
        whiteSpace: 'pre-line' as const,
        maxWidth: '1100px',
        color: Colors.text,
        textAlign: 'justify' as const
    },
    img: {width: '420px'},
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'}
}

const Partnership = () => {
    const { goBack } = useHistory();
    const [{ isFetching }, update] = useFetch(
        {
            provider: sendPartnershipDataRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: () => {
                    showSuccessToast('Dados enviados com sucesso! Aguarde o contato da equipe Silicred.');
                    goBack();
                },
                error: (d: any) => showErrorToast(d)
            },
        }
    );
    return (
        <Col style={styles.container}>
            <img style={styles.bg} src={partnerImg} />
            <div style={{ display: 'flex', flexDirection: 'column', position: 'relative'}}>
                <h2 style={styles.title}>Seja parceiro</h2>
                <p style={styles.text}>
                    A SILICRED possui financiamento rápido para seus clientes pagarem procedimentos e tratamentos a vista. Isso mesmo. Você recebe a vista, sem risco de inadimplência. Aumentando assim o seu número de clientes e faturamento. Ao todo temos cinco meios de pagamento para que os clientes de nossos parceiros tenham acesso aos serviços.
                </p>
                <p style={styles.text}>Preencha o formulário e torne-se um parceiro SILICRED.</p>
                <p style={styles.text}>Aumente seu faturamento.</p>
            </div>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={{
                    professional: '',
                    responsible: '',
                    email: '',
                    cel: '',
                }}
                onSubmit={update}>
                {({ handleSubmit, values }) => (
                    <Form>
                        <FormInputText label="Nome da clínica ou profissional" name="professional" value={values.professional} />
                        <FormInputText label="Nome do responsável" name="responsible" value={values.responsible} />
                        <FormInputText label="E-mail" name="email" value={values.email} />
                        <FormInputText label="Telefone" name="cel" value={MaskUtils.formatCel(values.cel)} />
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={goBack}
                                style={styles.btn}>Voltar</Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={() => handleSubmit()}
                                disabled={isFetching}
                                type='primary'
                                style={styles.btn}>
                                Enviar
                            </Btn>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Col>
    );
};

export default Partnership;
