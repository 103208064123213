import React, {useEffect} from "react";
import {Formik, useFormikContext} from "formik";
import {Metrics} from "../../../../config";
import {Btn, Divider, FormInputText, Row} from "../../../../common";
import {Form} from "antd";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'}
};

const OnChangeTrigger = () => {
    const {values, handleSubmit}: any = useFormikContext();
    useEffect(() => handleSubmit(values), [values, handleSubmit]);
    return (<div />)
}

export const LileveUserFilter = ({setFilter, filter, isFetching}: any) => {
  return (
      <Formik
          initialValues={filter}
          onSubmit={setFilter}
          enableReinitialize>
          {({values, setFieldValue}: any) => (
              <Form>
                  <FormInputText name='cidade' value={values.cidade} placeholder='Cidade' autoFocus />
                  <FormInputText name='estado' value={values.estado} placeholder='Estado' autoFocus />
                  <Row style={styles.btnRow}>
                      <Btn
                          onClick={() => {
                              setFieldValue('cidade', '');
                              setFieldValue('estado', '');
                          }}
                          disabled={isFetching}
                          type='primary'
                          style={styles.btn}>
                          Limpar filtro
                      </Btn>
                  </Row>
                <Divider />
                  <OnChangeTrigger />
              </Form>
          )}
      </Formik>
  );
};
