import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useAuthentication } from '../../context/AuthContext';
import {Row} from "../../common";

const PreLoader = ({ children }: any) => (
  <CheckRehydrated>{children}</CheckRehydrated>
);

const CheckRehydrated = ({ children }: any) => {
  const { authenticated } = useAuthentication();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authenticated === false || authenticated === true) {
      setLoading(false);
    }
  }, [authenticated]);

  if (loading) {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
        <Spin size="large" />
      </Row>
    );
  }
  return <>{children}</>;
};

export default PreLoader;
