import api from "./api";

export const findDreamsRequest = async (param: any, data: any) => {
    return api.get(`/api/users-filtered`, {params: data});
};
export const findDreamDetailRequest = async (param: any, data: any) => {
    const response: any = await api.get(`/api/users-admin/${param}`);
    return new Promise((resolve: any) => {
        resolve(
            {...response,
                data: {
                    ...response.data,
                    userProcedures: response?.data?.userProcedures.sort((a: any, b: any) => (a.agreementDt > b.agreementDt) ? 1 : -1)
                }
            }
        );
    });
};

export const reassignSellerRequest = (param: any, data: any) => {
    return api.post(`/api/user-procedures/reassign-seller/${data.userProcedureId}/${data.sellerId}`);
};

export const markPixPaidRequest = (param: any, data: any) => {
    return api.post(`/api/user-procedure-payments/pix/${data.paymentId}`, data);
};

export const downloadBankslipRequest = (param: any, data: any) => {
    return api.get(`/api/user-procedure-payments/download/${data}`);
};

export const downloadBankslipOverdueRequest = (param: any, data: any) => {
    return api.get(`/api/user-procedure-payments/overdue/download/${data}`);
};

export const findUsersRequest = (param: any, data: any) => {
    return api.get(`/api/users`, {params: data});
};

export const findUserDreamsRequest = () => {
    return api.get(`api/user-procedures/dreams`);
}

export const suspendUserDreamRequest = (param: any, id: any) => {
    return api.get(`api/user-procedures/suspend/${id}`);
}

export const deleteUserDreamRequest = (param: any, id: any) => {
    return api.get(`api/user-procedures/delete/${id}`);
}

export const dreamWithdrawRequest = (param: any, data: any) => {
    return api.post(`api/user-procedure-withdraws`, data);
}
