import {useEffect, useReducer, useRef} from 'react';
import useCompare from './useCompare';

const defaultPageSize = 10;

const initialState = {
    isFetching: false,
    filter: {},
    data: null,
    totalItems: 0,
    currentPage: 1,
    pageSize: defaultPageSize,
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {...state, isFetching: true};
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isFetching: false,
                data: action.payload.data,
                totalItems: action.payload.totalItems,
            };
        case 'FILTER':
            return {
                ...initialState,
                filter: action.payload.filter,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                data: action.payload.initialData,
                totalItems: action.payload.totalItems,
            };
        default:
            throw new Error();
    }
};

export default (
    {
      provider,
      param,
      requestOnMount,
      initialData,
      resultHandler,
      paginate
    }: any
                ) => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        data: initialData,
    });
    const diff = useCompare(param);
    const diffProvider = useCompare(provider);
    const shouldLoad = useRef(requestOnMount);
    const successHandler = resultHandler ? resultHandler.success : () => {};
    const errorHandler = resultHandler ? resultHandler.error : () => {};
    const {filter, currentPage, pageSize} = state;

    useEffect(() => {
        if (diff || diffProvider) {
            dispatch({type: 'FETCH_SUCCESS', payload: {data: initialData}});
        } else if (shouldLoad.current) {
            dispatch({type: 'FETCH_INIT'});
            provider(
                param,
                paginate ? {...filter, page: currentPage - 1, size: pageSize} : filter
            )
                .then((result: any) => {
                        dispatch({
                            type: 'FETCH_SUCCESS',
                            payload: {
                                data: result.status === 200 ? result?.data : initialData,
                                totalItems: parseInt(result?.headers['x-total-count'])
                            },
                        });
                    shouldLoad.current = false;
                    successHandler(result);
                })
                .catch(errorHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diff, diffProvider, filter, currentPage, provider, shouldLoad.current]);

    function fetchFilter(payload: any) {
        const f = payload?.filter || {};
        const page = payload?.page?.current || 1;
        const size = payload?.page?.size || defaultPageSize;
        dispatch({
            type: 'FILTER',
            payload: {
                filter: typeof f === 'string' || typeof f === 'number' ? f : {...f},
                currentPage: page,
                pageSize: size || defaultPageSize,
                initialData,
            },
        });
        shouldLoad.current = true;
    }

    return [state, fetchFilter];
};
