export const PROCEDURE_TYPES: any = {
    SCHEDULED_SURGERY_DEFAULT: 'SCHEDULED_SURGERY_DEFAULT',
    PLANNED_SURGERY_DEFAULT: 'PLANNED_SURGERY_DEFAULT',
    SILICASH: 'SILICASH',
    DEFAULT: 'Padrão',
    BODY: 'Corpo',
    NOT_SURGERY: 'Não cirurgico',
    BREAST: 'Seios',
    FACIAL: 'Face'
};
