import React from 'react';
import {useHistory} from 'react-router';
import CommissionForm from '../form';
import useFetch from "../../../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../../../configs/toast";
import {TitledContainer} from "../../../../components";
import {createCommissionRequest} from "../../../../services/commission";

const initialValues = {
    workgroupId: '',
    commission: 0,
}

export const CommissionCreate = () => {
  const {goBack} = useHistory();
  const [{isFetching}, create,] = useFetch(
    {
      provider: createCommissionRequest,
      param: '',
      requestOnMount: false,
      initialData: null,
      resultHandler: {
        success: () => {
          showSuccessToast('Registro criado com sucesso!')
          goBack();
        },
        error: (d: any) => showErrorToast(d)
      }
    }
  );

  return (
      <TitledContainer title={'Comissão'}>
          <CommissionForm goBack={goBack}
            isFetching={isFetching}
            onSubmit={create}
            initialValues={initialValues}
          />
      </TitledContainer>
  );
}
