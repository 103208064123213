import React, {useCallback, useEffect, useRef} from 'react';
import styled from "styled-components";
import {useHistory} from "react-router";
import {Link} from "../Topbar";
import {useAuthentication} from "../../context/AuthContext";
import {Metrics} from "../../../configs";

type ContainerProps = {
    isSideMenuVisible?: boolean,
    ref?: any
}

const UlStyled = styled.ul`
    display: flex;
    flex-direction: column;
`;

const LiStyled = styled.li`
    margin: 10px 0;
`;

const NavStyled = styled.nav`
    padding-right: 1.5rem;
    background: #49443e;
    float: right;
    border-radius: 15px;
    box-shadow: 1px 6px 10px #80808075;
`;

const MenuContainer = styled.div<ContainerProps>`
    z-index: 2;
    margin-top: 60px;
    position: fixed;
    top: 0;
    right: ${props => props.isSideMenuVisible ? '0' : '-300px'};
    transition: 0.5s all ease;
    text-align: left;
    @media (min-width: 768px) {
        display: none;
    }
`;

const SideMenubar = ({handleCloseMenu, isSideMenuVisible, setIsLoginVisible}: any) => {
    const ref = useRef();
    const { user, authenticated } = useAuthentication();
    const { push } = useHistory();
    const handleClickOutside = useCallback((event: any) => {
        // @ts-ignore
        if (ref && ref.current && !ref.current.contains(event.target)) {
            handleCloseMenu();
        }
    }, [handleCloseMenu]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return (
        <MenuContainer ref={ref} isSideMenuVisible={isSideMenuVisible}>
            <NavStyled>
                <UlStyled>
                    <LiStyled>
                        <span
                            style={{color: 'white', cursor: 'pointer'}}
                            onClick={() => {
                                handleCloseMenu();
                                push('/about');
                            }}>
                            sobre nós
                        </span>
                    </LiStyled>
                    <LiStyled>
                        <span
                            style={{color: 'white', cursor: 'pointer'}}
                            onClick={() => {
                                handleCloseMenu();
                                push('/how-works');
                            }}>
                            como funciona
                        </span>
                    </LiStyled>
                    <LiStyled>
                        <span
                            style={{color: 'white', cursor: 'pointer'}}
                            onClick={() => {
                                handleCloseMenu();
                                push('/simulation');
                            }}>
                            simulação
                        </span>
                    </LiStyled>
                    <LiStyled>
                        <span
                            style={{color: 'white', cursor: 'pointer'}}
                            onClick={() => {
                                handleCloseMenu();
                                push('/partnership');
                            }}>
                            seja parceiro
                        </span>
                    </LiStyled>
                    <LiStyled>
                        <span
                            style={{color: 'white', cursor: 'pointer'}}
                            onClick={() => {
                                handleCloseMenu();
                                push('/registration');
                            }}>
                            cadastre-se
                        </span>
                    </LiStyled>
                    <LiStyled>
                    {
                        authenticated ?
                            <span
                                style={{color: 'white', cursor: 'pointer'}}
                                onClick={() => {

                                    push('/sistema');
                                }}>
                                {user ? user?.name?.split(' ')?.[0] : "usuário"}
                            </span>
                            :
                            <span
                                onClick={() => {
                                    handleCloseMenu();
                                    setIsLoginVisible(true);
                                }}
                                style={{color: 'white', cursor: 'pointer'}}>
                                entrar
                            </span>
                    }
                    </LiStyled>
                    {/*<a target={'_blank'} style={{textTransform: 'uppercase' as const,*/}
                    {/*    fontFamily: 'montserrat-bold',*/}
                    {/*    textDecoration: 'none',*/}
                    {/*    fontSize: Metrics.fontSize.xxxsm,*/}
                    {/*    padding: '6px 12px',*/}
                    {/*    borderRadius: '12px',*/}
                    {/*    backgroundColor: '#073B4C',*/}
                    {/*    color: 'white'}}*/}
                    {/*   href="https://silicred.samclub.com.br/parceiro/login">*/}
                    {/*    Acesso clínicas*/}
                    {/*</a>*/}
                </UlStyled>
            </NavStyled>
        </MenuContainer>
    );
};

export default SideMenubar;
