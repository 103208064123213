import React, {useMemo} from "react";
import {GenericList} from "../../../components";
import {deleteNewsRequest, findNewsRequest} from "../../../services/news";

export const News = () => {
    const columns = useMemo(() => {
        return [
            {
                title: 'Título',
                key: 'title'
            },
            {
                title: 'Autor',
                key: 'author'
            },
            {
                title: 'Prévia',
                key: 'preview'
            },
        ];
    }, []);

  return (
      <GenericList
          alternate
          deletable
          title={'Notícias'}
          newPath={'news/create'}
          editPath={'news/edit'}
          providerFind={findNewsRequest}
          providerDelete={deleteNewsRequest}
          columns={columns}
      />
  );
};
