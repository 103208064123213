import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('O campo Nome é obrigatório.'),
    cel: Yup.string()
        .required('O campo Telefone é obrigatório.'),
    city: Yup.string()
        .required('O campo Cidade é obrigatório.'),
    state: Yup.string()
        .required('O campo Estado é obrigatório.'),
    professional: Yup.string()
        .required('O campo nome da clínica ou profissional é obrigatório.'),
});

export default ValidationSchema;
