import React from "react";
import {Col} from "../../../common";
import {SimulationForm, TitledContainer} from "../../../components";

const styles = {
    container: {alignItems: 'center'},
}
export const PerformSell = () => {
    return (
        <TitledContainer title={'Realizar venda'}>
            <Col style={styles.container}>
                <SimulationForm seller />
            </Col>
        </TitledContainer>
    );
};
