import React from 'react';
import styled from 'styled-components';
import {Modal as AModal} from "antd";

export const Modal = styled(AModal)`
  .ant-btn-primary {
    background-color: rgb(220, 20, 8);
    border-color: rgb(220, 20, 8);
  }
  .ant-btn-primary:hover {
    border-color: white !important;
    color: white !important;
  }
  .ant-btn:hover {
    border-color: unset;
    color: unset;
  }
`;

const ConfirmationModal = ({title, visible, onOk, onCancel, okText = 'Confirmar', cancelText = 'Cancelar', description}: any) => {
  return (
      <Modal
          title={title}
          open={visible}
          onOk={onOk}
          onCancel={onCancel}
          okText={okText}
          cancelText={cancelText}>
          <p>{description}</p>
      </Modal>
  );
}

export default ConfirmationModal;
