import React, {useEffect, useState} from 'react';
import {Btn, Col, Row} from "../../common";
import {Colors, Metrics} from "../../config";
import {Form, Steps} from "antd";
import {Formik} from "formik";
import useFetch from "../../hooks/useFetch";
import {useLocation} from "react-router";
import qs from "qs";
import RegistrationForm from "../RegistrationForm";
import useBreakpoint from "../../hooks/useBreakpoint";
import {useAuthentication} from "../../context/AuthContext";
import {findSellerName} from 'new/services/user';
import TermsAcceptance from "./terms";
import ContractValues from "./values";
import ContractTypes from "./types";

const {Step} = Steps;

export const DUE_DAYS = [
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 7},
    {value: 16},
    {value: 21},
    {value: 23},
    {value: 27},
];

const SimulationForm = ({seller}: any) => {
    const [isNewUser, setIsNewUser] = useState<any>(!seller ? true : null);
    const {user: authUser} = useAuthentication();
    const [step, setStep] = useState(0);
    const { isMobile } = useBreakpoint();
    const location = useLocation();
    const referenceSeller = qs.parse(location.search, {ignoreQueryPrefix: true}).referenceSeller as string;
    const preselectedValue = parseInt(qs.parse(location.search, {ignoreQueryPrefix: true}).value as string);
    const [{ isFetching: isFetchingSeller, data: sellerRef }] = useFetch(
        {
            provider: findSellerName,
            param: referenceSeller,
            requestOnMount: referenceSeller ? true : false,
            initialData: null,
            resultHandler: null
        });
    const [data, setData] = useState({
        value: preselectedValue || 0,
        installment: null,
        dueDay: null,
        user: null,
        startPayment: null,
        tax: null,
    });

    return (
        <Col fullWidthResponsive style={{ marginBlock: Metrics.spacingMD, alignItems: 'center', width: !isMobile ? '80%' : 'unset' }}>
            {
                sellerRef &&
                <span style={{color: Colors.primary, fontFamily: 'montserrat-semibold'}}>{`Vendedor: ${sellerRef?.name || 'não encontrado'}`}</span>
            }
            <Formik
                enableReinitialize
                initialValues={data}
                onSubmit={() => {}}>
                {({ handleSubmit, values, setFieldValue }) => (
                    <Form style={{width: '100%'}}>
                            <Col style={{width: '100%'}}>
                                <Steps type="navigation"
                                       direction={isMobile ? "vertical" : 'horizontal'}
                                       size="small"
                                       current={step}>
                                    <Step title="Tipo de serviço" />
                                    <Step title="Valor e vencimento" />
                                    {
                                        !authUser || seller && <Step title="Cadastro" />
                                    }
                                    <Step title="Contrato" />
                                </Steps>
                                {
                                    step === 0 && <ContractTypes setStep={setStep} seller={seller} />
                                }
                                {
                                    step === 1 && <ContractValues setStep={setStep} />
                                }

                                { authUser && !seller ?
                                    <Col>
                                        { step === 2 &&
                                            <TermsAcceptance />
                                        }
                                    </Col>
                                    :
                                    <Col>
                                        { step === 2 &&
                                            <RegistrationForm
                                                isNewUser={isNewUser}
                                                setIsNewUser={setIsNewUser}
                                                seller={seller}
                                                initialValues={values.user}
                                                simulation
                                                onSubmit={(val: any) => {
                                                    setFieldValue('user', val);
                                                    setStep(s => ++s);
                                                }}
                                                isFetching={false}
                                            />
                                        }
                                    </Col>
                                }
                                {
                                    !authUser && step === 3 && <TermsAcceptance />
                                }
                                {
                                    seller && step === 3 && <TermsAcceptance seller={seller} />
                                }
                                {
                                    step > 0 &&
                                    <Row style={{justifyContent: 'center'}}>
                                        <Btn onClick={() => setStep(s => --s)}>
                                            Voltar
                                        </Btn>
                                    </Row>
                                }
                            </Col>

                    </Form>
                )}
            </Formik>
        </Col>
    );
}

export default SimulationForm;
