import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .nullable()
        .required('O campo nome é obrigatório.'),
    minValue: Yup.string()
        .nullable()
        .required('O campo valor mínimo é obrigatório.'),
    maxValue: Yup.string()
        .nullable()
        .required('O campo valor máximo é obrigatório.'),
    type: Yup.string()
        .nullable()
        .required('O campo tipo é obrigatório.'),
    shortDesc: Yup.string()
        .nullable()
        .required('O campo Descrição curta é obrigatório.'),
    fullDesc: Yup.string()
        .nullable()
        .required('O campo Descrição completa é obrigatório.'),
    image: Yup.string()
        .nullable()
        .required('O campo imagem é obrigatório.'),
    treatment: Yup.string()
        .nullable()
        .required('O campo Tratamento é obrigatório.'),
    basicInfo: Yup.object().shape({
        title: Yup.string()
            .nullable()
            .required('O Campo Título é obrigatório.'),
        desc: Yup.string()
            .nullable()
            .required('O Campo Descrição é obrigatório.'),
    })
});

export default ValidationSchema;


//     <p style={styles.title}>Informações básicas</p>
// <FormInputText label="Título" name="basicInfo.title" value={values?.basicInfo?.title} />
// <FormTextArea rows={8} label="Descrição" name="basicInfo.desc" value={values?.basicInfo?.desc} />
// <FormTextArea rows={8} label="Tratamento" name="treatment" value={values?.treatment} />
// <p style={styles.title}>Perguntas frequentes</p>
// <Faq />
// <FormCheckbox name="active" label="Ativo"/>
//     </Col>
// }
