import React from 'react';
import {ConfigProvider} from 'antd';
import {toast, ToastContainer} from 'react-toastify';
import 'moment/locale/pt-br';
import 'react-toastify/dist/ReactToastify.css';
// import 'react-lightbox-component/build/css/index.css';
import ptBR from 'antd/es/locale/pt_BR';
import {HashRouter as Router} from 'react-router-dom';
import {AuthProvider} from "../context/AuthContext";
import PreLoader from "./PreLoader";


const ptbr: any = {
  ...ptBR,
  DatePicker: {
    ...ptBR.DatePicker,
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
    weekFormat: 'YYYY-wo',
    monthFormat: 'YYYY-MM',
  },
};

const Providers = ({ children }: any) => {
  toast.configure();
  return (
      <Router>
        <AuthProvider>
          <PreLoader>
            <ConfigProvider locale={ptbr}>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
              />
              {children}
            </ConfigProvider>
          </PreLoader>
        </AuthProvider>
      </Router>
  );
};

export default Providers;
