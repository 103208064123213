import api from "./api";
import Moment from "moment";
import {DateUtils} from "../../util/DateUtils";
import {unFormat} from "../../configs/utils/MasksUtil";

function unformatUser(user: any): any {
    if (user.birthdate) {
        user.birthdate = DateUtils.convertStringBRToString(user.birthdate);
    }
    if (user.cpf) {
        user.cpf = unFormat(user.cpf);
    }
    if (user.address) {
        if (user.address.postalCode && user.address.postalCode.toString().length === 9) {
            user.address.postalCode = user.address.postalCode.toString().replace('-', '');
        }
    }
    return user;
}

export const findUserRequest = async (param: any, data: any) => {
    const response: any = await api.get(`/api/users/${param}`, data);
    return new Promise((resolve: any) => {
        resolve(
            {...response,
                data: {...response.data, birthdate:
                    Moment(response.data.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            }
        );
    });
};

export const findSellerName = async (param: any) => {
    return api.get(`api/users/seller/${param}`);
};

export const findUsersBySupervisorRequest = (param: any, data: any) => {
    return api.get(`/api/users`, {params: {...data, supervisorId: param}});
};

export const findUsersRequest = (param: any, data: any) => {
    return api.get(`/api/users`, {params: data});
};

export const findAllUsersRequest = () => {
    return api.get(`/api/users-all`);
};

export const findSupervisorsRequest = () => {
    return api.get(`/api/supervisors`);
};

export const findSellersRequest = () => {
    return api.get(`/api/sellers`);
};

export const registerUserRequest = (param: any, data: any) => {
    const userUnformated = unformatUser(data);
    return api.post(`/api/register/v2`, userUnformated);
};

export const createUserRequest = (param: any, data: any) => {
    const userUnformated = unformatUser(data);
    return api.post(`/api/users`, userUnformated);
};

export const updateUserRequest = (param: any, data: any) => {
    const userUnformated = unformatUser(data);
    return api.put(`/api/users`, userUnformated);
};

export const completeDataUserRequest = (param: any, data: any) => {
    const userUnformated = unformatUser(data);
    return api.put(`/api/users/complete`, userUnformated);
};

export const deleteUserRequest = (param: any, data: any) => {
    return api.delete(`/api/users`, data);
};
