import React from "react";
import {Btn, Col, Divider, Row} from "../../../common";
import {Colors, Metrics} from "../../../config";
import {TitledContainer} from "../../../components";
import {Formik} from "formik";
import ValidationSchema from "./ValidationSchema";
import {Form} from "antd";
import FormUploader from "../../../common/form/FormUploader";
import useDownload from "../../../hooks/useDownload";
import {generateLotteryIntegrationFileRequest, uploadLotteryIntegrationFileRequest} from "../../../services/lottery";
import useFetch from "../../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../../configs/toast";

const styles = {
    btn: {background: Colors.primaryDark, marginTop: Metrics.spacingMD, width: '250px'},
    btnRow: {
    },
    text: {
        color: Colors.text,
        fontSize: Metrics.fontSize.xxsm,
        textAlign: 'justify' as const,
        marginBlock: Metrics.spacingMD
    },
};

export const LotteryIntegration = () => {
    const [{isFetching: isFetchingUpload}, upload] = useFetch(
        {
            provider: uploadLotteryIntegrationFileRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: () => {
                    showSuccessToast('Arquivo carregado com sucesso!')
                },
                error: (d: any) => showErrorToast(d)
            }
        }
    );
    const { isFetching, fetch } = useDownload(
        generateLotteryIntegrationFileRequest(),
        "",
        false,
        'report',
        () => {}
    );
    return (
        <TitledContainer title={'Integração Loteria'}>
            <Col style={{ marginBlock: Metrics.spacingMD }}>
                <Col style={{borderRadius: '8px', marginBlock: Metrics.spacingMD, padding: Metrics.spacingMD, boxShadow: '1px 1px 15px #e0e0e0'}}>
                    <Formik
                        enableReinitialize
                        initialValues={{file: null}}
                        onSubmit={upload}
                        validationSchema={ValidationSchema}>
                        {({handleSubmit, values}) => (
                            <Form>
                                <p style={styles.text}>Faça o upload do arquivo de integração recebido pela seguradora.</p>
                                <FormUploader success={false}
                                              file={true} fieldName="file" contentTypeField="imageContentType"/>
                              <Row style={styles.btnRow}>
                                <Btn
                                    onClick={() => {
                                        if (values.file) {
                                            handleSubmit();
                                        } else {
                                            showErrorToast('Favor selecionar um arquivo para carregar.');
                                        }

                                    }} style={styles.btn} type='primary'>Enviar</Btn>
                              </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
                <Divider />
                <Col style={{borderRadius: '8px', marginBlock: Metrics.spacingMD, padding: Metrics.spacingMD, boxShadow: '1px 1px 15px #e0e0e0'}}>
                    <p style={styles.text}>Faça o download do arquivo de integração que será enviado para a seguradora.</p>
                    <Btn
                        loading={isFetching}
                        disabled={isFetching}
                        onClick={() => fetch()}
                        type='primary'
                        style={styles.btn}>
                        Download
                    </Btn>
                </Col>
            </Col>
        </TitledContainer>
    );
};
