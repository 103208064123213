import React from 'react';
import {Col} from 'antd';
import {useHistory} from 'react-router';
import UserForm from '../form';
import useFetch from "../../../../hooks/useFetch";
import {createUserRequest} from "../../../../services/user";
import {showErrorToast, showSuccessToast} from "../../../../../configs/toast";
import {TitledContainer} from "../../../../components";
import InstallmentForm from "../form";
import {createInstallmentRequest} from "../../../../services/installment";

const initialValues = {
    value: '',
    taxAdm: '',
}

export const InstallmentCreate = () => {
  const {goBack} = useHistory();
  const [{isFetching}, create,] = useFetch(
    {
      provider: createInstallmentRequest,
      param: '',
      requestOnMount: false,
      initialData: null,
      resultHandler: {
        success: () => {
          showSuccessToast('Registro criado com sucesso!')
          goBack();
        },
        error: (d: any) => showErrorToast(d)
      }
    }
  );

  return (
      <TitledContainer title={'Parcelamentos'}>
          <InstallmentForm goBack={goBack}
            isFetching={isFetching}
            onSubmit={create}
            initialValues={initialValues}
          />
      </TitledContainer>
  );
}
