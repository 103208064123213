import { useEffect, useReducer, useCallback } from "react";
import api from "../services/api";
import {showErrorToast} from "../../configs/toast";

const INITIAL_STATE = {
  data: undefined,
  isFetching: false,
};

const reducer = (_: any, action: any) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {data: undefined, isFetching: true};
    case "FETCH_SUCCESS":
      return {data: action.data, isFetching: false};
    default:
      throw new Error();
  }
};

export default function useDownload(url: any, initialData: any, fetchOnMount = true, prefileName: any, success: any) {
  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
  });

  const fetch = useCallback((par?: any) => {
      dispatch({type: "FETCH_INIT"});
      const param = par;
      if (param && param.undefined) {
        delete param.undefined;
      }
      api.get(
        url,
        {
          responseType: 'blob',
          params: param
        }).then(response => {
          const tempUrl = window.URL.createObjectURL(
              new Blob([response.data], {type: response.data.type})
          );
          const link = document.createElement('a');
          link.href = tempUrl;
          const contentDisposition = response.headers['content-disposition'];
          const filename = contentDisposition.substring(contentDisposition.indexOf('=') + 1);
          link.setAttribute('download', `${prefileName || filename}.xlsx`);
          link.click();
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: {
              data: initialData,
            },
          });
          if (success) {
            success(response);
          }
    }).catch(err => {
      // showErrorToast(err.statusText);
      console.log(err);
    });
    },
    [url]
  );

  useEffect(() => {
    if (fetchOnMount) {
      fetch();
    }
  }, [fetchOnMount, fetch]);

  return {isFetching: state.isFetching, data: state.data, fetch};
}
