import React from 'react';
import './styles.css';

import styled from 'styled-components';

const Container = styled.div`
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(204, 204, 204);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: white;
  }
  .ant-layout-sider-trigger {
    background-color: white;
    color: #000;
    border-top: 1px solid #00000029;
  }
`;

export default ({
  onClick,
  children,
  responsive,
  fullWidthResponsive,
  reverse,
  style,
  ...rest
}: any) => (
  <Container
    onClick={onClick}
    className={`${responsive ? 'col-responsive' : ''} ${
      reverse ? 'col-reverse-common' : 'col-common'
    } ${fullWidthResponsive ? 'full-width-responsive' : ''}
     ${onClick ? 'hoverable' : ''}`}
    style={{ ...style }}
    {...rest}>
    {children}
  </Container>
);
