import React from 'react';
import {useHistory} from 'react-router';
import useFetch from "../../../../hooks/useFetch";
import {createUserRequest} from "../../../../services/user";
import {showErrorToast, showSuccessToast} from "../../../../../configs/toast";
import {TitledContainer, UserForm} from "../../../../components";

const initialValues = {
    name: '',
    cpf: '',
    rg: '',
    email: '',
    birthdate: '',
    gender: '',
    phone: '',
    supervisorId: null,
    workgroupId: null,
    address: {
        postalCode: '',
        street: '',
        state: '',
        city: '',
        district: '',
        complement: '',
        number: '',
    },
    authorities: []
}

export const UserCreate = () => {
  const {goBack} = useHistory();
  const [{isFetching}, create,] = useFetch(
    {
      provider: createUserRequest,
      param: '',
      requestOnMount: false,
      initialData: null,
      resultHandler: {
        success: () => {
          showSuccessToast('Registro criado com sucesso!')
          goBack();
        },
        error: (d: any) => showErrorToast(d)
      }
    }
  );

  return (
      <TitledContainer title={'Usuários'}>
          <UserForm goBack={goBack}
            isFetching={isFetching}
            onSubmit={create}
            initialValues={initialValues}
          />
      </TitledContainer>
  );
}
