import React, {useState} from "react";
import {Empty, Modal} from "antd";

import moment from "moment";
import {Colors, Metrics} from "../../config";
import useBreakpoint from "../../hooks/useBreakpoint";
import useFetch from "../../hooks/useFetch";
import {downloadBankslipRequest, findUserDreamsRequest} from "../../services/dream";
import {Col, Row} from "../../common";
import Filters from "../../../configs/Filters";
import {TitledContainer, WithdrawRequestForm} from "../../components";
import compraProgramadaImg from 'assets/images/iconCompraProgramada.svg'
import cofreImg from 'assets/images/cofre.svg'
import {DreamDetail} from "./detail";
import {showErrorToast} from "../../../configs/toast";

const DEAL_STATUS_TYPES: any = {
    CURRENT: 'CURRENT',
    COMPLETE: 'COMPLETE',
    SUSPENDED: 'SUSPENDED',
    CANCELED: 'CANCELED'
}


const PAYMENT_METHODS: any = {
    BANK_SLIP: 'Boleto',
    CREDIT_CARD: 'Cartão de crédito',
    DEBIT_CARD: 'Cartão de débito'
}
const DEAL_STATUS: any = {
    CURRENT: 'Vigente',
    COMPLETE: 'Finalizado/Completo',
    SUSPENDED: 'Suspenso',
    CANCELED: 'Cancelado'
}

const DEAL_STATUS_COLOR: any = {
    CURRENT: Colors.primary,
    COMPLETE: 'rgb(109, 189, 121)',
    CANCELED: '#ec0202',
    SUSPENDED: '#8a8a8a'
}

const styles = {
    title: {color: Colors.primary},
    detailLabel: {
        cursor: 'pointer',
        fontSize: Metrics.fontSize.sm,
        color: Colors.secondary
    },
    withdrawLabel: {
        fontSize: Metrics.fontSize.xxxsm,
        color: Colors.text,
        cursor: 'pointer',
        marginTop: Metrics.spacingSM
    },
    fieldName: {color: 'rgb(133 133 133)', marginRight: Metrics.spacingSM},
    labels: {
        width: '100px',
        fontSize: Metrics.fontSize.xxxsm,
        color: Colors.text
    },
    remaining: {
        fontSize: Metrics.fontSize.xxsm,
        color: 'red'
    },
    paid: {
        fontSize: Metrics.fontSize.xxsm,
        color: 'rgb(109, 189, 121)'
    },
    values: {
        fontSize: Metrics.fontSize.xxsm
    },
    labelValBlock: {
        marginBlock: Metrics.spacingSM
    }
}

const LeftPig = ({totalPaid, totalAgreement}: any) => {
    return (
        <Col style={{position: 'relative', width: '90px'}}>
            <img src={cofreImg} style={{width: '90px'}} />
            <div
                style={{
                    width: '100%',
                    zIndex: 1,
                    height: `${100 - totalPaid*100/totalAgreement}%`,
                    position: 'absolute',
                    backgroundColor: 'white',
                    opacity: 0.5
                }} />
            <Col
                style={{
                    width: '100%',
                    height: '100%',
                    zIndex: 2,
                    left: 0,
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: Colors.white,
                    fontSize: Metrics.fontSize.md,
                    fontFamily: 'montserrat-bold',
                    textShadow: '1px 1px 5px black'
                }}>
                <span>
                    <b>
                        {(Math.round((totalPaid*100/totalAgreement) * 100) / 100).toFixed(1)}%
                    </b>
                </span>
            </Col>
        </Col>
    );
}

export const MyDream = ({detail, setDetail}: any) => {
    const {isMobile} = useBreakpoint();
    const [isDetailVisible, setIsDetailVisible] = useState();
    const [isWithdrawVisible, setIsWithdrawVisible] = useState<any>();
    const [{ isFetching: isFetchingDownload }, download] = useFetch(
        {
            provider: downloadBankslipRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: (res: any) => {
                    const link = document.createElement('a');
                    link.href = res.data._links.payBoleto.printHref;
                    link.target = '_blank';
                    link.click();
                }
            }
        });
    const [{ isFetching, data }] = useFetch(
        {
            provider: findUserDreamsRequest,
            param: detail,
            requestOnMount: true,
            initialData: [],
            resultHandler: null
        });

    return (
        <TitledContainer title={'Meus sonhos'}>
            <Col style={{width: '100%', alignItems: 'center', marginTop: Metrics.spacingLG}}>
                {
                    data?.length === 0 &&
                    <Empty />
                }
                {data.map((d: any) => (
                    <Row responsive
                         style={{
                             justifyContent: 'space-evenly',
                             boxShadow: '1px 1px 15px #e0e0e0',
                             marginBlock: Metrics.spacingMD,
                             borderRadius: '10px',
                             width: '85%',
                             height: isMobile ? 'unset' : '220px',
                             opacity: d.status === DEAL_STATUS_TYPES.SUSPENDED ? 0.3 : 1,
                             position: 'relative'
                         }}
                    >
                        <Row
                            style={{
                                justifyContent: 'space-around',
                            }}>
                            <Col style={{justifyContent: 'space-around', marginInline: Metrics.spacingLG}}>
                                <Col style={styles.labelValBlock}>
                                    <img src={compraProgramadaImg} style={{width: isMobile ? '45px' : '90px'}} />
                                    <span style={styles.labels}>{d.procedure.name}</span>
                                </Col>
                                <Col style={styles.labelValBlock}>
                                    <span style={styles.values}>{d.number}</span>
                                    <span style={styles.labels}>Número do acordo</span>
                                </Col>
                            </Col>

                            <Col style={{justifyContent: 'space-around', marginInline: Metrics.spacingLG}}>
                                <Col style={styles.labelValBlock}>
                                    <span style={{...styles.values, color: DEAL_STATUS_COLOR[d.status]}}>
                                        {DEAL_STATUS[d.status]}
                                    </span>
                                    <span style={styles.labels}>Situação do acordo</span>
                                </Col>
                                <Col style={styles.labelValBlock}>
                                    <span style={styles.values}>{d.luckyNumber || '-'}</span>
                                    <span style={styles.labels}>Número da sorte</span>
                                </Col>
                                <Col style={styles.labelValBlock}>
                                    <span style={styles.values}>{PAYMENT_METHODS[d.paymentMethod]}</span>
                                    <span style={styles.labels}>Método de pagamento</span>
                                </Col>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                justifyContent: 'space-around',
                            }}>
                            <Col style={{justifyContent: 'space-around', marginInline: Metrics.spacingLG}}>
                                <Col style={styles.labelValBlock}>
                                    <span style={styles.values}>{moment(d?.agreementDt).format('DD/MM/YYYY')}</span>
                                    <span style={styles.labels}>Data do acordo</span>
                                </Col>
                                <Col style={styles.labelValBlock}>
                                    <span style={styles.values}>{moment(d?.firstPaymentDt).format('DD/MM/YYYY')}</span>
                                    <span style={styles.labels}>1º pagamento</span>
                                </Col>
                                <Col style={styles.labelValBlock}>
                                    <span style={styles.values}>{`Dia ${d?.dueDay}`}</span>
                                    <span style={styles.labels}>Vencimento</span>
                                </Col>
                            </Col>
                            <Col style={{justifyContent: 'space-around', marginInline: Metrics.spacingLG}}>
                                <Col style={styles.labelValBlock}>
                                    <span style={styles.values}>{Filters.convertMoneyTextMask(d?.totalAgreement)}</span>
                                    <span style={styles.labels}>Total do acordo</span>
                                </Col>
                                <Col style={styles.labelValBlock}>
                                    <span style={styles.paid}>{Filters.convertMoneyTextMask(d?.totalPaid)}</span>
                                    <span style={styles.labels}>Total pago</span>
                                </Col>
                                <Col style={styles.labelValBlock}>
                                    <span style={styles.remaining}>{Filters.convertMoneyTextMask(d?.totalRemaining)}</span>
                                    <span style={styles.labels}>Total restante</span>
                                </Col>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                justifyContent: 'space-around',
                            }}>
                            <Col style={{justifyContent: 'space-around'}}>
                                <Col>
                                    <LeftPig totalAgreement={d?.totalAgreement} totalPaid={d?.totalPaid} />
                                    <span onClick={() => {
                                        if (d?.status === DEAL_STATUS_TYPES.CURRENT || d?.status === DEAL_STATUS_TYPES.COMPLETE) {
                                            setIsWithdrawVisible(d.id);
                                        } else {
                                            showErrorToast("Não é possível solicitar resgate de contratos cancelados ou suspensos.");
                                        }
                                    }} style={styles.withdrawLabel}>Solicitar resgate</span>
                                </Col>
                                <Col>
                                    <span onClick={() => setIsDetailVisible(d)} style={styles.detailLabel}>Detalhes</span>
                                </Col>
                            </Col>
                        </Row>
                    </Row>
                ))}
                <DreamDetail detail={isDetailVisible} setDetail={setIsDetailVisible} />
            </Col>
            {
                isWithdrawVisible &&
                <Modal open={isWithdrawVisible} footer={null} closable onCancel={() => setIsWithdrawVisible(null)}>
                    <p style={styles.title}>Solicitação de resgate</p>
                    <WithdrawRequestForm userProcedureId={isWithdrawVisible} setModalVisible={setIsWithdrawVisible} />
                </Modal>
            }
        </TitledContainer>
    );
};
