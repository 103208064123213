import React, {useCallback, useRef, useState} from 'react';
import {
    DrawerComponent,
    DrawerContainer,
    GridContainerHello,
    GridContainerItems,
    HelloTextSubTitle,
    HelloTextTitle,
    Image,
    ImageContainer,
    ResponsiveMenuButton,
    ResponsiveMenuButtonContainer
} from './styles';
import { FaTimes } from 'react-icons/fa';
import ActionIconsDrawer from '../ActionIconsDrawer';
import logo from 'assets/images/logo-transparent.png'
import {useHistory, useLocation} from 'react-router';
import {AUTHORITIES} from '../../../authorities.constants';
import {Button} from "antd";
import {Colors, Metrics} from "../../config";
import {Btn, Col} from "../../common";
import {useAuthentication} from "../../context/AuthContext";

const styles = {
    closeBtn: {
        border: 'none',
        background: 'transparent',
        color: '#fff',
        position: 'absolute',
        left: 0,
        minWidth: '30px'
    }
}
const SistemaDrawer = (props: any) => {
    const { user, hasAnyAuthority } = useAuthentication();
    const { pathname } = useLocation();
    const { push } = useHistory();
    const incompleteUser = false;
    const ref = useRef();
    const [menuVisible, setMenuVisible] = useState(false);
    const itensMenu = MenuItems({path: '/sistema'}, incompleteUser);

    const validateActiveItem = useCallback((item) => {
        if (item.exact) {
            return pathname === item.route;
        } else {
            return pathname.includes(item.route);
        }
    }, [pathname]);


    const activateMenu = useCallback( () => {
        setMenuVisible(!menuVisible);
    }, [setMenuVisible, menuVisible] );

    const getFirstName = (name: string | undefined) => {
        return name ? name.split(' ')[0] : ' ';
    };

    return (
        <>
            <ResponsiveMenuButtonContainer>
                {
                    menuVisible ?
                        <Btn style={styles.closeBtn} onClick={() => setMenuVisible(false)}><FaTimes /></Btn> :
                        <ResponsiveMenuButton onClick={activateMenu}/>
                }
            </ResponsiveMenuButtonContainer>
            <DrawerComponent ref={ref} menuVisible={menuVisible}>
                <ActionIconsDrawer/>
                <DrawerContainer>
                    <GridContainerHello>
                        <div style={{display: 'grid', height: 'fit-content'}}>
                            <HelloTextTitle>{`Olá,`}</HelloTextTitle>
                            {user && <HelloTextSubTitle>{getFirstName(user.name)}</HelloTextSubTitle>}
                        </div>
                    </GridContainerHello>
                    <GridContainerItems>
                        <Col>
                            {itensMenu.map((item, key) => {
                                return item.children.some(c => hasAnyAuthority(c.hasAnyAuthorities || [])) &&
                                    <div key={key} style={{marginTop: Metrics.spacingLG}}>
                                    <p
                                        style={{
                                            color: Colors.primaryDark,
                                            marginBlock: Metrics.spacingMD,
                                        }}>
                                            {item.title}
                                    </p>
                                        {
                                            item.children.map((c, i) => {
                                                return hasAnyAuthority(c.hasAnyAuthorities || []) &&
                                                    <Button
                                                        onClick={() => {
                                                            setMenuVisible(false);
                                                            push(c.route);
                                                        }}
                                                        key={i}
                                                        style={{
                                                            textAlign: 'left',
                                                            backgroundColor: validateActiveItem(c) ? Colors.primaryDark : 'transparent',
                                                            color: Colors.white,
                                                            fontSize: Metrics.fontSize.xsm,
                                                            border: 'none',
                                                            fontFamily: validateActiveItem(c) ? 'montserrat-semibold' : 'montserrat-regular',
                                                            width: '230px',
                                                            marginBlock: Metrics.spacingMinimun,
                                                            transition: 'all 0.6s ease'
                                                        }}
                                                    >
                                                        {c.label}
                                                    </Button>
                                            })}
                                    </div>
                            })}
                        </Col>
                        <ImageContainer>
                            <Image src={logo}/>
                        </ImageContainer>
                    </GridContainerItems>
                </DrawerContainer>
            </DrawerComponent>
        </>
    )
};

const MenuItems = (match: any, incompleteUser: boolean) => {
    return (incompleteUser ? [] : [
        {
            title: '',
            children: [
                {label: 'Meus Sonhos', active: false, route: `${match.path}/dreams`, hasAnyAuthorities: [AUTHORITIES.USER]},
                {label: 'Serviços', active: true, route: `${match.path}/services`, hasAnyAuthorities: [AUTHORITIES.USER]},
                {label: 'Número da Sorte', active: false, route: `${match.path}/page-lucky-number`, hasAnyAuthorities: [AUTHORITIES.USER]},
            ],
        },
        {
            title: 'Backoffice',
            children: [
                {label: 'Realizar venda', active: false, route: `${match.path}/perform-sell`, hasAnyAuthorities: [AUTHORITIES.ADMIN, AUTHORITIES.SELLER, AUTHORITIES.SUPERVISOR]},
                {label: 'Vendas', active: false, route: `${match.path}/sell`, hasAnyAuthorities: [AUTHORITIES.SELLER], exact: true},
                {label: 'Visão supervisor', active: false, route: `${match.path}/supervisor`, hasAnyAuthorities: [AUTHORITIES.SUPERVISOR]},
                {label: 'Vendedores', active: false, route: `${match.path}/sellers`, hasAnyAuthorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERVISOR], exact: true},
                {label: 'Relatorios', active: false, route: `${match.path}/reports`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Integração Loteria', active: false, route: `${match.path}/lottery-integration`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Empresa', active: false, route: `${match.path}/company`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Grupos', active: false, route: `${match.path}/workgroups`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Comissão', active: false, route: `${match.path}/commissions`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Parcelamentos', active: false, route: `${match.path}/installments`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Cupons de Desconto', active: false, route: `${match.path}/coupons`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Parâmetros', active: false, route: `${match.path}/parameters`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Procedimentos', active: false, route: `${match.path}/procedures`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Profissionais', active: false, route: `${match.path}/professionals`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Parceiros', active: false, route: `${match.path}/influencers`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Notícias', active: false, route: `${match.path}/news`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Depoimentos', active: false, route: `${match.path}/testimonials`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Usuários', active: false, route: `${match.path}/users`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
                {label: 'Usuários Lileve', active: false, route: `${match.path}/lileve-users`, hasAnyAuthorities: [AUTHORITIES.ADMIN]},
            ]
        },
    ])
};

export default SistemaDrawer;
