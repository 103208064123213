import React from 'react';
import {Metrics} from "../../config";
import {Button} from "antd";

const stylesInternal = {
    btn: {
        marginInline: Metrics.spacingMD,
        marginBlock: Metrics.spacingMD,
        minWidth: '230px',
        fontFamily: 'montserrat-medium'
    }
}

const Btn = ({children, style, ...rest}: any) => {
    return (
    <Button
        {...rest}
        style={{...stylesInternal.btn, ...style}}>
        {children}
    </Button>
    )
}

export default Btn;
