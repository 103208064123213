import React from "react";
import {useHistory, useParams} from "react-router";
import TestimonialForm from "../form";
import useFetch from "../../../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../../../configs/toast";
import {TitledContainer} from "../../../../components";
import {findTestimonialRequest, updateTestimonialRequest} from "../../../../services/testimonial";

export const TestimonialEdit = () => {
    const { goBack } = useHistory();
    const {id}: any = useParams();
    const [{ isFetching, data }] = useFetch(
        {
            provider: findTestimonialRequest,
            param: id,
            requestOnMount: true,
            initialData: null,
            resultHandler: null
        });
    const [{ isFetching: isFetchingUpdate }, update] = useFetch(
        {
            provider: updateTestimonialRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
              success: () => {
                showSuccessToast('Registro atualizado com sucesso!');
                goBack();
              },
              error: (d: any) => showErrorToast(d)
            },
        }
    );
    return (
        <TitledContainer title={'Depoimentos'}>
            {data &&
                <TestimonialForm
                    isFetching={isFetching || isFetchingUpdate}
                    goBack={goBack}
                    onSubmit={update}
                    initialValues={data}
                />
            }
        </TitledContainer>
    );
}
