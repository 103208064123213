import React, {useCallback} from "react";
import {useFormikContext} from "formik";
import {Btn, FormInputText} from "../../../../common";
import {Colors, Metrics} from "../../../../config";
import {Button} from "antd";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    faqBlock: {
        border: `1px solid ${Colors.secondary}`,
        borderRadius: '12px',
        paddingInline: Metrics.spacingMD,
        paddingBottom: Metrics.spacingMD,
        margin: Metrics.spacingSM,
        position: 'relative' as const
    },
    floatingDeleteBtn: {
        backgroundColor: Colors.primary,
        border: 'none',
        color: Colors.white,
        position: 'absolute' as const,
        right: 10,
        top: 10,
        zIndex: 2
    }
};

const Faq = () => {
    const {values, setFieldValue}: any = useFormikContext();

    const handleAdd = useCallback(() => {
        setFieldValue('faqs', [
            ...(values.faqs ? values.faqs : []),
            {
                question: '',
                answer: '',
                id: Math.floor(Math.random() * 100001)
            }
        ])
    },[values]);

    const handleRemove = useCallback((id) => {
        setFieldValue('faqs', values.faqs.filter((f: any) => f.id !== id));
    },[values]);

    return (
        <div>
            {console.log(values)}
            {values?.faqs?.map( (f: any, i: any) => (
                <div key={f.id} style={styles.faqBlock}>
                    <Button
                        onClick={() => handleRemove(f.id)}
                        style={styles.floatingDeleteBtn}
                    >Remover</Button>
                    <FormInputText
                        label="Pergunta"
                        name={`faqs[${i}].question`}
                        value={values.faqs[i]?.question} />
                    <FormInputText
                        label="Resposta"
                        name={`faqs[${i}].answer`}
                        value={values.faqs[i]?.answer} />
                </div>
            ))}
            <Btn
                type='primary'
                onClick={handleAdd}
            >Adicionar nova pergunta/resposta</Btn>
        </div>

    );
}

export default Faq;
