import api from "./api";

export const findWorkgroupRequest = async (param: any, data: any) => {
    return api.get(`/api/workgroup/${param}`, data);
};

export const findWorkgroupsRequest = (param: any, data: any) => {
    return api.get(`/api/workgroup`, {params: data});
};

export const findAllWorkgroupsRequest = (param: any, data: any) => {
    return api.get(`/api/workgroup/all`, {params: data});
};

export const createWorkgroupRequest = (param: any, data: any) => {
    return api.post(`/api/workgroup`, data);
};

export const updateWorkgroupRequest = (param: any, data: any) => {
    return api.put(`/api/workgroup`, data);
};

export const deleteWorkgroupRequest = (param: any, data: any) => {
    return api.delete(`/api/workgroup/${data}`);
};
