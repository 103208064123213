/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useFormikContext } from 'formik';
import { FormItem, Select as Dropdown } from 'formik-antd';
// import Picker from '../Picker';

const FormDropdown = ({
  label,
  name,
  value,
  placeholder,
  required,
  multiple,
  allowClear = true,
  showSearch,
  filterOption,
  propertyLabel = 'label',
  propertyValue = 'value',
  list = [],
  disabled,
  showValidateSuccess,
  onChange: onChangeParam,
  formStyle,
  grouped = false,
}: any) => {
  const { getFieldMeta } = useFormikContext();
  const { error, touched } = getFieldMeta(name);

  const onChange = (newValue: any) => {
    if (!disabled && onChangeParam) {
      onChangeParam(newValue);
    }
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <FormItem
      style={{ width: '100%', ...formStyle }}
      name={name}
      required={required}
      showValidateSuccess={showValidateSuccess}>
      <label style={{ color: error && touched ? '#ff696b' : 'unset' }}>
        {required ? `${label} *` : label}
      </label>
      <Dropdown
        allowClear={allowClear}
        filterOption={filterOption}
        showSearch={showSearch}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        {...(multiple ? { mode: 'multiple' } : [])}>
        {grouped &&
          list.map((gv: any) => (
            <Dropdown.OptGroup key={gv.label} label={gv.label}>
              {gv.values.map((item: any) => (
                <Dropdown.Option
                  key={item[propertyValue]}
                  value={item[propertyValue]}>
                  {item[propertyLabel]}
                </Dropdown.Option>
              ))}
            </Dropdown.OptGroup>
          ))}
        {!grouped &&
          list.map((item: any, index: any) => (
            // FIXME: Verificar para utilizar outro valor como key do componente
            // eslint-disable-next-line react/no-array-index-key
            <Dropdown.Option key={index} value={item[propertyValue]} style={{textAlign: 'left'}}>
              {item[propertyLabel]}
            </Dropdown.Option>
          ))}
      </Dropdown>
    </FormItem>
  );
};

export default FormDropdown;
