import React, {useCallback, useEffect, useState} from 'react';
import {ActionIcons, Col, Row, Table} from "../../common";
import {Metrics} from "../../config";
import {Table as TableAntd} from "antd";
import useBreakpoint from "../../hooks/useBreakpoint";
import useFetchPag from "../../hooks/useFetchPag";
import {findDreamsRequest} from "../../services/dream";
import Filters from "../../../configs/Filters";
import moment from "moment";
import {DreamFilter} from "./filter";
import {DreamDetail} from "./detail";


const { Column } = TableAntd;

export const DreamContent = ({seller, supervisor}: any) => {
    const {isMobile} = useBreakpoint();
    const [filter, setFilter] = useState({active: true});
    const [detail, setDetail] = useState<any>();
    const [
        { isFetching, data, totalItems, currentPage: current, pageSize },
        fetch,
    ] = useFetchPag({
        provider: findDreamsRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
        paginate: true,
    });

    const handleFind = useCallback(
        (p: any) => {
            fetch({
                filter: {
                    ...filter,
                    sellerId: seller || null,
                    supervisorId: supervisor || null
                },
                page: { current: p?.current || 1, size: p?.pageSize || null }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [current, fetch, filter]
    );

    useEffect(() => handleFind(null),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);

    return (
        <Col>
            <Col style={{ marginBlock: Metrics.spacingMD }}>
                <DreamFilter filter={filter} setFilter={setFilter} />
                <Table
                    pagination={{
                        total: totalItems,
                        current,
                        pageSize,
                    }}
                    isFetching={isFetching}
                    rowKey="id"
                    handleFind={handleFind}
                    data={data}
                >
                    <Column title={'Nome'} dataIndex="name" key="name" />
                    <Column title={'Email'} dataIndex="email" key="email" />
                    {!isMobile && <Column title={'CPF/CNPJ'} dataIndex="cpf" key="cpf" render={(_: any, rowData: any) =>
                        <span>{rowData.cnpj ? Filters.inputMaskCNPJ(rowData.cnpj) : Filters.inputMaskCPF(rowData.cpf)}</span>} />}
                    <Column title={'Telefone'} dataIndex="phone" key="phone" render={(_: any, rowData: any) =>
                        <span>{Filters.inputMaskTELWithDDD(rowData.phone)}</span>} />
                    {!isMobile &&
                        <Column title={'Dt Cadastro'} dataIndex="createdDate" key="createdDate" render={(_: any, rowData: any) =>
                            <span>{moment(rowData.createdDate).format('DD/MM/YYYY')}</span>} />}
                    <Column
                        title={'Ação'}
                        dataIndex="action"
                        key="action"
                        render={(i, record: any) => (
                            <Row>
                                <ActionIcons
                                    type={"view"}
                                    actionFn={() => setDetail(record.login)}
                                />
                            </Row>
                        )}
                    />
                </Table>
            </Col>
            {detail && <DreamDetail detail={detail} setDetail={setDetail} seller={seller} supervisor={supervisor} />}
        </Col>
    );
};

export default DreamContent;
