import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Formik} from 'formik';
import {Btn, Col, Row} from "../../common";
import {Form} from "antd";
import {Colors, Metrics} from "../../config";
import ExtraInfo from "./ExtraInfo";
import FinancialInfo from "./FinancialInfo";
import ClientInfo from "./ClientInfo";
import Offers from "./Offers";
import Contract from "./Contract";
import useFetch from "../../hooks/useFetch";
import {getFinancingOffersRequest, updateInfoFinancingRequest} from "../../services/financing";
import OperationSummary from "./OperationSummary";
import Facematch from "./Facematch";
import {facematchRequest} from "../../services/facematch";

const styles = {
    btnSecondary: {
        marginInline: Metrics.spacingMD,
        minWidth: '250px'
    },
}

const FinancingForm = ({visible, setVisible}: any) => {
    const [step, setStep] = useState(0);
    const formikRef: any = useRef();
    const [{ isFetching: isFetchingOffers, data: offers }, fetchOffers] = useFetch(
        {
            provider: getFinancingOffersRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: null
        });
    const [{ isFetching: isFetchingUpdateInfo, data: updatedInfo }, updateInfo] = useFetch(
        {
            provider: updateInfoFinancingRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: null
        });
    const [{ isFetching: bbb, data: aaa }, validateSelfie] = useFetch(
        {
            provider: facematchRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: null
        });

    const handleReset = useCallback(() => {
        formikRef?.current?.resetForm();
    },[formikRef]);

    const handleStepDecider = useCallback(() => {
        if (step === 1 || step === 3 || step === 5) {
            formikRef?.current?.handleSubmit();
        }
        if (step < 4) {
            setStep(s => s+1);
        }
    },[step]);

    const handleSubmitFunction = useMemo(() => {
        if (step === 2) {
            return fetchOffers;
            // return () => {};
        }
        if (step === 5) {
            return validateSelfie;
        }
        return updateInfo;
        // return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);


    return (
        <Col>
            <Formik
                innerRef={formikRef}
                initialValues={
                    {
                        person:
                            {
                                name: 'Carlos Olivera',
                                cpfStr: '060.104.109-75',
                                dateOfBirth: '20/01/1990',
                                phoneStr: '48991456901',
                                email: 'carloseduardoolivera@gmail.com',
                            },
                        request: {
                            amount: 10000,
                            annotated: false,
                            employmentStatus: 2,
                            income: 20000,
                            reason: 1,
                        },
                        employmentInfo: {
                            occupationGroup: 1,
                        },
                        extraPersonalInfo: {
                            idNumber: '1234',
                            motherName: 'Claisse',
                            nationality: 55,
                            maritalStatus: 0,
                            educationLevel: 1,
                        },
                        homeInfo: {
                            city: 'Florianópolis',
                            neighborhood: 'Ingleses',
                            street: 'Rua dos Ingleses',
                            number: '123',
                            state: 'SC',
                            zipCode: '88000-000',
                        },
                        facematch: {}
                    }
                }
                enableReinitialize
                onSubmit={handleSubmitFunction}
            >
                {({values}: any) => (
                    <Form>
                       <Row style={{justifyContent: 'center'}}>
                            <span style={{textAlign: 'center', fontFamily: 'montserrat-bold', color: Colors.primary, fontSize: Metrics.fontSize.sm}}>
                                Realize uma simulação do financiamento para realizar seus sonhos!
                            </span>
                       </Row>
                        <Col style={{alignItems: "center", padding: Metrics.spacingMD}}>
                            { step === 0 &&
                                <ClientInfo />
                            }
                            {step === 1 &&
                                <FinancialInfo />
                            }
                            {step === 2 &&
                                <Offers isFetching={isFetchingOffers} data={offers} />
                            }
                            {step === 3 &&
                                <ExtraInfo />
                            }
                            {step === 4 &&
                                <OperationSummary />
                            }
                            {/*{step === 5 &&*/}
                            {/*    <Facematch />*/}
                            {/*}*/}
                            {step === 5 &&
                                <Contract />
                            }
                            <Row style={{marginTop: Metrics.spacingLG}}>
                                <Btn
                                    disabled={step === 0}
                                    onClick={() => setStep(s => s-1)}>
                                    Voltar
                                </Btn>
                                <Btn
                                    disabled={!values.agreed && step === 1}
                                    onClick={handleStepDecider}
                                    type='primary'>
                                    Avançar
                                </Btn>
                            </Row>
                            {/*<Col>*/}
                            {/*    <Btn*/}
                            {/*        onClick={handleSubmit}*/}
                            {/*        type='primary'>*/}
                            {/*        Enviar*/}
                            {/*    </Btn>*/}
                            {/*    <Btn*/}
                            {/*        onClick={() => setVisible(false)}*/}
                            {/*        style={styles.btnSecondary}>*/}
                            {/*        Cancelar*/}
                            {/*    </Btn>*/}
                            {/*</Col>*/}
                        </Col>
                    </Form>
                )}
            </Formik>
        </Col>
    );
};

export default FinancingForm;
