import api from "./api";

export const findAllCouponsRequest = async () => {
    return api.get(`/api/coupon/all`);
};

export const findCouponRequest = async (param: any, data: any) => {
    return api.get(`/api/coupon/byid/${param}`, data);
};

export const findCouponsRequest = (param: any, data: any) => {
    return api.get(`/api/coupon`, {params: data});
};

export const createCouponRequest = (param: any, data: any) => {
    return api.post(`/api/coupon`, data);
};

export const updateCouponRequest = (param: any, data: any) => {
    return api.put(`/api/coupon`, data);
};

export const deleteCouponRequest = (param: any, data: any) => {
    return api.delete(`/api/coupon/${data}`);
};




