import React from "react";
import {Col} from "../../common";
import {Colors, Metrics} from "../../config";
import {RegistrationForm} from "../../components";
import useFetch from "../../hooks/useFetch";
import {completeDataUserRequest} from "../../services/user";
import {showSuccessToast} from "../../../configs/toast";
import {useHistory} from "react-router";
import {useAuthentication} from "../../context/AuthContext";
import moment from "moment";

const styles = {
    container: {alignItems: 'center', padding: `75px ${Metrics.spacingXLG}`},
    title: {
        color: Colors.primary,
    },
    img: {width: '90px'},
}

const CompleteData = () => {
    const { push } = useHistory();
    const { user, refreshAccount } = useAuthentication();
    const [{isFetching}, create,] = useFetch(
    {
        provider: completeDataUserRequest,
        param: '',
        requestOnMount: false,
        initialData: null,
        resultHandler: {
            success: () => {
                refreshAccount();
                showSuccessToast('Seus dados foram atualizados com sucesso.')
            },
        }
        }
    );
    return (
        <Col style={styles.container}>
            <h2 style={styles.title}>Por favor, complete seu cadastro para continuar</h2>
            <RegistrationForm initialValues={{
                ...user,
                cpf: '',
                birthdate: moment(user.birthdate)
                    .format('DD/MM/YYYY')
            }} onSubmit={create} isFetching={isFetching} />
        </Col>
    );
};

export default CompleteData;
