import React, {useCallback, useEffect, useState} from "react";
import {Table as TableAntd} from "antd";
import {Col, Table} from "../../../common";
import {Metrics} from "../../../config";
import {TitledContainer} from "../../../components";
import useBreakpoint from "../../../hooks/useBreakpoint";
import Filters from "../../../../configs/Filters";
import {findLileveUsersRequest} from "../../../services/lileveUser";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import {LileveUserFilter} from "./filter";

const { Column } = TableAntd;

export const LileveUser = () => {
    const [filter, setFilter] = useState({});
    const {isMobile} = useBreakpoint();
    const [
    { isFetching, data: response },
    fetch,
    ] = useFetch({
        provider: findLileveUsersRequest,
        param: "",
        requestOnMount: true,
        initialData: []
    });

    const handleFind = useCallback(
    () => {
        fetch({...filter});
    },
    [fetch, filter]
    );

    useEffect(() => handleFind(), [filter]);

    return (
      <TitledContainer title={'Usuários Lileve'}>
        <Col style={{ marginBlock: Metrics.spacingMD }}>
            <LileveUserFilter filter={filter} setFilter={setFilter} isFetching={isFetching} />
            <Table
                pagination={{
                    pageSize: 10,
                }}
                isFetching={isFetching}
                rowKey="id"
                handleFind={handleFind}
                data={response?.data || []}>
                    <Column title={'Login'} dataIndex="username" key="username" />
                    <Column title={'Nome'} dataIndex="name" key="name" />
                    <Column title={'Email'} dataIndex="email" key="email" />
                    <Column title={'Telefone'} dataIndex="phone" key="phone" render={(_: any, rowData: any) => <span>{Filters.inputMaskTELWithDDD(rowData.phone)}</span>} />
                    <Column title={'Código Parceiro'} dataIndex="promotional_code" key="promotional_code" />
                    <Column title={'Cidade'} dataIndex="city" key="city" />
                    <Column title={'Estado'} dataIndex="state" key="state" />
                    {!isMobile && <Column title={'Dt Nascimento'} dataIndex="created_at" key="created_at" render={(_: any, rowData: any) =>
                    <span>{rowData.created_at ? moment(rowData.created_at).format('DD/MM/yyyy') : '-'}</span>} /> }
            </Table>
        </Col>
    </TitledContainer>
  );
};
