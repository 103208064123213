import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {accountRequest, authenticateRequest} from "../../services/auth";
import {Storage} from "../../util";
import {showErrorToast} from "../../../configs/toast";
import api from "../../services/api";

export default function useAuth() {
  const { push } = useHistory();
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [authenticated, setAuthenticated] = useState(null);
  const [acceptedLgpd, setAcceptedLgpd] = useState(null);
  const [user, setUser] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(async () => {
    async function checkLgpd() {
      setAcceptedLgpd(Storage.getLgpd());
    }

    async function retrieveToken() {
      setIsFetching(true);
      try {
        const { data: userAccountResponse } = await accountRequest();
        setUser(userAccountResponse);
        setAuthenticated(true);
        setIsFetching(false);
      } catch (e) {
        setAuthenticated(false);
        setUser(null);
        setIsFetching(false);
      }
    }
    await retrieveToken();
    await checkLgpd();
  }, []);

  const hasAnyAuthority = useCallback( (authorities) => {
    if (user?.authorities && user?.authorities?.length !== 0) {
      if (authorities.length === 0) {
        return true;
      }
      return authorities.some(auth => user?.authorities?.includes(auth));
    }
    return false;
  }, [user]);

  async function handleLogin({ username, password }) {
    setIsFetching(true);
    try {
      const { data } = await authenticateRequest({
        username,
        password,
      });
      if (data.id_token) {
        await Storage.setToken(data.id_token);
        setAuthenticated(true);
      }
      const { data: userAccountResponse } = await accountRequest();
      setUser(userAccountResponse);
      setIsLoginVisible(false);
      push('/sistema');
      setIsFetching(false);
    } catch(e) {
      setIsFetching(false);
      showErrorToast(e?.data?.detail)
    }
  }

  async function handleLoginGoogle(googleResponse) {
    setIsFetching(true);
    try {
      const { data } = await api.post('api/authenticate/google', {accessToken: googleResponse['tokenId']});
      if (data.id_token) {
        await Storage.setToken(data.id_token);
        setAuthenticated(true);
      }
      const { data: userAccountResponse } = await accountRequest();
      setUser(userAccountResponse);
      setIsLoginVisible(false);
      push('/sistema');
      setIsFetching(false);
    } catch(e) {
      setIsFetching(false);
      showErrorToast(e?.data?.detail)
    }
  }

  async function refreshAccount() {
    setIsFetching(true);
    try {
      const { data: userAccountResponse } = await accountRequest();
      setUser(userAccountResponse);
      push('/sistema');
      setIsFetching(false);
    } catch(e) {
      setIsFetching(false);
      showErrorToast(e?.data?.detail)
    }
  }

  async function handleLogout() {
    setAuthenticated(false);
    setUser(null);
    await Storage.clearToken();
  }

  async function handleAcceptLgpd() {
    setAcceptedLgpd(true);
    await Storage.acceptLgpd();
  }

  return {
    authenticated,
    isFetching,
    user,
    isLoginVisible,
    setIsLoginVisible,
    acceptedLgpd,
    handleAcceptLgpd,
    hasAnyAuthority,
    handleLogin,
    handleLoginGoogle,
    refreshAccount,
    handleLogout,
  };
}
