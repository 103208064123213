import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    professional: Yup.string()
        .required('O campo Nome é obrigatório.'),
    responsible: Yup.string()
        .required('O campo responsável é obrigatório.'),
    cel: Yup.string()
        .required('O campo telefone é obrigatório.'),
    email: Yup.string()
        .required('O campo e-mail é obrigatório.'),
});

export default ValidationSchema;
