import React from "react";
import validationSchema from './ValidationSchema';
import {Form} from 'antd';
import {Formik} from "formik";
import {Btn, FormDropdown, FormInputText, Row} from "../../../../common";
import {Metrics} from "../../../../config";
import {useHistory} from "react-router";
import Whitespace from "../../../../common/whitespace";
import useFetch from "../../../../hooks/useFetch";
import {findAllWorkgroupsRequest} from "../../../../services/workgroup";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'}
};

const CommissionForm = ({onSubmit, initialValues, isFetching}: any) => {
    const {goBack} = useHistory();
    const [
        { isFetching: isFetchingWorkgroups, data: workgroups }] = useFetch({
        provider: findAllWorkgroupsRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
    });
    return (
        <div>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {({ handleSubmit, values }) => (
                    <Form>
                        <FormDropdown
                            label="Grupo de trabalho"
                            value={values.workgroupId}
                            name="workgroupId"
                            list={workgroups.map((u: any) => ({label: u.name, value: u.id}))}
                        />
                        <FormInputText label="Comissão (%)" name="commission" value={values.commission} />
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={goBack}
                                style={styles.btn}>Voltar</Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={() => handleSubmit()}
                                disabled={isFetching}
                                type='primary'
                                style={styles.btn}>
                                Salvar
                            </Btn>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default CommissionForm;
