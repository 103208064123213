import React from "react";
import {Form, Table as TableAntd} from "antd";
import useFetch from "../../../hooks/useFetch";
import {Btn, Col, FormDropdown, FormInputText, Row} from "../../../common";
import {Colors, Metrics} from "../../../config";
import {TitledContainer} from "../../../components";
import {Formik} from "formik";
import {findBanksRequest, findCompanyRequest, updateCompanyRequest} from "../../../services/company";
import {showSuccessToast} from "../../../../configs/toast";
import Filters from "../../../../configs/Filters";

const { Column } = TableAntd;
const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '200px'},
    title: {marginTop: Metrics.spacingLG, marginBottom: 0, color: Colors.primary},
};

export const Company = () => {
    const [{ isFetching: isFetchingUpdate }, updateCompany] = useFetch(
        {
            provider: updateCompanyRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: () => showSuccessToast('Cadastro atualizado com sucesso!')
            }
        });
    const [{ isFetching, data }] = useFetch(
        {
            provider: findCompanyRequest,
            param: '',
            requestOnMount: true,
            initialData: null,
            resultHandler: null
        });
    const [{ isFetching: isFetchingBanks, data: banks }] = useFetch(
        {
            provider: findBanksRequest,
            param: '',
            requestOnMount: true,
            initialData: [],
            resultHandler: null
        });
    return (
        <TitledContainer title={'Empresa'}>
            <Col style={{ marginBlock: Metrics.spacingMD }}>
                <Formik
                    initialValues={data || {}}
                    onSubmit={updateCompany}
                    enableReinitialize>
                    {({values, handleSubmit}: any) => (
                        <Form>
                            <p style={styles.title}>Informações Gerais</p>
                            <FormInputText name='name' value={values.name} label='Empresa' />
                            <FormInputText name='cnpj' value={Filters.inputMaskCNPJ(values.cnpj)} label='CNPJ' />
                            <p style={styles.title}>Informações bancárias</p>
                            <FormDropdown
                                label='Banco'
                                value={values.bank}
                                name="bank"
                                list={banks.map((b: any) => ({label: b, value: b}))}
                            />
                            <Row responsive>
                                <FormInputText fullWidth name='accountCode' value={values.accountCode} label='Número da Conta' />
                                <div style={{marginInline: Metrics.spacingMD}} />
                                <FormInputText fullWidth name='accountDigit' value={values.accountDigit} label='Dígito da Conta' />
                                <div style={{marginInline: Metrics.spacingMD}} />
                                <FormInputText fullWidth name='wallet' value={values.wallet} label='Carteira' />
                            </Row>
                            <Row responsive>
                                <FormInputText fullWidth name='agency' value={values.agency} label='Agência' />
                                <div style={{marginInline: Metrics.spacingMD}} />
                                <FormInputText fullWidth name='agencyDigit' value={values.agencyDigit} label='Digito da Agência' />
                                <div style={{marginInline: Metrics.spacingMD}} />
                                <FormInputText fullWidth name='agreement' value={values.agreement} label='Convênio' />
                            </Row>
                            <p style={styles.title}>Informações de Contato</p>
                            <FormInputText name='postalCode' value={Filters.inputMaskCEP(values.postalCode)} label='CEP' />
                            <Row responsive>
                                <FormInputText fullWidth name='street' value={values.street} label='Logradouro' />
                                <div style={{marginInline: Metrics.spacingMD}} />
                                <FormInputText name='number' value={values.number} label='Number' />
                                <div style={{marginInline: Metrics.spacingMD}} />
                                <FormInputText fullWidth name='district' value={values.district} label='Bairro' />
                            </Row>
                            <Row>
                                <FormInputText fullWidth name='city' value={values.city} label='Cidade' />
                                <div style={{marginInline: Metrics.spacingMD}} />
                                <FormInputText name='uf' value={values.uf} label='UF' />
                            </Row>
                            <Row style={styles.btnRow}>
                                <Btn
                                    onClick={handleSubmit}
                                    disabled={isFetching}
                                    type='primary'
                                    style={styles.btn}>
                                    Atualizar
                                </Btn>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Col>
        </TitledContainer>
    );
};
