import api from "./api";

export const facematchRequest = async (_: any, data: any) => {
    console.log(data);
    return api.post(`financing/facematch`,
        {
            "matriz": "silicred_ocr_fm_serpro_documentoscopia_teste_v6",
            "parametros": {
                "imagens_face_match": [`${data.facematch.selfieContentType},${data.facematch.selfie}`],
                "doc_imagem_frente": `${data.facematch.idFrontContentType},${data.facematch.idFront}`,
                "doc_imagem_verso": `${data.facematch.idBackContentType},${data.facematch.idBack}`,
                "doc_imagem_completa": `${data.facematch.idFrontContentType},${data.facematch.idFront}`
            }
        })
};
