import React from "react";
import validationSchema from './ValidationSchema';
import {Form} from 'antd';
import {Formik} from "formik";
import {Btn, Col, FormInputText, FormTextArea, Row} from "../../../../common";
import {Metrics} from "../../../../config";
import {useHistory} from "react-router";
import Whitespace from "../../../../common/whitespace";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'}
};

const ParameterForm = ({onSubmit, initialValues, isFetching}: any) => {
    const {goBack} = useHistory();
    return (
        <div>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {({ handleSubmit, values }) => (
                    <Form>
                        <FormInputText label="Chave" name="key" value={values.key} disabled={values?.id} />
                        <FormTextArea rows={8} label="Valor" name="value" value={values.value} />
                        <div style={{textAlign: 'center'}}>
                            {
                                (values.key === 'CONTRACT_SCHEDULED_SURGERY_DEFAULT' || values.key === 'CONTRACT_PLANNED_SURGERY_DEFAULT') &&
                                <Col style={{
                                    maxWidth: '570px',
                                    textAlign: 'center',
                                    padding: '2% 5%',
                                    boxShadow: '5px 5px 10px gainsboro',
                                    borderRadius: '15px',
                                    fontFamily: 'montserrat-light',
                                    margin: '0 auto',
                                }}>
                                    <span style={{
                                        fontSize: 'medium',
                                        fontFamily: 'montserrat-medium'
                                    }}>Legenda informações dinâmicas contrato</span>
                                    <span style={{
                                        fontSize: 'small',
                                        maxWidth: '570px',
                                    }}>
                                        Inclua as variáveis no template para que ela sejam substituídas pelos
                                        dados do cliente na hora da exibição do contrato.</span>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Nome</label>
                                        <label>{'${name}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Cpf</label>
                                        <label>{'${cpf}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Rg</label>
                                        <label>{'${rg}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Endereço completo</label>
                                        <label>{'${address}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Cidade</label>
                                        <label>{'${city}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Valor Contratado</label>
                                        <label>{'${hiredValue}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Taxa de adesão</label>
                                        <label>{'${tax}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Valor Do Contrato</label>
                                        <label>{'${contractValue}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Valor da Parcela</label>
                                        <label>{'${installmentValue}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Quantidade de Parcelas</label>
                                        <label>{'${monthsQt}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Dia Do Pagamento</label>
                                        <label>{'${payday}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Data abreviada: (xx/xx/xxxx)</label>
                                        <label>{'${currentShortDate}'}</label>
                                    </Row>
                                    <Row style={{justifyContent: 'space-between'}}>
                                        <label>Data completa: (xx de Março de 2022)</label>
                                        <label>{'${currentLongDate}'}</label>
                                    </Row>
                                </Col>
                            }
                        </div>
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={goBack}
                                style={styles.btn}>Voltar</Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={() => handleSubmit()}
                                disabled={isFetching}
                                type='primary'
                                style={styles.btn}>
                                Salvar
                            </Btn>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default ParameterForm;
