import Moment from 'moment';

const clearStringOnlyNumbers = (value: any) => String(value).replace(/\D/g, '');

const inputMaskEmail = (value: any) => {
  if (!value) {
    return value;
  }
  const mask = value.toLowerCase();
  return mask;
};

const inputMaskCPF = (value: any) => {
  if (!value) {
    return value;
  }
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 11) {
    mask = mask.substring(0, 11);
  }

  if (mask.length <= 11) {
    mask = mask.replace(/(\d{3})(\d)/, '$1.$2');
    mask = mask.replace(/(\d{3})(\d)/, '$1.$2');
    mask = mask.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }
  return mask;
};

const removeMaskCPF = (value: any) => clearStringOnlyNumbers(value);

const inputMaskCNPJ = (value: any) => {
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 14) {
    mask = mask.substring(0, 14);
  }
  if (mask.length <= 14) {
    mask = mask.replace(/(\d{2})(\d)/, '$1.$2');
    mask = mask.replace(/(\d{3})(\d)/, '$1.$2');
    mask = mask.replace(/(\d{3})(\d)/, '$1/$2');
    mask = mask.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
  }
  return mask;
};

const removeMaskCNPJ = (value: any) => clearStringOnlyNumbers(value);

const inputMaskCPFCNPJ = (value: any) => {
  if (!value) {
    return value;
  }
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 14) {
    mask = mask.substring(0, 14);
  } else if (mask.length <= 11) {
    mask = mask.substring(0, 11);
  }

  if (mask.length <= 11) {
    mask = mask.replace(/(\d{3})(\d)/, '$1.$2');
    mask = mask.replace(/(\d{3})(\d)/, '$1.$2');
    mask = mask.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else if (mask.length <= 14) {
    mask = mask.replace(/(\d{2})(\d)/, '$1.$2');
    mask = mask.replace(/(\d{3})(\d)/, '$1.$2');
    mask = mask.replace(/(\d{3})(\d)/, '$1/$2');
    mask = mask.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
  }
  return mask;
};

const removeMaskCPFCNPJ = (value: any) => clearStringOnlyNumbers(value);

const inputMaskSSN = (value: any) => {
  let mask;
  mask = value.replace(/\D/g, '');

  if (mask.length > 9) {
    mask = mask.substring(0, 9);
  }
  if (mask.length <= 9) {
    mask = mask.replace(/(\d{3})(\d)/, '$1-$2');
    mask = mask.replace(/(\d{2})(\d{4})$/, '$1-$2');
  }
  return mask;
};

const removeMaskSSN = (value: any) => {
  return value.replace(/\D/g, '');
};

const inputMaskEIN = (value: any) => {
  if (!value) {
    return value;
  }
  let mask;
  mask = value.replace(/\D/g, '');

  if (mask.length > 9) {
    mask = mask.substring(0, 9);
  }

  if (mask.length <= 8) {
    mask = mask.replace(/(\d{1})(\d{7})/, '$1-$2');
  } else if (mask.length <= 9) {
    mask = mask.replace(/(\d{2})(\d{7})$/, '$1-$2');
  }
  return mask;
};

const inputMaskForIssuingAgencyUF = (inputValue: any) => {
  if (!inputValue) {
    return '';
  }

  let mask = inputValue.replace(/[^A-Za-z]/g, '');

  if (mask.length > 5) {
    mask = mask.substring(0, 5);
  }
  mask = mask.replace(/(\w{3})(\w{2})/, '$1/$2');

  return mask;
};

const removeMaskEIN = (value: any) => value.replace(/\D/g, '');

const inputMaskTEL = (value: any) => {
  if (!value) {
    return value;
  }
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 9) {
    mask = mask.substring(0, 9);
  }

  if (mask.length <= 8) {
    mask = mask.replace(/(\d{4})(\d)/, '$1-$2');
  } else if (mask.length <= 9) {
    mask = mask.replace(/(\d{1})(\d)/, '$1 $2');
    mask = mask.replace(/(\d{4})(\d{4})$/, '$1-$2');
  }
  return mask;
};

const inputMaskTELWithDDD = (value: any) => {
  if (!value) {
    return value;
  }
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 15) {
    mask = mask.substring(0, 15);
  }

  mask = mask.replace(/(\d{2})(\d)/, '($1) $2');
  mask = mask.replace(/(\d)(\d{4})$/, '$1-$2');

  return mask;
};

const inputMaskTELNineNumber = (value: any) => {
  if (!value) {
    return value;
  }
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 9) {
    mask = mask.substring(0, 9);
  }

  mask = mask.replace(/(\d{1})(\d)/, '$1 $2');
  mask = mask.replace(/(\d{4})(\d{4})$/, '$1-$2');

  return mask;
};

const inputMaskTextAndNumber = (value: any) => {
  if (!value) {
    return value;
  }
  const mask = value.replace(
    /[^\.a-zA-Z0-9çÇàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\s]/gi,
    ''
  );

  return mask;
};

const removeMaskTEL = (value: any) => clearStringOnlyNumbers(value);

const inputMaskDATE = (value: any) => {
  if (!value) {
    return value;
  }
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 8) {
    mask = mask.substring(0, 8);
  }

  if (mask.length <= 8) {
    mask = mask.replace(/(\d{2})(\d)/, '$1/$2');
    mask = mask.replace(/(\d{2})(\d{4})$/, '$1/$2');
  }
  return mask;
};

const inputMaskDATEMonthYear = (value: any) => {
  if (!value) {
    return value;
  }
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 6) {
    mask = mask.substring(0, 6);
  }

  if (mask.length <= 6) {
    mask = mask.replace(/(\d{2})(\d)/, '$1/$2');
    mask = mask.replace(/(\d{2})(\d{4})$/, '$1/$2');
  }
  return mask;
};

const removeMaskDATE = (value: any) => {
  const newValue = clearStringOnlyNumbers(value);
  return Moment(newValue, 'DDMMYYYY').format('YYYY-MM-DD');
};

const removeMaskDATELetOnlyNumbers = (value: any) => {
  const newValue = clearStringOnlyNumbers(value);
  return Moment(newValue, 'DDMMYYYY').format('YYYYMMDD');
};

const removeMaskDATEMonthYear = (value: any) => {
  return value.replace('/', '-');
};

const inputMaskCEP = (value: any) => {
  if (!value) {
    return value;
  }
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 8) {
    mask = mask.substring(0, 8);
  }
  if (mask.length === 8) {
    mask = mask.replace(/(\d{5})(\d{3})$/, '$1-$2');
  }
  return mask;
};

const removeMaskCEP = (value: any) => {
  // const clearValue = clearStringOnlyNumbers(value);
  // return parseInt(clearValue, 10);
  return clearStringOnlyNumbers(value);
};

const toCamelCase = (textValue: any) => {
  return textValue
    .split(' ')
    .map((splitText: any) => {
      return (
        splitText.charAt(0).toUpperCase() + splitText.slice(1).toLowerCase()
      );
    })
    .join(' ');
};

const toUpperCase = (textValue: any) => {
  const resultText = textValue ? textValue.toUpperCase() : textValue;
  return resultText;
};

const toLowerCase = (textValue: any) => {
  const resultText = textValue.toLowerCase();
  return resultText;
};

const organizeTransactionsAndConcatValues = (transactions: any) => {
  const { length } = transactions;

  const transactionGroups: any = {};
  for (let i = 0; i < length; i += 1) {
    const { valorBRL, grupoDescricaoMCC, grupoMCC, flagCredito } = transactions[
      i
    ];

    const resultValue = flagCredito === 1 ? valorBRL : 0;

    if (grupoMCC === null) {
      if (!transactionGroups[0]) {
        transactionGroups[0] = {
          value: resultValue,
          groupName: 'Outros',
          mcc: 0,
        };
      } else {
        transactionGroups[0].value += resultValue;
      }
    } else if (!transactionGroups[grupoMCC]) {
      transactionGroups[grupoMCC] = {
        value: resultValue,
        groupName: grupoDescricaoMCC,
        mcc: grupoMCC,
      };
    } else {
      transactionGroups[grupoMCC].value += resultValue;
    }
  }

  const transitionGroupArray = Object.values(transactionGroups);
  transitionGroupArray.sort((a: any, b: any) => (a.value < b.value ? 1 : -1));
  return transitionGroupArray;
};

function barcodeMask1(value: any, isArrecadacao: any) {
  const clearValue = clearStringOnlyNumbers(value);
  let newValue = '';

  if (isArrecadacao) {
    // // Tipo Arrecadação
    const part1 = clearValue.substring(0, 11);
    newValue += part1;
    const part2 = clearValue.substring(11, 12);
    newValue += (part1.length === 11 ? ' ' : '') + part2;
  } else {
    // Tipo Boleto
    const part1 = clearValue.substring(0, 5);
    newValue += part1;
    const part2 = clearValue.substring(5, 10);
    newValue += (part1.length === 5 ? '.' : '') + part2;
  }
  return newValue;
}

function barcodeMask2(value: any, isArrecadacao: any) {
  const clearValue = clearStringOnlyNumbers(value);
  let newValue = '';
  const type = newValue.charAt(0) === '8';

  if (isArrecadacao) {
    // // Tipo Arrecadação
    const part1 = clearValue.substring(0, 11);
    newValue += part1;
    const part2 = clearValue.substring(11, 12);
    newValue += (part1.length === 11 ? ' ' : '') + part2;
  } else {
    // Tipo Boleto
    const part1 = clearValue.substring(0, 5);
    newValue += part1;
    const part2 = clearValue.substring(5, 11);
    newValue += (part1.length === 5 ? '.' : '') + part2;
  }
  return newValue;
}

function barcodeMask3(value: any, isArrecadacao: any) {
  const clearValue = clearStringOnlyNumbers(value);
  let newValue = '';

  if (isArrecadacao) {
    // // Tipo Arrecadação
    const part1 = clearValue.substring(0, 11);
    newValue += part1;
    const part2 = clearValue.substring(11, 12);
    newValue += (part1.length === 11 ? ' ' : '') + part2;
  } else {
    // Tipo Boleto
    const part1 = clearValue.substring(0, 5);
    newValue += part1;
    const part2 = clearValue.substring(5, 11);
    newValue += (part1.length === 5 ? '.' : '') + part2;
    const part3 = clearValue.substring(11, 12);
    newValue += (part2.length === 6 ? ' ' : '') + part3;
  }
  return newValue;
}

function barcodeMask4(value: any, isArrecadacao: any) {
  const clearValue = clearStringOnlyNumbers(value);
  let newValue = '';
  const type = newValue.charAt(0) === '8';

  if (isArrecadacao) {
    // // Tipo Arrecadação
    const part1 = clearValue.substring(0, 11);
    newValue += part1;
    const part2 = clearValue.substring(11, 12);
    newValue += (part1.length === 11 ? ' ' : '') + part2;
  } else {
    // Tipo Boleto
    newValue = clearValue;
  }
  return newValue;
}

const barcodeMask = (value: any) => {
  const clearValue = clearStringOnlyNumbers(value);

  let newValue = '';

  const type = newValue.charAt(0) === '8';
  if (type) {
    // // Tipo Arrecadação

    const part11 = clearValue.substring(0, 11);
    newValue += part11;

    const part12 = clearValue.substring(11, 12);
    newValue += (part11.length === 11 ? ' ' : '') + part12;

    const part21 = clearValue.substring(12, 23);
    newValue += (part12.length === 1 ? '\n' : '') + part21;

    const part22 = clearValue.substring(23, 24);
    newValue += (part21.length === 11 ? ' ' : '') + part22;

    const part31 = clearValue.substring(24, 35);
    newValue += (part22.length === 1 ? '\n' : '') + part31;

    const part32 = clearValue.substring(35, 36);
    newValue += (part31.length === 11 ? ' ' : '') + part32;

    const part41 = clearValue.substring(36, 47);
    newValue += (part32.length === 1 ? '\n' : '') + part41;

    const part42 = clearValue.substring(47, 48);
    newValue += (part41.length === 11 ? ' ' : '') + part42;
  } else {
    // Tipo Boleto

    const part11 = clearValue.substring(0, 5);
    newValue += part11;
    const part12 = clearValue.substring(5, 10);
    newValue += (part11.length === 5 ? '.' : '') + part12;
    const part21 = clearValue.substring(10, 15);
    newValue += (part12.length === 5 ? ' ' : '') + part21;
    const part22 = clearValue.substring(15, 21);
    newValue += (part21.length === 5 ? '.' : '') + part22;
    const part31 = clearValue.substring(21, 26);
    newValue += (part22.length === 6 ? ' ' : '') + part31;
    const part32 = clearValue.substring(26, 32);
    newValue += (part31.length === 5 ? '.' : '') + part32;
    const part41 = clearValue.substring(32, 33);
    newValue += (part32.length === 6 ? ' ' : '') + part41;
    const part51 = clearValue.substring(33, 47);
    newValue += (part41.length === 1 ? ' ' : '') + part51;
  }
  return newValue;
};

const barcodeMaskWithBreakLine = (value: any) => {
  const clearValue = clearStringOnlyNumbers(value);

  let newValue = '';

  const type = newValue.charAt(0) === '8';
  if (type) {
    // // Tipo Arrecadação

    const part11 = clearValue.substring(0, 11);
    newValue += part11;

    const part12 = clearValue.substring(11, 12);
    newValue += (part11.length === 11 ? ' ' : '') + part12;

    const part21 = clearValue.substring(12, 23);
    newValue += (part12.length === 1 ? '\n' : '') + part21;

    const part22 = clearValue.substring(23, 24);
    newValue += (part21.length === 11 ? ' ' : '') + part22;

    const part31 = clearValue.substring(24, 35);
    newValue += (part22.length === 1 ? '\n' : '') + part31;

    const part32 = clearValue.substring(35, 36);
    newValue += (part31.length === 11 ? ' ' : '') + part32;

    const part41 = clearValue.substring(36, 47);
    newValue += (part32.length === 1 ? '\n' : '') + part41;

    const part42 = clearValue.substring(47, 48);
    newValue += (part41.length === 11 ? ' ' : '') + part42;
  } else {
    // Tipo Boleto

    const part11 = clearValue.substring(0, 5);
    newValue += part11;
    const part12 = clearValue.substring(5, 10);
    newValue += (part11.length === 5 ? '.' : '') + part12;
    const part21 = clearValue.substring(10, 15);
    newValue += (part12.length === 5 ? '\n' : '') + part21;
    const part22 = clearValue.substring(15, 21);
    newValue += (part21.length === 5 ? '.' : '') + part22;
    const part31 = clearValue.substring(21, 26);
    newValue += (part22.length === 6 ? '\n' : '') + part31;
    const part32 = clearValue.substring(26, 32);
    newValue += (part31.length === 5 ? '.' : '') + part32;
    const part41 = clearValue.substring(32, 33);
    newValue += (part32.length === 6 ? '\n' : '') + part41;
    const part51 = clearValue.substring(33, 47);
    newValue += (part41.length === 1 ? ' ' : '') + part51;
  }
  return newValue;
};

const taxMask = (value: any) => {
  if (value) {
    const stringOnlyNumbers = `${Number(value).toFixed(2)}`.replace(/\D/g, '');
    if (!stringOnlyNumbers) {
      return '0,00%';
    }

    const { length } = stringOnlyNumbers;
    if (length === 1) {
      return value >= 0
        ? `0,0${stringOnlyNumbers}%`
        : `-0,0${stringOnlyNumbers}%`;
    }
    if (length === 2) {
      return value >= 0
        ? `0,${stringOnlyNumbers}%`
        : `-0,${stringOnlyNumbers}%`;
    }
    let taxMask = '';

    for (let i = length - 1; i >= 0; i -= 1) {
      if (i === length - 2) {
        taxMask = `,${stringOnlyNumbers[i]}${taxMask}`;
      } else if (i < length - 5 && (i - length - 3) % 3 === 0) {
        taxMask = `${stringOnlyNumbers[i]}.${taxMask}`;
      } else {
        taxMask = `${stringOnlyNumbers[i]}${taxMask}`;
      }
    }

    return value >= 0 ? `${taxMask}%` : `-${taxMask}%`;
  }
  return '0,00%';
};

const convertMoneyTextMask = (value: any) => {
  if (value) {
    const stringOnlyNumbers = `${Number(value).toFixed(2)}`.replace(/\D/g, '');
    if (!stringOnlyNumbers) {
      return 'R$ 0,00';
    }

    const { length } = stringOnlyNumbers;
    if (length === 1) {
      return value >= 0
        ? `R$ 0,0${stringOnlyNumbers}`
        : `R$ -0,0${stringOnlyNumbers}`;
    }
    if (length === 2) {
      return value >= 0
        ? `R$ 0,${stringOnlyNumbers}`
        : `R$ -0,${stringOnlyNumbers}`;
    }
    let moneyMask = '';

    for (let i = length - 1; i >= 0; i -= 1) {
      if (i === length - 2) {
        moneyMask = `,${stringOnlyNumbers[i]}${moneyMask}`;
      } else if (i < length - 5 && (i - length - 3) % 3 === 0) {
        moneyMask = `${stringOnlyNumbers[i]}.${moneyMask}`;
      } else {
        moneyMask = `${stringOnlyNumbers[i]}${moneyMask}`;
      }
    }

    return value >= 0 ? `R$ ${moneyMask}` : `R$ -${moneyMask}`;
  }
  return 'R$ 0,00';
};

const convertMoneyInputMaskV2 = (value: any) => {
  // const int = parseInt(value.replace(/[\D]+/g, ''));
  //
  // let tmp = `${int}`;
  // tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
  // if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');

  return value;
};

const convertMoneyInputMask = (value: any) => {
  if (value) {
    let mask = `${value}`.replace(/\D/g, '');

    if (!mask || Number(mask) <= 0) {
      return '';
    }

    const contador = (value.length - 5) / 3;

    mask = mask.replace(/^([.\d]+)(\d{2})$/, '$1,$2');
    for (let i = 0; i < contador; i += 1) {
      mask = mask.replace(/(\d+)(\d{3})([.,\d]+)$/, '$1.$2$3');
    }

    mask = `R$ ${mask}`;
    return mask;
  }

  return value;
};

const removeMoneyMask = (value: any) => {
  const stringValue = `${value}`.replace(/\D/g, '');
  if (stringValue.length === 1) {
    return parseFloat(`${value < 0 ? '-' : ''}0.0${stringValue}`);
  }
  if (stringValue.length === 2) {
    return parseFloat(`${value < 0 ? '-' : ''}0.${stringValue}`);
  }

  return parseFloat(stringValue.replace(/(\d+)(\d{2})$/, '$1.$2'));
};

const formatBankId = (bankId: any) => {
  const stringBankId = `${bankId}`;
  const { length } = stringBankId;
  if (length === 1) {
    return `00${bankId}`;
  }
  if (length === 2) {
    return `0${bankId}`;
  }
  return `${bankId}`;
};

const formatCardNumber = (cardNumber: any) => {
  const stringNumber = `${cardNumber}`;
  const { length } = stringNumber;
  let formatedCardNumber = '';
  for (let i = 0; i < length; i += 1) {
    formatedCardNumber += i % 4 === 0 ? ` ${cardNumber[i]}` : cardNumber[i];
  }

  return formatedCardNumber;
};

const formatCC = (cdCta: any) => {
  const stringNumber = `${cdCta}`;
  const len = stringNumber.length;
  const midleLen = 3;
  const endLen = 1;

  if (len < midleLen + endLen) {
    return cdCta;
  }

  const beginLen = len - (midleLen + endLen);

  const indexMidleStart = len - (midleLen + endLen);
  const indexMidleEnd = len - endLen;
  const beginCC = stringNumber.substr(0, beginLen);
  const midleCC = stringNumber.substr(indexMidleStart, midleLen);
  const endCC = stringNumber.substr(indexMidleEnd, endLen);

  if (beginCC) {
    return `${beginCC}-${midleCC}-${endCC}`;
  }

  return `${midleCC}-${endCC}`;
};

const inputMaskBankAccountNumber = (value: any) => {
  if (!value) {
    return value;
  }
  let mask = clearStringOnlyNumbers(value);

  if (mask.length > 12) {
    mask = mask.substring(0, 12);
  }
  if (mask.length > 4) {
    mask = mask.replace(/(.$)$/, '-$1');
  }
  return mask;
};

export default {
  inputMaskEmail,
  inputMaskCPF,
  removeMaskCPF,
  inputMaskCNPJ,
  removeMaskCNPJ,
  inputMaskSSN,
  removeMaskSSN,
  inputMaskEIN,
  removeMaskEIN,
  inputMaskTEL,
  inputMaskTELWithDDD,
  removeMaskTEL,
  inputMaskTELNineNumber,
  inputMaskTextAndNumber,
  inputMaskDATE,
  removeMaskDATE,
  removeMaskDATELetOnlyNumbers,
  inputMaskDATEMonthYear,
  removeMaskDATEMonthYear,
  inputMaskCEP,
  inputMaskForIssuingAgencyUF,
  removeMaskCEP,
  toCamelCase,
  toUpperCase,
  clearStringOnlyNumbers,
  removeMoneyMask,
  convertMoneyTextMask,
  convertMoneyInputMask,
  taxMask,
  barcodeMask,
  barcodeMask1,
  barcodeMask2,
  barcodeMask3,
  barcodeMask4,
  organizeTransactionsAndConcatValues,
  formatBankId,
  convertMoneyInputMaskV2,
  formatCardNumber,
  formatCC,
  inputMaskCPFCNPJ,
  removeMaskCPFCNPJ,
  barcodeMaskWithBreakLine,
  inputMaskBankAccountNumber,
  toLowerCase,
  // setCardFlag,
};
