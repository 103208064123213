import React from "react";
import {Col, Row} from "../../../common";
import {Colors, Metrics} from "../../../config";
import {TitledContainer} from "../../../components";
import sucessoImg from "../../../../assets/images/sucesso.png";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '200px'},
    title: {marginTop: Metrics.spacingLG, marginBottom: 0, color: Colors.primary},
    text: {
        color: Colors.text,
        fontSize: Metrics.fontSize.xxxsm,
        textAlign: 'justify' as const,
        marginBlock: Metrics.spacingMD
    },
    col: {
        width: '20%',
        color: Colors.text,
        fontSize: Metrics.fontSize.xxxsm
    },
    colCent: {
        width: '20%',
        color: Colors.text,
        fontSize: Metrics.fontSize.xxxsm,
        textAlign: 'center'
    },
    redAlg: {
        marginLeft: Metrics.spacingMinimun,
        color: 'red'
    },
    img: {
        width: '170px',
        height: '170px',
        objectFit: 'cover' as const
    }
};

const LuckyTable = () => (
  <Col style={{width: '100%', marginBottom: Metrics.spacingMD, maxWidth: '800px'}}>
      <Row>
          <Col style={styles.col} />
          <Col style={styles.colCent} />
          <Col style={styles.colCent}>Algarismo da centena de milhar:</Col>
          <Col style={styles.colCent}>Considerar:</Col>
      </Row>
      <Row>
          <Col style={styles.col}>1º Prêmio</Col>
          <Row style={styles.colCent}>32.8<b style={styles.redAlg}>75</b></Row>
          <Col style={styles.colCent}>0 ou 5</Col>
          <Col style={styles.colCent}>0</Col>
      </Row>
      <Row>
          <Col style={styles.col}>2º Prêmio</Col>
          <Row style={styles.colCent}>23.96<b style={styles.redAlg}>9</b></Row>
          <Col style={styles.colCent}>1 ou 6</Col>
          <Col style={styles.colCent}>1</Col>
      </Row>
      <Row>
          <Col style={styles.col}>3º Prêmio</Col>
          <Row style={styles.colCent}>62.43<b style={styles.redAlg}>6</b></Row>
          <Col style={styles.colCent}>2 ou 7</Col>
          <Col style={styles.colCent}>2</Col>
      </Row>
      <Row>
          <Col style={styles.col}>4º Prêmio</Col>
          <Row style={styles.colCent}>01.28<b style={styles.redAlg}>4</b></Row>
          <Col style={styles.colCent}>3 ou 8</Col>
          <Col style={styles.colCent}>3</Col>
      </Row>
      <Row>
          <Col style={styles.col}>5º Prêmio</Col>
          <Row style={styles.colCent}>36.39<b style={styles.redAlg}>7</b></Row>
          <Col style={styles.colCent}>4 ou 9</Col>
          <Col style={styles.colCent}>4</Col>
      </Row>
  </Col>
);

export const LuckyNumber = () => {
    return (
        <TitledContainer title={'Número da Sorte'}>
            <Col style={{ marginBlock: Metrics.spacingMD }}>
                <Row responsive>
                    <img src={sucessoImg} style={styles.img} alt=""/>
                    <Col>
                        <span style={styles.text}>1) Você cliente LILEVE da modalidade COMPRA PROGRAMADA SILICRED, estando em dia com o plano de poupança que escolheu, recebe sem custo adicional, um Número da Sorte informado no certificado de Sorteio, e concorre semanalmente a um prêmio de R$ 10.000,00 [dez mil reais] pela Loteria Federal do Brasil. O valor livre de Imposto de Renda a ser sorteado será informado no Certificado de Sorteio.</span>
                        <span style={styles.text}>2) Os sorteios serão realizados aos sábados de cada semana do mês subsequente ao do vencimento de seu pagamento, desde que sua data base seja antes do dia 14. Caso sua data base seja após dia 15, passa a concorrer a partir do segundo mês subsequente. O sorteio é garantido por Título de Capitalização emitido pela Caixa Capitalização S/A CNPJ 01.599.296/0001-71, N°(s) Processo SUSEP: 00000.100060/8999-21,15414.900033/2019-85. A Cia de Seguros Previdência do Sul irá adquirir os títulos em seu nome, transferindo-lhe os direitos do sorteio. A transferência refere-se exclusivamente ao direito de participação no sorteio. (Telefone Susep: 0800-021-8484 - www.susep.gov.br SAC: 0800-722-0264 / Ouvidoria 0800-722-0266 / Atendimento a Deficientes: 0800-722-0265)</span>
                    </Col>
                </Row>
                <span style={styles.text}>3) O Título contemplado com o prêmio principal será aquele cujo número para fins de sorteio coincidir na mesma ordem com o número formado pela junção dos algarismos da dezena simples e da unidade simples do 1º prêmio com os algarismos da unidade simples do 2º ao 5º prêmio da extração da Loteria Federal do Brasil, lidos verticalmente do 1º ao 5º prêmio, de cima para baixo, após aplicação da tabela de conversão. Não havendo a extração da Loteria Federal na data prevista será considerada a extração que a substitua.</span>
                <LuckyTable />
                <span style={styles.text}>Exemplo: O número extraído do resultado da Loteria Federal seria 759.647. Para se obter o número contemplado utiliza-se a tabela abaixo à direita para a conversão do algarismo da centena de milhar do número extraído:</span>
                <span style={styles.text}>No exemplo, o número contemplado com o prêmio principal seria: 1º Prêmio = <b style={{color: 'black'}}>259.647</b></span>
                <span style={styles.text}>BOA SORTE !</span>
            </Col>
        </TitledContainer>
    );
};
