import React from "react";
import {Col} from "../../common";
import {Colors, Metrics} from "../../config";
import useFetch from "../../hooks/useFetch";
import {showSuccessToast} from "../../../configs/toast";
import {useHistory, useParams} from "react-router";
import PasswordRecoveryForm from "./PasswordRecoveryForm";
import girlLogin from 'assets/images/recoverPwd.png'
import girlLoginMob from 'assets/images/recoverPwdMob.png'
import logo from "../../../assets/images/logo-transparent.png";
import PasswordChangeForm from "./PasswordChangeForm";
import {changePasswordRequest, recoverPasswordRequest} from "../../services/auth";
import useBreakpoint from "../../hooks/useBreakpoint";

const styles = {
    container: {
        width: '100%',
        height: '100%',
        alignItems: 'flex-end',
        padding: `75px ${Metrics.spacingXLG}`,
        backgroundSize: 'cover'
    },
    containerMob: {
        width: '100%',
        height: '100%',
        alignItems: 'flex-end',
        padding: `25px ${Metrics.spacingMD}`,
        backgroundSize: 'cover'
    },
    title: {
        color: Colors.primary,
        maxWidth: '400px'
    },
    logo: {
        maxWidth: '100px',
        marginBottom: Metrics.spacingLG
    },
    block: {
        minWidth: '420px',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        padding: Metrics.spacingMD,
        borderRadius: '14px',
        alignItems: 'center'
    },
    blockMob: {
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        padding: Metrics.spacingMD,
        borderRadius: '14px',
        alignItems: 'center'
    }
}

const PasswordRecovery = () => {
    const { goBack, push } = useHistory();
    const { isMobile } = useBreakpoint();
    const {key}: any = useParams();
    const [{isFetching}, init] = useFetch(
    {
        provider: recoverPasswordRequest,
        param: '',
        requestOnMount: false,
        initialData: null,
        resultHandler: {
            success: () => {
                goBack();
                showSuccessToast('Foi enviado um e-mail com as informações para prosseguir com a recuperação de senha para o e-mail informado.')
            },
        }
    });
    const [{isFetching: isFetchingChangePassword}, finish] = useFetch(
        {
            provider: changePasswordRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: () => {
                    showSuccessToast('Senha alterada com sucesso! Faça login utilizando a nova senha para acessar a área interna do sistema.');
                    push('/');
                },
            }
        });
    return (
        <Col style={{...(isMobile ? styles.containerMob : styles.container), backgroundImage: `url(${isMobile ? girlLoginMob : girlLogin})`}}>
            <Col style={isMobile ? styles.blockMob : styles.block}>
                <img style={styles.logo} src={logo}/>
                { key ?
                    <Col>
                        <span style={styles.title}>
                            Por favor, para continuar, crie uma nova senha de acesso:
                        </span>
                        <PasswordChangeForm onSubmit={finish} isFetching={isFetchingChangePassword} />
                    </Col>
                        :
                    <Col>
                        <span style={styles.title}>
                            Por favor, insira seu e-mail de cadastro para solicitar a recuperação da senha:</span>
                        <PasswordRecoveryForm onSubmit={init} isFetching={isFetching} />
                    </Col>
                }
            </Col>

        </Col>
    );
};

export default PasswordRecovery;
