import React from 'react';
import Routes from './routes';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Providers from "./new/config/Providers";

const App: React.FC = () => {
    toast.configure();

    return (
        <Providers>
            <Routes />
        </Providers>
    );
};

export default App;
