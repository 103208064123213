import {ParameterKey, PaymentMethodEnum, PaymentStatusEnum, ProcedureStatus, SimulationTypeEnum} from "../constants";


export function getLabelBankSlipSituacion(status: PaymentStatusEnum | undefined, breakpoint: string | undefined) {
    const mobile = !(breakpoint === 'lg' || breakpoint === 'md');
    switch (status) {
        case PaymentStatusEnum.PAID:
            return mobile ? 'PAG' : 'PAGO';
        case PaymentStatusEnum.PENDING:
            return mobile ? 'À VENC' : 'À VENCER';
        case PaymentStatusEnum.OVERDUE: {
            return mobile ? 'VENC' : 'VENCIDO';
        }
    }
}

export function getLabelProcedureStatus(status: ProcedureStatus | undefined) {
    switch (status) {
        case ProcedureStatus.COMPLETE:
            return 'Finalizado';
        case ProcedureStatus.CURRENT:
            return 'Vigente';
    }
}

export function getLabelPaymentMethod(method: PaymentMethodEnum | undefined) {
    switch (method) {
        case PaymentMethodEnum.BANK_SLIP:
            return 'Boleto Bancário';
        case PaymentMethodEnum.CREDIT_CARD:
            return 'Cartão de Crédito';
        case PaymentMethodEnum.DEBIT_CARD:
            return 'Cartão de Débito';
    }
}

export function getLabelSimulationType(type: SimulationTypeEnum | undefined) {
    switch (type) {
        case SimulationTypeEnum.PARCEL_QT:
            return 'Quantidade de Parcelas';
        case SimulationTypeEnum.PARCEL_VALUE:
            return 'Valor da Parcela';
        case SimulationTypeEnum.STARTER_VALUE:
            return 'Valor de Entrada';
    }
}

export function getLabelParameterKey(type: ParameterKey | undefined) {
    switch (type) {
        case ParameterKey.LOGIN_TEXT:
            return 'Texto login';
        case ParameterKey.ABOUT_US:
            return 'Sobre Nós';
        case ParameterKey.HOW_WORK:
            return 'Como Funciona';
        case ParameterKey.CONTRACT:
            return 'Contrato';
    }
}
