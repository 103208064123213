import React, {useMemo, useState} from "react";
import {deleteUserRequest, findUsersBySupervisorRequest} from "../../../services/user";
import {format} from "date-fns";
import {DateUtils} from "../../../../util/DateUtils";
import {GenericList} from "../../../components";
import useBreakpoint from "../../../hooks/useBreakpoint";
import Filters from "../../../../configs/Filters";
import {useAuthentication} from "../../../context/AuthContext";
import {UserFilter} from "../user/filter";


export const Seller = () => {
    const { user } = useAuthentication();
    const { isMobile } = useBreakpoint();
    const [filter, setFilter] = useState({});
    const columns = useMemo(() => {
        return [
            {
                title: 'Nome',
                key: 'name'
            },
            {
                title: 'Email',
                key: 'email'
            },
            ...(!isMobile ? [
                {
                    title: 'Cpf/Cnpj',
                    key: 'cpf',
                    render: (_: any, rowData: any) => (<span>{rowData.cpf ? Filters.inputMaskCPF(rowData.cpf) : Filters.inputMaskCNPJ(rowData.cnpj)}</span>)
                }
            ] : []),
            ...(!isMobile ? [
                {
                    title: 'Dt nascimento',
                    key: 'birthdate',
                    render: (_: any, rowData: any) =>
                        <span>{rowData.birthdate ?
                            format(new Date(DateUtils.convertStringToDateWithoutHours(rowData.birthdate)), 'dd/MM/yyyy') :
                            '-'}
                    </span>
                }
            ] : []),
        ]
    }, [isMobile]);

  return (
      <GenericList
          title={'Vendedores'}
          newPath={'sellers/create'}
          editPath={'sellers/edit'}
          providerFind={findUsersBySupervisorRequest}
          providerParam={user?.id}
          providerDelete={deleteUserRequest}
          columns={columns}
          filter={filter}
          filterElement={<UserFilter filter={filter} setFilter={setFilter} isFetching={false}  />}
      />
  );
};
