import { useEffect, useReducer, useRef } from 'react';
import useCompare from './useCompare';
import {showErrorToast, showSuccessToast} from "../../configs/toast";

const initialState = {
  isFetching: false,
  filter: {},
  data: null,
  totalItems: 0,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isFetching: true };
    case 'FETCH_ERR':
      return { ...state, isFetching: false };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
        totalItems: action.payload.totalItems,
      };
    case 'FILTER':
      return {
        ...initialState,
        filter: action.payload.filter,
        data: action.payload.initialData,
        totalItems: action.payload.totalItems,
      };
    default:
      throw new Error();
  }
};

export default (
    {
      provider,
      param,
      requestOnMount,
      initialData,
      resultHandler
    }: any
) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    data: initialData,
  });

  const diff = useCompare(param);
  const diffProvider = useCompare(provider);
  const shouldLoad = useRef(requestOnMount);
  const successHandler = resultHandler && resultHandler.success ? resultHandler.success : () => {};
  const errorHandler = resultHandler && resultHandler.error ? resultHandler.error : (er: any) => showErrorToast(er?.data?.detail || er?.data?.title);
  const { filter } = state;

  useEffect(() => {
    if (diff || diffProvider) {
      dispatch({ type: 'FETCH_SUCCESS', payload: { data: initialData } });
    } else if (shouldLoad.current) {
      dispatch({ type: 'FETCH_INIT' });
      provider(param, filter)
        .then((result: any) => {
            dispatch({
              type: 'FETCH_SUCCESS',
              payload: {
                data: result.status === 200 ? result?.data : initialData,
                totalItems: result?.headers['x-total-count']
              },
            });
          shouldLoad.current = false;
          if (result.status === 200 || result.status === 204 || result.status === 201) {
            successHandler(result);
          } else {
            errorHandler(result);
          }
        })
        .catch((err: any) => {
          dispatch({ type: 'FETCH_ERR' });
          console.log(err);
          errorHandler(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diff, diffProvider, filter, provider, shouldLoad.current]);

  function fetchFilter(payload: any) {
    shouldLoad.current = true;
    dispatch({
      type: 'FILTER',
      payload: {
        filter:
          typeof payload === 'string' || typeof payload === 'number'
            ? payload
            : { ...payload },
        initialData,
      },
    });
  }

  return [state, fetchFilter];
};
