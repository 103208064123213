import React from 'react';
import styled from "styled-components";
import {Close} from "@material-ui/icons";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Whitespace from "../../common/whitespace";
import useBreakpoint from "../../hooks/useBreakpoint";
import {Modal} from "antd";

export const CloseIcon = styled(Close)`
  font-size: small;
  cursor: pointer;
`;

const styles: any = {
    btn: {
        backgroundColor: '#BD997F',
        color: 'white',
        border: 'none',
        borderRadius: '100px',
        padding: '10px 25px',
        cursor: 'pointer',
        margin: '0 10px',
    },
    text: {
        marginBottom: '5px',
        fontSize: '12px',
        fontFamily: 'montserrat-regular'
    },
    bold: {
        marginBottom: '5px',
        fontSize: '12px',
    },
    link: {
        fontSize: '12px !important',
        color: 'blue',
        marginBottom: '5px',
    }
}
const CookiesDialog = ({
    setLgpd,
    dialogVisible,
    setDialogVisible,
    }: any) => {
    const { isMobile } = useBreakpoint();

  return (
      <Modal
          closable
          open={dialogVisible} style={{width: !isMobile ? '50vw' : '90vw'}} onCancel={() => setDialogVisible(false)}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={() => setDialogVisible(false)}>
                <CloseIcon />
            </div>
            <div>
                <div  style={{padding: '0 5% 2%', display: 'flex', flexDirection: 'column'}}>
                    <p style={{marginBottom: '10px', fontWeight: 'bold'}}>Definições dos Cookies</p>
                    <p style={styles.text}>Lei Geral de Proteção de Dados</p>
                    <p style={{marginBottom: '10px', fontSize: 'sm'}}>O que são cookies?</p>
                    <p style={styles.text}>Cookies são arquivos salvos em seu computador, tablet ou telefone quando você visita um site. Usamos os cookies necessários para fazer o site funcionar da melhor forma possível e sempre aprimorar os nossos serviços.</p>
                    <p style={styles.text}>Alguns cookies são classificados como necessários e permitem as funcionalidades centrais, como segurança, gerenciamento de rede e acessibilidade. Esses cookies podem ser coletados e armazenados assim que você inicia sua navegação ou quando usa algum recurso que os requer.</p>
                    <Whitespace height='10px' />
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            Cookies Primários
                        </AccordionSummary>
                        <AccordionDetails>
                            <p style={styles.text}>Alguns cookies serão colocados em seu dispositivo diretamente pelo nosso site - são conhecidos como cookies primários. Eles são essenciais para você navegar no site e usar seus recursos.</p>
                            <p style={styles.bold}>Temporários</p>
                            <p style={styles.text}>Nós utilizamos cookies de sessão. Eles são temporários e expiram quando você fecha o navegador ou quando a sessão termina.</p>
                            <p style={styles.bold}>Finalidade</p>
                            <p style={styles.text}>Estabelecer controle de idioma e segurança ao tempo da sessão.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header2">
                            Cookies de Terceiros
                        </AccordionSummary>
                        <AccordionDetails>
                            <p style={styles.text}>Outros cookies são colocados no seu dispositivo não pelo site que você está visitando, mas por terceiros, como, por exemplo, os sistemas analíticos.</p>
                            <p style={styles.bold}>Temporários</p>
                            <p style={styles.text}>Nós utilizamos cookies de sessão. Eles são temporários e expiram quando você fecha o navegador ou quando a sessão termina.</p>
                            <p style={styles.bold}>Finalidade</p>
                            <p style={styles.text}>Coletam informações sobre como você usa o site, como as páginas que você visitou e os links em que clicou. Nenhuma dessas informações pode ser usada para identificá-lo. Seu único objetivo é possibilitar análises e melhorar as funções do site.</p>
                        </AccordionDetails>
                    </Accordion>
                    <p style={styles.text}>Você pode desabilitá-los alterando as configurações do seu navegador, mas saiba que isso pode afetar o funcionamento do site.</p>
                    <a target='_blank' className='lgpd-link' href={'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR'}>Chrome</a>
                    <a target='_blank' className='lgpd-link' href={'https://support.mozilla.org/pt-BR/kb/desative-cookies-terceiros-impedir-rastreamento'}>Firefox</a>
                    <a target='_blank' className='lgpd-link' href={'https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies'}>Microsoft Edge</a>
                    <a target='_blank' className='lgpd-link' href={'https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies'}>Internet Explorer</a>
                    <p style={styles.text}>Caso queira mais informações sobre os cookies de terceiros acesse:</p>
                    <a target='_blank' className='lgpd-link' href={'https://support.google.com/analytics/answer/6004245?hl=pt-BR&ref_topic=2919631'}>Google Analytics</a>
                    <a target='_blank' className='lgpd-link' href={'https://www.hotjar.com/legal/policies/privacy/'}>Hotjar</a>
                </div>
                <div style={{display: 'flex', flexDirection: !isMobile ? 'row' : 'column', alignItems: 'center', justifyContent: 'center',width: '100%', paddingBottom: '10px'}}>
                    <button style={styles.btn} onClick={ () => {
                        setLgpd('true');
                        localStorage.setItem('lgpd', 'true');
                    }}>Eu concordo</button>
                </div>
            </div>
      </Modal>
  );
};

export default CookiesDialog;
