import React, {useMemo, useState} from "react";
import {GenericList} from "../../../components";
import {deleteUserRequest, findUsersRequest} from "../../../services/user";
import useBreakpoint from "../../../hooks/useBreakpoint";
import Filters from "../../../../configs/Filters";
import {format} from "date-fns";
import {DateUtils} from "../../../../util/DateUtils";
import {UserFilter} from "./filter";


export const User = () => {
    const { isMobile } = useBreakpoint();
    const [filter, setFilter] = useState({});
    const columns = useMemo(() => {
        return [
            {
                title: 'Nome',
                key: 'name'
            },
            {
                title: 'Email',
                key: 'email'
            },
        ...(!isMobile ? [
                {
                    title: 'Cpf/Cnpj',
                    key: 'cpf',
                    render: (_: any, rowData: any) => (<span>{rowData.cpf ? Filters.inputMaskCPF(rowData.cpf) : Filters.inputMaskCNPJ(rowData.cnpj)}</span>)
                }
            ] : []),
        ...(!isMobile ? [
                {
                    title: 'Dt nascimento',
                    key: 'birthdate',
                    render: (_: any, rowData: any) =>
                        <span>{rowData.birthdate ?
                            format(new Date(DateUtils.convertStringToDateWithoutHours(rowData.birthdate)), 'dd/MM/yyyy') :
                            '-'}
                    </span>
                }
        ] : []),
        ]
    }, [isMobile]);

    return (
        <GenericList
            title={'Usuários'}
            newPath={'users/create'}
            editPath={'users/edit'}
            providerFind={findUsersRequest}
            providerDelete={deleteUserRequest}
            columns={columns}
            filter={filter}
            filterElement={<UserFilter filter={filter} setFilter={setFilter} isFetching={false}  />}
        />
    );
};
