import api from "./api";

export const findTestimonialRequest = async (param: any, data: any) => {
    return api.get(`/api/testimonials/${param}`, data);
};

export const findTestimonialsRequest = (param: any, data: any) => {
    return api.get(`/api/testimonials`, {params: data});
};

export const createTestimonialRequest = (param: any, data: any) => {
    return api.post(`/api/testimonials`, data);
};

export const updateTestimonialRequest = (param: any, data: any) => {
    return api.put(`/api/testimonials`, data);
};

export const deleteTestimonialRequest = (param: any, data: any) => {
    return api.delete(`/api/testimonials/${data}`);
};
