export class MaskUtils {
    private static DECIMAL_SEPARATOR = '.';
    private static GROUP_SEPARATOR = ',';
    private static maskedId: any;

    public maskDDD = ['(', /[1-9]/, /[1-9]/, ')'];

    public static maskTime = function(userInput: any) {
        const hora: string = userInput;
        if (hora.startsWith('2')) {
            return [/[2]/, /[0-3]/, ':', /[0-5]/, /\d/];
        } else {
            return [/[0-1]/, /\d/, ':', /[0-5]/, /\d/];
        }
    };

    public static formatPIS(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        const teste = parts[0].substring(0, 14);
        parts[0] = teste;
        this.maskedId = this.pis(parts[0]);
        return this.maskedId;
    }

    public static formatCnpj(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        const teste = parts[0].substring(0, 14);
        parts[0] = teste;
        this.maskedId = this.cnpj(parts[0]);
        return this.maskedId;
    }

    public static formatCpf(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        const teste = parts[0].substring(0, 11);
        parts[0] = teste;
        this.maskedId = this.cpf_mask(parts[0]);
        return this.maskedId;
    }

    public static formatDate(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        if (parts[0].length > 8) {
            const teste = parts[0].substring(0, 8);
            parts[0] = teste;
        }
        this.maskedId = this.date_mask(parts[0]);
        return this.maskedId;
    }

    public static formatDateMonthYear(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        if (parts[0].length > 6) {
            const teste = parts[0].substring(0, 6);
            parts[0] = teste;
        }
        this.maskedId = this.date_month_year_mask(parts[0]);
        return this.maskedId;
    }

    public static formatCep(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        if (parts[0].length > 8) {
            const teste = parts[0].substring(0, 8);
            parts[0] = teste;
        }
        this.maskedId = this.cep_mask(parts[0]);
        return this.maskedId;
    }

    public static formatCpfCnpj(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        if (parts[0].length > 11) {
            const teste = parts[0].substring(0, 14);
            parts[0] = teste;
            this.maskedId = this.cnpj(parts[0]);
        } else {
            const teste = parts[0].substring(0, 11);
            parts[0] = teste;
            this.maskedId = this.cpf_mask(parts[0]);
        }
        return this.maskedId;
    }

    public static formatCel(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        if (parts[0].length > 11) {
            const teste = parts[0].substring(0, 11);
            parts[0] = teste;
        }
        if (parts[0].length < 11) {
            this.maskedId = this.residencial_mask(parts[0]);
        } else {
            this.maskedId = this.celular_mask(parts[0]);
        }
        return this.maskedId;
    }

    public static formatResidencial(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        if (parts[0].length > 8) {
            const teste = parts[0].substring(0, 8);
            parts[0] = teste;
        }
        this.maskedId = this.residencial_mask(parts[0]);
        return this.maskedId;
    }

    public static formatarDDD(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        const teste = parts[0].substring(0, 2);
        parts[0] = teste;
        this.maskedId = this.formatDdd(parts[0]);
        return this.maskedId;
    }

    public static formatCns(valString: any) {
        if (!valString) {
            return '';
        }
        const val = valString.toString();
        const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
        const teste = parts[0].substring(0, 15);
        parts[0] = teste;
        this.maskedId = this.cns_mask(parts[0]);
        return this.maskedId;
    }

    public static unFormat(val: string | number) {
        val = val ? val.toString() : '';
        if (!val) {
            return '';
        }
        val = val.replace(/\D/g, '');

        if (this.GROUP_SEPARATOR === ',') {
            return val.replace(/,/g, '');
        } else {
            return val.replace(/\./g, '');
        }
    }

    public static cpf_mask(v: any) {
        v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
        v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o sexo e o setimo dígitos
        v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
        return v;
    }

    public static pis(v: any) {
        v = v.replace(/\D/g, '');
        v = v.replace(/(\d{3})(\d)/, '$1.$2');
        v = v.replace(/^(\d{3})\.(\d{5})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
        v = v.replace(/^(\d{3})\.(\d{5})\.(\d{2})(\d)/, '$1.$2.$3-$4'); // Coloca ponto entre o quinto e o sexto dígitos
        return v;
    }

    public static cnpj(v: any) {
        v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
        v = v.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
        v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca uma barra entre o oitavo e o nono dígitos
        v = v.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
        return v;
    }

    public static cep_mask(v: any) {
        v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
        v = v.replace(/(\d{5})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
        return v;
    }

    public static celular_mask(v: any) {
        v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
        v = v.replace(/(\d{2})(\d)/, '$1 $2'); // Coloca espaço entre o primeiro e o segundo digito
        v = v.replace(/(\d{5})(\d)/, '$1-$2'); // Coloca hífen entre o quinto e o sexto digito
        return v;
    }

    public static residencial_mask(v: any) {
        v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
        v = v.replace(/(\d{2})(\d)/, '$1 $2'); // Coloca espaço entre o primeiro e o segundo digito
        v = v.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca hífen entre o quarto e o quinto digito
        return v;
    }

    public static cns_mask(v: any) {
        v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
        v = v.replace(/^[0]/, ''); // Remove digito se o primeiro for 0
        v = v.replace(/^((?!(0))[0-9]{15})$/g, '$1');
        return v;
    }

    public static formatDdd(v: any) {
        v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
        v = v.replace(/^(\d\d)/g, '($1)'); // Coloca parênteses em volta dos dois primeiros dígitos
        return v;
    }

    public static date_mask(v: any) {
        v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
        v = v.replace(/(\d{2})(\d)/, '$1/$2'); // Coloca hífen entre o segundo e o terceiro digito
        v = v.replace(/(\d{2})(\d)/, '$1/$2'); // Coloca hífen entre o quarto e o quinto digito
        return v;
    }

    public static date_month_year_mask(v: any) {
        v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
        v = v.replace(/(\d{2})(\d)/, '$1/$2'); // Coloca hífen entre o segundo e o terceiro digito
        return v;
    }
}
