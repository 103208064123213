import React, {useEffect, useMemo} from 'react';
import {Container, PageContainer} from './styles'
import SistemaDrawer from '../SistemaDrawer';
import {Switch, useLocation} from 'react-router-dom';
import {AUTHORITIES} from '../../../authorities.constants';
import {User} from "../../features/backoffice/user";
import {UserCreate} from "../../features/backoffice/user/create";
import {UserEdit} from "../../features/backoffice/user/edit";
import {Dream} from "../../features/backoffice/dream";
import {Report} from "../../features/backoffice/report";
import {Company} from "../../features/backoffice/company";
import {LuckyNumber} from "../../features/backoffice/luckyNumber";
import {LotteryIntegration} from "../../features/backoffice/lotteryIntegration";
import {LileveUser} from "../../features/backoffice/lileve-user";
import {Service} from "../../features/service";
import {MyDream} from "../../features/myDream";
import {Installment} from "../../features/backoffice/installment";
import {InstallmentCreate} from "../../features/backoffice/installment/create";
import {InstallmentEdit} from "../../features/backoffice/installment/edit";
import {Partner} from "../../features/backoffice/partner";
import {PartnerCreate} from "../../features/backoffice/partner/create";
import {PartnerEdit} from "../../features/backoffice/partner/edit";
import {Parameter} from "../../features/backoffice/parameter";
import {ParameterEdit} from "../../features/backoffice/parameter/edit";
import {Professional} from "../../features/backoffice/professional";
import {ProfessionalCreate} from "../../features/backoffice/professional/create";
import {ProfessionalEdit} from "../../features/backoffice/professional/edit";
import {useAuthentication} from "../../context/AuthContext";
import {Testimonial} from "../../features/backoffice/testimonial";
import {TestimonialCreate} from "../../features/backoffice/testimonial/create";
import {TestimonialEdit} from "../../features/backoffice/testimonial/edit";
import {News} from "../../features/backoffice/news";
import {NewsCreate} from "../../features/backoffice/news/create";
import {NewsEdit} from "../../features/backoffice/news/edit";
import {Procedure} from "../../features/backoffice/procedure";
import {ProcedureCreate} from "../../features/backoffice/procedure/create";
import {ProcedureEdit} from "../../features/backoffice/procedure/edit";
import PrivateRoute from "../PrivateRoute";
import {PerformSell} from "../../features/backoffice/performSell";
import {Sell} from "../../features/backoffice/sell";
import CompleteData from "../../features/completeData";
import {useHistory} from "react-router";
import {Workgroup} from "../../features/backoffice/workgroup";
import {WorkgroupCreate} from "../../features/backoffice/workgroup/create";
import {WorkgroupEdit} from "../../features/backoffice/workgroup/edit";
import {Commission} from "../../features/backoffice/commission";
import {CommissionCreate} from "../../features/backoffice/commission/create";
import {CommissionEdit} from "../../features/backoffice/commission/edit";
import {Supervisor} from "../../features/backoffice/supervisor";
import {Seller} from "../../features/backoffice/seller";
import {SellerCreate} from "../../features/backoffice/seller/create";
import {SellerEdit} from "../../features/backoffice/seller/edit";
import {Coupon} from "../../features/backoffice/coupon";
import {CouponCreate} from "../../features/backoffice/coupon/create";
import {CouponEdit} from "../../features/backoffice/coupon/edit";

const SISTEMA_ROUTES = [
    {
        path: `services`,
        component: Service,
        authorities: [AUTHORITIES.USER]
    },
    {
        path: `page-lucky-number`,
        component: LuckyNumber,
        authorities: [AUTHORITIES.USER]
    },
    {
        path: `complete-data`,
        component: CompleteData,
        authorities: [AUTHORITIES.USER]
    },
    {
        path: `perform-sell`,
        component: PerformSell,
        authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SELLER, AUTHORITIES.SUPERVISOR]
    },
    {
        path: `sell`,
        component: Sell,
        authorities: [AUTHORITIES.SELLER]
    },
    {
        path: `supervisor`,
        component: Supervisor,
        authorities: [AUTHORITIES.SUPERVISOR]
    },
    {
        path: `lottery-integration`,
        component: LotteryIntegration,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `company`,
        component: Company,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `reports`,
        component: Report,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `lileve-users`,
        component: LileveUser,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `users`,
        component: User,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `users/create`,
        component: UserCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `users/edit/:login`,
        component: UserEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `sellers`,
        component: Seller,
        authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERVISOR]
    },
    {
        path: `sellers/create`,
        component: SellerCreate,
        authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERVISOR]
    },
    {
        path: `sellers/edit/:login`,
        component: SellerEdit,
        authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERVISOR]
    },
    {
        path: `workgroups`,
        component: Workgroup,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `workgroups/create`,
        component: WorkgroupCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `workgroups/edit/:id`,
        component: WorkgroupEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `commissions`,
        component: Commission,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `commissions/create`,
        component: CommissionCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `commissions/edit/:id`,
        component: CommissionEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `coupons`,
        component: Coupon,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `coupons/create`,
        component: CouponCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `coupons/edit/:id`,
        component: CouponEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `installments`,
        component: Installment,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `installments/create`,
        component: InstallmentCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `installments/edit/:id`,
        component: InstallmentEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `influencers`,
        component: Partner,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `influencers/create`,
        component: PartnerCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `influencers/edit/:id`,
        component: PartnerEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `parameters`,
        component: Parameter,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `parameters/edit/:id`,
        component: ParameterEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `professionals`,
        component: Professional,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `professionals/create`,
        component: ProfessionalCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `professionals/edit/:id`,
        component: ProfessionalEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `news`,
        component: News,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `news/create`,
        component: NewsCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `news/edit/:id`,
        component: NewsEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `procedures`,
        component: Procedure,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `procedures/create`,
        component: ProcedureCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `procedures/edit/:id`,
        component: ProcedureEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `testimonials`,
        component: Testimonial,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `testimonials/create`,
        component: TestimonialCreate,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `testimonials/edit/:id`,
        component: TestimonialEdit,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `services`,
        component: Service,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `services`,
        component: Service,
        authorities: [AUTHORITIES.ADMIN]
    },
    {
        path: `services`,
        component: Service,
        authorities: [AUTHORITIES.ADMIN]
    },

]


const SistemaContainer = () => {
    const { user: authUser } = useAuthentication();
    const location = useLocation();
    const { push } = useHistory();
    const isPendingUser = useMemo(() => {
        return authUser?.cpf?.includes('pnd');
    }, [authUser]);

    useEffect(
        () => {
            if (location.pathname !== '/sistema/complete-data' && isPendingUser) {
                push('/sistema/complete-data');
            }
        },
        [location, isPendingUser, push]);

    const handleCheckAdmin = useMemo(() => authUser?.authorities?.find((a: any) => a === AUTHORITIES.ADMIN), [authUser]);
    const handleCheckSupervisor = useMemo(() => authUser?.authorities?.find((a: any) => a === AUTHORITIES.SUPERVISOR), [authUser]);
    const handleCheckSeller = useMemo(() => authUser?.authorities?.find((a: any) => a === AUTHORITIES.SELLER), [authUser]);

    const handleCheckAdminVersion = useMemo(() => {
        if (handleCheckAdmin) {
            return Dream;
        } else {
            if (handleCheckSupervisor) {
                return Supervisor;
            } else if (handleCheckSeller) {
                return Sell;
            } else {
                return MyDream;
            }
        }
    }, [handleCheckAdmin, handleCheckSupervisor, handleCheckSeller]);

    return (
        <Container>
            <SistemaDrawer/>
            <PageContainer>
                <div style={{width: '100%', height: '100%', display: 'flex'}}>
                    <Switch>
                        {SISTEMA_ROUTES.map((sr: any) => (
                            <PrivateRoute key={sr.path} exact path={`/sistema/${sr.path}`}
                                          component={sr.component}
                                          hasAnyAuthorities={sr.authorities}/>
                        ))}
                        <PrivateRoute exact path={`/sistema/dreams`}
                                      component={handleCheckAdminVersion}
                                      hasAnyAuthorities={[AUTHORITIES.USER]}/>
                        <PrivateRoute path={``} component={handleCheckAdminVersion} />
                    </Switch>
                </div>
            </PageContainer>
        </Container>
    )
};

export default SistemaContainer;
