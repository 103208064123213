import React from "react";
import validationSchema from './ValidationSchema';
import {Form} from 'antd';
import {Formik} from "formik";
import {Btn, FormInputText, FormTextArea, Row} from "../../../../common";
import {AddressForm} from "../../../../components";
import {Colors, Metrics} from "../../../../config";
import {useHistory} from "react-router";
import Whitespace from "../../../../common/whitespace";
import FormUploader from "../../../../common/form/FormUploader";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'},
    title: {marginTop: Metrics.spacingLG, marginBottom: 0, color: Colors.primary},
};

const ProfessionalForm = ({onSubmit, initialValues, isFetching}: any) => {
    const {goBack} = useHistory();
    return (
        <div>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {({ handleSubmit, values }) => (
                    <Form>
                        <p style={styles.title}>Informações gerais</p>
                        <FormInputText label="Nome" name="name" value={values.name} />
                        <Row responsive>
                            <FormInputText fullWidth label="CRM" name="crm" value={values.crm} />
                            <Whitespace width={Metrics.spacingLG} />
                            <FormInputText fullWidth label="Especialidade" name="specialty" value={values.specialty} />
                        </Row>
                        <p style={styles.title}>Informações de contato</p>
                        <Row responsive>
                            <FormInputText fullWidth label="Whatsapp" name="whatsapp" value={values.whatsapp} />
                            <Whitespace width={Metrics.spacingLG} />
                            <FormInputText fullWidth label="Instagram" name="instagram" value={values.instagram} />
                        </Row>
                        <Row responsive>
                            <FormInputText fullWidth label="Linkedin" name="linkedin" value={values.linkedin} />
                            <Whitespace width={Metrics.spacingLG} />
                            <FormInputText fullWidth label="Facebook" name="facebook" value={values.facebook} />
                        </Row>
                        <FormInputText label="Telefone da Clínica" name="phone" value={values.phone} />
                        <p style={styles.title}>Endereço</p>
                        <AddressForm />
                        <p style={styles.title}>Qualificações</p>
                        <FormTextArea rows={8} label="Qualificações" name="services" value={values.services} />
                        <FormUploader success={false}
                                      file={true} fieldName="image" contentTypeField="imageContentType"/>
                        {
                            values?.image &&
                            <img
                                style={{marginBlock: Metrics.spacingSM, maxWidth: '350px'}}
                                src={`data:image/jpg;base64,${values?.image}`}
                                alt={'imagem'} />
                        }
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={goBack}
                                style={styles.btn}>Voltar</Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={() => handleSubmit()}
                                disabled={isFetching}
                                type='primary'
                                style={styles.btn}>
                                Salvar
                            </Btn>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default ProfessionalForm;
