import api from "./api";

export const findNewsByIdRequest = async (param: any, data: any) => {
    return api.get(`/api/news/${param}`, data);
};

export const findNewsRequest = async (param: any, data: any) => {
    const limitPreview = (preview: any) => {
        return preview.length > 154 ? `${preview.slice(0, 154)}...` : preview;
    }
    const response = await api.get(`/api/news`, {params: data});
    return {
        ...response,
        data: {
            ...response.data,
            data: response.data.data.map((d: any) => ({
                ...d,
                preview: limitPreview(d.preview)
            }))
        }
    }
};

export const createNewsRequest = (param: any, data: any) => {
    return api.post(`/api/news`, data);
};

export const updateNewsRequest = (param: any, data: any) => {
    return api.put(`/api/news`, data);
};

export const deleteNewsRequest = (param: any, data: any) => {
    return api.delete(`/api/news/${data}`);
};
