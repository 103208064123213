import {Col, FormDropdown, FormInputText, Row} from "../../../common";
import React from "react";
import {useFormikContext} from "formik";
import {AddressForm} from "../../index";
import {Metrics} from "../../../config";
import Whitespace from "../../../common/whitespace";


const ExtraInfo = () => {
    const { values }: any = useFormikContext();

    return (
        <Col>
            <h3>Informações adicionais</h3>
            <Row>
                <FormInputText fullWidth label="RG" name='extraPersonalInfo.idNumber' value={values.extraPersonalInfo.idNumber} placeholder='RG' />
                <Whitespace width={Metrics.spacingLG} />
                <FormInputText fullWidth label="Nome da mãe" name='extraPersonalInfo.motherName' value={values.extraPersonalInfo.motherName} placeholder='Nome da mãe' />
            </Row>
            <Row>
                <FormDropdown
                    fullWidth
                    formStyle={{marginTop: Metrics.spacingSM}}
                    label="Nacionalidade"
                    value={values.extraPersonalInfo.nationality}
                    name="extraPersonalInfo..nationality"
                    list={[
                        {label: 'Brasileira', value: 55},
                        {label: 'Estrangeira', value: 99},
                    ]}
                />
                <Whitespace width={Metrics.spacingLG} />
                <FormDropdown
                    fullWidth
                    formStyle={{marginTop: Metrics.spacingSM}}
                    label="Área de atuação"
                    value={values.employmentInfo.occupationGroup}
                    name="employmentInfo.occupationGroup"
                    list={[
                        {label: 'Educação', value: 0},
                        {label: 'Direito', value: 1},
                        {label: 'Saúde', value: 2},
                        {label: 'Engenharia e Arquitetura', value: 3},
                        {label: 'Negócios e Economia', value: 4},
                        {label: 'Comunicação e Design', value: 5},
                        {label: 'Corretor de imóveis', value: 6},
                        {label: 'Agronegócio', value: 7},
                        {label: 'Tecnologia', value: 8},
                        {label: 'Transporte', value: 9},
                        {label: 'Comércio', value: 10},
                        {label: 'Outra área', value: 99},
                    ]}
                />
            </Row>
            <AddressForm prefix={'homeInfo'} />
        </Col>
    );
};

export default ExtraInfo;
