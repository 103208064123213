import React, {useEffect, useMemo, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import Menubar from './new/components/Menubar';
import PrivateRoute from './new/components/PrivateRoute';
import Registration from './new/features/registration';
import About from './new/features/about';
import HowWorks from './new/features/howWorks';
import Simulation from './new/features/simulation';
import HomeNew from './new/features/home';
import Success from "./new/features/success";
import {Modal, Tooltip} from "antd";
import {useLocation} from "react-router";
import Topbar from "./new/components/Topbar";
import {useAuthentication} from "./new/context/AuthContext";
import {LoginForm} from "./new/components";
import PasswordRecovery from "./new/features/passwordRecovery";
import SistemaContainer from "./new/components/SistemaContainer";
import LgpdWarn from "./new/components/LgpdWarn";
import PrivacyPolicy from "./new/features/privacyPolicy";
import Terms from "./new/features/terms";
import wup from "./assets/images/whatsapp-icon.png";
import Partnership from "./new/features/partnership";


const SistemaPage = () => <SistemaContainer/>;

const SystemRoutes = (props: any) => {
    return (
        <Switch>
            <Route path={`${props.match.path}/reset/finish/:key`} component={PasswordRecovery}/>
            <Route path={`${props.match.path}/reset-password`} component={PasswordRecovery}/>
            <PrivateRoute path={`${props.match.path}`} component={SistemaPage}/>
        </Switch>
    );
}


export default () => {
    const {isLoginVisible, setIsLoginVisible, handleAcceptLgpd, acceptedLgpd } = useAuthentication()
    const { pathname } = useLocation();
    const [isDarkMenu, setIsDarkMenu] = useState<boolean>();

    const visibleTopMenu: any = useMemo(() =>
        pathname && pathname?.indexOf('sistema') === -1, [pathname]);

    useEffect(() => {
        const onScroll = (e: any) => {
            if (pathname?.indexOf('home') === -1 && pathname !== '/') {
                setIsDarkMenu(true);
            } else {
                setIsDarkMenu(e.target.documentElement.scrollTop > 25);
            }
        };
        window.addEventListener("scroll", onScroll);
        window.scrollTo(0,0);
        if (pathname?.indexOf('home') === -1 && pathname !== '/') {
            setIsDarkMenu(true);
        } else {
            setIsDarkMenu(false);
        }
        return () => window.removeEventListener("scroll", onScroll);
    }, [pathname]);

    return (
        <div style={{height: '100%'}}>
            {
                visibleTopMenu &&
                <Topbar isDarkMenu={isDarkMenu && false} setIsLoginVisible={setIsLoginVisible}>
                    <Menubar/>
                </Topbar>
            }
            {
                isLoginVisible &&
                <Modal open={isLoginVisible} footer={null} onCancel={() => setIsLoginVisible(false)}>
                    <LoginForm />
                </Modal>
            }
            <div style={{height: '100%'}}>
                <Switch>
                    <Route path="/sistema" component={SystemRoutes}/>
                    <Route exact path="/registration" component={Registration}/>
                    <Route exact path="/simulation" component={Simulation}/>
                    <Route exact path="/how-works" component={HowWorks}/>
                    <Route exact path="/partnership" component={Partnership}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/terms" component={Terms}/>
                    <Route exact path="/privacy" component={PrivacyPolicy}/>
                    <Route path="/success" component={Success}/>
                    <Route path="/" component={HomeNew}/>
                </Switch>
            </div>
            {
                !acceptedLgpd &&
                <LgpdWarn setLgpd={handleAcceptLgpd} />
            }
            <a href={`https://api.whatsapp.com/send?phone=554832096404`} target="noreferrer" style={{ position: 'fixed', bottom: 15, right: 15, zIndex: 9999}}>
                <Tooltip placement="leftTop" title="Clique para falar conosco através do WhatsApp">
                    <img
                        className="growing"
                        style={{width: '50px'}} src={wup}/>
                </Tooltip>
            </a>
        </div>
    )
}
