import React, {useCallback} from 'react'
import {useFormikContext} from "formik";
import {Checkbox, FormItem} from 'formik-antd';
import {Metrics} from "../../../config";

const FormCheckbox = ({name, required, label, onChange, showValidateSuccess, formStyle}: any) => {
    const {values, setFieldValue}: any = useFormikContext();
    const handleChange = useCallback( (event: any) => {
        setFieldValue(name, !values[name]);
        if (onChange) {
            onChange(!values[name]);
        }
    }, [setFieldValue, name, values, onChange]);

    return (
        <FormItem
            className={'antd-checkbox'}
            style={{ width: '100%', ...formStyle }}
            name={name}
            required={required}
            showValidateSuccess={showValidateSuccess}>
            <Checkbox name={name} checked={values[name]} onChange={handleChange}/>
            <label style={{marginLeft: Metrics.spacingMD, lineHeight: '10px'}} onClick={handleChange}>{label}</label>
        </FormItem>
    );
};

export default FormCheckbox
