import styled from "styled-components";
import {Colors, Metrics} from "../../config";

export const ContractOption = styled.div<any>`
    display: flex;
    box-shadow: 1px 1px 15px #e0e0e0;
    padding: ${() => Metrics.spacingMD};
    margin-block: ${() => Metrics.spacingMD};
    border-radius: 15px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    border: ${({selected}) => selected ? `3px solid ${Colors.secondary}` : 'unset'};
    transition: all 0.3s ease;
  &:active {
      color: ${() => Colors.secondary};
      transform: scale(1.1) !important;
    }
    &:focus {
      color: ${() => Colors.secondary};
      transform: scale(1.1) !important;
    }
    :hover {
      color: ${() => Colors.secondary};
      opacity: 0.9;
      transform: scale(1.05);
      cursor: pointer;
    }
`;

export const ContractLink = styled.a<any>`
  display: flex;
  box-shadow: 1px 1px 15px #e0e0e0;
  padding: ${() => Metrics.spacingMD};
  margin-block: ${() => Metrics.spacingMD};
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.3s ease;
  &:active {
    color: ${() => Colors.secondary};
    transform: scale(1.1) !important;
  }
  &:focus {
    color: ${() => Colors.secondary};
    transform: scale(1.1) !important;
  }
  :hover {
    color: ${() => Colors.secondary};
    opacity: 0.9;
    transform: scale(1.05);
    cursor: pointer;
  }
`;

export const InstallmentOption = styled.div<any>`
    border: none;
    transition: all 0.3s ease;
    background-color: transparent;
    margin-block: ${() => Metrics.spacingMinimun};
    transform: ${({selected}) => selected ? 'scale(1.05)' : 'unset'};
    color: ${({selected}) => selected ? Colors.secondary : 'unset'};
    font-family: ${({selected}) => selected ? 'montserrat-bold' : 'montserrat-semibold'};
    &:active {
      color: ${() => Colors.secondary};
      transform: scale(1.12) !important;
    }
    &:focus {
      color: ${() => Colors.secondary};
      transform: scale(1.12) !important;
    }
    :hover {
      color: ${() => Colors.secondary};
      opacity: 0.9;
      transform: scale(1.05);
      cursor: pointer;
    }
`;
