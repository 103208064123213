import React, {useState} from 'react';
import {useFormikContext} from "formik";
import compraProgramadaImg from "../../../../assets/images/iconCompraProgramada.svg";
import financiamentoImg from "../../../../assets/images/iconFinanciamento.svg";
import pig from "../../../../assets/images/pigyellow.png";
import {FinancingModal} from "../../index";
import {Col} from 'new/common';
import {Colors, Metrics} from "../../../config";
import {ContractOption} from '../styles';
import {useLocation, useHistory} from "react-router";

export const DUE_DAYS = [
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 7},
    {value: 16},
    {value: 21},
    {value: 23},
    {value: 27},
];


const styles = {
    typeImg: {
        width: '90px'
    },
    title: {
        fontSize: Metrics.fontSize.xxsm,
        color: Colors.secondary
    },
    selectLabel: {
        fontSize: Metrics.fontSize.xxsm,
        marginBlock: Metrics.spacingSM,
        color: Colors.primaryDark
    },
    description: {
        fontSize: Metrics.fontSize.xxsm,
        color: Colors.text,
        fontWeight: 'bolder',
        marginBottom: Metrics.spacingSM
    },
    typeBlock: {
        display: 'flex',
        boxShadow: '1px 1px 15px #e0e0e0',
        padding: Metrics.spacingMD,
        marginBlock: Metrics.spacingSM,
        borderRadius: '15px',
        width: '100%',
        cursor: 'pointer',
        boxSizing: 'border-box' as const,
        textDecoration: 'none'
    },
    valueBlock: {
        boxShadow: '1px 1px 15px #e0e0e0',
        padding: Metrics.spacingMD,
        marginBlock: Metrics.spacingSM,
        borderRadius: '15px',
        width: '100%',
        cursor: 'pointer'
    },
    textBlock: {
        marginLeft: Metrics.spacingMD
    },
    btnSecondary: {
        marginInline: Metrics.spacingMD,
        minWidth: '250px'},
    firstPayment: {
        textAlign: 'center' as const,
        color: Colors.primary,
        marginBlock: Metrics.spacingMD,
    }
}

const StepContainer = ({children}: any) => (
    <Col fullWidthResponsive style={{marginTop: Metrics.spacingMD, alignItems: 'center'}}>
        <Col fullWidthResponsive style={{width: '80%'}}>
            {children}
        </Col>
    </Col>
);


const ContractTypes = ({setStep, seller}: any) => {
    const path = useLocation();
    const isSimulation = path.pathname.includes('simulation');
    const { setFieldValue, values }: any = useFormikContext();
    const [isFinancingModal, setIsFinancingModal] = useState<any>();
    return (
        <StepContainer>
            <FinancingModal visible={isFinancingModal} setVisible={setIsFinancingModal} />
            <span style={styles.selectLabel}>Selecione o tipo de serviço desejado:</span>
            {seller &&
                <ContractOption
                    style={{
                        display: 'flex',
                        boxShadow: '1px 1px 15px #e0e0e0',
                        padding: Metrics.spacingMD,
                        marginBlock: Metrics.spacingMD,
                        borderRadius: '15px',
                        width: '100%',
                        cursor: 'pointer',
                        boxSizing: 'border-box',
                        textDecoration: 'none',
                    }}
                    onClick={() => {
                        setFieldValue('type', 'SILICASH')
                        setStep(1);
                    }}>
                    <img alt={'financiamento'} style={styles.typeImg} src={financiamentoImg}/>
                    <Col style={{
                        marginLeft: Metrics.spacingLG,
                        justifyContent: 'center'
                    }}>
                        <span style={{
                            fontSize: Metrics.fontSize.md,
                            color: Colors.secondary
                        }}>Silicash</span>
                    </Col>
                </ContractOption>
            }
            <ContractOption
                style={{
                    display: 'flex',
                    boxShadow: '1px 1px 15px #e0e0e0',
                    padding: Metrics.spacingMD,
                    marginBlock: Metrics.spacingMD,
                    borderRadius: '15px',
                    width: '100%',
                    cursor: 'pointer',
                    boxSizing: 'border-box',
                    textDecoration: 'none',
                }}
                selected={values.type === 'PLANNED_SURGERY'}
                onClick={() => {
                    setFieldValue('type', 'PLANNED_SURGERY')
                    setStep(1);
                }}>
                <img style={styles.typeImg} src={compraProgramadaImg} />
                <Col style={styles.textBlock}>
                    <span style={styles.title}>Data Certa</span>
                    <span style={styles.description}>Realiza seu procedimento na metade do período contratado.</span>
                </Col>
            </ContractOption>
            <ContractOption
                style={{
                    display: 'flex',
                    boxShadow: '1px 1px 15px #e0e0e0',
                    padding: Metrics.spacingMD,
                    marginBlock: Metrics.spacingMD,
                    borderRadius: '15px',
                    width: '100%',
                    cursor: 'pointer',
                    boxSizing: 'border-box',
                    textDecoration: 'none',
                }}
                selected={values.type === 'SCHEDULED_SURGERY'}
                onClick={() => {
                setFieldValue('type', 'SCHEDULED_SURGERY')
                setStep(1);
            }}>
                <img style={styles.typeImg} src={pig} />
                <Col style={styles.textBlock}>
                    <span style={styles.title}>Procedimento Programado</span>
                    <span style={styles.description}>
                        A opção mais tranquila de programar seu sonho
                    </span>
                </Col>
            </ContractOption>
            {isSimulation && <ContractOption
                style={{
                    display: seller ? 'none' : 'flex',
                    boxShadow: '1px 1px 15px #e0e0e0',
                    padding: Metrics.spacingMD,
                    marginBlock: Metrics.spacingMD,
                    borderRadius: '15px',
                    width: '100%',
                    cursor: 'pointer',
                    boxSizing: 'border-box',
                    textDecoration: 'none',
                }}
                onClick={() => setIsFinancingModal(true)}>
                <img alt={'financiamento'} style={styles.typeImg} src={financiamentoImg}/>
                <Col style={styles.textBlock}>
                    <span style={styles.title}>Financiamento</span>
                    <span style={styles.description}>
                        Para você que tem pressa em realizar seu sonho de imediato.
                    </span>
                </Col>
            </ContractOption>}
            {!isSimulation && <>
                <ContractOption
                    style={{
                        display: 'flex',
                        boxShadow: '1px 1px 15px #e0e0e0',
                        padding: Metrics.spacingMD,
                        marginBlock: Metrics.spacingMD,
                        borderRadius: '15px',
                        width: '100%',
                        cursor: 'pointer',
                        boxSizing: 'border-box',
                        textDecoration: 'none',
                    }}
                    onClick={() => {
                        window.open('https://silicred.samclub.com.br/parceiro/login', '_blank');
                    }}>
                    <img alt={'financiamento'} style={styles.typeImg} src={financiamentoImg}/>
                    <Col style={styles.textBlock}>
                        <span style={styles.title}>Banco BV</span>
                        <span style={styles.description}>
                        Para você que tem pressa em realizar seu sonho de imediato.
                    </span>
                    </Col>
                </ContractOption>
            </>}
                {/*<ContractOption*/}
                {/*    style={{*/}
                {/*        display: 'flex',*/}
                {/*        boxShadow: '1px 1px 15px #e0e0e0',*/}
                {/*        padding: Metrics.spacingMD,*/}
                {/*        marginBlock: Metrics.spacingMD,*/}
                {/*        borderRadius: '15px',*/}
                {/*        width: '100%',*/}
                {/*        cursor: 'pointer',*/}
                {/*        boxSizing: 'border-box',*/}
                {/*        textDecoration: 'none',*/}
                {/*    }}*/}
                {/*    onClick={() => {*/}
                {/*        window.open('https://parceiros.geru.com.br/#/pages/login', '_blank');*/}
                {/*    }}>*/}
                {/*    <img alt={'financiamento'} style={styles.typeImg} src={financiamentoImg}/>*/}
                {/*    <Col style={styles.textBlock}>*/}
                {/*        <span style={styles.title}>Financeira OPENCO</span>*/}
                {/*        <span style={styles.description}>*/}
                {/*        Para você que tem pressa em realizar seu sonho de imediato.*/}
                {/*    </span>*/}
                {/*    </Col>*/}
                {/*</ContractOption>*/}

            {/*<ContractLink*/}
            {/*    style={{*/}
            {/*        display: seller ? 'none' : 'flex',*/}
            {/*        boxShadow: '1px 1px 15px #e0e0e0',*/}
            {/*        padding: Metrics.spacingMD,*/}
            {/*        marginBlock: Metrics.spacingMD,*/}
            {/*        borderRadius: '15px',*/}
            {/*        width: '100%',*/}
            {/*        cursor: 'pointer',*/}
            {/*        boxSizing: 'border-box',*/}
            {/*        textDecoration: 'none',*/}
            {/*    }}*/}
            {/*    href={'https://www.simule.com.br/silicred.consorcios/consorcio'}*/}
            {/*              target="_blank">*/}
            {/*    <img style={styles.typeImg} src={consorcioImg} />*/}
            {/*    <Col style={styles.textBlock}>*/}
            {/*        <span style={styles.title}>Consórcio</span>*/}
            {/*        <span style={styles.description}>*/}
            {/*            Os participantes adquirem uma cota e pagam prestações mensais, gerando recursos para a contemplação dos consorciados.*/}
            {/*        </span>*/}
            {/*    </Col>*/}
            {/*</ContractLink>*/}
        </StepContainer>
    );
}

export default ContractTypes;
