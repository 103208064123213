import React from 'react';
import {useHistory} from 'react-router';
import CouponForm from '../form';
import useFetch from "../../../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../../../configs/toast";
import {TitledContainer} from "../../../../components";
import {createCouponRequest} from "../../../../services/coupon";

const initialValues = {
    code: '',
    value: '',
    percentage: '',
}

export const CouponCreate = () => {
  const {goBack} = useHistory();
  const [{isFetching}, create,] = useFetch(
    {
      provider: createCouponRequest,
      param: '',
      requestOnMount: false,
      initialData: null,
      resultHandler: {
        success: () => {
          showSuccessToast('Registro criado com sucesso!')
          goBack();
        },
        error: (d: any) => showErrorToast(d)
      }
    }
  );

  return (
      <TitledContainer title={'Cupons de Desconto'}>
          <CouponForm goBack={goBack}
            isFetching={isFetching}
            onSubmit={create}
            initialValues={initialValues}
          />
      </TitledContainer>
  );
}
