import React from "react";
import {GenericList} from "../../../components";
import {deleteProfessionalRequest, findProfessionalsRequest} from "../../../services/professional";

export const Professional = () => {
    const columns: any = [
        {
            title: 'Nome',
            key: 'name'
        },
        {
            title: 'CRM',
            key: 'crm',
        }
    ]
  return (
        <GenericList
            deletable
            alternate
            title={'Profissionais'}
            newPath={'professionals/create'}
            editPath={'professionals/edit'}
            providerFind={findProfessionalsRequest}
            providerDelete={deleteProfessionalRequest}
            columns={columns}
        />
  );
};
