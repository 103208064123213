import api from "./api";

export const findProcedureByIdRequest = async (param: any, data: any) => {
    return api.get(`/api/procedures/${param}`, data);
};

export const findProceduresRequest = (param: any, data: any) => {
    return api.get(`/api/procedures`, {params: data});
};

export const createProcedureRequest = (param: any, data: any) => {
    return api.post(`/api/procedures`, data);
};

export const updateProcedureRequest = (param: any, data: any) => {
    return api.put(`/api/procedures`, data);
};

export const deleteProcedureRequest = (param: any, data: any) => {
    return api.delete(`/api/procedures/${data}`);
};
