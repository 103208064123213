import React from "react";
import {Modal, Spin, Table as AntdTable} from "antd";
import moment from "moment";
import useBreakpoint from "../../../hooks/useBreakpoint";
import {downloadBankslipRequest} from "../../../services/dream";
import {ActionIcons, Btn, Col, Row, Table} from "../../../common";
import useFetch from "../../../hooks/useFetch";
import Filters from "../../../../configs/Filters";
import {getLabelBankSlipSituacion} from "../../../../util";
import {Colors, Metrics} from "../../../config";
import {PaymentStatusEnum} from "../../../../constants";


const {Column} = AntdTable;
const styles = {
    title: {color: Colors.primary},
    fieldName: {color: 'rgb(133 133 133)', marginRight: Metrics.spacingSM}
}

const getColorFromPayment = (situacao: PaymentStatusEnum | undefined) => {
    switch (situacao) {
        case PaymentStatusEnum.OVERDUE:
            return '#ba4142';
        case PaymentStatusEnum.PAID:
            return '#6dbd79';
        case PaymentStatusEnum.PENDING:
            return '#CCCCCC';
    }
};

export const DreamDetail = ({detail: data, setDetail}: any) => {
    const {isMobile} = useBreakpoint();
    const [{ isFetching: isFetchingDownload }, download] = useFetch(
        {
            provider: downloadBankslipRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: (res: any) => {
                    const link = document.createElement('a');
                    link.href = res.data._links.payBoleto.printHref;
                    link.target = '_blank';
                    link.click();
                }
            }
        });
    return (
        <Modal open={data} footer={<Btn onClick={() => setDetail(null)}>Fechar</Btn>} onCancel={() => setDetail(null)} width={isMobile ? '100%' : '70%'}>
            <Modal footer={null} closable={false} open={isFetchingDownload}>Aguarde! Carregando boleto... <Spin /></Modal>
            {data &&
                <Col>
                    <p style={styles.title}><b>Parcelas</b></p>
                    <Table
                        indentSize={2}
                        style={isMobile ? {width: '100%'} : {}}
                        showHeader={false}
                        data={data?.userPayments.filter((up: any) => up.parcelNumber)
                            .sort((a: any, b: any) => {
                                // @ts-ignore
                                return a.parcelNumber > b.parcelNumber ? 1 : -1;
                            })}>
                        {!isMobile &&
                        <Column title={''} dataIndex="parcelNumber" key="parcelNumber" render={(_, rowData: any) => <span>{rowData?.parcelNumber}</span>}  />
                        }
                        <Column title={''} dataIndex="dueDate" key="dueDate"
                                render={(_, rowData: any) => <span>{moment(rowData?.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>}  />
                        <Column title={''} dataIndex="value" key="value" render={(_, rowData: any) => <span>{Filters.convertMoneyTextMask(rowData?.value)}</span>}  />
                        <Column title={''} dataIndex="status" key="status"
                                render={(_, rowData: any) =>
                                    <Row>
                                        <span style={{color: getColorFromPayment(rowData?.status)}}>
                                            {getLabelBankSlipSituacion(rowData?.status, 'md')}
                                        </span>
                                        {
                                            rowData?.paymentDate ?
                                        <span style={{marginLeft: Metrics.spacingMinimun, fontSize: Metrics.fontSize.xxxsm}}>
                                            ({moment(rowData?.paymentDate).format('DD/MM/YYYY')})
                                        </span> : ''
                                        }
                                    </Row>
                                }
                        />
                        <Column
                            title={''}
                            dataIndex="action"
                            key="action"
                            render={(i, record: any) => (
                                <Row>
                                    {
                                        record?.status !== PaymentStatusEnum.PAID &&
                                        <ActionIcons
                                            type={"download"}
                                            actionFn={() => !isFetchingDownload ? download(record.id) : () => {
                                            }}
                                        />
                                    }
                                </Row>
                            )}
                        />
                    </Table>
                </Col>
            }
        </Modal>
    );
};
