import * as Yup from "yup";
import {MaskUtils} from "../../../util/MasksUtil";
import {validateCPF} from "../../../util/CPFUtil";
import {DateUtils} from "../../../util/DateUtils";

export const ExistingUserValidationSchema = Yup.object().shape({
    id: Yup.number()
        .nullable()
        .required('O campo Usuário é obrigatório.'),
});

export const NewUserValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email('O E-mail informado não é válido.')
        .trim()
        .nullable()
        .required('O campo E-mail é obrigatório.'),
    name: Yup.string()
        .required('O campo Nome é obrigatório.'),
    cpf: Yup.string()
        .trim()
        .nullable()
        .required('O campo CPF é obrigatório')
        .max(14, 'O CPF informado não é válido.')
        .min(14, 'O CPF informado não é válido.')
        .test('cpf', 'O CPF Informado não é válido.', val => {
            return validateCPF(val || '')
        }),
    rg: Yup.string()
        .trim()
        .nullable()
        .required('RG é obrigatório.'),
    gender: Yup.mixed().required('O campo Sexo é obrigatório.'),
    phone: Yup.string()
        .trim()
        .nullable()
        .required('O Campo Telefone obrigatório.')
        .test('phone', 'O Telefone informado não é válido.', val => {
            const phoneUnformat = MaskUtils.unFormat(val || '');
            return phoneUnformat.length === 10 || phoneUnformat.length === 11;
        }),
    birthdate: Yup.string()
        .min(8, 'A Data de Nascimento informada não é valida')
        .nullable()
        .required('O Campo Data de Nascimento é obrigatório.')
        .test('birthdate', 'A Data de Nascimento informada não é valida', val => {
            return DateUtils.validateStringBRDateLimitToday(val);
        }),
    address: Yup.object().shape({
        street: Yup.string()
            .nullable()
            .required('O Campo Endereço é obrigatório.'),
        state: Yup.string()
            .nullable()
            .required('O Campo Estado é obrigatório.'),
        district: Yup.string()
            .nullable()
            .required('O Campo Bairro é obrigatório.'),
        postalCode: Yup.string()
            .trim()
            .required('O Campo CEP é obrigatório.'),
        city: Yup.string()
            .nullable()
            .required('O Campo Cidade é obrigatório.'),
        number: Yup.string()
            .nullable()
            .required('O Campo Número é obrigatório.')
    })
});
