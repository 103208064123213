import React, {useCallback} from 'react';
import {Col, FormInputText, Row} from "../../common";
import {Metrics} from "../../config";
import {useFormikContext} from "formik";
import useFetch from "../../hooks/useFetch";
import {findAddressByCepRequest} from "../../services/address";
import {MaskUtils} from "../../../util/MasksUtil";
import Whitespace from "../../common/whitespace";


const AddressForm = ({prefix = 'address'}) => {
    const {values, setFieldValue}: any = useFormikContext();
    const handleNewAddress = useCallback( (data) => {
        setFieldValue(`${prefix}.street`, data.street);
        setFieldValue(`${prefix}.state`, data.state);
        setFieldValue(`${prefix}.city`, data.city);
        setFieldValue(`${prefix}.district`, data.district);
    }, [prefix, setFieldValue]);
    const [{ isFetching }, findAddressByCep] = useFetch({
        provider: findAddressByCepRequest,
        param: "",
        requestOnMount: false,
        initialData: [],
        resultHandler: {
            success: ({data: d}: any) => handleNewAddress(d),
        },
    });
    const handleCepChange = useCallback( (event: any) => {
        const value = MaskUtils.formatCep(event);
        if (value.length === 9) {
            findAddressByCep(value.replace('-', ''));
        }
    }, [findAddressByCep]);

    return (
      <Col>
          <FormInputText required label="CEP"
            name={`${prefix}.postalCode`}
            value={MaskUtils.formatCep(values[prefix]?.postalCode) || ''}
            onChange={handleCepChange}/>
          <FormInputText required disabled={isFetching} label="Rua"
            name={`${prefix}.street`}
            value={values[prefix]?.street} />
          <FormInputText required disabled={isFetching}
             label="Bairro"
             name={`${prefix}.district`}
             value={values[prefix]?.district} />
          <Row responsive>
              <FormInputText required fullWidth disabled={isFetching}
                 label="Cidade"
                 name={`${prefix}.city`}
                 value={values[prefix]?.city} />
              <Whitespace width={Metrics.spacingLG} />
              <FormInputText required fullWidth disabled={isFetching}
                 label="Estado"
                 name={`${prefix}.state`}
                 value={values[prefix]?.state} />
          </Row>
          <Row responsive>
              <FormInputText required fullWidth label="Número"
                 name={`${prefix}.number`}
                 value={values[prefix]?.number} />
              <Whitespace width={Metrics.spacingLG} />
              <FormInputText fullWidth label="Complemento"
                 name={`${prefix}.complement`}
                 value={values[prefix]?.complement} />
          </Row>
      </Col>
    );
}

export default AddressForm;
