import React, {useCallback, useState} from 'react';
import './topbar.css';
import logo from 'assets/images/logo-transparent.png';
import newLogo from 'assets/images/newlogo.png';
import styled from 'styled-components';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {Link as LinkRouter} from "react-router-dom";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Metrics} from "../../../configs";
import {useAuthentication} from "../../context/AuthContext";
import {CloseIcon, MenuIcon} from "../ActionIconsDrawer/styles";
import {Col, Row} from "../../common";
import SideMenubar from "../SideMenubar";
import {useHistory, useLocation} from "react-router";
import useBreakpoint from "../../hooks/useBreakpoint";

export const Link = styled(LinkRouter)<any>`
  text-decoration: none;
  font-size: ${() => Metrics.fontSize.xxsm};
  color: #3c3e39 !important;
  place-self: ${props => props.placeself || ''};
  transition: all 0.3s ease;
  }
`;

export const PhoneIconStyled = styled(PhoneIcon)`
    color: #f3bf57;
`;

export const FacebookIconStyled = styled(FacebookIcon)`
    color: #3c3e39;
`;

export const InstagramIconStyled = styled(InstagramIcon)`
    color: #3c3e39;
`;

export const LinkedInIconStyled = styled(LinkedInIcon)`
    color: #3c3e39;
`;

const ResponsiveMenuButton = styled<any>(MenuIcon)`
  color: black;
    @media (min-width: 768px) {
        display: none;
    }
`;

export const ResponsiveCloseButton = styled<any>(CloseIcon)`
  color: black;
    @media (min-width: 768px) {
        display: none;
    }
`;

const ResponsiveMenuButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
    @media (min-width: 768px) {
        display: none;
    }
`;

const LogoStyled = styled.img`
    max-width: 100px;
    transition: all 0.5s ease;
    @media (max-width: 1150px) {
        max-width: 60px;
    }
`;

type PropsNav = {
    isDarkMenu?: boolean
}

const NavStyled = styled.nav<PropsNav>`
    background: ${props => props.isDarkMenu ? `#7c685d` : `rgba(0, 0, 0, 0)`};
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    transition: all 0.3s ease;
`;

const styles = {
    icon: {
        borderRadius: '100px'
    },
    menuItems: {
        textTransform: 'uppercase' as const,
        marginInline: Metrics.spacingSM,
        fontFamily: 'montserrat-bold',
        cursor: 'pointer',
        color: '#3c3e39',
        textDecoration: 'none',
        fontSize: Metrics.fontSize.xxsm
    }
}

const Topbar: any = ({children, history, setIsLoginVisible, isDarkMenu}: any) => {
    const { user: authUser, authenticated }: any = useAuthentication();
    const { isMobile } = useBreakpoint();
    const [visible, setVisible] = useState(false);
    const {pathname} = useLocation();
    const handleCloseMenu = () => {
        setVisible(false);
    };

    const handleOpenResponsiveMenuButton = useCallback( () => setVisible(true), [setVisible]);
    const handleCloseResponsiveMenuButton = useCallback( () => setVisible(false), [setVisible]);

    return (
            <Col style={{backgroundColor: isMobile ? '#e9e9e9' : 'unset'}}>
                <ResponsiveMenuButtonContainer>
                    {
                        visible ?
                            <ResponsiveCloseButton onClick={handleCloseResponsiveMenuButton}/> :
                            <ResponsiveMenuButton onClick={handleOpenResponsiveMenuButton}/>
                    }
                    <Link to="/" style={{position: 'absolute', top: 0, right: 0}}>
                        <img src={newLogo} style={{height: '60px', width: '190px'}} />
                    </Link>
                </ResponsiveMenuButtonContainer>
                <SideMenubar setIsLoginVisible={setIsLoginVisible} isSideMenuVisible={visible} handleCloseMenu={handleCloseMenu}/>
                <Col style={{display: isMobile ? 'none' : 'flex', position: 'relative', color: '#3C3E39'}}>
                    <div style={{
                        position: 'absolute',
                        left: '11%',
                        bottom: 11,
                        width: '140px',
                        height: '20px',
                        borderRadius: '150px',
                        backgroundColor: 'black',
                        boxShadow: '1px 10px 10px black',
                        zIndex: 0
                    }} />
                    <Row style={{justifyContent: 'flex-end', alignItems: 'center', paddingBlock: Metrics.spacingMinimun}}>
                        <Link style={styles.menuItems} to="/registration/">Cadastre-se</Link>
                        {
                            authenticated ?
                                <Link style={styles.menuItems} to="/sistema">
                                    {authUser ? authUser?.name?.split(' ')?.[0] : "Usuário"}
                                </Link>
                                :
                                <span onClick={setIsLoginVisible} style={styles.menuItems}>Entrar</span>
                        }
                        {/*<FacebookIcon style={styles.icon} />*/}
                        {/*<InstagramIcon style={styles.icon} />*/}
                        {/*<LinkedInIcon style={styles.icon} />*/}
                        <a target="noreferrer"
                           href="https://www.facebook.com/silicred">
                            <FacebookIconStyled />
                        </a>
                        <a target="noreferrer"
                           href="https://www.instagram.com/silicred/">
                            <InstagramIconStyled />
                        </a>
                        <a target="noreferrer" href="https://www.linkedin.com/company/silicred-solu%C3%A7%C3%B5es-financeiras/mycompany/?viewAsMember=true">
                            <LinkedInIconStyled />
                        </a>
                    </Row>
                    <Row style={{
                        background: '#e9e9e9',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '9%',
                        zIndex: 1,
                    }}>
                        <Col>
                            <Link to="/">
                                <img src={newLogo} style={{height: '60px', width: '190px'}} />
                            </Link>
                        </Col>
                        <Row style={{alignItems: 'center'}}>
                            { pathname === '/' ?
                                <AnchorLink
                                    style={styles.menuItems}
                                    href={'#aboutUs'}>Sobre nós</AnchorLink>
                            :
                                <Link style={styles.menuItems} to="/">Sobre nós</Link>
                            }
                            <Link style={styles.menuItems} to="/how-works/">Como funciona</Link>
                            <Link style={styles.menuItems} to="/simulation/">Simulação</Link>
                            <Link style={styles.menuItems} to="/partnership/">Seja parceiro</Link>
                            { pathname === '/' ?
                                <AnchorLink style={styles.menuItems} href={'#testimonial'}>Depoimentos</AnchorLink>
                                :
                                <Link style={styles.menuItems} to="/">Depoimentos</Link>
                            }
                            {/*<a target={'_blank'} style={{textTransform: 'uppercase' as const,*/}
                            {/*    fontFamily: 'montserrat-bold',*/}
                            {/*    textDecoration: 'none',*/}
                            {/*    fontSize: Metrics.fontSize.xxxsm,*/}
                            {/*    padding: '6px 12px',*/}
                            {/*    borderRadius: '12px',*/}
                            {/*    backgroundColor: '#073B4C',*/}
                            {/*    color: 'white'}}*/}
                            {/*   href="https://silicred.samclub.com.br/parceiro/login">*/}
                            {/*    Acesso clínicas*/}
                            {/*</a>*/}
                        </Row>
                    </Row>
                </Col>
            </Col>
        );
};

export default Topbar;
