import styled from 'styled-components';
import {MenuIcon} from "../ActionIconsDrawer/styles";
import {Colors} from "../../config";

type DrawerProps = {
    menuVisible: boolean,
    ref?: any
}

export const DrawerComponent = styled.div<DrawerProps>`
    position: relative;
    display: flex;
    min-height: 100vh;
    width: 300px;
    background-color: #BD997F;
    box-shadow: 1px 2px 11px -2px #0000009e;
    grid-area: drawer;
    z-index: 2;
    left: 0;
    transition: 0.5s all ease;
    @media (max-width: 1150px) {
        left: ${props => props.menuVisible ? '0px' : '-340px'};
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    text-align: center;
`;

export const Image = styled.img`
    width: 100px;
    filter: brightness(100);
`;

export const DrawerContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

const TextDefault = styled.span`
  font-size: 30px;
  line-height: 30px;
  font-family: montserrat-light;
  color: ${() => Colors.white};
`;

export const HelloTextTitle = styled(TextDefault)`
  margin-top: 15px;
`;

export const HelloTextSubTitle = styled(TextDefault)`
  font-family: montserrat-semibold;
`;

export const GridContainerHello = styled.div`
  display: grid;
  flex: 1;
`;

export const GridContainerItems = styled.div`
  // display: grid;
  // flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 5%;
`;

export const Ul = styled.ul`
    justify-content: unset !important;
    flex-direction: column;
    height: fit-content;
    padding: 0;
`;

type Props = {
    inactive?: boolean
}

export const Li = styled.li<Props>`
    margin-left: 0;
    width: 100%;
    background-color: ${props => props.inactive ? 'transparent' : '#816B5D'};
    font-family: montserrat-regular;
    color: ${() => Colors.white};
    border-radius: 30px;
    padding: 10px 10px;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    transition: all 0.5s ease;
    :hover {
        background-color: rgba(129, 107, 92, 0.8);
    }
    :after {
      position: absolute;
        width: 5px;
        height: 5px;
        border-top: 2px solid #816b5d;
        border-right: 2px solid #816b5d;
        border-bottom: 2px solid #816b5d;
        border-left: 2px solid #816b5d;
        background: #816b5d;
        left: -1px;
        top: 33%;
        content: '';
        transform: rotate(45deg);
        display: ${props => props.inactive ? 'none' : ''};
    }
    :before {
        position: absolute;
        width: 5px;
        height: 5px;
        border-top: 2px solid #816b5d;
        border-right: 2px solid #816b5d;
        border-bottom: 2px solid #816b5d;
        border-left: 2px solid #816b5d;
        background: #816b5d;
        right: -1px;
        top: 33%;
        content: '';
        transform: rotate(45deg);
        display: ${props => props.inactive ? 'none' : ''};
    }
`;

export const ResponsiveMenuButton = styled(MenuIcon)`
    color: #fff;
    position: fixed;
    top: 10px;
    border-radius: 7px;
    left: 10px;
    z-index: 3;
    background-color: #816b5d;
    @media (min-width: 1151px) {
        display: none;
    }
`;

export const ResponsiveMenuButtonContainer = styled.div`
    color: black;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    @media (min-width: 1151px) {
        display: none;
    }
    svg {
        width: 1.5em;
        height: 1.5em;
    }
`;
