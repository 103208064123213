import styled from 'styled-components';
import HelpOutlineIconImport from '@material-ui/icons/HelpOutline';
import {Animated as AnimatedCss} from "react-animated-css";
import SettingsIconImport from '@material-ui/icons/Settings';
import MenuIconImport from '@material-ui/icons/Menu';
import CloseIconImport from '@material-ui/icons/Close';
import {Colors} from "../../config";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    z-index: 1;
`;

export const ContainerIcons = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const HelpOutlineIcon = styled(HelpOutlineIconImport)`
    margin-top: 10px;
    cursor: pointer;
    color: ${() => Colors.white};
`;

export const ActionsContainer = styled.div`
    width: 70px;
    background: #494440;
    position: absolute;
    //margin-left: 29px;
    overflow: hidden;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: inset 1px 1px 4px #00000061;
`;

export const ActionItemContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
`;

export const ActionItem = styled.span`
    cursor: pointer;
    color: #FFF;
    font-family: montserrat-medium;
`;

export const Animated = styled(AnimatedCss)`
    position: absolute;
    width: 70px;
    background: #494440;
    overflow: hidden;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: inset 1px 1px 4px #00000061;
`;

export const SettingsIcon = styled(SettingsIconImport)`
    color: ${() => Colors.white};
    cursor: pointer;
`;

export const MenuIcon = styled(MenuIconImport)`
    color: ${() => Colors.white};
    cursor: pointer;
`;

export const CloseIcon = styled(CloseIconImport)`
    color: ${() => Colors.white};
    cursor: pointer;
`;
