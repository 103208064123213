import React, {useCallback} from 'react';
import {Form} from "antd";
import {Formik} from "formik";
import validationSchema from "./ValidationSchema";
import {Btn, Col, FormInputPwd} from "../../../common";
import {Colors, Metrics} from "../../../config";
import {useHistory, useParams} from "react-router";


const styles = {
    btn: {
        color: 'white',
        backgroundColor: Colors.secondary,
        marginTop: Metrics.spacingLG,
        width: '100%',
        borderRadius: '13px'
    },
    btnSec: {
        marginTop: Metrics.spacingSM,
        width: '100%',
        borderRadius: '13px',
        border: 'none'
    },
    btnRow: {
        alignItems: 'center'
    }
}


const PasswordChangeForm = ({onSubmit, isFetching}: any) => {
    const {key}: any = useParams();
    const { goBack } = useHistory();
    const handleSubmit = useCallback((val) => {
        onSubmit({
            newPassword: val.password,
            key
        });
    }, [key, onSubmit]);
    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{password: '', passwordConfirmation: ''}}
            onSubmit={handleSubmit}>
            {({ handleSubmit, values }) => (
                <Form>
                    <FormInputPwd
                        style={{background: '#ffffff9c'}}
                        fullWidth
                        placeholder="Nova senha"
                        type="password"
                        name="password"
                        value={values.password}
                    />
                    <FormInputPwd
                        style={{background: '#ffffff9c'}}
                        fullWidth
                        placeholder="Confirmação da senha"
                        type="password"
                        name="passwordConfirmation"
                        value={values.passwordConfirmation}
                    />
                    <Col style={styles.btnRow}>
                        <Btn
                            onClick={() => handleSubmit()}
                            disabled={isFetching}
                            loading={isFetching}
                            style={styles.btn}>
                            Confirmar
                        </Btn>
                        <Btn
                            onClick={goBack}
                            disabled={isFetching}
                            style={styles.btnSec}>
                            Voltar
                        </Btn>
                    </Col>
                </Form>
            )}
        </Formik>
    );
}

export default PasswordChangeForm;
