import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    @media (max-width: 1150px) {
        grid-template-columns: 0 auto;
    }
    grid-template-columns: 300px auto;
    grid-template-rows: auto;
    grid-template-areas: 
    "drawer content"
`;

export const PageContainer = styled.div`
  width: 100%;
  grid-area: content;
  // max-height: 100vh;
  // overflow: auto;
  // overflow-x: hidden;
`;
