import React from 'react';
import {
    ArrowDownwardOutlined, BlockOutlined, CheckOutlined,
    CloudUpload,
    CreateOutlined,
    DeleteOutlined,
    EditOutlined, PersonOutlined,
    RemoveRedEye, VerifiedUserOutlined
} from "@material-ui/icons";
import {Colors} from "../../config";
import {Col} from "../index";
import {Tooltip} from "antd";

const styles = {
    icon: {
        cursor: 'pointer',
        color: Colors.primary,
    },
    typeErr: {
        color: 'red',
        fontSize: '9px',
        width: '40px'
    }
}

const ACTION_TYPES =
    {
        CREATE: 'create',
        EDIT: 'edit',
        DELETE: 'delete',
        SUSPEND: 'suspend',
        UPLOAD: 'upload',
        DOWNLOAD: 'download',
        VIEW: 'view',
        CHECK: 'check',
        REASSIGN: 'reassign'
    }

const ActionIcons = ({type, actionFn = () => {}}: any) => {
    if (ACTION_TYPES.CREATE === type) {
        return (
            <Col onClick={actionFn}>
                <Tooltip title={'Criar'}>
                    <CreateOutlined style={styles.icon} />
                </Tooltip>
            </Col>
        );
    }
    if (ACTION_TYPES.EDIT === type) {
        return (
            <Col onClick={actionFn}>
                <Tooltip title={'Editar'}>
                    <EditOutlined style={styles.icon} />
                </Tooltip>
            </Col>
        );
    }
    if (ACTION_TYPES.DELETE === type) {
        return (
            <Col onClick={actionFn}>
                <Tooltip title={'Excluir'}>
                    <DeleteOutlined style={styles.icon} />
                </Tooltip>
            </Col>
        );
    }
    if (ACTION_TYPES.SUSPEND === type) {
        return (
            <Col onClick={actionFn}>
                <Tooltip title={'Suspender'}>
                    <BlockOutlined style={styles.icon} />
                </Tooltip>
            </Col>
        );
    }
    if (ACTION_TYPES.UPLOAD === type) {
        return (
            <Col onClick={actionFn}>
                <Tooltip title={'Upload'}>
                    <CloudUpload style={styles.icon} />
                </Tooltip>
            </Col>
        );
    }
    if (ACTION_TYPES.VIEW === type) {
        return (
            <Col onClick={actionFn}>
                <Tooltip title={'Visualizar'}>
                    <RemoveRedEye style={styles.icon} />
                </Tooltip>
            </Col>

        );
    }
    if (ACTION_TYPES.DOWNLOAD === type) {
        return (
            <Col onClick={actionFn}>
                <Tooltip title={'Download'}>
                    <ArrowDownwardOutlined style={styles.icon} />
                </Tooltip>
            </Col>
        );
    }
    if (ACTION_TYPES.CHECK === type) {
        return (
            <Col onClick={actionFn}>
                <Tooltip title={'Dar baixa'}>
                    <CheckOutlined style={styles.icon} />
                </Tooltip>
            </Col>
        );
    }
    if (ACTION_TYPES.REASSIGN === type) {
        return (
            <Col onClick={actionFn}>
                <Tooltip title={'Alterar vendedor'}>
                    <PersonOutlined style={styles.icon} />
                </Tooltip>
            </Col>
        );
    }
    return (
        <span style={styles.typeErr}>typeErr!</span>
    )
}

export default ActionIcons;
