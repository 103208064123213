import React from "react";
import {Col} from "../../common";
import {Colors, Metrics} from "../../config";
import sucessoImg from "../../../assets/images/sucesso.png";

const styles = {
    container: {alignItems: 'center', padding: `75px ${Metrics.spacingXLG}`},
    title: {marginTop: Metrics.spacingMD, color: Colors.text},
    text: {
        marginTop: Metrics.spacingMD,
        color: Colors.text,
        fontSize: Metrics.fontSize.xxsm,
    },
}

const Success = () => {
    return (
        <Col style={styles.container}>
            <p style={styles.title}>Bem vindo à Silicred!</p>
            <img  src={sucessoImg} alt=""/>
            <p style={styles.text}>Acesse seu e-mail para finalizar seu contrato.</p>
            <p style={styles.text}>Você receberá um número da sorte para concorrer à 10 mil reais toda semana!</p>
            <p style={styles.text}>O que você faria com R$ 10 mil ???</p>
        </Col>
    );
};

export default Success;
