import React from "react";
import validationSchema from './ValidationSchema';
import {Form} from 'antd';
import {Formik} from "formik";
import {Btn, FormInputText, FormTextArea, Row} from "../../../../common";
import {Metrics} from "../../../../config";
import {useHistory} from "react-router";
import FormUploader from "../../../../common/form/FormUploader";
import Whitespace from "../../../../common/whitespace";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'}
};

const TestimonialForm = ({onSubmit, initialValues, isFetching}: any) => {
    const {goBack} = useHistory();
    return (
        <div>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {({ handleSubmit, values }) => (
                    <Form>
                        <FormInputText label="Nome" name="name" value={values.name} />
                        <FormTextArea rows={8} label="Texto" name="text" value={values.text} />
                        <FormUploader success={false}
                                      file={true} fieldName="image" contentTypeField="imageContentType"/>
                        {
                            values?.image &&
                            <img
                                style={{marginBlock: Metrics.spacingSM}}
                                src={`data:image/jpg;base64,${values?.image}`}
                                alt={'imagem'} />
                        }
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={goBack}
                                style={styles.btn}>Voltar</Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={() => handleSubmit()}
                                disabled={isFetching}
                                type='primary'
                                style={styles.btn}>
                                Salvar
                            </Btn>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default TestimonialForm;
