import React, {useCallback} from 'react';
import {Btn, FormDropdown, FormInputText, Row} from "../../common";
import {Metrics} from "../../config";
import {Form} from "antd";
import {Formik} from "formik";
import Whitespace from "../../common/whitespace";
import validationSchema from "./ValidationSchema";
import useFetch from "../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../configs/toast";
import {dreamWithdrawRequest} from "../../services/dream";


const defaultInitialValues = {
    account: '',
    accountType: '',
    agency: '',
    bank: '',
}

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'}
}


const WithdrawRequestForm = ({ setModalVisible, userProcedureId }: any) => {
    const [{ isFetching }, fetch] = useFetch({
        provider: dreamWithdrawRequest,
        param: "",
        requestOnMount: false,
        initialData: [],
        resultHandler: {
            success: () => {
                setModalVisible(false);
                showSuccessToast('O resgate foi solicitado com sucesso. Nossa equipe entrará em contato com você em breve.');
            },
            error: (d: any) => showErrorToast(d),
        },
    });

    const onSubmit = useCallback((values) => {
        fetch({
            ...values,
            userProcedureId
        });
    }, [userProcedureId])

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={defaultInitialValues}
            onSubmit={fetch}>
            {({ handleSubmit, values }) => (
                <Form>
                    <Row responsive>
                        <FormInputText required fullWidth label="Agência" name="agency" value={values.agency} />
                        <Whitespace width={Metrics.spacingLG} />
                        <FormInputText required fullWidth label="Número da conta" name="account" value={values.account} />
                    </Row>
                    <FormInputText required fullWidth label="Código do banco" name="bank" value={values.bank} />
                    <FormDropdown
                        required
                        fullWidth
                        label="Tipo de conta"
                        value={values.accountType}
                        name="accountType"
                        list={[
                            {label: 'Conta corrente', value: 'CURRENT'},
                            {label: 'Poupança', value: 'SAVINGS'},
                        ]}
                    />
                    <Row style={styles.btnRow}>
                        <Btn
                            onClick={() => handleSubmit()}
                            disabled={isFetching}
                            type='primary'
                            style={styles.btn}>
                            Solicitar
                        </Btn>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default WithdrawRequestForm;
