import React from 'react';
import './styles.css';
import styled from "styled-components";
import {useHistory} from "react-router";
import useBreakpoint from "../../hooks/useBreakpoint";
import {Col, Row} from "../../common";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

// const LiStyled = styled.ul`
//     text-align: center;
// `;

// const NavStyled = styled.nav`
//     width: 100%;
//     margin-top: 1.5rem;
//     margin-right: 5%;
//     float: right;
//     border-radius: 0;
// `;

// const MenuContainer = styled.div`
//     text-align: right;
//     width: 70%;
//     @media (max-width: 1150px) {
//         display: none;
//     }
// `;

const styles = {
    menu: {
        textAlign: 'right' as const,
        width: '70%'
    },
    li: {
        textAlign: 'center' as const
    },
    ul: {
        margin: 0,
        width: 'unset',
        display: 'inline-flex',
        marginLeft: '2.5rem'
    },
    nav: {
        width: '100%',
        marginTop: '1.5rem',
        marginRight: '5%',
        float: 'right' as const,
        borderRadius: 0
    }
}

const MENUBAR_ITEMS = [
    {
        route:  '/about',
        label: 'Sobre nós'
    },
    {
        route:  '/how-works',
        label: 'Como funciona'
    },
    {
        route:  '/simulation',
        label: 'Simulação'
    },
    {
        route:  '/registration',
        label: 'Cadastre-se'
    },
]

const Menubar: React.FC = () => {
    const { push } = useHistory();
    const { isMobile } = useBreakpoint();
    return (
        !isMobile ? <div style={styles.menu}>
            <nav style={styles.nav}>
                <ul style={styles.ul}>
                    {MENUBAR_ITEMS.map( (mi: any) =>
                        (
                            <ul style={styles.li} key={mi.route}>
                                <span
                                    style={{color: 'white', cursor: 'pointer'}}
                                    onClick={() => push(mi.route)}>
                                    {mi.label}
                                </span>
                            </ul>
                        )
                    )}
                </ul>
            </nav>
        </div> : <div />
    );
};

export default Menubar;
