import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .nullable()
        .required('O campo nome é obrigatório.'),
    code: Yup.string()
        .nullable()
        .required('O campo código é obrigatório.'),
});

export default ValidationSchema;
