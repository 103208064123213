import React from "react";
import {GenericList} from "../../../components";
import {deleteTestimonialRequest, findTestimonialsRequest} from "../../../services/testimonial";

export const Testimonial = () => {

    const columns: any = [
        {
            title: 'Nome',
            key: 'name'
        },
        {
            title: 'Texto',
            key: 'text'
        }
    ]

  return (
      <GenericList
          alternate
          deletable
          title={'Depoimentos'}
          newPath={'testimonials/create'}
          editPath={'testimonials/edit'}
          providerFind={findTestimonialsRequest}
          providerDelete={deleteTestimonialRequest}
          columns={columns}
      />
  );
};
