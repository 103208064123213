import React from "react";
import {GenericList} from "../../../components";
import {deletePartnerRequest, findPartnerRequest} from "../../../services/partner";

export const Partner = () => {
    const columns: any = [
        {
            title: 'Nome',
            key: 'name'
        },
        {
            title: 'Código promocional',
            key: 'code'
        },
    ]

  return (
      <GenericList
          alternate
          deletable
          title={'Parceiros'}
          newPath={'influencers/create'}
          editPath={'influencers/edit'}
          providerFind={findPartnerRequest}
          providerDelete={deletePartnerRequest}
          columns={columns}
      />
  );
};
