export const PAGE_SIZE = 20;
export const MONTH_NAMES = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

export enum PaymentStatusEnum {
    PENDING = 'PENDING',
    PAID = 'PAID',
    OVERDUE = 'OVERDUE'
}

export enum ProcedureStatus {
    CURRENT = 'CURRENT',
    COMPLETE = 'COMPLETE'
}

export enum PaymentMethodEnum {
    BANK_SLIP = 'BANK_SLIP',
    CREDIT_CARD = 'CREDIT_CARD',
    DEBIT_CARD = 'DEBIT_CARD'
}

export enum ContractTypeEnum {
    FINANCIAMENTO = 'FINANCIAMENTO',
    CONSORCIO = 'CONSORCIO',
    COMPRA_PROGRAMADA = 'COMPRA_PROGRAMADA'
}

export enum SimulationTypeEnum {
    PARCEL_VALUE = 'PARCEL_VALUE',
    PARCEL_QT = 'PARCEL_QT',
    STARTER_VALUE = 'STARTER_VALUE'
}

export enum ParameterKey {
    ABOUT_US = 'ABOUT_US',
    HOW_WORK = 'HOW_WORK',
    CONTRACT = 'CONTRACT',
    LOGIN_TEXT = 'LOGIN_TEXT'
}
