import * as Yup from "yup";
import {validateCPF} from "../../../../../util/CPFUtil";
import {MaskUtils} from "../../../../../util/MasksUtil";
import {DateUtils} from "../../../../../util/DateUtils";

const ValidationSchema = Yup.object().shape({
    key: Yup.string()
        .nullable()
        .required('O campo chave é obrigatório.'),
    value: Yup.string()
        .nullable()
        .required('O campo valor é obrigatório.'),
});

export default ValidationSchema;
