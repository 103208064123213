import api from "./api";

export const findInstallmentRequest = async (param: any, data: any) => {
    return api.get(`/api/installments/${param}`, data);
};

export const findInstallmentsRequest = (param: any, data: any) => {
    return api.get(`/api/installments`, {params: data});
};

export const createInstallmentRequest = (param: any, data: any) => {
    return api.post(`/api/installments`, data);
};

export const updateInstallmentRequest = (param: any, data: any) => {
    return api.put(`/api/installments`, data);
};

export const deleteInstallmentRequest = (param: any, data: any) => {
    return api.delete(`/api/installments/${data}`);
};
