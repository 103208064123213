import {InputNumber} from 'antd';
import React from 'react';

const locale = 'pt-br';

const InputMoney = ({
  label,
  value,
  onChange: onChangeParam,
  required,
  placeholder,
  autoFocus,
  ...rest
}: any) => {

  const onChange = (newValue: any) => {
    onChangeParam(newValue);
  };

  const currencyFormatter = (val: any) =>
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'BRL',
    }).format(val);

  const currencyParser = (val: any) => {
    try {
      let stringValue = val;
      // for when the input gets clears
      if (typeof stringValue === 'string' && !stringValue.length) {
        stringValue = '0.0';
      }

      // detecting and parsing between comma and dot
      const group = new Intl.NumberFormat(locale)
        .format(1111)
        .replace(/1/g, '');
      const decimal = new Intl.NumberFormat(locale)
        .format(1.1)
        .replace(/1/g, '');
      let reversedVal = stringValue.replace(new RegExp(`\\${group}`, 'g'), '');
      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
      //  => 1232.21 €

      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, '');
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch {
      return 0;
    }
  };

  return (
        <InputNumber
          mode="outlined"
          value={value}
          formatter={currencyFormatter}
          parser={currencyParser}
          onChange={onChange}
          autoFocus={autoFocus}
          placeholder={!label ? placeholder : ''}
          {...rest}
        />
  );
};

export default InputMoney;
