import { hot } from 'react-hot-loader/root'
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import './antd.css';


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const HotApp = process.env.NODE_ENV === 'development' ? hot(App) : App;
ReactDOM.render(<HotApp />, document.getElementById('root'));

serviceWorker.unregister();

