import React from "react";
import validationSchema from './ValidationSchema';
import {Form} from 'antd';
import {Formik} from "formik";
import {Btn, Col, FormDropdown, FormInputText, FormTextArea, Row} from "../../../../common";
import {Colors, Metrics} from "../../../../config";
import {useHistory} from "react-router";
import FormUploader from "../../../../common/form/FormUploader";
import FormCheckbox from "../../../../common/form/FormCheckbox";
import Whitespace from "../../../../common/whitespace";
import {PROCEDURE_TYPES} from "../util/PROCEDURE_TYPES";
import Faq from "../util/faq";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'},
    title: {marginTop: Metrics.spacingLG, marginBottom: 0, color: Colors.primary},
    warning: {
        marginTop: Metrics.spacingMD,
        fontSize: Metrics.fontSize.xxxsm,
        color: 'red'
    }
};

const ProcedureForm = ({onSubmit, initialValues, isFetching}: any) => {
    const {goBack} = useHistory();
    return (
        <div>
            <Formik
                enableReinitialize
                validationSchema={initialValues?.type !== PROCEDURE_TYPES.SILICASH && initialValues?.type !== PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT && initialValues?.type !== PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT ? validationSchema : null}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {({ handleSubmit, values }) => (
                    <Form>
                        { values?.type === PROCEDURE_TYPES.SILICASH || values?.type === PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT || values?.type === PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT ?
                            <p style={styles.warning}>
                                Este procedimento é uma referência para a definição de valores mínimo e
                                máximo para contratação de serviços.</p> :
                            <div/>
                        }
                        <FormInputText
                            disabled={values?.type === PROCEDURE_TYPES.SILICASH || values?.type === PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT || values?.type === PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT}
                            label="Nome"
                            name="name"
                            value={values.name}
                        />
                        <Row responsive>
                            <FormInputText fullWidth label="Valor mínimo" name="minValue" value={values.minValue} />
                            <Whitespace width={Metrics.spacingLG} />
                            <FormInputText fullWidth label="Valor máximo" name="maxValue" value={values.maxValue} />
                        </Row>
                        <FormInputText
                            disabled={values?.type === PROCEDURE_TYPES.SILICASH || values?.type === PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT || values?.type === PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT}
                            label="Prioridade" name="priority" value={values.priority} />
                        <FormDropdown
                            disabled={values?.type === PROCEDURE_TYPES.SILICASH || values?.type === PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT || values?.type === PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT}
                            label="Tipo"
                            value={values.type}
                            name="type"
                            list={Object.keys(PROCEDURE_TYPES)
                                .map( pt => ({label: PROCEDURE_TYPES[pt], value: pt}))
                                .filter( p => values?.type === PROCEDURE_TYPES.SILICASH || values?.type === PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT || values?.type === PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT ? true : values?.type !== PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT && values?.type !== PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT)
                            }
                        />
                        <FormInputText
                            disabled={values?.type === PROCEDURE_TYPES.SILICASH || values?.type === PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT || values?.type === PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT}
                            label="Descrição curta" name="shortDesc" value={values.shortDesc} />
                        <FormTextArea
                            disabled={values?.type === PROCEDURE_TYPES.SILICASH || values?.type === PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT || values?.type === PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT}
                            rows={8} label="Descrição completa" name="fullDesc" value={values.fullDesc} />
                        {
                            values?.type !== PROCEDURE_TYPES.SILICASH && values?.type !== PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT && values?.type !== PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT &&
                                <Col>
                                    <FormUploader success={false}
                                                  disabled={values?.type === PROCEDURE_TYPES.SILICASH || values?.type === PROCEDURE_TYPES.PLANNED_SURGERY_DEFAULT || values?.type === PROCEDURE_TYPES.SCHEDULED_SURGERY_DEFAULT}
                                                  file={true} fieldName="image" contentTypeField="imageContentType"/>
                                    {
                                        values?.image &&
                                        <img
                                            style={{marginBlock: Metrics.spacingSM, maxWidth: '350px'}}
                                            src={`data:image/jpg;base64,${values?.image}`}
                                            alt={'imagem'} />
                                    }
                                    <p style={styles.title}>Informações básicas</p>
                                    <FormInputText label="Título" name="basicInfo.title" value={values?.basicInfo?.title} />
                                    <FormTextArea rows={8} label="Descrição" name="basicInfo.desc" value={values?.basicInfo?.desc} />
                                    <FormTextArea rows={8} label="Tratamento" name="treatment" value={values?.treatment} />
                                    <p style={styles.title}>Perguntas frequentes</p>
                                    <Faq />
                                    <FormCheckbox name="active" label="Ativo"/>
                                </Col>
                        }
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={goBack}
                                style={styles.btn}>Voltar</Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={() => handleSubmit()}
                                disabled={isFetching}
                                type='primary'
                                style={styles.btn}>
                                Salvar
                            </Btn>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default ProcedureForm;
