import React from 'react';
import {Formik} from 'formik';
import logo from 'assets/images/logo-transparent.png'
import * as Yup from 'yup';
import {FcGoogle} from 'react-icons/fc';
import {useAuthentication} from "../../context/AuthContext";
import {Btn, Col, FormInputPwd, FormInputText, Row} from "../../common";
import {Colors, Metrics} from "../../config";
import {useHistory} from "react-router";
import GoogleLogin from 'react-google-login';
import {GOOGLE_CLIENT_ID} from "../../../environments";
import {Button} from "antd";

const styles = {
    logo: {
        maxWidth: '100px',
        marginBottom: Metrics.spacingLG
    },
    fields: {
        width: '310px'
    },
    container: {
        alignItems: 'center',
        paddingBlock: Metrics.spacingLG
    },
    loginBtn: {
        backgroundColor: Colors.secondary,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        paddingInline: Metrics.spacingMD,
        marginBlock: Metrics.spacingMinimun,
        width: '300px',
        color: 'white',
        border: 'none',
    },
    fbBtnContainer: {
        cursor: 'pointer',
        backgroundColor: 'rgb(59, 77, 179)',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        paddingInline: Metrics.spacingMD,
        marginBlock: Metrics.spacingMinimun,
        width: '300px'
    },
    googleBtnContainer: {
        cursor: 'pointer',
        backgroundColor: '#4081EC',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        marginBlock: Metrics.spacingMinimun,
        width: '220px'
    },
    fbBtn: {
        backgroundColor: 'rgb(59, 77, 179)',
        border: 'none',
        color: 'white'
    },
    googleIcon: {
        fontSize: Metrics.fontSize.md,
        backgroundColor: '#fff',
    },
    googleBtn: {
        backgroundColor: '#4081EC',
        border: 'none',
        color: 'white',
        fontWeight: 'bolder'
    },
    forgotRegisterLabel: {
        color: Colors.primary,
        fontSize: Metrics.fontSize.xxsm,
        marginBlock: Metrics.spacingSM,
        cursor: 'pointer'
    },
    label: {
        marginBlock: Metrics.spacingMD,
        fontSize: Metrics.fontSize.xxxsm
    }
}

const schema = Yup.object().shape({
    username: Yup.string()
        .required('O campo e-mail é de preenchimento obrigatório.'),
    password: Yup.string()
        .required('O campo senha é de preenchimento obrigatório.')
});

const LoginForm = () => {
    const { handleLogin, handleLoginGoogle, setIsLoginVisible, isFetching }: any = useAuthentication();
    const { push } = useHistory();

    const initialValues: any = {
        username: '',
        password: ''
    };

    const keyPressed = (event: any, values: any, isValid: any, submit: any, validateForm: any) => {
        if (event.key === "Enter") {
            submit().then(() => {
                validateForm(values).then((valid: any) => {
                    if (Object.keys(valid).length === 0) {
                        // onSubmit(values)
                    }
                });
            });
        }
    };

    return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleLogin}
                validationSchema={schema}>
                {({handleSubmit, values, handleChange, isValid, submitForm, validateForm}) => (
                    <Col style={styles.container}>
                        <img style={styles.logo} src={logo}/>
                        <Col style={styles.fields}>
                            <FormInputText
                                autoFocus
                                fullWidth
                                placeholder="E-mail"
                                name="username"
                                value={values.username}
                                onKeyPress={(event: any) => keyPressed(event, values, isValid, submitForm, validateForm)}
                            />
                            <FormInputPwd
                                fullWidth
                                placeholder="Senha"
                                type="password"
                                name="password"
                                value={values.password}
                                onKeyPress={(event: any) => keyPressed(event, values, isValid, submitForm, validateForm)}
                            />
                        </Col>
                        <span
                            onClick={() => {
                                setIsLoginVisible(false);
                                push('/sistema/reset-password');
                            }}
                            style={styles.forgotRegisterLabel}>Esqueci minha Senha</span>
                        <Btn
                            loading={isFetching}
                            style={styles.loginBtn}
                            onClick={() => handleSubmit()}>
                            Acessar
                        </Btn>
                        <span style={styles.label}>OU</span>
                        <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            render={(renderProps: any) => (
                                <Row style={styles.googleBtnContainer}>
                                    <FcGoogle style={styles.googleIcon} />
                                    <Button
                                        style={styles.googleBtn} onClick={(event: any) => {
                                        renderProps.onClick(event);
                                    }}>
                                        entrar com o google
                                    </Button>
                                </Row>
                            )}
                            onSuccess={handleLoginGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                        <label style={styles.label}>Não possui uma conta?</label>
                        <span
                            onClick={() => {
                                setIsLoginVisible(false);
                                push('/registration');
                            }}
                            style={styles.forgotRegisterLabel}>Cadastre-se</span>
                    </Col>
                )}
            </Formik>
    )
};

export default LoginForm;
