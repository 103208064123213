import React from "react";
import {GenericList} from "../../../components";
import {deleteParameterRequest, findParametersRequest} from "../../../services/parameter";

export const Parameter = () => {
    const columns: any = [
        {
            title: 'Chave',
            key: 'key',
            render: (i: any, record: any) => {
                if (record?.key === 'CONTRACT_SCHEDULED_SURGERY_DEFAULT') {
                    return <span>Contrato Cirurgia Planejada</span>
                }
                if (record?.key === 'CONTRACT_PLANNED_SURGERY_DEFAULT') {
                    return <span>Contrato Data Certa</span>
                }
                if (record?.key === 'CONTRACT_SILICASH_DEFAULT') {
                    return <span>Contrato Silicash</span>
                }
                return <span>{record?.key}</span>
            }
        },
        {
            title: 'Valor',
            key: 'value',
            render: (i: any, record: any) => (
             <span>{`${record?.value?.slice(0, 100)}...`}</span>)
        }
    ]
    return (
        <GenericList
            title={'Parâmetros'}
            editPath={'parameters/edit'}
            providerFind={findParametersRequest}
            providerDelete={deleteParameterRequest}
            columns={columns}
        />
    );
};
