import styled from "styled-components";
import {Table as ATable} from "antd";


export const TableStyled = styled(ATable)`
  &.ant-table-wrapper {
    .ant-table-column-title {
      color: #44444f;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.8px;
    }
    .ant-table-thead {
      > tr {
        th {
          padding: 11px 13px;
          &:first-child {
            border-top-left-radius: ${10}px;
            border-bottom-left-radius: ${10}px;
          }
          &:last-child {
            border-top-right-radius: ${10}px;
            border-bottom-right-radius: ${10}px;
          }
        }
      }
    }
    .ant-table-row {
    }
  }
`;
