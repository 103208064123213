import React from "react";
import {GenericList} from "../../../components";
import {deleteCouponRequest, findCouponsRequest} from "../../../services/coupon";
import Filters from "../../../../configs/Filters";

export const Coupon = () => {
    const columns: any = [
        {
            title: 'Código',
            key: 'code'
        },
        {
            title: 'Valor',
            key: 'value',
            render: (value: number) => value ? `${Filters.convertMoneyTextMask(value)}` : '-'
        },
        {
            title: 'Porcentagem',
            key: 'percentage',
            render: (percentage: number) => percentage ? `${percentage}%` : '-'
        },
    ]
  return (
      <GenericList
          deletable
          title={'Cupons de desconto'}
          newPath={'coupons/create'}
          editPath={'coupons/edit'}
          providerFind={findCouponsRequest}
          providerDelete={deleteCouponRequest}
          columns={columns}
      />
  );
};
