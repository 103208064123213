import {showErrorToast, showSuccessToast} from "../configs/toast";
import moment from "moment";

export class DateUtils {

    public static convertStringToDate(date: any): Date | undefined {
        if (date) {
            const dateArray = date.split('-');
            return new Date(+dateArray[0], +dateArray[1] - 1, +dateArray[2]);
        }
    }

    public static convertStringToDateWithoutHours(date: any): Date {
        const dateArray = date.split('-');
        return new Date(+dateArray[0], +dateArray[1] - 1, +dateArray[2], 0, 0, 0);
    }

    public static convertStringBRToDate(date: any): Date | undefined {
        if (date) {
            const dateArray = date.split('/');
            return new Date(+dateArray[2], +dateArray[1] - 1, +dateArray[0]);
        }
    }

    public static convertStringToStringBR(date: any): string {
        const dateArray = date.split('-');
        return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    }


    public static convertStringBRToString(date: any): string {
        const dateArray = date.split('/');
        return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    }

    public static validateStringBRDate(date: any): boolean {
        if (date) {
            const dateArray = date.split('/');
            const dateToParse = `${+dateArray[2]}-${+dateArray[1] - 1}-${+dateArray[0]}`;
            return !isNaN(Date.parse(dateToParse));
        }
        return false;
    }

    public static validateStringBRDateLimitToday(date: any): boolean {
        if (date) {
            const dateArray = date.split('/');
            const dateToParse = `${+dateArray[2]}-${+dateArray[1]}-${+dateArray[0]}`;
            const isNan = !(moment(dateToParse, 'YYYY-MM-DD').format('YYYY-MM-DD') === dateToParse ||
                moment(dateToParse, 'YYYY-M-DD').format('YYYY-M-DD') === dateToParse ||
                moment(dateToParse, 'YYYY-M-D').format('YYYY-M-D') === dateToParse ||
                moment(dateToParse, 'YYYY-MM-D').format('YYYY-MM-D') === dateToParse
            );
            if (isNan) {
                return false;
            } else {
                const dateConvert = new Date(+dateArray[2], +dateArray[1] - 1, +dateArray[0], 0, 0, 0);
                return !(dateConvert >= new Date());
            }
        }
        return false;
    }

    public static secondsToTimeString(seconds: number): string {
        let aux: number = seconds;

        const horas: number = Math.trunc(aux / 3600);
        aux = aux - horas * 3600;
        const minutos: number = Math.trunc(aux / 60);
        aux = aux - minutos * 60;
        const segundos: number = aux;

        return `${horas.toString(10)}:${this.pad(minutos, 2)}:${this.pad(segundos, 2)}`;
    }

    public static pad(num: any, size: any) {
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }

    public static BRtoDate(dateStr: any) {
        const parts = dateStr.split('/');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    public static zeraHoraTimezone(data: string): string {
        return data
            .substr(0, 11)
            .concat('00:00:00')
            .concat(data.substr(19, 10));
    }
}
