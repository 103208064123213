// api/parameters/key/CONTRACT

import api from "./api";

export const findContractRequest = async (param: any) => {
    return api.get(`api/parameters/key/CONTRACT_${param}_DEFAULT`);
};

export const findParameterRequest = async (param: any, data: any) => {
    return api.get(`/api/parameters/${param}`, data);
};

export const findParameterByKeyRequest = async (key: any, data: any) => {
    return api.get(`/api/parameters/key/${key}`);
};

export const findParametersRequest = (param: any, data: any) => {
    return api.get(`/api/parameters`, {params: data});
};

export const createParameterRequest = (param: any, data: any) => {
    return api.post(`/api/parameters`, data);
};

export const updateParameterRequest = (param: any, data: any) => {
    return api.put(`/api/parameters`, data);
};

export const deleteParameterRequest = (param: any, data: any) => {
    return api.delete(`/api/parameters`, data);
};
