import React from 'react';
import {useHistory} from 'react-router';
import NewsForm from '../form';
import useFetch from "../../../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../../../configs/toast";
import {TitledContainer} from "../../../../components";
import {createNewsRequest} from "../../../../services/news";

const initialValues = {
    title: '',
    author: '',
    preview: '',
    embed_video: '',
    display_video: '',
    content: '',
    image_preview: '',
}

export const NewsCreate = () => {
  const {goBack} = useHistory();
  const [{isFetching}, create,] = useFetch(
    {
      provider: createNewsRequest,
      param: '',
      requestOnMount: false,
      initialData: null,
      resultHandler: {
        success: () => {
          showSuccessToast('Registro criado com sucesso!')
          goBack();
        },
        error: (d: any) => showErrorToast(d)
      }
    }
  );

  return (
      <TitledContainer title={'Notícia'}>
          <NewsForm goBack={goBack}
            isFetching={isFetching}
            onSubmit={create}
            initialValues={initialValues}
          />
      </TitledContainer>
  );
}
