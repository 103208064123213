import React from "react";
import {Col} from "../../common";
import aboutUsImg from "../../../assets/images/sobreNos.svg";
import {Colors, Metrics} from "../../config";
import useFetch from "../../hooks/useFetch";
import {findParameterByKeyRequest} from "../../services/parameter";

const styles = {
    container: {alignItems: 'center', padding: `75px ${Metrics.spacingXLG}`},
    title: {
        color: Colors.primary,
    },
    text: {
        fontSize: Metrics.fontSize.xxsm,
        whiteSpace: 'pre-line' as const,
        maxWidth: '1100px',
        color: Colors.text,
        textAlign: 'justify' as const
    },
    img: {width: '120px'}
}
const About = () => {
    const [{ isFetching, data }] = useFetch(
        {
            provider: findParameterByKeyRequest,
            param: 'ABOUT_US',
            requestOnMount: true,
            initialData: '',
            resultHandler: null
        });
    return (
        <Col style={styles.container}>
            <img style={styles.img} src={aboutUsImg} alt=""/>
            <h2 style={styles.title}>Sobre nós</h2>
            <p style={styles.text}>
                {data?.value}
            </p>
        </Col>
    );
};

export default About;
