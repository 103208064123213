import React from 'react';
import {Col} from "../index";

const Divider = () => (
  <Col style={{width: '100%', alignItems: 'center'}}>
      <div style={{width: '80%', height: '2px', background: 'rgba(128,128,128,0.21)', margin:  '20px 0' }} />
  </Col>
);

export default Divider;
