import React, {useCallback, useEffect, useState} from "react";
import {Form, Table as TableAntd} from "antd";
import useFetchPag from "new/hooks/useFetchPag";
import {generateSellReportRequest, getSellReportRequest} from "../../../../../services/report";
import {Btn, Col, Divider, FormDropdown, FormInputText, Row, Table} from "../../../../../common";
import {Metrics} from "../../../../../config";
import Filters from "../../../../../../configs/Filters";
import Whitespace from "../../../../../common/whitespace";
import {Formik} from "formik";
import useFetch from "../../../../../hooks/useFetch";
import {findSellersRequest, findSupervisorsRequest} from "../../../../../services/user";
import useDownload from "../../../../../hooks/useDownload";
import {DateUtils} from "../../../../../../util/DateUtils";

const { Column } = TableAntd;

export const CommissionReport = ({styles}: any) => {
    const [filter, setFilter] = useState({});
    const [
        { isFetching: isFetchingSupervisors, data: supervisors }] = useFetch({
        provider: findSupervisorsRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
    });
    const [
        { isFetching: isFetchingSellers, data: sellers }] = useFetch({
        provider: findSellersRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
    });
    const [
        { isFetching, data, totalItems, currentPage: current, pageSize },
        fetch,
    ] = useFetchPag({
        provider: getSellReportRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
        paginate: true,
    });
    const { isFetching: isFetchingSell, fetch: fetchSellReport } = useDownload(
        generateSellReportRequest(),
        "",
        false,
        'report',
        () => {}
    );

    const handleFind = useCallback(
        (p: any) => {
            fetch({ page: { current: p?.current || current, size: p?.pageSize }, filter });
        },
        [current, fetch, filter]
    );

    const handleDownloadReport = useCallback(
        () => {
            const valuesFormated: any = {...filter};
            if (valuesFormated.agreementDateStart) {
                valuesFormated.agreementDateStart = DateUtils.convertStringBRToString(valuesFormated.agreementDateStart);
            }
            if (valuesFormated.agreementDateEnd) {
                valuesFormated.agreementDateEnd = DateUtils.convertStringBRToString(valuesFormated.agreementDateEnd);
            }
            fetchSellReport(valuesFormated);
        },
        [filter]
    );

    const submit = useCallback(
        (val: any) => {
            setFilter(val);
        },
        [setFilter]
    );

    useEffect(() => handleFind(null), [filter]);

    return (
        <Col style={{ marginBlock: Metrics.spacingMD }}>
            <Formik
                initialValues={filter}
                onSubmit={submit}
                enableReinitialize>
                {({handleSubmit, values, setFieldValue}: any) => (
                    <Form>
                        <FormDropdown
                            label="Supervisor"
                            value={values.supervisorId}
                            name="supervisorId"
                            list={supervisors.map((u: any) => ({label: u.name, value: u.id}))}
                        />
                        <FormDropdown
                            label="Vendedor"
                            value={values.sellerId}
                            name="sellerId"
                            list={sellers.map((u: any) => ({label: u.name, value: u.id}))}
                        />
                        <Col>
                            <label style={{marginTop: Metrics.spacingSM}}>
                                Data do contrato
                            </label>
                            <Row responsive style={{width: '100%'}}>
                                <FormInputText
                                    fullWidth
                                    name='agreementDateStart'
                                    value={values.agreementDateStart}
                                    placeholder='Contratos a partir de' />
                                <Whitespace width={Metrics.spacingLG} />
                                <FormInputText
                                    fullWidth
                                    name='agreementDateEnd'
                                    value={values.agreementDateEnd}
                                    placeholder='Contratos até' />
                            </Row>
                        </Col>
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={() => {
                                    setFieldValue('supervisorId', null);
                                    setFieldValue('sellerId', null);
                                    setFieldValue('suspended', false);
                                    setFieldValue('overdue', false);
                                    handleSubmit();
                                }}
                                disabled={isFetching}>
                                Limpar filtro
                            </Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={handleDownloadReport}
                                disabled={isFetching}
                                style={styles.btnDark}
                                type='primary'>
                                Exportar
                            </Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={handleSubmit}
                                disabled={isFetching}
                                type='primary'>
                                Pesquisar
                            </Btn>
                        </Row>
                        <Divider />
                    </Form>
                )}
            </Formik>
            <Table
                pagination={{
                    total: totalItems,
                    current,
                    pageSize,
                }}
                isFetching={isFetching}
                rowKey="id"
                handleFind={handleFind}
                data={data}
            >
                <Column title={'Nome'} dataIndex="client" key="client" />
                <Column title={'Telefone'} dataIndex="phone" key="phone" render={(_: any, rowData: any) =>
                    <span>{Filters.inputMaskTELWithDDD(rowData.phone)}</span>} />
                <Column title={'Valor contrato'} dataIndex="contractValue" key="contractValue"
                        render={(_, rowData: any) => <span>{Filters.convertMoneyTextMask(rowData?.contractValue)}</span>}  />
                <Column title={'Qt parcelas'} dataIndex="installmentNr" key="installmentNr" />
                <Column title={'Valor parcela'} dataIndex="installmentValue" key="installmentValue"
                        render={(_, rowData: any) => <span>{Filters.convertMoneyTextMask(rowData?.installmentValue)}</span>}  />
                <Column title={'Vendedor'} dataIndex="sellerName" key="sellerName" />
                <Column title={'Comissão vendedor'} dataIndex="sellerCommission" key="sellerCommission"
                        render={(_, rowData: any) => <span>{Filters.convertMoneyTextMask(rowData?.sellerCommission)}</span>}  />
                <Column title={'Supervisor'} dataIndex="supervisorName" key="supervisorName" />
                <Column title={'Comissão supervisor'} dataIndex="supervisorCommission" key="supervisorCommission"
                        render={(_, rowData: any) => <span>{Filters.convertMoneyTextMask(rowData?.supervisorCommission)}</span>}  />
            </Table>
        </Col>
    );
};
