import React from 'react';
import {useHistory} from 'react-router';
import PartnerForm from '../form';
import useFetch from "../../../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../../../configs/toast";
import {TitledContainer} from "../../../../components";
import {createPartnerRequest} from "../../../../services/partner";
import TestimonialForm from "../form";
import {createTestimonialRequest} from "../../../../services/testimonial";

const initialValues = {
    value: '',
    taxAdm: '',
}

export const TestimonialCreate = () => {
  const {goBack} = useHistory();
  const [{isFetching}, create,] = useFetch(
    {
      provider: createTestimonialRequest,
      param: '',
      requestOnMount: false,
      initialData: null,
      resultHandler: {
        success: () => {
          showSuccessToast('Registro criado com sucesso!')
          goBack();
        },
        error: (d: any) => showErrorToast(d)
      }
    }
  );

  return (
      <TitledContainer title={'Depoimentos'}>
          <TestimonialForm goBack={goBack}
            isFetching={isFetching}
            onSubmit={create}
            initialValues={initialValues}
          />
      </TitledContainer>
  );
}
