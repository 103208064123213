import React from 'react';
import {Form} from "antd";
import {Formik} from "formik";
import validationSchema from "./ValidationSchema";
import {Btn, Col, FormInputText} from "../../../common";
import {Colors, Metrics} from "../../../config";
import {useHistory} from "react-router";


const styles = {
    btn: {
        color: 'white',
        backgroundColor: Colors.secondary,
        marginTop: Metrics.spacingLG,
        width: '100%',
        borderRadius: '13px',
        border: 'none'
    },
    btnSec: {
        marginTop: Metrics.spacingSM,
        width: '100%',
        borderRadius: '13px',
        border: 'none'
    },
    btnRow: {
        alignItems: 'center'
    }
}


const PasswordRecoveryForm = ({onSubmit, isFetching}: any) => {
    const { goBack } = useHistory();
    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{email: ''}}
            onSubmit={(val) => onSubmit(val.email)}>
            {({ handleSubmit, values }) => (
                <Form>
                    <FormInputText
                        style={{background: '#ffffff9c'}}
                        required
                        fullWidth
                        placeholder="E-mail"
                        name="email"
                        value={values.email}
                    />
                    <Col style={styles.btnRow}>
                        <Btn
                            onClick={() => handleSubmit()}
                            disabled={isFetching}
                            loading={isFetching}
                            style={styles.btn}>
                            Confirmar
                        </Btn>
                        <Btn
                            onClick={goBack}
                            disabled={isFetching}
                            style={styles.btnSec}>
                            Voltar
                        </Btn>
                    </Col>
                </Form>
            )}
        </Formik>
    );
}

export default PasswordRecoveryForm;
