import {Col} from "../../../common";
import React from "react";
import {useFormikContext} from "formik";
import Filters from "../../../../configs/Filters";
import {Colors, Metrics} from "../../../config";


const OperationSummary = () => {
    const { values }: any = useFormikContext();

    return (
        <Col>
            <h3>Resumo da Operação</h3>
            <Col style={{
                borderRadius: Metrics.defaultRadius,
                padding: Metrics.spacingLG,
                borderColor: Colors.primary,
                borderWidth: 1,
                borderStyle: 'solid'
            }}>
                <span style={{marginRight: Metrics.spacingSM, textAlign: 'center'}}>Valor solicitado:</span>
                <span style={{fontSize: Metrics.fontSize.lg, textAlign: 'center'}}>{Filters.convertMoneyTextMask(values?.request?.amount)}</span>
                <Col style={{fontSize: Metrics.fontSize.xxxsm, color: Colors.gray}}>
                    <span>{`Data do primeiro vencimento: ${values?.selectedOffer?.firstInstallmentDueDate}`}</span>
                    <span>{`Dia de vencimento das parcelas: ${values?.selectedOffer?.installmentsDueDay}`}</span>
                    <span>{`Taxa de juros mensal: ${Filters.convertMoneyTextMask(values?.selectedOffer?.monthlyInterestRate)}`}</span>
                    <span>{`Taxa de multa única: ${Filters.convertMoneyTextMask(values?.selectedOffer?.onetimeFineRate)}`}</span>
                    <span>{`Taxa de juros anual: ${Filters.convertMoneyTextMask(values?.selectedOffer?.annualInterestRate)}`}</span>
                    <span>{`Taxa: ${Filters.convertMoneyTextMask(values?.selectedOffer?.fee)}`}</span>
                    <span>{`Impostos: ${Filters.convertMoneyTextMask(values?.selectedOffer?.taxes)}`}</span>
                </Col>
            </Col>
        </Col>
    );
};

export default OperationSummary;
