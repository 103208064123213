import React, {useCallback, useState} from "react";
import {Btn, Col, Row} from "../../common";
import {useHistory} from "react-router";
import {Colors, Metrics} from "../../config";
import useBreakpoint from "../../hooks/useBreakpoint";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import {Carousel, Slider} from "antd";
import cardWoman from "../../../assets/images/cardwoman.jpeg";
import handWoman from "../../../assets/images/handwoman2.png";
import fundoAma from "../../../assets/images/fundoama.png";
import fundoBra from "../../../assets/images/fundobra.png";
import useFetch from "../../hooks/useFetch";
import {findDefaultProcedureRequest, findMaxInstallmentsRequest} from "../../services/service";
import Filters from "../../../configs/Filters";
import compProg from "../../../assets/images/iconCompraProgramadaWhite.svg";
import comp from "../../../assets/images/icon-programada.png";
import fin from "../../../assets/images/icon-fina.png";
import cartao from "../../../assets/images/icon-cartao.png";
import footLogo from "../../../assets/images/footerlogo.png";
import aline from "../../../assets/images/Aline.jpg";
import marisa from "../../../assets/images/Marisa.jpg";
import ash from "../../../assets/images/ash.jpeg";
import debora from "../../../assets/images/debora.jpeg";
import marilia from "../../../assets/images/marilia.jpeg";
import frame from "../../../assets/images/frame.png";
import pkg from '../../../../package.json';
import {HouseRounded, MailRounded, PhoneRounded} from "@material-ui/icons";

const testimonials = [
    {
        img: aline,
        name: 'Aline Dias de Oliveira',
        city: 'Palhoça - SC',
        text: 'Tive todo apoio necessário do time Silicred para realizar a cirurgia dos meus sonhos! Eles têm várias opções de meios de pagamento... Quando algumas portas já estavam se fechando em outros lugares, descobri o adiantamento de cartão de crédito e graças a essa ajuda da Silicred, tive o dinheiro na hora na minha conta e tenho todo dinheiro da minha cirurgia. Sempre que eu precisei, o atendimento foi impecável e esclarecedor! Gratidão!'
    },
    {
        img: marisa,
        name: 'Marisa Pasquelon',
        city: 'Florianópolis - SC',
        text: 'Supersatisfeita com a realização do meu sonho! Fiz com um excelente profissional, e com a parceria da SILICRED pude pagar em suaves prestações! Vale a pena ! Autoestima lá em cima!'
    },
    {
        img: marilia,
        name: 'Marília do Livramento Castelan Viana',
        city: 'Criciúma – SC',
        text: 'Quero deixar aqui meu agradecimento à equipe Silicred, pela realização de um sonho. Com a ajuda deles vou conseguir realizar minha cirurgia. Tive todos os esclarecimentos possíveis. Gratidão!'
    },
    {
        img: ash,
        name: 'Ashelly Macksuelly G. Lacerda',
        city: 'Florianópolis - SC',
        text: 'Nossa vocês são incríveis!\n' +
            'Realizam sonhos mesmo.\n' +
            'Super indico para todas minhas amigas.\n' +
            'Parabéns para a equipe Silicred!'
    },
    {
        img: debora,
        name: 'Débora Martins Gaspar',
        city: 'Florianópolis - SC',
        text: 'Queria muito fazer minha cirurgia, mas precisava de um apoio financeiro que fosse viável para a minha realidade. Com a ajuda da Silicred, consegui realizar esse sonho. Eles são extremamente atenciosos. Me passaram inúmeras opções de parcelamento. Foi muito rápido, o que me deixou mais feliz. Eles se preocupam com o cliente e se fazem presente em cada etapa do processo.'
    },
]

const styles = {
    bigTitle: {
        fontSize: Metrics.fontSize.xxxxlg,
        marginBlock: 0,
        color: 'rgba(255, 255, 255, 0.9)',
        textAlign: 'center' as const
    },
    foot: {
        display: 'flex',
        marginBlock: Metrics.spacingMinimun,
        color: '#FEFEFE',
        alignItems: 'center'
    },
    icon: {
        marginInline: Metrics.spacingMD,
        marginBlock: Metrics.spacingMinimun,
        padding: Metrics.spacingMinimun,
        backgroundColor: '#262C28',
        borderRadius: '100px',
        color: '#FEFEFE'
    }
}

const ValueSelector = () => {
    const { push } = useHistory();
    const {isMobile} = useBreakpoint();
    const [{ isFetching: isFetchingDefaultProcedure, data: procedureDefault }] = useFetch(
        {
            provider: findDefaultProcedureRequest,
            param: '',
            requestOnMount: true,
            initialData: null,
            resultHandler: null
        });
    const [{ data: max }] = useFetch(
        {
            provider: findMaxInstallmentsRequest,
            param: '',
            requestOnMount: true,
            initialData: null,
            resultHandler: null
        });
    const [val, setVal] = useState(procedureDefault?.minValue);
    const handleSimulation = useCallback(() => push(`simulation?value=${val}`),[push, val]);
    return (
        <Col style={{paddingTop: Metrics.spacingXXXXLG}}>
        <Row
            responsive
            style={{
                width: isMobile ? '96%' : 'unset',
                zIndex: 1,
                alignSelf: 'center',
                borderRadius: '35px',
                overflow: 'hidden',
                left: isMobile ? 0 : '10%',
                top: '10%',
                marginInline: isMobile ? '2%' : 'unset'
        }}>
            <Col style={{ position: 'relative', justifyContent: 'space-between' }}>
                {/*<span style={{*/}
                {/*    position: 'absolute',*/}
                {/*    fontFamily: isMobile ? 'montserrat-black' : 'montserrat-bold',*/}
                {/*    top: isMobile ? 'unset' : 20,*/}
                {/*    bottom: isMobile ? 20 : 'unset',*/}
                {/*    zIndex: 1,*/}
                {/*    color: isMobile ? 'white' : '#3C3E39',*/}
                {/*    fontSize: Metrics.fontSize.xxlg,*/}
                {/*    textAlign: 'center' as const,*/}
                {/*    textShadow: isMobile ? '1px 1px 10px black' : '1px 1px 1px white',*/}
                {/*    textTransform: 'uppercase'*/}
                {/*}}>Realize a cirurgia de seu sonho</span>*/}
                {/*<span />*/}
                {/*<img src={handWoman}*/}
                {/*    style={{*/}
                {/*        maxWidth: isMobile ? '100%' : '310px',*/}
                {/*        height: isMobile ? '350px' : '100%',*/}
                {/*        objectFit: 'cover' as const,*/}
                {/*}} />*/}
            </Col>
            <Col style={{
                backgroundColor: '#fff',
                padding: `${Metrics.spacingXXXLG} ${Metrics.spacingMD}`,
                alignItems: 'center',
                justifyContent: 'space-between',
                maxWidth: isMobile ? 'unset' : '360px',
                width: isMobile ? '100%' : 'unset',
            }}>
                <span style={{
                    fontFamily: 'montserrat-bold',
                    color: '#969696',
                    fontSize: Metrics.fontSize.lg,
                    textAlign: 'center' as const,
                    textTransform: 'uppercase'
                }}>Quanto você precisa?</span>
                <span style={{
                    fontFamily: 'montserrat-bold',
                    fontSize: Metrics.fontSize.md,
                    marginTop: Metrics.spacingMD,
                    textAlign: 'center',
                    color: '#969696',
                }}>
                        {Filters.convertMoneyTextMask(val || procedureDefault?.minValue)}
                </span>
                { procedureDefault &&
                    <Slider
                        tooltip={{open: false}}
                        min={procedureDefault?.minValue}
                        max={procedureDefault?.maxValue}
                        step={500}
                        defaultValue={procedureDefault?.minValue}
                        value={val || procedureDefault?.minValue}
                        onChange={(e: any) => setVal(e)}
                        style={{width: '300px'}}
                    />
                }
                {/*<Row style={{justifyContent: 'space-between', width: '100%'}}>*/}
                {/*    <span style={{fontSize: Metrics.fontSize.xxxsm, color: Colors.text}}>*/}
                {/*        {Filters.convertMoneyTextMask(procedureDefault?.minValue || 0)}*/}
                {/*    </span>*/}
                {/*    <span style={{fontSize: Metrics.fontSize.xxxsm, color: Colors.text}}>*/}
                {/*        {Filters.convertMoneyTextMask(procedureDefault?.maxValue || 0)}*/}
                {/*    </span>*/}
                {/*</Row>*/}
                <Btn
                    style={{
                        fontFamily: 'montserrat-bold',
                        backgroundColor: 'transparent',
                        fontSize: Metrics.fontSize.md,
                        fontWeight: 'bolder',
                        color: '#969696',
                        borderRadius: '18px',
                        border: 'none',
                        textTransform: 'uppercase',
                        minWidth: '250px',
                        marginBlock: Metrics.spacingLG
                    }}
                    onClick={handleSimulation}>
                    Simular
                </Btn>
            </Col>
        </Row>
        <Col style={{
            paddingBlock: Metrics.spacingXLG,
            fontSize: Metrics.fontSize.xxsm,
            fontFamily: 'montserrat-bold',
            color: '#fff',
            textAlign: 'center' as const,
            textTransform: 'uppercase'
        }}>
                        <b>
                            {`Valores de ${Filters.convertMoneyTextMask(procedureDefault?.minValue || 0)} 
                            a R$ ${Filters.convertMoneyTextMask(procedureDefault?.maxValue || 0)} em até ${max?.value || 1}x. Taxas a partir de 1,4% ao mês`}
                        </b>
            <span>Parcelas na Modalidade de Procedimento Programado</span>
        </Col>
        </Col>
    )
}

const Home = () => {
    const { push } = useHistory();
    const { isMobile } = useBreakpoint();
    const blockHeight = '380px';
    return (
        <Col style={{width: '100%'}}>
            {
                !isMobile ?
                    <Row>
                        <Col style={{
                            background: `url(${fundoAma})`,
                            backgroundSize: 'cover',
                            width: '40%'
                        }}>
                            <span style={{
                                paddingTop: Metrics.spacingXXXLG,
                                fontFamily: 'montserrat-bold',
                                color: Colors.white,
                                fontSize: Metrics.fontSize.xxlg,
                                textAlign: 'center' as const,
                                textTransform: 'uppercase'
                            }}>realizando sonhos</span>
                            <Row style={{justifyContent: 'center', position: 'relative'}}>
                                <ValueSelector />
                                <div />
                            </Row>
                            <Col id={'aboutUs'} style={{
                                height: blockHeight,
                                color: 'white',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBlock: Metrics.spacingXLG,
                                paddingInline: Metrics.spacingMD
                            }}>
                                <span style={{
                                    fontFamily: 'montserrat-black',
                                    marginBottom: Metrics.spacingXLG,
                                    fontSize: Metrics.fontSize.xxlg,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bolder'
                                }}>Sobre nós</span>
                                        <span style={{
                                            fontFamily: 'montserrat-regular',
                                            fontSize: Metrics.fontSize.md,
                                            textAlign: 'center' as const
                                        }}>Somos uma plataforma moderna e integrada de soluções e meios de pagamento para procedimentos cirúrgicos em geral, estéticos, de cirurgia plástica e odontologia. Agora você pode realizar o seu sonhado procedimento, escolhendo a melhor forma de pagamento que cabe no seu orçamento. Aqui na SILICRED você escolhe a melhor forma de se programar e paga seu procedimento à vista, obtendo o melhor custo-benefício!</span>
                                    </Col>
                                    <Col style={{
                                        height: blockHeight,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <img src={compProg} style={{width: '130px'}} />
                                    </Col>
                                    <Col style={{
                                        height: blockHeight,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <img src={comp} style={{width: '130px'}} />
                                    </Col>
                                    <Col style={{
                                        height: blockHeight,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <img src={cartao} style={{width: '130px'}} />
                                    </Col>
                                    <Col style={{
                                        height: blockHeight,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <img src={fin} style={{width: '130px'}} />
                                    </Col>
                                    <div/>
                                </Col>
                                <Col style={{
                                    // background: `url(${fundoBra})`,
                                    backgroundSize: 'cover',
                                    width: '60%',
                                    position: 'relative'
                                }}>
                                    <img src={cardWoman} style={{height: '600px', objectFit: 'contain' as const}} />
                                    <Col style={{
                                        height: blockHeight,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBlock: Metrics.spacingXLG,
                                        zIndex: 1,
                                    }}>
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/m40HPyfHuis?si=U4tvnCnLpNxxSamO"
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                    </Col>
                                    <Col style={{
                                        height: blockHeight,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                <span style={{
                                    fontFamily: 'montserrat-black',
                                    marginBottom: Metrics.spacingXLG,
                                    fontSize: Metrics.fontSize.xxlg,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bolder',
                                    color: '#EFA301'
                                }}>Data-certa:</span>
                                        <span
                                            style={{
                                                color: '#7a7a7a',
                                                fontFamily: 'montserrat-semibold',
                                                fontSize: Metrics.fontSize.lg,
                                                maxWidth: '620px',
                                                textAlign: 'center'}}
                                        >Planeje a realização do seu sonho! Mesmo se tiver restrição no nome, você paga em 12 ou até 60 meses, e realiza o procedimento assim que tiver pago a metade das parcelas. E ainda concorre toda semana ao sorteio de R$ 10.000,00</span>
                                    </Col>
                                    <Col style={{
                                        height: blockHeight,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                <span style={{
                                    fontFamily: 'montserrat-black',
                                    marginBottom: Metrics.spacingXLG,
                                    fontSize: Metrics.fontSize.xxlg,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bolder',
                                    color: '#EFA301'
                                }}>Procedimento Programado:</span>
                                        <span
                                            style={{
                                                color: '#7a7a7a',
                                                fontFamily: 'montserrat-semibold',
                                                fontSize: Metrics.fontSize.lg,
                                                maxWidth: '620px',
                                                textAlign: 'center'}}
                                        >Programe o seu sonho! A partir de R$ 100,00 por mês. Você concorre toda semana ao sorteio de R$ 10.000,00</span>
                                    </Col>
                                {/*    <Col style={{*/}
                                {/*        height: blockHeight,*/}
                                {/*        justifyContent: 'center',*/}
                                {/*        alignItems: 'center'*/}
                                {/*    }}>*/}
                                {/*<span style={{*/}
                                {/*    fontFamily: 'montserrat-black',*/}
                                {/*    marginBottom: Metrics.spacingXLG,*/}
                                {/*    fontSize: Metrics.fontSize.xxlg,*/}
                                {/*    textTransform: 'uppercase',*/}
                                {/*    fontWeight: 'bolder',*/}
                                {/*    color: '#EFA301'*/}
                                {/*}}>Adiantamento de cartão de crédito:</span>*/}
                                {/*        <span*/}
                                {/*            style={{*/}
                                {/*                color: '#7a7a7a',*/}
                                {/*                fontFamily: 'montserrat-semibold',*/}
                                {/*                fontSize: Metrics.fontSize.lg,*/}
                                {/*                maxWidth: '620px',*/}
                                {/*                textAlign: 'center'}}*/}
                                {/*        >Solução para quem tem limite em um ou mais cartões. Dinheiro no mesmo dia com taxas imbatíveis e até 18 parcelas</span>*/}
                                {/*    </Col>*/}
                                    <Col style={{
                                        height: blockHeight,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                <span style={{
                                    fontFamily: 'montserrat-black',
                                    marginBottom: Metrics.spacingXLG,
                                    fontSize: Metrics.fontSize.xxlg,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bolder',
                                    color: '#EFA301'
                                }}>Financiamento:</span>
                                        <span
                                            style={{
                                                color: '#7a7a7a',
                                                fontFamily: 'montserrat-semibold',
                                                fontSize: Metrics.fontSize.lg,
                                                maxWidth: '620px',
                                                textAlign: 'center'}}
                                        >Simples e rápido! Para quem tem pressa do resultado. Escolha a parcela que cabe no seu bolso</span>
                                    </Col>
                                    <Col
                                        id={'testimonial'}
                                        style={{
                                            height: '600px',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                <span style={{
                                    fontFamily: 'montserrat-black',
                                    marginBottom: Metrics.spacingXLG,
                                    fontSize: Metrics.fontSize.xxlg,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bolder',
                                    color: '#EFA301'
                                }}>Depoimentos</span>
                                <Carousel autoplay style={{width: '600px', height: '480px'}}>
                                    {
                                        testimonials.map(t => (
                                            <Col
                                                key={t.name}
                                                style={{
                                                    height: '200px',
                                                    color: '#fff',
                                                    lineHeight: '160px',
                                                    textAlign: 'center',
                                                    background: '#364d79',
                                                }}>
                                                <Col style={{position: 'relative', height: '200px', justifyContent: 'center', alignItems: 'center'}}>
                                                    <img src={frame}
                                                         style={{
                                                             position: 'absolute',
                                                             width: '185px',
                                                             height: '185px',
                                                         }}
                                                    />
                                                    <img src={t.img}
                                                         style={{
                                                             width: '135px',
                                                             height: '135px',
                                                             borderRadius: '20px',
                                                             objectFit: 'cover' as const
                                                         }}
                                                    />
                                                </Col>
                                                <Col style={{justifyContent: 'center', alignItems: 'center'}}>
                                                    <span style={{
                                                        fontFamily: 'montserrat-black',
                                                        fontSize: Metrics.fontSize.sm,
                                                        textTransform: 'uppercase',
                                                        fontWeight: 'bolder',
                                                        color: '#EFA301'
                                                    }}>
                                                        {t.name}
                                                    </span>
                                                    <span style={{
                                                        marginBottom: Metrics.spacingMD,
                                                        fontSize: Metrics.fontSize.sm,
                                                        textTransform: 'uppercase',
                                                        fontWeight: 'bolder',
                                                        color: '#EFA301'
                                                    }}>
                                                        {t.city}
                                                    </span>
                                                        <span style={{
                                                            width: '500px',
                                                            textAlign: 'center'
                                                        }}>{t.text}</span>
                                                </Col>
                                            </Col>
                                        ))
                                    }
                                </Carousel>
                            </Col>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col style={{backgroundColor: '#EFA301'}}>
                            <span style={{
                                paddingTop: Metrics.spacingLG,
                                fontFamily: 'montserrat-bold',
                                color: Colors.white,
                                fontSize: Metrics.fontSize.lg,
                                textAlign: 'center' as const,
                                textTransform: 'uppercase'
                            }}>realizando sonhos</span>
                            <Row style={{justifyContent: 'space-between'}}>
                                <ValueSelector />
                                <div />
                            </Row>
                            <Col id={'aboutUs'} style={{
                                color: 'white',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBlock: Metrics.spacingXLG,
                                paddingInline: Metrics.spacingMD
                            }}>
                                <span style={{
                                    fontFamily: 'montserrat-black',
                                    marginBottom: Metrics.spacingXLG,
                                    fontSize: Metrics.fontSize.xxlg,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bolder'
                                }}>Sobre nós</span>
                                <span style={{
                                    fontFamily: 'montserrat-regular',
                                    fontSize: Metrics.fontSize.md,
                                    textAlign: 'center' as const
                                }}>Somos uma plataforma moderna e integrada de soluções e meios de pagamento para procedimentos estéticos e de cirurgia plástica. Agora você pode realizar o seu sonhado procedimento, escolhendo a melhor forma de pagamento que cabe no seu orçamento. Aqui na SILICRED você escolhe a melhor forma de se programar e paga seu procedimento à vista, obtendo o melhor custo-benefício!</span>
                            </Col>
                            <img src={cardWoman}
                                 style={{
                                     width: '100%',
                                     height: '400px', objectFit: 'contain' as const}} />
                            <Col style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBlock: Metrics.spacingXLG,
                                zIndex: 1
                            }}>
                                <iframe width="350" height="315" src="https://www.youtube.com/embed/m40HPyfHuis?si=U4tvnCnLpNxxSamO"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </Col>

                            <Col style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: Metrics.spacingMD
                            }}>
                                <img src={compProg} style={{width: '90px'}} />
                                <Col style={{
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <span style={{
                                    fontFamily: 'montserrat-black',
                                    marginBottom: Metrics.spacingXLG,
                                    fontSize: Metrics.fontSize.xxlg,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bolder',
                                    textAlign: 'center',
                                    color: '#fff'
                                }}>Data-certa:</span>
                                    <span
                                        style={{
                                            color: '#7a7a7a',
                                            fontFamily: 'montserrat-semibold',
                                            fontSize: Metrics.fontSize.lg,
                                            maxWidth: '620px',
                                            textAlign: 'center'}}
                                    >Planeje a realização do seu sonho! Mesmo se tiver restrição no nome, você paga em 12 ou até 60 meses, e realiza o procedimento assim que tiver pago a metade das parcelas. E ainda concorre toda semana ao sorteio de R$ 10.000,00</span>
                                </Col>
                            </Col>
                            <Col style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: Metrics.spacingMD
                            }}>
                                <img src={comp} style={{width: '90px'}} />
                                <Col style={{
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <span style={{
                                    fontFamily: 'montserrat-black',
                                    marginBottom: Metrics.spacingXLG,
                                    fontSize: Metrics.fontSize.xxlg,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bolder',
                                    textAlign: 'center',
                                    color: '#fff'
                                }}>Procedimento Programado:</span>
                                    <span
                                        style={{
                                            color: '#7a7a7a',
                                            fontFamily: 'montserrat-semibold',
                                            fontSize: Metrics.fontSize.lg,
                                            maxWidth: '620px',
                                            textAlign: 'center'}}
                                    >Programe o seu sonho! A partir de R$ 100,00 por mês. Você concorre toda semana ao sorteio de R$ 10.000,00</span>
                                </Col>
                            </Col>
                            {/*<Col style={{*/}
                            {/*    justifyContent: 'center',*/}
                            {/*    alignItems: 'center',*/}
                            {/*    marginBlock: Metrics.spacingXLG*/}
                            {/*}}>*/}
                            {/*    <img src={cartao} style={{width: '90px'}} />*/}
                            {/*    <Col style={{*/}
                            {/*        justifyContent: 'center',*/}
                            {/*        alignItems: 'center'*/}
                            {/*    }}>*/}
                            {/*        <span style={{*/}
                            {/*            fontFamily: 'montserrat-black',*/}
                            {/*            marginBottom: Metrics.spacingXLG,*/}
                            {/*            fontSize: Metrics.fontSize.xxlg,*/}
                            {/*            textTransform: 'uppercase',*/}
                            {/*            fontWeight: 'bolder',*/}
                            {/*            textAlign: 'center',*/}
                            {/*            color: '#fff'*/}
                            {/*        }}>Adiantamento de cartão de crédito:</span>*/}
                            {/*        <span*/}
                            {/*            style={{*/}
                            {/*            color: '#7a7a7a',*/}
                            {/*            fontFamily: 'montserrat-semibold',*/}
                            {/*            fontSize: Metrics.fontSize.lg,*/}
                            {/*            maxWidth: '620px',*/}
                            {/*            textAlign: 'center'}}*/}
                            {/*        >Solução para quem tem limite em um ou mais cartões. Dinheiro no mesmo dia com taxas imbatíveis e até 18 parcelas</span>*/}
                            {/*    </Col>*/}
                            {/*</Col>*/}
                            <Col style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: Metrics.spacingMD
                            }}>
                                <img src={fin} style={{width: '90px'}} />
                                <Col style={{
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <span style={{
                                        fontFamily: 'montserrat-black',
                                        marginBottom: Metrics.spacingXLG,
                                        fontSize: Metrics.fontSize.xxlg,
                                        textTransform: 'uppercase',
                                        fontWeight: 'bolder',
                                        textAlign: 'center',
                                        color: '#fff'
                                    }}>Financiamento:</span>
                                    <span
                                        style={{
                                            color: '#7a7a7a',
                                            fontFamily: 'montserrat-semibold',
                                            fontSize: Metrics.fontSize.lg,
                                            maxWidth: '620px',
                                            textAlign: 'center'}}
                                    >Simples e rápido! Para quem tem pressa do resultado. Escolha a parcela que cabe no seu bolso</span>
                                </Col>
                            </Col>
                            <Col
                                    id={'testimonial'}
                                    style={{
                                        height: '600px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: Metrics.spacingLG,
                                        minWidth: 0
                                    }}>
                                <span style={{
                                    fontFamily: 'montserrat-black',
                                    marginBottom: Metrics.spacingXLG,
                                    fontSize: Metrics.fontSize.xxlg,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bolder',
                                    color: '#fff'
                                }}>Depoimentos</span>
                                    <Carousel autoplay style={{width: '350px', height: '510px'}}>
                                        {
                                            testimonials.map(t => (
                                                <Col
                                                    style={{
                                                        height: '300px',
                                                        color: '#fff',
                                                        lineHeight: '160px',
                                                        textAlign: 'center',
                                                        background: '#364d79',
                                                    }}>
                                                    <Col style={{position: 'relative', height: '200px', justifyContent: 'center', alignItems: 'center'}}>
                                                        <img src={frame}
                                                             style={{
                                                                 position: 'absolute',
                                                                 width: '185px',
                                                                 height: '185px',
                                                             }}
                                                        />
                                                        <img src={t.img}
                                                             style={{
                                                                 width: '135px',
                                                                 height: '135px',
                                                                 borderRadius: '20px',
                                                                 objectFit: 'cover' as const
                                                             }}
                                                        />
                                                    </Col>
                                                    <Col style={{justifyContent: 'center', alignItems: 'center'}}>
                                                    <span style={{
                                                        fontFamily: 'montserrat-black',
                                                        fontSize: Metrics.fontSize.sm,
                                                        textTransform: 'uppercase',
                                                        fontWeight: 'bolder',
                                                        color: '#fff'
                                                    }}>
                                                        {t.name}
                                                    </span>
                                                        <span style={{
                                                            marginBottom: Metrics.spacingMD,
                                                            fontSize: Metrics.fontSize.sm,
                                                            textTransform: 'uppercase',
                                                            fontWeight: 'bolder',
                                                            color: '#fff'
                                                        }}>
                                                        {t.city}
                                                    </span>
                                                        <span style={{
                                                            width: '350px',
                                                            textAlign: 'center'
                                                        }}>{t.text}</span>
                                                    </Col>
                                                </Col>
                                            ))
                                        }
                                    </Carousel>
                                </Col>
                            <div/>
                        </Col>
                    </Row>
            }
            <Row
                responsive
                style={{
                backgroundColor: '#787878',
                justifyContent: 'space-between',
                color: 'rgb(169, 169, 169)',
                paddingInline: Metrics.spacingSM,
                paddingTop: Metrics.spacingLG,
                paddingBottom: Metrics.spacingSM,
                alignItems: 'center',
                fontSize: Metrics.fontSize.xsm
            }}>
                <Col>
                    <img src={footLogo} style={{ width: '180px' }} />
                    <span style={{fontSize: Metrics.fontSize.xxsm}}>{`versão ${pkg.version}`}</span>
                    <Row responsive
                         style={{
                             backgroundColor: '#787878',
                             justifyContent: 'space-between',
                             color: 'rgb(238,238,238)',
                             paddingBottom: Metrics.spacingSM,
                             alignItems: 'center',
                         }}>
                        <Row responsive>
                <span
                    onClick={() => push('terms')}
                    style={{marginTop: Metrics.spacingMD, fontSize: Metrics.fontSize.xxxsm, cursor: 'pointer', textTransform: 'uppercase'}}>
                    termos de uso
                </span>
                <span
                    onClick={() => push('privacy')}
                    style={{marginLeft: Metrics.spacingSM, marginTop: Metrics.spacingMD, fontSize: Metrics.fontSize.xxxsm, cursor: 'pointer', textTransform: 'uppercase'}}>
                    política de privacidade
                </span>
                        </Row>
                    </Row>
                </Col>
                <Col>
                    <h5 style={styles.foot}>
                        <HouseRounded style={styles.icon} />
                        Rua Saldanha Marinho 374 – sala 701 – Centro – Florianópolis – SC</h5>
                    <h5 style={styles.foot}>
                        <PhoneRounded style={styles.icon} />
                        48 3209-6404</h5>
                    <h5 style={styles.foot}>
                        <MailRounded style={styles.icon} />
                        contato@silicred.com.br</h5>
                    <span style={{ marginTop: '15px', fontSize: 12, flex: 1, textAlign: 'center', color: 'rgb(238,238,238)' }}>
                        SILICRED ADMINISTRACAO EM CREDITO LTDA - CNPJ 35.237.854/0001-70
                    </span>
                </Col>
                <Col responsive>
                    <a target="noreferrer"
                       href="https://www.facebook.com/silicred">
                        <FacebookIcon style={styles.icon} />
                    </a>
                    <a target="noreferrer"
                       href="https://www.instagram.com/silicred/">
                        <InstagramIcon style={styles.icon} />
                    </a>
                    <a target="noreferrer" href="https://www.linkedin.com/company/silicred-solu%C3%A7%C3%B5es-financeiras/mycompany/?viewAsMember=true">
                        <LinkedInIcon style={styles.icon} />
                    </a>
                </Col>
            </Row>
            <span style={{
                backgroundColor: '#787878',
                color: 'rgb(238,238,238)',
                width: '100%',
                textAlign: 'center',
                fontSize: '11px',
                paddingBlock: '15px'
            }}>
                desenvolvimento por <a style={{ color: 'white', textDecoration: 'underline' }} href="https://dtdevs.com" target="_blank">dtdevs</a>
            </span>
        </Col>
    );
};

export default Home;
