import React, {useCallback, useEffect, useState} from 'react'
import {useFormikContext} from "formik";
import styled from "styled-components";
import {Col} from "../../index";
import {Colors, Metrics} from "../../../config";
import {DeleteOutline} from "@material-ui/icons";
import {Button, Upload} from "antd";

const InvisibleBtn = styled(Button)`
    background: transparent;
    width: 250px;
    color: ${() => Colors.text};
    &:active {
      border: ${() => `1px solid ${Colors.primaryDark}`};
      color: ${() => Colors.primaryDark};
      background: transparent;
    }
    &:focus {
      border: ${() => `1px solid ${Colors.primaryDark}`};
      color: ${() => Colors.primaryDark};
      background: transparent;
    }
    :hover {
      border: ${() => `1px solid ${Colors.primaryDark}`};
      color: ${() => Colors.primaryDark};
      background: transparent;
    }
`;

const styles = {
    loadBtn: {
        position: 'absolute' as const,
        background: Colors.primary,
        padding: Metrics.spacingSM,
        color: Colors.white,
        cursor: 'pointer'
    },
    fileName: {
        fontSize: Metrics.fontSize.xxsm
    },
    deleteIcon: {
        fontSize: Metrics.fontSize.sm,
        cursor: 'pointer'
    },
    inputContainer: {position: 'relative', height: '40px', width: '225px', overflow: 'hidden', alignItems: 'center'}
}

const FormUploader = ({fieldName, contentTypeField, success, file, label, disabled}: any) => {
    const {values, setFieldValue}: any = useFormikContext();
    const [queuedFileName, setQueuedFileName] = useState(null);
    const reader = new FileReader();

    const handleChange = useCallback( (f: any) => {
        // console.log(e);
        // const files = Array.from(e.target.files);
        // files.forEach((file: any, i: any) => {
            setQueuedFileName(f.name);
            setFieldValue(contentTypeField, f.type);
            reader.readAsDataURL(f);
            reader.onload = function () {
                if (reader && reader.result && (typeof reader.result === 'string')) {
                    setFieldValue(fieldName, reader.result.split(',')[1]);
                }
            };
        // })
    }, [setFieldValue, contentTypeField, fieldName, reader]);

    const handleRemoveFile = useCallback( () => {
        setFieldValue(fieldName, null);
        setFieldValue(contentTypeField, null);
        setQueuedFileName(null);

    }, [setFieldValue, fieldName, contentTypeField]);

    const props = {
        showUploadList: false,
        beforeUpload: (file: any) => {
            handleChange(file)
            return false;
        },
    };

    useEffect( () => {
        if (success) {
            handleRemoveFile();
        }
    }, [success]);

    return (
        <Col style={{marginInline: Metrics.spacingMD}}>
            {
                queuedFileName ?
                    <p style={styles.fileName}>{queuedFileName}<DeleteOutline style={styles.deleteIcon} onClick={handleRemoveFile} /></p> : <p />
            }
            <Col>
                <Upload {...props}>
                    <InvisibleBtn disabled={disabled}>Clique aqui para carregar</InvisibleBtn>
                </Upload>
            </Col>
        </Col>
    );
};

export default FormUploader
