import {Col, Row} from "../../../common";
import React, {useState} from "react";
import {useFormikContext} from "formik";
import Filters from "../../../../configs/Filters";
import {Colors, Metrics} from "../../../config";
import {Button, Input, Tooltip} from "antd";
import {CheckCircleOutlined} from "@material-ui/icons";


const Contract = () => {
    return (
        <Col>
            <h3>Para assinar o contrato, siga os passos:</h3>
            <span>1 - Solicite o código por SMS</span>
            <span>1 - Informe o código recebido</span>
            <Col style={{marginBlock: Metrics.spacingMD}}>
                <Button style={{width: '200px'}} type={'primary'}>Receber código</Button>
                <Row style={{alignItems: 'center'}}>
                    <Input style={{marginBlock: Metrics.spacingMD, marginRight: Metrics.spacingSM}} placeholder={"Informe aqui o código recebido"} />
                    <Button type={'primary'}>Validar código</Button>
                </Row>
            </Col>
            <Col style={{height: '700px', width: '700px', border: '1px solid black'}}>
                <h3>Contrato exemplo</h3>
                <h4>conteúdo do contrato</h4>
                <h4>conteúdo do contrato</h4>
                <h4>conteúdo do contrato</h4>
                <h4>conteúdo do contrato</h4>
                <h4>conteúdo do contrato</h4>
                <h4>conteúdo do contrato</h4>
            </Col>
        </Col>
    );
};

export default Contract;
