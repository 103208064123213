import React, {useState} from 'react';
import {Checkbox, Modal} from "antd";
import {useFormikContext} from "formik";
import {useHistory, useLocation} from "react-router";
import qs from "qs";
import moment from "moment";
import {useAuthentication} from "../../../context/AuthContext";
import useFetch from 'new/hooks/useFetch';
import {findContractRequest} from "../../../services/parameter";
import {
    downloadContractFileRequest,
    hireServiceInternalRequest,
    hireServiceRequest,
    sendContractByEmailRequest
} from "../../../services/service";
import {MONTH_NAMES} from "../../../../constants";
import {Btn, Col} from "../../../common";
import {Colors, Metrics} from "../../../config";
import {porExtenso, estilo} from 'numero-por-extenso';
const { confirm } = Modal;

export const DUE_DAYS = [
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 7},
    {value: 16},
    {value: 21},
    {value: 23},
    {value: 27},
];

const TermsAcceptance = ({seller}: any) => {
    const location = useLocation();
    const referenceSeller = qs.parse(location.search, {ignoreQueryPrefix: true}).referenceSeller as string;
    const { user } = useAuthentication();
    const { push } = useHistory();
    const [agreed, setAgreed] = useState<any>(false);
    const { values }: any = useFormikContext();
    const [{ isFetching, data }] = useFetch(
        {
            provider: findContractRequest,
            param: values?.type,
            requestOnMount: true,
            initialData: null,
            resultHandler: null
        });
    const [{ isFetching: isFetchingHire }, hire] = useFetch(
        {
            provider: user && !seller ? hireServiceInternalRequest : hireServiceRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: (response: any) => {
                    sendEmail(
                        {
                            contractText: replaceContractVariables(data?.value || ''),
                            user: {
                                ...(seller ? values.user : user || values.user),
                                birthdate: user ? moment(user?.birthdate).toDate() : moment(values?.user?.birthdate).toDate(),
                            }
                        }
                    );
                   if (user) {
                       push('new-my-dream');
                   } else {
                       push('success');
                   }
                },
            }
    });
    const [{ isFetching: isFetchingSendEmail }, sendEmail] = useFetch(
        {
            provider: sendContractByEmailRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: null
        });
    const [{ isFetching: isFetchingDownload }, download] = useFetch(
        {
            provider: downloadContractFileRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: (response: any) => {
                    const tempUrl = window.URL.createObjectURL(
                        new Blob([response.data], {type: response.data.type})
                    );
                    // @ts-ignore
                    const link = document.createElement('a');
                    link.href = tempUrl;
                    const contentDisposition = response.headers['content-disposition'];
                    const filename = contentDisposition.substring(contentDisposition.indexOf('=') + 1);
                    link.setAttribute('download', `${filename}.pdf`);
                    link.click();
                }
            }
        });


    const formatAddress = (address: any | undefined) => {
        return `${address?.street}, ${address?.number}, ${address?.complement ? address?.complement : ''} - ${address?.district} - ${address?.city}/${address?.state} ${address?.postalCode ? address?.postalCode : address?.postal_code}`;
    };

    const replaceContractVariables = (text: string) => {
        let preparedText = text;
        const now: any = new Date();
        const userInternal: any = seller ? values.user : user || values.user;
        preparedText = preparedText.replaceAll('${name}', userInternal?.name || '');
        preparedText = preparedText.replaceAll('${cpf}', userInternal?.cpf || '');
        preparedText = preparedText.replaceAll('${rg}', userInternal?.rg || '');
        preparedText = preparedText.replaceAll('${address}', formatAddress(userInternal?.address) || '');
        preparedText = preparedText.replaceAll('${city}', userInternal?.address?.city || '');
        preparedText = preparedText.replaceAll('${hiredValue}', `R$ ${values?.installment?.hiredValue?.toFixed(2)} (${porExtenso(values?.installment?.hiredValue?.toFixed(2), estilo.monetario)})` || '');
        preparedText = preparedText.replaceAll('${contractValue}', `R$ ${values?.installment?.agreementValue?.toFixed(2)} (${porExtenso(values?.installment?.agreementValue?.toFixed(2), estilo.monetario)})` || '');
        preparedText = preparedText.replaceAll('${installmentValue}', `R$ ${values?.installment?.value?.toFixed(2)} (${porExtenso(values?.installment?.value?.toFixed(2), estilo.monetario)})` || '');
        preparedText = preparedText.replaceAll('${monthsQt}', values?.installment?.months || '');
        preparedText = preparedText.replaceAll('${payday}', `${moment(values?.startPayment).format('D')}` || '');
        preparedText = preparedText.replaceAll('${tax}', values?.tax);
        preparedText = preparedText.replaceAll('${currentShortDate}', (`${moment(now).format('DD/MM/yyyy')}`));
        preparedText = preparedText.replaceAll('${currentLongDate}', (`${now.getDate()} de ${MONTH_NAMES[now.getMonth()]} de ${now.getFullYear()}`));
        return preparedText
    };

    const showHireConfirm = (payment: any) => {
        return confirm({
            title: 'Contratar serviço',
            content: 'Tem certeza que deseja contratar este serviço?',
            onOk() {
                hire({
                    ...values,
                    sellerId: seller ? user.id : referenceSeller || null,
                    user: {
                        ...(seller ? values.user : user || values.user),
                        birthdate: user && !seller ? moment(user?.birthdate, 'YYYY-MM-DD').toDate() : moment(values?.user?.birthdate,'DD/MM/YYYY').toDate(),
                    }
                });
            },
            onCancel() {},
        });
    }

    return (
        <Col
            style={{
                marginBlock: Metrics.spacingLG,
            }}>
            <span
                style={{color: Colors.primaryDark}}
            >Por favor, leia atentamente o contrato e role até o final da página para aceitar.</span>
            <Col
                style={{
                    boxShadow: '1px 1px 15px #e0e0e0',
                    padding: Metrics.spacingMD,
                    marginBlock: Metrics.spacingMD,
                    borderRadius: '8px'
                }}>
                <p
                    style={{
                        whiteSpace: 'pre-wrap',
                        textAlign: 'justify',
                        lineHeight: '1.6rem',
                        textIndent: '50px',
                        fontFamily: 'montserrat-regular',
                        fontSize: Metrics.fontSize.xxsm,
                    }}>
                    {replaceContractVariables(data?.value || '')}
                </p>
                <Checkbox checked={agreed} onClick={setAgreed}>Li e aceito os termos e condições</Checkbox>
                <Btn
                    style={{marginBlock: Metrics.spacingMD}}
                    type='primary' disabled={!agreed} onClick={showHireConfirm}>Continuar</Btn>
                <Btn
                    loading={isFetchingDownload}
                    onClick={() => download({contractText: replaceContractVariables(data?.value || '')})}>
                    Clique aqui para fazer o download deste contrato
                </Btn>
            </Col>
        </Col>
    )
}

export default TermsAcceptance;
