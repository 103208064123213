import React from "react";
import {useHistory, useParams} from "react-router";
import {findUserRequest, updateUserRequest} from "../../../../services/user";
import useFetch from "../../../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../../../configs/toast";
import {TitledContainer, UserForm} from "../../../../components";

export const UserEdit = () => {
    const { goBack } = useHistory();
    const {login}: any = useParams();
    const [{ isFetching, data }] = useFetch(
        {
            provider: findUserRequest,
            param: login,
            requestOnMount: true,
            initialData: null,
            resultHandler: null
        });
    const [{ isFetching: isFetchingUpdate }, update] = useFetch(
        {
            provider: updateUserRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
              success: () => {
                showSuccessToast('Registro atualizado com sucesso!');
                goBack();
              },
              error: (d: any) => showErrorToast(d)
            },
        }
    );
    return (
        <TitledContainer title={'Usuários'}>
            {data &&
                <UserForm
                    isFetching={isFetching || isFetchingUpdate}
                    goBack={goBack}
                    onSubmit={update}
                    initialValues={data}
                />
            }
        </TitledContainer>
    );
}
