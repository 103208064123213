import React from "react";
import {SimulationForm, TitledContainer} from "../../components";
import {Col} from "../../common";

const styles = {
    container: {alignItems: 'center'},
}

export const Service = () => {
    return (
    <TitledContainer title={'Serviços'}>
        <Col style={styles.container}>
            <SimulationForm />
        </Col>
    </TitledContainer>
    );
};
