import React, {useCallback, useEffect, useState} from "react";
import {Metrics} from "new/config";
import {useHistory} from "react-router";
import useFetch from "../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../configs/toast";
import {TitledContainer} from "../index";
import {ActionIcons, Col, ConfirmationModal, Row, Table} from "../../common";
import {Table as TableAntd} from "antd";
import useFetchPag from "../../hooks/useFetchPag";

const { Column } = TableAntd;


const GenericList = ({
        title,
        newPath,
        editPath,
        providerFind = () => {},
        providerParam = '',
        providerDelete = () => {},
        deletable,
        columns = [],
        filter,
        filterElement,
        alternate,
    }: any) => {
    const [localPageCurrent, setLocalPageCurrent] = useState<any>(1);
    const [localPageSize, setLocalPageSize] = useState<any>(10);
    const [deleteModalVisible, setDeleteModalVisible] = useState<any>();
    const { push } = useHistory();

    const [
        { isFetching: isFetchingPage, data, totalItems, currentPage: current, pageSize },
        fetchPage,
    ] = useFetchPag({
        provider: providerFind,
        param: providerParam,
        requestOnMount: !alternate,
        initialData: [],
        paginate: true,
    });

    const [
        { isFetching, data: response },
        fetch,
    ] = useFetch({
        provider: providerFind,
        param: "",
        requestOnMount: alternate,
        initialData: []
    });

    const [{ isFetching: isFetchingDelete }, deleteRecord] = useFetch({
        provider: providerDelete,
        param: "",
        requestOnMount: false,
        initialData: [],
        resultHandler: {
            success: () => {
                handleFind(0);
                setDeleteModalVisible(false);
                showSuccessToast('Registro apagado com sucesso.');
            },
            error: (d: any) => showErrorToast(d),
        },
    });

    const handleFind = useCallback(
        (p: any) => {
            if (alternate) {
                setLocalPageCurrent(p?.current);
                setLocalPageSize(p?.pageSize);
            } else {
                fetchPage({
                    page: { current: p?.current === null ? current : p?.current, size: p?.pageSize },
                    filter
                });
            }
        },
        [alternate, fetchPage, current, filter]
    );

    useEffect(() => {
        if (filter) {
            handleFind(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    return (
        <TitledContainer title={title} newFn={newPath ? () => push(newPath) : null}>
            {filterElement}
            <Col style={{ marginBlock: Metrics.spacingMD }}>
                <Table
                    pagination={{
                        total: totalItems,
                        current: alternate ? localPageCurrent : current,
                        pageSize: alternate ? localPageSize : pageSize,
                    }}
                    isFetching={isFetching || isFetchingPage}
                    rowKey="id"
                    handleFind={handleFind}
                    data={alternate ? response?.data || [] : data}>
                    {
                        columns.map( (c: any) => (
                            <Column title={c.title} dataIndex={c.key} key={c.key} render={c?.render || null} />
                        ))
                    }
                    <Column
                        title={'Ação'}
                        dataIndex="action"
                        key="action"
                        render={(i, record: any) => (
                            <Row>
                                {editPath &&
                                    <ActionIcons
                                        type={"edit"}
                                        actionFn={() => push(`${editPath}/${record?.login || record.id}`)}
                                    />
                                }
                                {deletable &&
                                    <ActionIcons
                                        type={"delete"}
                                        actionFn={() => !isFetchingDelete ? setDeleteModalVisible(record.id) : () => {}}
                                    />
                                }
                                {
                                    deleteModalVisible === record.id &&
                                    <ConfirmationModal
                                        title={'Tem certeza que quer deletar este registro?'}
                                        description={'Está ação não pode ser desfeita.'}
                                        visible={deleteModalVisible === record.id}
                                        onOk={() => {
                                            deleteRecord(record.id);
                                        }}
                                        onCancel={() => setDeleteModalVisible(false)}
                                    />
                                }
                            </Row>
                        )}
                    />
                </Table>
            </Col>
        </TitledContainer>
    );
}

export default GenericList;
