import api from "./api";

export const findCommissionRequest = async (param: any, data: any) => {
    return api.get(`/api/commission/${param}`, data);
};

export const findCommissionsRequest = (param: any, data: any) => {
    return api.get(`/api/commission`, {params: data});
};

export const findAllCommissionsRequest = (param: any, data: any) => {
    return api.get(`/api/commission/all`, {params: data});
};

export const createCommissionRequest = (param: any, data: any) => {
    return api.post(`/api/commission`, data);
};

export const updateCommissionRequest = (param: any, data: any) => {
    return api.put(`/api/commission`, data);
};

export const deleteCommissionRequest = (param: any, data: any) => {
    return api.delete(`/api/commission/${data}`);
};
