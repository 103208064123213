import React, {useCallback, useRef, useState} from 'react';
import {Formik} from 'formik';
import {Btn, Col, FormInputText} from "../../common";
import {Form, Modal} from "antd";
import useFetch from "../../hooks/useFetch";
import {sendFinancingDataRequest} from "../../services/financing";
import {showSuccessToast} from "../../../configs/toast";
import validationSchema from "../../features/service/ValidationSchema";
import {MaskUtils} from "../../../util/MasksUtil";
import {Colors, Metrics} from "../../config";
import FinancingForm from "../FinancingForm";

const styles = {
    btnSecondary: {
        marginInline: Metrics.spacingMD,
        minWidth: '250px'
    },
}

const FinancingModal = ({visible, setVisible}: any) => {
    const formikRef: any = useRef();
    const [openCoActive, setOpenCoActive] = useState(false);
    const handleReset = useCallback(() => {
        formikRef?.current?.resetForm();
    },[formikRef]);


    const [{ isFetching, data }, send] = useFetch(
        {
            provider: sendFinancingDataRequest,
            param: '',
            requestOnMount: false,
            initialData: [],
            resultHandler: {
                success: () => {
                    setVisible(false);
                    handleReset();
                    showSuccessToast('Dados enviados! Logo um de nossos consultores entrará em contato.');
                }
            }
        });


    return (
        <Modal open={visible}
               width={'85%'}
               footer={null}
               okText={'Enviar'}
               cancelText={'Cancelar'}
               onCancel={() => setVisible(false)}>
            {openCoActive ?
                <FinancingForm />
            :
                <Formik
                    innerRef={formikRef}
                    initialValues={
                        {
                            name: '',
                            cel: '',
                            city: '',
                            state: '',
                            professional: '',
                            professionalContact: '',
                        }
                    }
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={send}
                >
                    {({handleSubmit, values}: any) => (
                        <Form>
                            <p style={{color: Colors.gray, marginBlock: Metrics.spacingMD}}>Nós realizamos os pagamentos direto para os profissionais da saúde, informe o seu médico, dentista, clínica ou hospital, que cadastraremos ele para o recebimento do seu financiamento.</p>
                            <FormInputText label="Nome" name='name' value={values.name} placeholder='Nome' />
                            <FormInputText label="Telefone" name="cel" value={MaskUtils.formatCel(values.cel)} />
                            <FormInputText label="Cidade" name='city' value={values.city} placeholder='Cidade' />
                            <FormInputText label="Estado" name='state' value={values.state} placeholder='Estado' />
                            <FormInputText label="Nome da Clínica ou Profissional" name="professional" value={values.professional} />
                            <FormInputText label="Telefone da Clínica ou Profissional" name="professionalContact" value={MaskUtils.formatCel(values.professionalContact)} />
                            <Col>
                                <Btn
                                    onClick={handleSubmit}
                                    type='primary'>
                                    Enviar
                                </Btn>
                                <Btn
                                    onClick={() => setVisible(false)}
                                    style={styles.btnSecondary}>
                                    Cancelar
                                </Btn>
                            </Col>
                        </Form>
                    )}
                </Formik>
            }
        </Modal>
    );
};

export default FinancingModal;
