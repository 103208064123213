import api from "./api";

export const findPartnerRequest = async (param: any, data: any) => {
    return api.get(`/api/influencers/${param}`, data);
};

export const findPartnersRequest = (param: any, data: any) => {
    return api.get(`/api/influencers`, {params: data});
};

export const sendPartnershipDataRequest = (param: any, data: any) => {
    return api.post(`/api/partner`, data);
};

export const createPartnerRequest = (param: any, data: any) => {
    return api.post(`/api/influencers`, data);
};

export const updatePartnerRequest = (param: any, data: any) => {
    return api.put(`/api/influencers`, data);
};

export const deletePartnerRequest = (param: any, data: any) => {
    return api.delete(`/api/influencers/${data}`);
};
