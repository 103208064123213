import React, {useCallback, useState} from "react";
import {Tabs} from "antd";
import {TitledContainer} from "../../../components";
import {CommissionReport} from "./types/Commission";
import {GeneralReport} from "./types/General";
import {Colors, Metrics} from "../../../config";

const { TabPane } = Tabs;

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btnDark: {backgroundColor: Colors.primaryDark}
};

export const Report = () => {
    const [activeKey, setActiveKey] = useState<any>('1');

    const handleChangeTab = useCallback((key) =>
        setActiveKey(key), [])

    return (
        <TitledContainer title={'Gerar Relatório'}>
            <Tabs activeKey={activeKey} onChange={handleChangeTab}>
                <TabPane tab="Geral" key="1">
                    {activeKey === '1' && <GeneralReport styles={styles} />}
                </TabPane>
                <TabPane tab="Comissão" key="2">
                    {activeKey === '2' && <CommissionReport styles={styles} />}
                </TabPane>
            </Tabs>
        </TitledContainer>
    );
};
