import api from "./api";
import {DateUtils} from "../../util/DateUtils";

const formatReportDate = (data: any) => {
    const valuesFormated = {...data};
    if (valuesFormated.agreementDateStart) {
        valuesFormated.agreementDateStart = DateUtils.convertStringBRToString(valuesFormated.agreementDateStart);
    }
    if (valuesFormated.agreementDateEnd) {
        valuesFormated.agreementDateEnd = DateUtils.convertStringBRToString(valuesFormated.agreementDateEnd);
    }
    return valuesFormated;
}
export const generateSellReportRequest = () => `/api/reports/sell-file`;

export const generateSimpleReportRequest = () => `/api/reports/simple`;

export const generateCompleteReportRequest = () => `/api/reports/full`;

export const getSellReportRequest = (param: any, data: any) => {
    return api.get(`/api/reports/sell`,{params: formatReportDate(data)});
};
