import React from "react";
import {Col} from "../../common";
import aboutUsImg from "../../../assets/images/sobreNos.svg";
import {Colors, Metrics} from "../../config";

const styles = {
    container: {alignItems: 'center', padding: `75px ${Metrics.spacingXLG}`},
    title: {
        color: Colors.primary,
    },
    text: {
        fontSize: Metrics.fontSize.xxsm,
        whiteSpace: 'pre-line' as const,
        width: '1100px',
        fontFamily: 'montserrat-medium',
        color: Colors.text,
        textAlign: 'justify' as const
    },
    text2: {
        fontSize: Metrics.fontSize.xxsm,
        whiteSpace: 'pre-line' as const,
        fontFamily: 'montserrat-bold',
        width: '1100px',
        color: Colors.text,
        textAlign: 'justify' as const
    },
    img: {width: '120px'}
}
const PrivacyPolicy = () => {
    return (
        <Col style={styles.container}>
            <img style={styles.img} src={aboutUsImg} alt=""/>
            <h2 style={styles.title}>Política de Privacidade - SILICRED</h2>
            <p style={styles.text}>
                Na SILICRED ADMINISTRAÇÃO EM CRÉDITO LTDA, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 35.237.854/0001-70, localizada à Rua Fúlvio Aducci 627 – sala 908 , bairro Estreito - Florianópolis, Estado de Santa Catarina, CEP: 88075-000, representada pelos seus sócios administradores, a privacidade e a segurança são prioridades no tocante ao tratamento de dados pessoais, sendo que estamos comprometidos organicamente com a legalidade, legitimidade e a devida transparência, princípios estes que regem nossa atividade com relação aos nossos pacientes e clientes diversos.
            </p>
            <p style={styles.text}>
                Esta Política de Privacidade estabelece como é feita a coleta, uso e transferência de informações dos pacientes e clientes diversos ou ainda, outras pessoas que acessam ou usam nosso site.
            </p>
            <p style={styles.text}>
                Ao utilizar nossos serviços, você passa a entender que coletaremos e usaremos suas informações pessoais nas formas descritas nesta Política, sob as normas de Proteção de Dados - LGPD, Lei Federal n°13.709/2018, das disposições consumeristas da Lei Federal n° 8078/1990 e as demais normas pertencentes ao Ordenamento Jurídico brasileiro aplicáveis.
            </p>
            <p style={styles.text}>
                Com fundamento nessas premissas, a SILICRED ADMINISTRAÇÃO EM CRÉDITO LTDA, doravante denominada simplesmente como SILICRED, inscrita no CNPJ/MF sob o nº 35.237.854/0001-70, no papel de CONTROLADORA DE DADOS, obriga-se às disposições constantes na presente Política de Privacidade.
            </p>
            <p style={styles.text2}>
                1. Quem somos?
            </p>
            <p style={styles.text}>
                A SILICRED ADMINISTRAÇÃO EM CRÉDITO LTDA, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 35.237.854/0001-70, localizada à Rua Fúlvio Aducci 627 – sala 908 , bairro Estreito - Florianópolis, Estado de Santa Catarina, CEP: 88075-000, representada pelos seus sócios administradores, é uma empresa privada cujas atividades sociais são a prestação de serviços a seus usuários que desejam realizar cirurgia plástica em condições financeiras baseadas nas facilidades creditícias, aproximando-os de profissionais devidamente capacitados, nos termos do Ordenamento Ético Jurídico vigente.
            </p>
            <p style={styles.text2}>
                I. INFORMAÇÕES GENÉRICAS
            </p>
            <p style={styles.text}>
                A SILICRED surgiu a partir de uma percepção obtida junto ao mercado fornecedor de serviços de estética e cirurgia plástica, que dava conta de que havia um grande número de cirurgias que não se realizavam em face da limitação financeira  daqueles que tinham essa pretensão, por qualquer razão íntima ou necessidade estética ou até mesmo funcional.
            </p>
            <p style={styles.text}>
                Partindo desse pressuposto, foi concebida uma metodologia que pudesse transpor essa limitação financeira, viabilizando a todos aqueles que desejam realizar uma cirurgia plástica ou procedimento estético a efetiva concretização desse objetivo.
            </p>
            <p style={styles.text}>
                Dessa forma, de forma contratualizada, é proposto as seguintes formas financeiras: cirurgia programada,  cirurgia planejada, adiantamento de cartão de crédito, financiamento e consórcio.
            </p>
            <p style={styles.text}>
                Assim, a SILICRED passou a se caracterizar como uma empresa prestadora de serviço a seus usuários que desejam realizar cirurgia plástica, cirurgia geral, implante capilar, tratamentos odontológicos, procedimentos estéticos, entre outros, acima referidas, em condições financeiras baseadas nas facilidades creditícias, aproximando-os de profissionais devidamente capacitados, nos termos do Ordenamento Ético Jurídico vigente.
            </p>
            <p style={styles.text2}>
                II. COMO OCORRE A PRESTAÇÃO DOS SERVIÇOS
            </p>
            <p style={styles.text}>
                A partir de um contato prévio com a empresa, os usuários dos serviços prestados pela SILICRED, de forma contratualizada, optarão pela forma creditícia de viabilização dos procedimentos plásticos ou estéticos: cirurgia programada,  cirurgia planejada, adiantamento de cartão de crédito, financiamento e consórcio.
            </p>
            <p style={styles.text}>
                Na disciplina contratual, estão previstas as condições operacionais a partir das quais se concretizará a realização dos procedimentos optados pelos usuários, assim como também todas as informações técnicas, legais e éticas necessárias a esclarecer estes usuários sobre o procedimento a que irá se submeter.
            </p>
            <p style={styles.text}>
                Os contatos com a SILICRED ocorrem tanto de forma presencial, telemática ou por redes sociais.
            </p>
            <p style={styles.text2}>
                2. Quais dados coletamos sobre você e para qual finalidade?
            </p>
            <p style={styles.text}>
                Nosso web site coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a prestação dos serviços, aprimorando a experiência de uso e também o inter-relacionamento entre a empresa e o paciente/cliente.
            </p>
            <p style={styles.text2}>
                2.1. Dados pessoais fornecidos pelo titular
            </p>
            <p style={styles.text2}>
                DADOS PESSOAIS PARA A IDENTIFICAÇÃO - utilizamos os dados abaixo consignados,  para   permitir  a   sua identificação em   nossos   canais de atendimento, como chave  de autenticação  ao  acessar  as  áreas  restritas  de  nossos canais digitais, como referência para o enriquecimento e atualização de seu cadastro, como confirmação de  identidade para garantir o cumprimento da legislação vigente, como dado  para personalizar o seu contato e garantir ações e soluções  direcionadas   para   gêneros   específicos, como  referência  para  permitir  a  preparação ou adequação do atendimento ao público portador de deficiências físicas, para ações  de  comunicação: NOME DO PACIENTE, NACIONALIDADE, PROFISSÃO, ESTADO CIVIL, IDADE, ENDEREÇO, RG, CPF, NOME DO RESPONSÁVEL PELO PACIENTE, NACIONALIDADE, PROFISSÃO, ESTADO CIVIL, IDADE, GRAU DE PARENTESCO, ENDEREÇO, RG, CPF.
            </p>
            <p style={styles.text}>
                2.2. A elaboração do plano de realização do procedimento observa a técnica hoje vigente, observando as características individuais de cada paciente. Faz parte do plano de realização do procedimento, que o usuário/paciente compareça a consulta pré procedimento e siga as orientações técnicas do profissional, as cautelas e precauções descritas no Termo de Consentimento.
            </p>
            <p style={styles.text}>
                2.3. A execução do procedimento observará as técnicas consagradas na literatura técnica especializada, sendo que, a critério do profissional, com fundamento na ética profissional e nas disposições legais aplicáveis, que for realizar a técnica e levando em conta as condições apresentadas no momento da realização da mesma, poderá haver necessidade de alteração e/ou complementação da técnica.
            </p>
            <p style={styles.text2}>
                3. Como coletamos os seus dados?
            </p>
            <p style={styles.text}>
                Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte forma direta, em entrevista (anamnese) e por via telemática, através do web site
            </p>
            <p style={styles.text2}>
                3.1. Consentimento
            </p>
            <p style={styles.text}>
                É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza a SILICRED a tratar seus dados.
            </p>
            <p style={styles.text}>
                Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento.
            </p>
            <p style={styles.text}>
                O seu consentimento será obtido de forma específica para a finalidade descrita no item  2, acima, evidenciando o compromisso de transparência e boa-fé doa SILICRED com relação aos seus pacientes/clientes, em estrita observância as disposições legais vigentes aplicáveis à espécie. Ao utilizar os serviços da SILICRED e fornecer seus dados pessoais, você está ciente e consentindo com as disposições desta Política de Privacidade, além de ser devidamente comunicado de seus direitos, conhecendo-os e tendo conhecimento de como exercê-los.
            </p>
            <p style={styles.text}>
                A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.
            </p>
            <p style={styles.text2}>
                É importante destacar que a revogação do consentimento para o tratamento dos dados poderá implicar na impossibilidade de prestação dos serviços prestados pela SILICRED, prejudicando os interesses do paciente/cliente.
            </p>
            <p style={styles.text2}>
                4. Quais são os seus direitos?
            </p>
            <p style={styles.text}>
                A SILICRED assegura a seus usuários/clientes o integral conhecimento e fruição dos seus direitos de titular de dados pessoais, previstos no Art. 18 da Lei Geral de Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:
            </p>
            <p style={styles.text}>
                •	Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.
            </p>
            <p style={styles.text}>
                •	Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio eletrônico, seguro e idôneo.
            </p>
            <p style={styles.text}>
                •	Corrigir seus dados, ao solicitar a edição, correção ou atualização destes.
            </p>
            <p style={styles.text}>
                •	Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a legislação através da anonimização, bloqueio ou eliminação.
            </p>
            <p style={styles.text}>
                •	Solicitar a portabilidade de seus dados, através de um relatório de dados cadastrais que ao SILICRED trata a seu respeito.
            </p>
            <p style={styles.text}>
                •	Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.
            </p>
            <p style={styles.text}>
                •	Revogar seu consentimento, desautorizando o tratamento de seus dados.
            </p>
            <p style={styles.text}>
                •	Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as consequências da negativa.
            </p>
            <p style={styles.text2}>
                5. Como você pode exercer seus direitos de titular?
            </p>
            <p style={styles.text}>
                Para exercer seus direitos de titular de dados pessoais, você deve entrar em contato com a SILICRED através dos seguintes meios disponíveis:
            </p>
            <p style={styles.text2}>
                CONTATOS:
            </p>
            <p style={styles.text2}>
                TELEFONE - (48) 3209-6404;
            </p>
            <p style={styles.text2}>
                FACEBOOK -   SILICRED – Facebook.com/silicred
            </p>
            <p style={styles.text2}>
                WHATSApp – (48) 3209-6404 ou através do site: www.silicred.com.br
            </p>
            <p style={styles.text2}>
                INSTAGRAN – instagran.com/silicred
            </p>
            <p style={styles.text2}>
                PRESENCIAL - Rua Fúlvio Aducci 627 – sala 908 , bairro Estreito - Florianópolis, Estado de Santa Catarina, CEP: 88075-000
            </p>
            <p style={styles.text}>
                De forma a garantir a sua correta identificação como titular dos dados pessoais objeto da solicitação, é possível que solicitemos documentos ou demais comprovações que possam comprovar sua identidade. Nessa hipótese, você será informado previamente.
            </p>
            <p style={styles.text2}>
                6. Como e por quanto tempo seus dados serão armazenados?
            </p>
            <p style={styles.text}>
                Seus dados pessoais coletados pela SILICRED serão utilizados e armazenados durante o tempo necessário para a prestação do serviço acrescido de mais 10 anos – responsabilidade profissional, e para que as finalidades elencadas na presente Política de Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores.
            </p>
            <p style={styles.text}>
                De modo geral, seus dados serão mantidos enquanto a relação contratual e a responsabilidade profissional entre você e ao SILICRED perdurar. Findado o período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no Art. 16 Lei Geral de Proteção de Dados, a saber:
            </p>
            <p style={styles.text2}>
                I – cumprimento de obrigação legal ou regulatória pelo controlador;
            </p>
            <p style={styles.text2}>
                II – estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
            </p>
            <p style={styles.text2}>
                III – transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; ou
            </p>
            <p style={styles.text2}>
                IV – uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
            </p>
            <p style={styles.text}>
                Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos serão mantidas, a despeito da exclusão dos demais dados.
            </p>
            <p style={styles.text}>
                O armazenamento de dados coletados pela SILICRED reflete intrinsecamente o nosso compromisso com a segurança e privacidade dos seus dados pessoais. Empregamos medidas e soluções técnicas de proteção, certificadas e aptas a garantir a confidencialidade, integridade e inviolabilidade dos seus dados. Adicionalmente a isso,  também dispomos de medidas técnicas de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.
            </p>
            <p style={styles.text2}>
                7. O que fazemos para manter seus dados seguros?
            </p>
            <p style={styles.text}>
                Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade.
            </p>
            <p style={styles.text}>
                Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, sensíveis, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.
            </p>
            <p style={styles.text2}>
                Entre as medidas que adotamos, destacamos as seguintes:
            </p>
            <p style={styles.text2}>
                •	Apenas pessoas autorizadas têm acesso a seus dados pessoais;
            </p>
            <p style={styles.text2}>
                •	O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade;
            </p>
            <p style={styles.text2}>
                •	Seus dados pessoais são armazenados em ambiente técnico seguro e idôneo;
            </p>
            <p style={styles.text2}>
                •	São utilizadas tecnologias de hardware e software certificadas a proporcionar segurança no armazenamento dos dados pessoais.
            </p>
            <p style={styles.text}>
                Sob o aspecto tecnológico:
            </p>
            <p style={styles.text}>
                •	 Firewall de Borda: Sistema de proteção de redes internas contra acessos não autorizados originados de uma rede não confiável (Internet), ao mesmo tempo que permite o acesso controlado da rede interna à Internet. Eles podem ser um hardware e/ou software, tendo diferentes tipos de proteção como: pacotes, e-mail, web, etc.;
            </p>
            <p style={styles.text}>
                •	Políticas de senhas de acesso: define as regras sobre o uso de senhas nos recursos computacionais, como tamanho mínimo e máximo, regra de formação e periodicidade de troca das mesmas;
            </p>
            <p style={styles.text}>
                •	Políticas de backup: define as regras sobre a realização de cópias de segurança dos dados, como tipo de mídia utilizada, período de retenção e frequência de execução;
            </p>
            <p style={styles.text}>
                •	Política de privacidade de dados: define como são tratadas as informações pessoais, sejam elas de clientes/pacientes, usuários, funcionários ou terceirizados que acessem o ambiente computacional da instituição;
            </p>
            <p style={styles.text}>
                •	Políticas de confidencialidade: define como são tratadas as informações institucionais da SILICRED, ou seja, se podem e como elas podem ser repassadas a terceiros;
            </p>
            <p style={styles.text}>
                •	Política de uso aceitável dos dados: também chamada de “Termo de Uso” ou “Termo de Serviço”, define as regras de uso dos recursos computacionais, os direitos e as responsabilidades de quem os utiliza e as situações que são consideradas abusivas. A política deve ser divulgada para todos os colaboradores da organização, de forma que os mesmos se responsabilizam a manter a segurança das informações;
            </p>
            <p style={styles.text}>
                •	Políticas de Criptografia: É o estudo dos princípios e técnicas pelas quais a informação pode ser transformada da sua forma original para outra ilegível, de forma que possa ser conhecida apenas por seu destinatário (detentor da “chave secreta”), o que a torna difícil de ser lida por alguém não autorizado. Assim sendo, só o receptor da mensagem pode ler a informação com facilidade;
            </p>
            <p style={styles.text}>
                •	Política de Certificados digitais e Assinaturas digitais: Técnicas que visam propiciar mais segurança às comunicações e transações nos meios eletrônicos, proporcionando a autenticidade e integridade das informações que tramitam de forma eletrônica;
            </p>
            <p style={styles.text}>
                •	Políticas de Registro de eventos (logs): É o registro de atividade gerado por programas e serviços de um computador. Ele pode ficar armazenado em arquivos, na memória do computador ou em bases de dados;
            </p>
            <p style={styles.text}>
                •	Políticas de Anti-malwares: “Ferramentas anti-malware são aquelas que procuram detectar e, então, anular ou remover os códigos maliciosos de um computador. Antivírus, antispyware, antirootkit e antitrojan são exemplos de ferramentas deste tipo.
            </p>
            <p style={styles.text}>
                A SILICRED se compromete, e efetivamente o faz, a adotar as melhores posturas profissionais e soluções tecnológicas, para evitar incidentes de segurança. Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É possível que, apesar de todos os nossos protocolos de segurança, problemas de culpa exclusivamente de terceiros ocorram, como ataques cibernéticos de hackers, ou também em decorrência da negligência ou imprudência do próprio paciente/cliente.
            </p>
            <p style={styles.text}>
                Em caso de incidentes de segurança que possam gerar riscos ou danos relevante para você ou qualquer um de nossos pacientes/clientes, efetuaremos as devidas comunicações aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.
            </p>
            <p style={styles.text2}>
                8. Com quem seus dados podem ser compartilhados?
            </p>
            <p style={styles.text}>
                Tendo em vista a preservação de sua privacidade, a SILICRED não compartilhará seus dados pessoais com nenhum terceiro não autorizado. Seus dados poderão ser compartilhados com nossos parceiros comerciais.
            </p>
            <p style={styles.text2}>
                Estes parceiros recebem seus dados apenas na medida do necessário para a prestação dos serviços contratados e nossos contratos são orientados pelas normas de proteção de dados do ordenamento jurídico brasileiro, assim como também possuem cláusulas especificas de confidencialidade.
            </p>
            <p style={styles.text2}>
                Contudo, nossos parceiros possuem suas próprias Políticas de Privacidade, que podem divergir desta.  Recomendamos a leitura desses documentos nos seus respectivos sites.
            </p>
            <p style={styles.text2}>
                Além disso, também existem outras hipóteses em que seus dados poderão ser compartilhados, que são:
            </p>
            <p style={styles.text2}>
                I – Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas ou governamentais competentes.
            </p>
            <p style={styles.text2}>
                II – Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática
            </p>
            <p style={styles.text2}>
                9. Cookies ou dados de navegação
            </p>
            <p style={styles.text}>
                A SILICRED faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que contém informações relacionadas à navegação do site. Em suma, os Cookies são utilizados para aprimorar a experiência de uso.
            </p>
            <p style={styles.text}>
                Ao acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.
            </p>
            <p style={styles.text2}>
                A SILICRED utiliza os seguintes Cookies:
            </p>
            <p style={styles.text}>
                Alguns cookies serão colocados em seu dispositivo diretamente pelo nosso site - são conhecidos como cookies primários. Eles são essenciais para você navegar no site e usar seus recursos.
            </p>
            <p style={styles.text}>
                Temporários: Nós utilizamos cookies de sessão. Eles são temporários e expiram quando você fecha o navegador ou quando a sessão termina. Finalidade: Estabelecer controle de idioma e segurança ao tempo da sessão.
            </p>
            <p style={styles.text}>
                Outros cookies são colocados no seu dispositivo não pelo site que você está visitando, mas por terceiros, como, por exemplo, os sistemas analíticos.
            </p>
            <p style={styles.text}>
                Temporários: Nós utilizamos cookies de sessão. Eles são temporários e expiram quando você fecha o navegador ou quando a sessão termina. Finalidade: Coletam informações sobre como você usa o site, como as páginas que você visitou e os links em que clicou. Nenhuma dessas informações pode ser usada para identificá-lo. Seu único objetivo é possibilitar análises e melhorar as funções do site.
            </p>
            <p style={styles.text2}>
                Você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
            </p>
            <p style={styles.text}>
                Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies.
            </p>
            <p style={styles.text}>
                Você pode ter maiores informações sobre os Cookies que utilizamos e como eles funcionam na nossa Política de Cookies, disponível em nosso site.
            </p>
            <p style={styles.text2}>
                10. Alteração desta Política de Privacidade
            </p>
            <p style={styles.text}>
                A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em: 25.03.22
            </p>
            <p style={styles.text}>
                Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em função da adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo. Recomendamos que você a revise com frequência.
            </p>
            <p style={styles.text}>
                Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre lhe notificaremos acerca das mudanças ocorridas.
            </p>
            <p style={styles.text2}>
                Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você fornece seu consentimento expresso, com base nos esclarecimentos e informações contidas nesta Política de Privacidade.
            </p>
            <p style={styles.text2}>
                11. Responsabilidade
            </p>
            <p style={styles.text}>
                A SILICRED prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados, em conformidade com os Arts. 42 a 45 da Lei Geral de Proteção de Dados.
            </p>
            <p style={styles.text2}>
                Assumimos o compromisso formal de manter esta Política de Privacidade atualizada, observando as disposições legais vigentes, assim como também os presentes termos de privacidade, zelando por seu fiel cumprimento.
            </p>
            <p style={styles.text}>
                Além disso, também assumimos o compromisso de buscar condições técnicas e organizacionais seguras,    aptas a proteger e garantir a segurança de todo o processo de tratamento de dados.
            </p>
            <p style={styles.text}>
                Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências complementares em relação ao tratamento de dados realizado pela SILICRED,  assumimos o compromisso formal de cumprir as exigências.
            </p>
            <p style={styles.text2}>
                11.1 Isenção de responsabilidade
            </p>
            <p style={styles.text}>
                Conforme mencionado no Tópico 7 desta Politica de Privacidade, embora adotemos elevados padrões de segurança, técnicos e organizacionais, a fim de evitar incidentes, não há nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a SILICRED não se responsabiliza por:
            </p>
            <p style={styles.text2}>
                I – Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento. Destacamos que a responsabilidade em relação à confidencialidade dos dados de acesso é do usuário.
            </p>
            <p style={styles.text2}>
                II – Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada doa SILICRED. Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as providências necessárias.
            </p>
            <p style={styles.text2}>
                III – Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a utilização dos serviços doa SILICRED; quaisquer consequências decorrentes de informações falsas ou inseridas de má-fé são de inteiramente responsabilidade do paciente/cliente.
            </p>
            <p style={styles.text2}>
                12.  Encarregado de Proteção de Dados
            </p>
            <p style={styles.text}>
                A SILICRED disponibiliza os seguintes meios para que você possa entrar em contato conosco para exercer seus direitos de titular:
            </p>
            <p style={styles.text2}>
                CONTATOS:
            </p>
            <p style={styles.text2}>
                TELEFONE - (48) 3209-6404;
            </p>
            <p style={styles.text2}>
                FACEBOOK -   SILICRED – Facebook.com/silicred
            </p>
            <p style={styles.text2}>
                WHATSApp – (48) 3209-6404 ou através do site: www.silicred.com.br
            </p>
            <p style={styles.text2}>
                INSTAGRAN – instagran.com/silicred
            </p>
            <p style={styles.text2}>
                PRESENCIAL - Rua Fúlvio Aducci 627 – sala 908 , bairro Estreito – Florianópolis SC
            </p>
            <p style={styles.text}>
                Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode entrar em contato com o nosso Encarregado de Proteção de Dados Pessoais - DPO, através dos seguintes canais:
            </p>
            <p style={styles.text}>
                financeiro@silicred.com.br
            </p>
            <p style={styles.text2}>
                SILICRED ADMINISTRAÇÃO EM CRÉDITO LTDA
            </p>
            <p style={styles.text2}>
                CNPJ/MF nº 35.237.854/0001-70
            </p>
        </Col>
    );
};

export default PrivacyPolicy;
