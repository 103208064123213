import React, {useCallback, useState} from "react";
import {Form, Modal} from "antd";
import {generateCompleteReportRequest, generateSimpleReportRequest} from "../../../../../services/report";
import {Btn, Col, Divider, FormInputText, Row} from "../../../../../common";
import {Metrics} from "../../../../../config";
import {Formik} from "formik";
import Whitespace from "../../../../../common/whitespace";
import useDownload from "../../../../../hooks/useDownload";
import {MaskUtils} from "../../../../../../util/MasksUtil";
import {DateUtils} from "../../../../../../util/DateUtils";
import {unFormat} from "../../../../../../configs/utils/MasksUtil";


export const GeneralReport = ({styles}: any) => {
    const [filter, setFilter] = useState({});
    const [reportTypeSelectionModal, setReportTypeSelectionModal] = useState<any>();
    const { isFetching: isFetchingSimple, fetch: fetchSimple } = useDownload(
        generateSimpleReportRequest(),
        "",
        false,
        'report',
        () => setReportTypeSelectionModal(false)
    );
    const { isFetching: isFetchingComplete, fetch: fetchComplete } = useDownload(
        generateCompleteReportRequest(),
        "",
        false,
        'report',
        () => setReportTypeSelectionModal(false)
    );

    const handleSubmit = useCallback((values: any, type: any) => {
        const valuesFormated = {...values};
        if (valuesFormated.agreementDateStart) {
            valuesFormated.agreementDateStart = DateUtils.convertStringBRToString(valuesFormated.agreementDateStart);
        }
        if (valuesFormated.agreementDateEnd) {
            valuesFormated.agreementDateEnd = DateUtils.convertStringBRToString(valuesFormated.agreementDateEnd);
        }
        setFilter(values);
        if (type === 'S') {
            fetchSimple(valuesFormated);
        } else {
            fetchComplete(valuesFormated);
        }
    }, [fetchComplete, fetchSimple]);

    return (
        <Col style={{ marginBlock: Metrics.spacingMD }}>
            <Formik
                initialValues={filter}
                onSubmit={setFilter}
                enableReinitialize>
                {({values, setFieldValue}: any) => (
                    <Form>
                        <FormInputText name='name' value={values.name} placeholder='Cliente' />
                        <FormInputText name='city' value={values.city} placeholder='Cidade' />
                        <FormInputText name='state' value={values.state} placeholder='Estado' />
                        <Row responsive style={{width: '100%'}}>
                            <FormInputText
                                fullWidth
                                name='agreementDateStart'
                                value={MaskUtils.formatDate(values.agreementDateStart)}
                                placeholder='Contratos a partir de' />
                            <Whitespace width={Metrics.spacingLG} />
                            <FormInputText
                                fullWidth
                                name='agreementDateEnd'
                                value={MaskUtils.formatDate(values.agreementDateEnd)}
                                placeholder='Contratos até' />
                        </Row>
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={() => {
                                    setFieldValue('name', '');
                                    setFieldValue('city', '');
                                    setFieldValue('state', '');
                                    setFieldValue('agreementDateStart', '');
                                    setFieldValue('agreementDateEnd', '');
                                }}
                                disabled={isFetchingSimple || isFetchingComplete}>
                                Limpar filtro
                            </Btn>
                            <Whitespace height={Metrics.spacingMD} />
                            <Btn
                                onClick={() => setReportTypeSelectionModal(true)}
                                disabled={isFetchingSimple || isFetchingComplete}
                                type='primary'>
                                Gerar relatório
                            </Btn>
                        </Row>
                        <Divider />
                        { reportTypeSelectionModal &&
                            <Modal open={reportTypeSelectionModal} title={'Tipo de relatório'}
                                   onCancel={() => setReportTypeSelectionModal(false)}
                                   footer={
                                       <Row style={{width: '100%', justifyContent: 'center'}}>
                                           <Btn
                                               onClick={() => handleSubmit(values, 'S')}
                                               disabled={isFetchingSimple || isFetchingComplete}
                                               type='primary'>
                                               Relatório simples
                                           </Btn>
                                           <Btn
                                               onClick={() => handleSubmit(values, 'F')}
                                               disabled={isFetchingSimple || isFetchingComplete}
                                               type='primary'
                                               style={styles.btnDark}>
                                               Relatório completo
                                           </Btn>
                                       </Row>
                                   }>
                                <span>Por favor, selecione o tipo de relatório que deseja gerar:</span>
                            </Modal>
                        }
                    </Form>
                )}
            </Formik>
        </Col>
    );
};
