import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DatePicker, Input, Modal, Select, Slider, Spin} from "antd";
import {useFormikContext} from "formik";

import moment from "moment";
import {Colors, Metrics} from 'new/config';
import useBreakpoint from "../../../hooks/useBreakpoint";
import useDebounce from "../../../hooks/useDebounce";
import useFetch from "../../../hooks/useFetch";
import {
    findCouponByCode,
    findDefaultProcedureRequest,
    simulationInstallmentOptionsRequest
} from "../../../services/service";
import {showErrorToast, showSuccessToast} from "../../../../configs/toast";
import {Btn, Col, Row} from "../../../common";
import Filters from "../../../../configs/Filters";
import {InstallmentOption} from '../styles';
import {format} from "date-fns";
import InputMoney from "../../InputMoney";

export const DUE_DAYS = [
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 7},
    {value: 16},
    {value: 21},
    {value: 23},
    {value: 27},
];

const styles = {
    typeImg: {
        width: '90px'
    },
    title: {
        fontSize: Metrics.fontSize.xxsm,
        color: Colors.secondary
    },
    selectLabel: {
        fontSize: Metrics.fontSize.xxsm,
        marginBlock: Metrics.spacingSM,
        color: Colors.primaryDark
    },
    description: {
        fontSize: Metrics.fontSize.xxsm,
        color: Colors.text,
        fontWeight: 'bolder',
        marginBottom: Metrics.spacingSM
    },
    typeBlock: {
        display: 'flex',
        boxShadow: '1px 1px 15px #e0e0e0',
        padding: Metrics.spacingMD,
        marginBlock: Metrics.spacingSM,
        borderRadius: '15px',
        width: '100%',
        cursor: 'pointer',
        boxSizing: 'border-box' as const,
        textDecoration: 'none'
    },
    valueBlock: {
        boxShadow: '1px 1px 15px #e0e0e0',
        padding: Metrics.spacingMD,
        marginBlock: Metrics.spacingSM,
        borderRadius: '15px',
        width: '100%',
        cursor: 'pointer'
    },
    textBlock: {
        marginLeft: Metrics.spacingMD
    },
    btnSecondary: {
        marginInline: Metrics.spacingMD,
        minWidth: '250px'},
    firstPayment: {
        textAlign: 'center' as const,
        color: Colors.primary,
        marginBlock: Metrics.spacingMD,
    }
}

const ContractValues = ({setStep}: any) => {
    const {values, setFieldValue}: any = useFormikContext();
    const { isMobile } = useBreakpoint();
    const [firstPayment, setFirstPayment] = useState<any>(false);
    const [isSelectStartVisible, setIsSelectStartVisible] = useState<any>(false);
    const [debSlider, setDebSlider] = useState(values.value || 0);
    const [debCode, setDebCode] = useState(values.discountCoupon || '');
    const debouncedSearchTerm = useDebounce(debSlider, 500);
    const debouncedDiscountCode = useDebounce(debCode, 500);
    const [{ isFetching: isFetchingDefaultProcedure, data: procedureDefault }] = useFetch(
        {
            provider: findDefaultProcedureRequest,
            param: values.type,
            requestOnMount: true,
            initialData: null,
            resultHandler: null
        });
    const [{ isFetching: isFetchingCoupon, data: coupon }, fetchCoupon] = useFetch(
        {
            provider: findCouponByCode,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: ({data: d}: any) => showSuccessToast('Desconto aplicado!'),
                error: ({data: d}: any) => showErrorToast('Cupom inválido!')
            },
        });
    const [{ isFetching, data }, fetch] = useFetch(
        {
            provider: simulationInstallmentOptionsRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: null
        });


    const handleDisabledPastDate = useCallback((current: any) => {
        return current < moment().subtract(1, 'day');
    }, [])

    const handleDisabledDate = useCallback((current: any) => {
        return current && current > moment().add(2, 'month') ||
            current < moment().subtract(1, 'day');
    }, [])

    const handleValidateNext = useCallback( () => {
        if (values.value && values.installment && values.startPayment && values.taxPaymentChoice) {
            if (values.taxPaymentChoice !== 'PICK_DATE' || (values.taxPaymentChoice === 'PICK_DATE' && values.taxStartPayment)) {
                return setStep(2);
            }
        }
        showErrorToast('Por favor preencha todos os campos para continuar');
    }, [setStep, values.installment, values.value, values.startPayment, values.taxPaymentChoice, values.taxStartPayment]);

    const finalTaxValue = useMemo(() => {
        const originalValue = values.type === 'PLANNED_SURGERY' || values.type === 'SILICASH' ? 490 : 100;
        if (!coupon) return originalValue;
        if (coupon.value) {
            return originalValue - coupon?.value;
        } else {
            return originalValue - (originalValue * (coupon?.percentage / 100));
        }
    }, [coupon, values.type]);

    // const handleFirstPayment = useCallback( (val) => {
    //     setFirstPayment(val);
    //     if (val === '0') {
    //         setFieldValue('startPayment', moment());
    //     } else if (val === '2'){
    //         setFieldValue('startPayment', moment().add(2, 'day'))
    //     }
    // }, [setFieldValue]);

    useEffect(() => {
        setFieldValue('value', debouncedSearchTerm);
        if (procedureDefault && debouncedSearchTerm) {
            if (debouncedSearchTerm < procedureDefault?.minValue || debouncedSearchTerm > procedureDefault?.maxValue) {
                showErrorToast(`Por favor, digite um valor entre ${Filters.convertMoneyTextMask(procedureDefault?.minValue || 0)} e ${Filters.convertMoneyTextMask(procedureDefault?.maxValue || 0)}.`);
            } else {
                fetch({
                    type: values.type,
                    simulationValue: debouncedSearchTerm,
                    procedure: procedureDefault
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, procedureDefault, values.type]);

    useEffect(() => {
        if (procedureDefault && values.type !== 'SILICASH') {
            setDebSlider(procedureDefault.minValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureDefault]);

    useEffect(() => {
        setFieldValue('tax', finalTaxValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalTaxValue]);

    useEffect(() => {
        setFieldValue('discountCoupon', debouncedDiscountCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedDiscountCode]);

    useEffect(() => {
        if (values.discountCoupon) {
            fetchCoupon(values.discountCoupon);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.discountCoupon]);

    return (
        <Col style={{alignItems: 'center'}}>
            <Col style={{width: isMobile ? '100%' : '400px', alignItems: 'center'}}>
                <Col style={styles.valueBlock}>
                    <span style={styles.selectLabel}>Quanto você precisa?</span>
                    {values.type === 'SILICASH' ? (
                        <>
                            <InputMoney
                                style={{width: '100%'}}
                                defaultValue={''}
                                formatter={(value: string) => `R$${value}`}
                                parser={(value: string) => value?.replace('R$', '') as unknown as number}
                                onChange={(e: any) => setDebSlider(e)}
                            />
                            <span style={{marginTop: Metrics.spacingSM, fontSize: Metrics.fontSize.xxxsm}}>{`Entre ${Filters.convertMoneyTextMask(procedureDefault?.minValue || 0)} e ${Filters.convertMoneyTextMask(procedureDefault?.maxValue || 0)}.`}</span>
                        </>
                        ) : (
                        <>
                            <span style={{fontSize: Metrics.fontSize.md, textAlign: 'center'}}>
                                {Filters.convertMoneyTextMask(values?.value || 0)}
                            </span>
                            <Row style={{justifyContent: 'center'}}>
                                <Slider
                                    tooltip={{
                                        formatter: (va) => Filters.convertMoneyTextMask(va)
                                    }}
                                    min={procedureDefault?.minValue}
                                    max={procedureDefault?.maxValue}
                                    step={500}
                                    value={debSlider || procedureDefault?.minValue}
                                    onChange={(e: any) => setDebSlider(e)}
                                    style={{width: '300px'}}
                                />
                            </Row>
                            <Row style={{justifyContent: 'space-between', marginTop: Metrics.spacingMD}}>
                                <span style={{fontSize: Metrics.fontSize.xxxxsm}}>
                                    {Filters.convertMoneyTextMask(procedureDefault?.minValue || 0)}
                                </span>
                                        <span style={{fontSize: Metrics.fontSize.xxxxsm}}>
                                    {Filters.convertMoneyTextMask(procedureDefault?.maxValue || 0)}
                                </span>
                            </Row>
                        </>
                    )}
                </Col>
                {
                    !!values?.value && values?.value >= procedureDefault?.minValue && values?.value <= procedureDefault?.maxValue &&
                    <Col style={{marginBlock: Metrics.spacingLG}}>
                        <span style={styles.description}>Selecione o parcelamento desejado:</span>
                        <Col style={{width: '100%', alignItems: 'center'}}>
                            <Row style={{justifyContent: 'space-between', flexWrap: 'wrap', maxWidth: '415px'}}>
                                {data && data.map((io: any) => (
                                    <InstallmentOption
                                        key={io?.value}
                                        selected={values?.installment?.months === io?.months}
                                        onClick={() => setFieldValue('installment', io)}
                                    >
                                        <span style={{fontSize: Metrics.fontSize.xxxxsm}}>{`${io.months}x de `}</span>
                                        <span>{`${Filters.convertMoneyTextMask(io?.value || 0)}`}</span>
                                    </InstallmentOption>
                                ))}
                            </Row>
                        </Col>
                    </Col>
                }
                {/*{*/}
                {/*    values?.installment &&*/}
                {/*    <Col style={{width: '100%'}}>*/}
                {/*        <Checkbox*/}
                {/*            style={{marginBottom: Metrics.spacingMD}}*/}
                {/*            checked={firstPayment}*/}
                {/*            onChange={(c) => {*/}
                {/*                setFirstPayment(c.target.checked);*/}
                {/*                if (!c.target.checked) {*/}
                {/*                    setFieldValue('startPayment', null);*/}
                {/*                }*/}
                {/*            }}>*/}
                {/*            Desejo selecionar uma data para entrada*/}
                {/*        </Checkbox>*/}
                {/*        {*/}
                {/*            firstPayment &&*/}
                {/*            <Col>*/}
                {/*                <span style={styles.description}>Escolha a data do primeiro pagamento de seu Boleto Bancário (entrada em até 60 dias):</span>*/}
                {/*                <DatePicker*/}
                {/*                    value={values.startPayment}*/}
                {/*                    disabledDate={handleDisabledDate}*/}
                {/*                    onChange={(dt) => setFieldValue('startPayment', dt)} />*/}
                {/*            </Col>*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*}*/}
                {values?.installment && !values.startPayment && (
                    <button
                        style={{backgroundColor: 'transparent', border: 'none', color: Colors.primary, textDecoration: 'underline', cursor: 'pointer', marginBottom: 12}}
                        onClick={() => setIsSelectStartVisible(true)}
                        type={'button'}>
                        Escolher a data dos pagamentos
                    </button>
                )}
                <Modal
                    open={isSelectStartVisible}
                    onOk={() => setIsSelectStartVisible(false)}
                    onCancel={() => setIsSelectStartVisible(false)}>
                    <Col>
                        <span style={styles.description}>Escolher a data dos pagamentos:</span>
                        <DatePicker
                            value={values.startPayment}
                            disabledDate={handleDisabledPastDate}
                            onChange={(dt) => {
                                setFieldValue('startPayment', dt);
                                setIsSelectStartVisible(false);
                            }}/>
                        </Col>
                </Modal>
                {values.startPayment && (
                    <Row style={{gap: 8}}>
                        <span style={{marginBottom: Metrics.spacingLG}}>
                            Primeiro pagamento: {format(new Date(values.startPayment), 'dd/MM/yyyy')}
                        </span>
                        <button
                        style={{backgroundColor: 'transparent', border: 'none', color: Colors.primary, textDecoration: 'underline', cursor: 'pointer', height: '15px'}}
                            onClick={() => setIsSelectStartVisible(true)}>
                            Alterar
                        </button>
                    </Row>
                )}
                {/*{*/}
                {/*    <Col style={{marginBlock: Metrics.spacingLG}}>*/}
                {/*        <span style={styles.description}>Escolha o melhor dia para o vencimento dos demais Boletos Bancários:</span>*/}
                {/*        <Row style={{justifyContent: 'space-evenly'}}>*/}
                {/*            {*/}
                {/*                DUE_DAYS.map((dd: any) => (*/}
                {/*                    <Button*/}
                {/*                        className={'clicable'}*/}
                {/*                        style={{*/}
                {/*                            paddingInline: isMobile ? '10px' : 'unset',*/}
                {/*                            minWidth: '30px',*/}
                {/*                            border: 'none',*/}
                {/*                            boxShadow: '3px 3px 7px #e0e0e0',*/}
                {/*                            borderRadius: '15px',*/}
                {/*                            fontWeight: 'bolder',*/}
                {/*                        }}*/}
                {/*                        key={dd.value}*/}
                {/*                        onClick={() => setFieldValue('dueDay', dd.value)}*/}
                {/*                        type={values.dueDay === dd.value ? 'primary' : 'default'}*/}
                {/*                    >*/}
                {/*                        {dd.value}*/}
                {/*                    </Button>*/}
                {/*                ))*/}
                {/*            }*/}
                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*}*/}
                    <Col>
                        <Col style={{width: '100%'}}>
                            <span>Taxa de adesão</span>
                            <Select allowClear placeholder={"Pagamento da taxa de adesão"} onChange={(s) => {
                                setFieldValue("taxStartPayment", null)
                                setFieldValue("taxPaymentChoice", s);
                            }}>
                                <Select.OptGroup>
                                    <Select.Option
                                        key={"1"}
                                        value={'IMMEDIATE'}>
                                        Pagar à vista
                                    </Select.Option>
                                    <Select.Option
                                        key={"2"}
                                        value={'ALONG_INSTALLMENT'}>
                                        Pagar junto com a 1a parcela
                                    </Select.Option>
                                    {(values.type === 'PLANNED_SURGERY' || values.type === 'SILICASH') &&
                                        <Select.Option
                                            key={"3"}
                                            value={'PICK_DATE'}>
                                            {values.taxStartPayment ? moment(values.taxStartPayment).format("DD/MM/YYYY") : 'Escolher data...'}
                                        </Select.Option>
                                    }
                                </Select.OptGroup>
                            </Select>
                        </Col>
                        {(values.type === 'SILICASH' || values.type === 'PLANNED_SURGERY') &&
                            values.taxPaymentChoice === 'PICK_DATE' && !values.taxStartPayment &&
                            <Modal open={values.taxPaymentChoice === 'PICK_DATE' && !values.taxStartPayment} onCancel={() => setFieldValue("taxPaymentChoice", null)}>
                                <Col>
                                    <span style={styles.description}>Escolha a data do pagamento da taxa de adesão:</span>
                                    <DatePicker
                                        value={values.taxStartPayment}
                                        disabledDate={handleDisabledDate}
                                        onChange={(dt) => setFieldValue('taxStartPayment', dt)} />
                                </Col>
                            </Modal>
                        }
                        {(values.type === 'SILICASH' || values.type === 'PLANNED_SURGERY') &&

                            <Col style={{width: '100%', marginTop: Metrics.spacingMD}}>
                            <span>Possui um cupom de desconto?</span>
                            <Row style={{position: 'relative'}}>
                                <Input
                                    placeholder={"Cupom de desconto"}
                                    required
                                    value={debCode}
                                    onChange={(e: any) => setDebCode(e.target.value)}
                                />
                                {isFetchingCoupon && <Spin style={{position: 'absolute', bottom: 0, right: '5px'}} />}
                            </Row>
                        </Col>
                        }

                        <Row style={{marginBlock: Metrics.spacingSM}}>
                            <span>{`Valor da taxa: R$ ${finalTaxValue}`}</span>
                        </Row>
                    </Col>
                    <Col>
                        <Btn
                            disabled={!values?.startPayment}
                            onClick={handleValidateNext}
                            type='primary'>
                            Quero contratar!
                        </Btn>
                    </Col>
            </Col>
        </Col>
    );
}

export default ContractValues;
