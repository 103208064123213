import React, {useEffect, useState} from 'react';
import {Btn, Col, FormDropdown, FormInputText, Row} from "../../common";
import {Colors, Metrics} from "../../config";
import {AutoComplete, Form, Radio} from "antd";
import {Formik} from "formik";
import {MaskUtils} from "../../../util/MasksUtil";
import Whitespace from "../../common/whitespace";
import {ExistingUserValidationSchema, NewUserValidationSchema} from "./ValidationSchema";
import {AddressForm} from "../index";
import useFetch from "../../hooks/useFetch";
import {findAllUsersRequest} from "../../services/user";


const defaultInitialValues = {
    id: null,
    name: '',
    cpf: '',
    rg: '',
    email: '',
    birthdate: '',
    gender: '',
    phone: '',
    influencer: '',
    address: {
        postalCode: '',
        street: '',
        state: '',
        city: '',
        district: '',
        complement: '',
        number: '',
    },
}

const styles = {
    btnGroup: {marginTop: Metrics.spacingXLG},
    btnRow: {width: '100%', justifyContent: 'center', marginTop: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'},
    primary: { backgroundColor: Colors.primaryDark, color: Colors.white, borderColor: Colors.primaryDark},
    titles: { justifyContent: 'center', alignItems: 'center'},
    form: {paddingTop: Metrics.spacingLG}
}


const RegistrationForm = ({onSubmit, isFetching, simulation, initialValues, seller, setIsNewUser, isNewUser}: any) => {
    const [
        { isFetching: isFetchingUsers, data: users },
    ] = useFetch({
        provider: findAllUsersRequest,
        param: "",
        requestOnMount: seller,
        initialData: []
    });

    return (
        <Formik
            enableReinitialize
            validationSchema={isNewUser ? NewUserValidationSchema : ExistingUserValidationSchema}
            initialValues={initialValues || defaultInitialValues}
            onSubmit={onSubmit}>
            {({ handleSubmit, values, resetForm, setValues }) => (
                <Form style={styles.form}>
                    <Col style={styles.titles}>
                        {seller &&
                            <Radio.Group style={styles.btnGroup} value={isNewUser} onChange={(nu: any) => {
                                setValues({});
                                setIsNewUser(nu?.target?.value);
                            }}>
                                <Radio.Button style={isNewUser === false ? styles.primary : {}} value={false}>Usuário já cadastrado</Radio.Button>
                                <Radio.Button style={isNewUser ? styles.primary : {}} value={true}>Novo Usuário</Radio.Button>
                            </Radio.Group>
                        }
                    </Col>
                    {isNewUser === false &&
                        <Col
                            style={{
                                marginTop: Metrics.spacingLG,
                                color: Colors.primaryDark
                            }}>
                            <FormDropdown
                                showSearch
                                required
                                fullWidth
                                label="Selecione o cliente que deseja realizar a contratação"
                                propertyLabel={'name'}
                                propertyValue={'id'}
                                value={values.id}
                                name="id"
                                list={users}
                                placeholder="Informe o nome"
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option?.props?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(val: any) => val ? setValues(users.find((u: any) => u.id === val)) : () => {}}
                            />
                        </Col>
                    }
                    { isNewUser &&
                        <Col>
                            { simulation && isNewUser &&
                                <span
                                    style={{
                                        marginTop: Metrics.spacingLG,
                                        color: Colors.primaryDark
                                    }}>
                                    Por favor, preencha seus dados para continuar
                                </span>
                            }
                            <Row responsive>
                                <FormInputText required fullWidth label="Nome" name="name" value={values.name} />
                                <Whitespace width={Metrics.spacingLG} />
                                <FormInputText required fullWidth label="CPF" maxLength={14} name="cpf" value={MaskUtils.formatCpf(values.cpf)} />
                                <Whitespace width={Metrics.spacingLG} />
                                <FormInputText required fullWidth label="RG" name="rg" value={values.rg} />
                            </Row>
                            <Row responsive>
                                <FormInputText required fullWidth label="Email" name="email" value={values.email} />
                                <Whitespace width={Metrics.spacingLG} />
                                <FormInputText
                                    required
                                    maxLength={10}
                                    fullWidth label="Data de Nascimento" name="birthdate" value={MaskUtils.formatDate(values.birthdate)} />
                                <Whitespace width={Metrics.spacingLG} />
                                <FormInputText
                                    required
                                    maxLength={13}
                                    fullWidth label="Telefone" name="phone" value={MaskUtils.formatCel(values.phone)} />
                            </Row>
                            <FormInputText fullWidth label="Código da Influencer / Parceiros (se houver)" name="influencer" value={values.influencer} />
                            <FormDropdown
                                required
                                fullWidth
                                label="Sexo"
                                value={values.gender}
                                name="gender"
                                list={[
                                    {label: 'Masculino', value: 'M'},
                                    {label: 'Feminino', value: 'F'},
                                ]}
                            />
                            <AddressForm />
                        </Col>
                    }
                    <Row style={styles.btnRow}>
                        {(isNewUser === false && values.id) || isNewUser ?
                            <Btn
                                onClick={() => handleSubmit()}
                                disabled={isFetching}
                                type='primary'
                                style={styles.btn}>
                                {simulation || initialValues?.id ? 'Continuar' : 'Cadastrar-se'}
                            </Btn> :
                            <div />
                        }
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default RegistrationForm;
