import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    workgroupId: Yup.number()
        .required('O campo Grupo de trabalho é obrigatório.'),
    commission: Yup.string()
        .required('O campo Comissão é obrigatório.'),
});

export default ValidationSchema;
