import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    value: Yup.string()
        .nullable()
        .required('O campo valor é obrigatório.'),
    taxAdm: Yup.string()
        .nullable()
        .required('O campo taxa administrativa é obrigatório.'),
});

export default ValidationSchema;
