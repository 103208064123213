import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Checkbox, DatePicker, Form, Input, Modal, Select, Spin, Table as AntdTable} from "antd";
import {Colors, Metrics} from "../../../config";
import {PaymentStatusEnum} from "../../../../constants";
import useBreakpoint from "../../../hooks/useBreakpoint";
import {
    deleteUserDreamRequest,
    downloadBankslipOverdueRequest,
    downloadBankslipRequest,
    findDreamDetailRequest,
    markPixPaidRequest,
    reassignSellerRequest,
    suspendUserDreamRequest
} from "../../../services/dream";
import useFetch from "../../../hooks/useFetch";
import {showErrorToast, showSuccessToast} from "../../../../configs/toast";
import {ActionIcons, Btn, Col, FormInputText, Row, Table} from "../../../common";
import Filters from "../../../../configs/Filters";
import moment from "moment";
import {findSellersRequest} from "../../../services/user";
import {DetailProcedureTable} from "../table";
import {ExpandLessOutlined, ExpandMoreOutlined} from "@material-ui/icons";
import {Formik} from "formik";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import api from "../../../services/api";
import * as Yup from "yup";
import {AUTHORITIES} from "../../../../authorities.constants";
import {useAuthentication} from "../../../context/AuthContext";
import {format, isAfter} from "date-fns";
import {FormItem} from "formik-antd";

const { confirm } = Modal;

const {Column} = AntdTable;
const styles = {
    title: {color: Colors.primary, marginTop: Metrics.spacingMD},
    fieldName: {color: 'rgb(133 133 133)', marginRight: Metrics.spacingSM},
    seller: {color: Colors.primary, marginRight: Metrics.spacingSM},
    sellerName: {color: 'rgb(133 133 133)'}
}

const validationSchemaComment = Yup.object().shape({
    subject: Yup.string()
        .max(30, 'O campo assunto deve ter no máximo 30 caracteres.')
        .required('O campo assunto é obrigatório.'),
    responsibleId: Yup.string().required('O campo responsável é obrigatório.'),
    content: Yup.string().required('O campo conteúdo é obrigatório.'),
});


const getColorFromPayment = (situacao: PaymentStatusEnum | undefined) => {
    switch (situacao) {
        case PaymentStatusEnum.OVERDUE:
            return '#ba4142';
        case PaymentStatusEnum.PAID:
            return '#6dbd79';
        case PaymentStatusEnum.PENDING:
            return '#CCCCCC';
    }
};

export const DreamDetail = ({detail, setDetail, seller, supervisor}: any) => {
    const {isMobile} = useBreakpoint();
    const {hasAnyAuthority} = useAuthentication();
    const canEditComment = hasAnyAuthority([AUTHORITIES.ADMIN, AUTHORITIES.SELLER, AUTHORITIES.SUPERVISOR]);
    const [customPaymentDate, setCustomPaymentDate] = useState<boolean>();
    const [isReassignVisible, setIsReassignVisible] = useState<any>();
    const [sellerQuery, setSellerQuery] = useState('');
    const [selectedInstallmentLine, setSelectedInstallmentLine] = useState<any>();
    const [paymentDate, setPaymentDate] = useState<any>();

    const [
        { data: sellers }] = useFetch({
        provider: findSellersRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
    });
    const [{ isFetching: isFetchingDownload }, download] = useFetch(
        {
            provider: downloadBankslipRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: (res: any) => {
                    const link = document.createElement('a');
                    link.href = res.data._links.payBoleto.printHref;
                    link.target = '_blank';
                    link.click();
                }
            },
        });
    const [{ isFetching: isFetchingDownloadOverdue }, downloadOverdue] = useFetch(
        {
            provider: downloadBankslipOverdueRequest,
            param: '',
            requestOnMount: false,
            initialData: null,
            resultHandler: {
                success: (res: any) => {
                    const link = document.createElement('a');
                    link.href = res.data._links.payBoleto.printHref;
                    link.target = '_blank';
                    link.click();
                }
            },
        });

    const [{ isFetching, data }, handleDetail] = useFetch(
        {
            provider: findDreamDetailRequest,
            param: detail,
            requestOnMount: false,
            initialData: null,
            resultHandler: null
        });
    const [{ isFetching: isFetchingDelete }, remove] = useFetch({
        provider: deleteUserDreamRequest,
        param: "",
        requestOnMount: false,
        initialData: [],
        resultHandler: {
            success: () => {
                handleDetail(detail);
                showSuccessToast('Contrato excluído com sucesso.');
            },
            error: (d: any) => showErrorToast(d),
        },
    });
    const [{ isFetching: isFetchingSuspend }, suspend] = useFetch({
        provider: suspendUserDreamRequest,
        param: "",
        requestOnMount: false,
        initialData: [],
        resultHandler: {
            success: () => {
                handleDetail(detail);
                showSuccessToast('Contrato suspenso com sucesso.');
            },
            error: (d: any) => showErrorToast(d),
        },
    });
    const [{ isFetching: isFetchingMarkPaid }, markPaid] = useFetch({
        provider: markPixPaidRequest,
        param: detail,
        requestOnMount: false,
        initialData: null,
        resultHandler: {
            success: () => {
                setPaymentDate(null);
                setCustomPaymentDate(false);
                setSelectedInstallmentLine(null);
                handleDetail(detail);
            }
        }
    });
    const [{ isFetching: isFetchingReassign }, reassignSeller] = useFetch({
        provider: reassignSellerRequest,
        param: detail,
        requestOnMount: false,
        initialData: null,
        resultHandler: {
            success: () => {
                setIsReassignVisible(null);
                showSuccessToast('Vendedor responsável alterado com sucesso.');
                handleDetail(detail);
            }
        }
    });

    const sellersFiltered = useMemo(() => sellers.filter((u: any) => !sellerQuery || u?.name?.toLowerCase().includes(sellerQuery.toLowerCase())), [sellers, sellerQuery]);

    const updateComment = async (d: any) => {
        try {
            await api.post('api/comment', {...d, userId: data.id});
            showSuccessToast('Observação salva com sucesso.');
            handleDetail();
        } catch (e) {
            console.log(e);
        }
    }

    const handleDownloadBankslip = (payment: any) => {
        if (payment?.status === PaymentStatusEnum.OVERDUE) {
            downloadOverdue(payment.id);
        } else {
            download(payment.id);
        }
    }

    const showDeleteConfirm = (payment: any) => {
        return confirm({
            title: 'Remoção de Contrato',
            content: 'Deseja mesmo Excluir o contrato selecionado?',
            onOk() {
                return remove(payment)
            },
            onCancel() {},
        });
    }

    const showSuspendConfirm = (payment: any) => {
        return confirm({
            title: 'Suspenção de Contrato',
            content: 'Deseja mesmo Suspender o contrato selecionado?',
            onOk() {
                return suspend(payment)
            },
            onCancel() {},
        });
    }

    const handleReassignSeller = useCallback(() => {
        reassignSeller({sellerId: isReassignVisible?.sellerId, userProcedureId: isReassignVisible?.id})
    }, [reassignSeller, isReassignVisible]);

    const handleMarkPaid = useCallback(() => {
        markPaid({paymentId: selectedInstallmentLine, paymentDt: moment(paymentDate).toDate()})
    }, [markPaid, selectedInstallmentLine, paymentDate]);

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    // eslint-disable-next-line
    useEffect(() => detail ? handleDetail(detail) : () => {}, [detail]);

    const expandedRowRender = (record: any) => <Col>
        {
            record?.sellerId &&
            <Row>
                <span style={styles.seller}>Vendedor(a):</span>
                <span style={styles.sellerName}>{`${record?.sellerName} (${record?.sellerId})`}</span>
            </Row>
        }
        {/*{*/}
        {/*    record?.userPayments.filter((up: any) => !up.parcelNumber && up.fee).map((u: any) => (*/}
        {/*        <Row key={u.id}>*/}
        {/*            <span>Taxa de adesão:</span>*/}
        {/*            <span style={{marginLeft: Metrics.spacingMD}}>{Filters.convertMoneyTextMask(u?.value)}</span>*/}
        {/*            <span style={{marginLeft: Metrics.spacingMD}}>{moment(u?.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>*/}
        {/*            <Row style={{marginLeft: Metrics.spacingMD}}>*/}
        {/*                <span style={{color: getColorFromPayment(u?.status)}}>*/}
        {/*                    {getLabelBankSlipSituacion(u?.status, 'md')}*/}
        {/*                </span>*/}
        {/*                {*/}
        {/*                    u?.paymentDate ?*/}
        {/*                        <span style={{marginLeft: Metrics.spacingMinimun, fontSize: Metrics.fontSize.xxxsm}}>*/}
        {/*                            ({moment(u?.paymentDate).format('DD/MM/YYYY')})*/}
        {/*                        </span> : ''*/}
        {/*                }*/}
        {/*            </Row>*/}
        {/*            <Row style={{marginLeft: Metrics.spacingMD}}>*/}
        {/*                {*/}
        {/*                    u?.status !== PaymentStatusEnum.PAID &&*/}
        {/*                    <Row>*/}
        {/*                        <ActionIcons*/}
        {/*                            type={"download"}*/}
        {/*                            actionFn={() => !isFetchingDownload ? handleDownloadBankslip(u) : () => {}}*/}
        {/*                        />*/}
        {/*                        {*/}
        {/*                            !seller && !supervisor &&*/}
        {/*                            <ActionIcons*/}
        {/*                                type={"check"}*/}
        {/*                                actionFn={() => !isFetchingMarkPaid ? setSelectedInstallmentLine(u.id) : () => {}}*/}
        {/*                            />*/}
        {/*                        }*/}
        {/*                    </Row>*/}
        {/*                }*/}
        {/*            </Row>*/}
        {/*        </Row>*/}
        {/*    ))*/}
        {/*}*/}
        {record?.userPayments?.some((up: any) => up.fee) ?
            <DetailProcedureTable
                data={record}
                fee
                isFetchingDownload={isFetchingDownload}
                isFetchingMarkPaid={isFetchingMarkPaid}
                handleDownloadBankslip={handleDownloadBankslip}
                setSelectedInstallmentLine={setSelectedInstallmentLine}
                seller={seller}
                supervisor={supervisor}
            /> : <div />
        }
        <DetailProcedureTable
            data={record}
            isFetchingDownload={isFetchingDownload}
            isFetchingMarkPaid={isFetchingMarkPaid}
            handleDownloadBankslip={handleDownloadBankslip}
            setSelectedInstallmentLine={setSelectedInstallmentLine}
            seller={seller}
            supervisor={supervisor}
        />
    </Col>;

    return (
        <Modal open={detail} footer={<Btn onClick={() => setDetail(null)}>Fechar</Btn>} onCancel={() => setDetail(null)} width={isMobile ? '100%' : '90%'}>
            <span>Detalhamento de cliente</span>
            {
                isFetching &&
                <Col>
                    <Spin />
                </Col>
            }
            {
                (isFetchingDownload || isFetchingDownloadOverdue) &&
                <Modal footer={null} closable={false} open={isFetchingDownload || isFetchingDownloadOverdue}>Aguarde! Carregando boleto... <Spin /></Modal>
            }
            {data &&
                <Col>
                    <Row responsive>
                        <Col style={{minWidth: '40%', marginInline: Metrics.spacingMD}}>
                            <p style={styles.title}><b>Dados gerais</b></p>
                            <span><b style={styles.fieldName}>Nome:</b>{data?.name}</span>
                            {
                                data?.cnpj ? (
                                        <span><b style={styles.fieldName}>CNPJ:</b>{Filters.inputMaskCNPJ(data?.cnpj)}</span>
                                ) :
                                <>
                                    <span><b style={styles.fieldName}>RG:</b>{data?.rg}</span>
                                    <span><b style={styles.fieldName}>CPF:</b>{Filters.inputMaskCPF(data?.cpf)}</span>
                                </>
                            }
                            <span><b
                                style={styles.fieldName}>Data de nascimento:</b>{moment(data?.birthdate).format('DD/MM/YYYY')}</span>
                        </Col>
                        <Col style={{marginInline: Metrics.spacingMD}}>
                            <p style={styles.title}><b>Endereço e contato</b></p>
                            <span>{ `${data?.address?.street}, ${data?.address?.number}, ${data?.address?.complement}, ${data?.address?.district}` }</span>
                            <span>{ `${data?.address?.postalCode}, ${data?.address?.city} - ${data?.address?.state}` }</span>
                            <span>{Filters.inputMaskTELWithDDD(data?.phone)}</span>
                            <span>{data?.email}</span>
                        </Col>
                    </Row>
                    <div style={{marginBlock: '24px'}}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <p style={styles.title}><b>Observações</b></p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    enableReinitialize
                                    validationSchema={validationSchemaComment}
                                    initialValues={{
                                        responsibleId: '',
                                        subject: '',
                                        content: ''
                                    }}
                                    onSubmit={updateComment}>
                                    {({ handleSubmit, values, setFieldValue, errors, touched }) => (
                                        <Form>
                                            <FormInputText label="Assunto" name="subject" value={values.subject} disabled={!canEditComment} />
                                            <Col style={{marginBlock: '16px'}}>

                                            <FormItem
                                                style={{marginTop: Metrics.spacingSM}}
                                                name={'responsibleId'}
                                                required={true}>
                                                <label style={{
                                                    color: errors['responsibleId'] && touched['responsibleId'] ? '#ff696b' : 'unset',
                                                    fontSize: Metrics.fontSize.xxsm
                                                }}>
                                                    Responsável*
                                                </label>
                                                <Select showSearch
                                                    filterOption={(inputValue, option) => !!(option?.children as unknown as string)?.toLowerCase().includes(inputValue.toLowerCase())}
                                                    disabled={!canEditComment} style={{width: '100%'}}
                                                    value={values?.responsibleId} placeholder={"Responsável"}
                                                    onChange={(s: any) => {
                                                        setFieldValue('responsibleId', s)
                                                    }}>
                                                    <Select.OptGroup label={''}>
                                                        {
                                                            sellers.map((s: any) => (
                                                                <Select.Option
                                                                    key={s?.id}
                                                                    value={s?.id}>
                                                                    {s?.name}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select.OptGroup>
                                                </Select>
                                            </FormItem>
                                            </Col>
                                            <FormInputText
                                                disabled={!canEditComment}
                                                label="Conteúdo" name="content" value={values.content}/>
                                            <Row responsive>
                                                <Btn
                                                    onClick={() => handleSubmit()}
                                                    disabled={isFetching}
                                                    type='primary'
                                                    style={{}}>
                                                    Salvar
                                                </Btn>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                                {
                                    data?.comments?.sort((a: any, b: any) => isAfter(new Date(a.createdDate), new Date(b.createdDate)) ? -1 : 1)?.map((c: any) => (
                                        <Col key={c.id} style={{marginBlock: Metrics.spacingMD, paddingBottom: 12, borderBottom: '1px solid #cacaca'}}>
                                            <span><b>Data:</b> {format(new Date(c.createdDate), 'dd/MM/yyyy hh:mm')}</span>
                                            <span><b>Assunto:</b> {c.subject}</span>
                                            <span><b>Responsável:</b> {sellers?.find?.((s: any) => s.id === c.responsibleId)?.name}</span>
                                            <span><b>Conteúdo:</b> {c.content}</span>
                                        </Col>
                                    ))
                                }
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <p style={styles.title}><b>Serviços contratados</b></p>
                    <Table
                        // rowClassName={(rec: any) => rec?.sellerId === seller ? 'row-highlight' : ''}
                        indentSize={2}
                        style={isMobile ? {width: '100%'} : {}}
                        showHeader={false}
                        pagination={false}
                        expandable={{
                            expandedRowRender,
                            expandIcon: ({ expanded, onExpand, record }: any) =>
                                expanded ? (
                                    <ExpandLessOutlined
                                        style={{cursor: 'pointer'}}
                                        onClick={e => onExpand(record, e)}
                                    />
                                ) : (
                                    <ExpandMoreOutlined
                                        style={{cursor: 'pointer'}}
                                        onClick={e => onExpand(record, e)}
                                    />
                                )
                        }}
                        data={data?.userProcedures}>
                        {!isMobile &&
                            <Column title={''} dataIndex="name" key="name"
                                    render={(_, rowData: any) =>
                                        <span
                                            style={{opacity: rowData?.suspended ? 0.5 : 1 }}>
                                            {`${rowData?.procedure?.name} ${rowData?.suspended ? '(suspenso)' : ''}`}
                                        </span>
                                    }/>
                        }
                        <Column title={''} dataIndex="agreementDt" key="agreementDt"
                                render={(_, rowData: any) => <span>{moment(rowData?.agreementDt).format('DD/MM/YYYY')}</span>}  />
                         <Column title={''} dataIndex="totalHired" key="totalHired"
                                render={(_, rowData: any) => rowData?.totalHired ? <span>{Filters.convertMoneyTextMask(rowData?.totalHired)}</span> : ''}  />
                        {
                            canEditComment &&
                            <Column title={''} dataIndex="totalAgreement" key="totalAgreement"
                                    render={(_, rowData: any) => <span>{Filters.convertMoneyTextMask(rowData?.totalAgreement)}</span>}  />
                        }
                        <Column
                            title={'Ação'}
                            dataIndex="action"
                            render={(i, record: any) => (
                                <Row>
                                    {!seller && !supervisor &&
                                        <ActionIcons
                                            type={"reassign"}
                                            actionFn={() => setIsReassignVisible(record)}
                                        />
                                    }
                                    {!seller && !supervisor &&
                                            <ActionIcons
                                                type={"delete"}
                                                actionFn={() => !isFetchingSuspend ? showDeleteConfirm(record.id) : () => {}}
                                            />
                                    }
                                    {!record?.suspended && (!seller || supervisor) &&
                                            <ActionIcons
                                                type={"suspend"}
                                                actionFn={() => !isFetchingSuspend ? showSuspendConfirm(record.id) : () => {}}
                                            />
                                    }
                                </Row>
                            )}
                        />
                    </Table>
                </Col>
            }
            {
                isReassignVisible &&
                <Modal open={isReassignVisible} footer={null} onCancel={() => setIsReassignVisible(false)}>
                    <Col>
                        <b style={{color: Colors.primary, marginBottom: Metrics.spacingMD}}>Alterar vendedor responsável</b>
                        <Input
                            style={{marginBlock: Metrics.spacingMD}}
                            placeholder='Pesquisar vendedor responsável'
                            value={sellerQuery}
                            onChange={s => setSellerQuery(s.target.value)} />
                        <Select value={isReassignVisible?.sellerId}  placeholder={"Vendedor responsável"} onChange={(s: any) => {
                            setSellerQuery('');
                            setIsReassignVisible((rea: any) => ({...rea, sellerId: s}))
                        }}>
                            <Select.OptGroup>
                                {
                                    sellersFiltered.map((s: any) => (
                                        <Select.Option
                                            key={s?.id}
                                            value={s?.id}>
                                            {s?.name}
                                        </Select.Option>
                                    ))
                                }
                            </Select.OptGroup>
                        </Select>
                        <Button
                            loading={isFetchingReassign}
                            style={{marginTop: Metrics.spacingLG}}
                            type='primary'
                            onClick={handleReassignSeller}>
                            Confirmar alteração
                        </Button>
                    </Col>
                </Modal>
            }
            { selectedInstallmentLine &&
                <Modal open={selectedInstallmentLine} footer={null}>
                    <Col>
                        <b style={{color: Colors.primary}}>Baixa em pagamento</b>
                        <span style={{marginBlock: Metrics.spacingMD, fontFamily: 'montserrat-medium'}}>Você tem certeza que deseja realizar esta ação? Não é possível reverter.</span>
                        <Checkbox
                            checked={customPaymentDate}
                            onChange={c => setCustomPaymentDate(c.target.checked)}>Desejo especificar a data da baixa</Checkbox>
                        {
                            customPaymentDate &&
                            <DatePicker
                                style={{marginBlock: Metrics.spacingMD}}
                                value={paymentDate}
                                onChange={(dt) => setPaymentDate(dt)} />
                        }
                        <Row style={{marginTop: Metrics.spacingMD, justifyContent: 'flex-end'}}>
                            <Button
                                style={{width: 150}}
                                onClick={() => {
                                    setSelectedInstallmentLine(null);
                                    setCustomPaymentDate(false);
                                    setPaymentDate(null);
                                }}>Cancelar</Button>
                            <Button
                                style={{width: 150, marginLeft: Metrics.spacingSM}}
                                type='primary'
                                onClick={handleMarkPaid}>
                                Confirmar
                            </Button>
                        </Row>
                    </Col>
                </Modal>
            }
        </Modal>
    );
};
