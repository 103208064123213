
const primary = '#BD997F';
const secondary = '#DBA119';
const primaryDark = '#7c685d';
const white = '#fff';
const gray = 'rgba(0, 0, 0, 0.65)';
const text = 'rgba(0, 0, 0, 0.65)';

export default {
  primary,
  primaryDark,
  secondary,
  white,
  gray,
  text
};
