import {Col, FormDropdown, FormInputText, Row} from "../../../common";
import React from "react";
import {useFormikContext} from "formik";
import {MaskUtils} from "../../../../util/MasksUtil";
import FormCheckbox from "../../../common/form/FormCheckbox";
import {Metrics} from "../../../config";


const FinancialInfo = () => {
    const { values }: any = useFormikContext();

    return (
        <Col>
            <Row>
                <Col style={{width: '49.5%', justifyContent: 'flex-start'}}>
                    <FormInputText label="Telefone Celular (com ddd)" name="person.phoneStr" value={MaskUtils.formatCel(values.person.phoneStr)} />
                    <FormInputText
                        maxLength={10}
                        label="Data de Nascimento" name="person.dateOfBirth" value={MaskUtils.formatDate(values.person.dateOfBirth)} />
                    <FormDropdown
                        formStyle={{marginTop: Metrics.spacingSM}}
                        label="Estado Civil"
                        value={values.extraPersonalInfo.maritalStatus}
                        name="extraPersonalInfo.maritalStatus"
                        list={[
                            {label: 'Solteiro', value: 0},
                            {label: 'Casado', value: 1},
                            {label: 'Divorciado', value: 2},
                            {label: 'Viúvo', value: 3},
                            {label: 'União estável', value: 4},
                        ]}
                    />
                    <FormDropdown
                        formStyle={{marginTop: Metrics.spacingSM}}
                        label="Grau de Escolaridade"
                        value={values.extraPersonalInfo.educationLevel}
                        name="extraPersonalInfo.educationLevel"
                        list={[
                            {label: 'Primário incompleto', value: 1},
                            {label: 'Primário completo', value: 2},
                            {label: 'Médio incompleto', value: 3},
                            {label: 'Médio completo', value: 4},
                            {label: 'Superior Incompleto', value: 5},
                            {label: 'Superior Completo', value: 6},
                            {label: 'Pós-graduado Incompleto', value: 10},
                            {label: 'Pós graduado Completo', value: 7},
                            {label: 'Mestrado Incompleto', value: 11},
                            {label: 'Mestrado Completo', value: 8},
                            {label: 'Doutorado Incompleto', value: 12},
                            {label: 'Doutorado Completo', value: 9},
                        ]}
                    />
                </Col>
                <Col style={{marginLeft: '1%', width: '49.5%', justifyContent: 'flex-start'}}>
                    <FormDropdown
                        formStyle={{marginTop: Metrics.spacingSM}}
                        label="Situação Profissional"
                        value={values.request.employmentStatus}
                        name="request.employmentStatus"
                        list={[
                            {label: 'Servidor público', value: 0},
                            {label: 'Empregado', value: 1},
                            {label: 'Autônomo', value: 2},
                            {label: 'Micro empresário', value: 3},
                            {label: 'Empreendedor', value: 4},
                            {label: 'Aposentado', value: 5},
                            {label: 'Desempregado', value: 6},
                            {label: 'Outros', value: 99},
                        ]}
                    />
                    <FormInputText label="Renda Mensal" name='request.income' value={values.request.income} placeholder='Renda Mensal' />
                    <FormDropdown
                        formStyle={{marginTop: Metrics.spacingSM}}
                        label="Motivo do Empréstimo"
                        value={values.request.reason}
                        name="request.reason"
                        list={[
                            {label: 'Compra', value: 1},
                            {label: 'Pagamento de contas', value: 2},
                            {label: 'Pagamento de dívida', value: 3},
                            {label: 'Despesas médicas', value: 4},
                            {label: 'Reformas', value: 5},
                            {label: 'Conserto de automóvel', value: 6},
                            {label: 'Ajuda a familiar', value: 7},
                            {label: 'Viagem', value: 8},
                            {label: 'Despesas educacionais', value: 9},
                            {label: 'Investimento em pequeno negócio', value: 10},
                            {label: 'Festa de casamento', value: 11},
                            {label: 'Outros', value: 99},
                        ]}
                    />
                </Col>
            </Row>
            <FormCheckbox
                formStyle={{marginTop: Metrics.spacingMD}}
                name="agreed"
                label="Eu autorizo o compartilhamento dos dados pessoais informados com a Geru, com o Sistema de Informações de Créditos do Banco Central e com as demais agências de crédito."
            />
        </Col>
    );
};

export default FinancialInfo;
