import React from 'react';
import {Col, Row} from "../../common";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {Colors, Metrics} from "../../config";
import useBreakpoint from "../../hooks/useBreakpoint";


const TitledContainer = ({children, title, newFn}: any) => {
    const { isMobile } = useBreakpoint();
  return (
      <Col style={{ width: "100%", padding: isMobile ? Metrics.spacingSM : Metrics.spacingLG, marginTop: isMobile ? Metrics.spacingLG : Metrics.spacingSM }}>
          <Row style={{ width: "100%", justifyContent: 'space-between' }}>
              <span style={{color: Colors.primary, fontSize: Metrics.fontSize.lg}}>{title}</span>
              {newFn &&
                  <AddCircleIcon onClick={newFn}
                     className={'add-btn'}
                     style={{
                         color: Colors.primary,
                         fontSize: Metrics.fontSize.xxxlg,
                         cursor: 'pointer'
                     }} />
              }
          </Row>
          {children}
      </Col>
  );
}

export default TitledContainer;
