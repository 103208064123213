import React from "react";
import {GenericList} from "../../../components";
import {deleteCommissionRequest, findCommissionsRequest} from "../../../services/commission";


export const Commission = () => {
    const columns: any = [
        {
            title: 'Grupo de trabalho',
            key: 'workgroupName'
        },
        {
            title: 'Comissão (%)',
            key: 'commission'
        },
    ]

  return (
        <GenericList
            deletable
            title={'Comissão'}
            newPath={'commissions/create'}
            editPath={'commissions/edit'}
            providerFind={findCommissionsRequest}
            providerDelete={deleteCommissionRequest}
            columns={columns}
        />
  );
};
