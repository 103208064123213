import React from "react";
import validationSchema from './ValidationSchema';
import {Form, Radio} from 'antd';
import {Formik} from "formik";
import { Metrics } from "new/config";
import {useHistory} from "react-router";
import useFetch from "new/hooks/useFetch";
import {findAllUsersRequest, findSupervisorsRequest} from "../../services/user";
import {findAllWorkgroupsRequest} from "../../services/workgroup";
import {Btn, Col, FormDropdown, FormInputText, Row, Whitespace} from "../../common";
import {MaskUtils} from "../../../util/MasksUtil";
import {AddressForm} from "../index";
import {findAllCouponsRequest} from "../../services/coupon";
import {FormControlLabel, RadioGroup} from "@material-ui/core";

const styles = {
    btnRow: {width: '100%', justifyContent: 'center', marginBlock: Metrics.spacingXLG},
    btn: {marginInline: Metrics.spacingMD, minWidth: '250px'}
};

const UserForm = ({onSubmit, initialValues, isFetching, seller}: any) => {
    const {goBack} = useHistory();
    const [
        { isFetching: isFetchingAllUsers, data: users }] = useFetch({
        provider: findAllUsersRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
    });
    const [
        { isFetching: isFetchingAllSupervisors, data: supervisors }] = useFetch({
        provider: findSupervisorsRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
    });
    const [
        { isFetching: isFetchingWorkgroups, data: workgroups }] = useFetch({
        provider: findAllWorkgroupsRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
    });
    const [
        { isFetching: isFetchingCoupons, data: coupons }] = useFetch({
        provider: findAllCouponsRequest,
        param: "",
        requestOnMount: true,
        initialData: [],
    });
    return (
        <div>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={{...initialValues, type: initialValues.cnpj ? 'CNPJ' : 'CPF'}}
                onSubmit={onSubmit}>
                {({ handleSubmit, values, setFieldValue }) => (
                    <Form>
                        <span style={{marginRight: 12}}>Tipo de pessoa:</span>
                        <Radio.Group
                            value={values.type}
                            onChange={(e) => setFieldValue('type', e.target.value)}
                        >
                            <Radio value="CPF">Física</Radio>
                            <Radio value="CNPJ">Jurídica</Radio>
                        </Radio.Group>
                        <FormInputText label={values.type === 'CPF' ? 'Nome' : 'Nome do responsável'} name="name" value={values.name}/>
                        {values.type === 'CPF' ?
                            <Row responsive>
                                <FormInputText fullWidth label="CPF" name="cpf" maxLength={14}
                                               value={MaskUtils.formatCpf(values.cpf)}/>
                                <Whitespace width={Metrics.spacingLG}/>
                                <FormInputText fullWidth label="RG" name="rg" value={values.rg}/>
                            </Row> :
                            <FormInputText label="CNPJ" name="cnpj" maxLength={18} value={MaskUtils.formatCnpj(values.cnpj)}/>
                        }
                        <Row responsive>
                            <FormInputText fullWidth label="Email" name="email" value={values.email}/>
                            <Whitespace width={Metrics.spacingLG}/>
                            <FormInputText
                                maxLength={10}
                                fullWidth
                                label="Data de Nascimento"
                                name="birthdate"
                                value={MaskUtils.formatDate(values.birthdate)}
                            />
                        </Row>
                        <FormDropdown
                            label="Sexo"
                            value={values.gender}
                            name="gender"
                            list={[
                                {label: 'Masculino', value: 'M'},
                                {label: 'Feminino', value: 'F'},
                            ]}
                        />
                        <FormInputText
                            maxLength={13}
                            label="Telefone"
                            name="phone"
                            value={MaskUtils.formatCel(values.phone)}
                        />
                        <AddressForm/>
                        <FormDropdown
                            disabled={seller}
                            multiple
                            label="Permissões"
                            value={seller ? ['ROLE_SELLER'] : values.authorities}
                            name="authorities"
                            list={
                                seller ?
                                    [{label: 'Vendedor', value: 'ROLE_SELLER'}] :
                                    [
                                        {label: 'Administrador', value: 'ROLE_ADMIN'},
                                        {label: 'Supervisor', value: 'ROLE_SUPERVISOR'},
                                        {label: 'Vendedor', value: 'ROLE_SELLER'},
                                        {label: 'Usuário', value: 'ROLE_USER'},
                                    ]}
                        />
                        <FormDropdown
                            disabled={seller}
                            label="Supervisor"
                            value={values.supervisorId}
                            name="supervisorId"
                            list={supervisors.map((u: any) => ({label: u.name, value: u.id}))}
                        />
                        <FormDropdown
                            label="Grupo de trabalho"
                            value={values.workgroupId}
                            name="workgroupId"
                            list={workgroups.map((u: any) => ({label: u.name, value: u.id}))}
                        />
                        {values.type === 'CPF' &&
                            <FormDropdown
                                label="Vínculo com empresa"
                                value={values.companyId}
                                name="companyId"
                                list={users.map((u: any) => ({label: u.name, value: u.id}))}
                            />
                        }
                        {
                            (values.authorities.includes('ROLE_SELLER') ||
                                values.authorities.includes('ROLE_SUPERVISOR')) &&
                            <FormDropdown
                                label="Cupom vinculado"
                                value={values.couponId}
                                name="couponId"
                                list={coupons.map((u: any) => ({
                                    label: `${u.code} - ${u.percentage ? '' : 'R$'}${u.percentage ? u.percentage : ''}${u.value ? u.value : ''}${u.percentage ? '%' : ''}`,
                                    value: u.id
                                }))}
                            />
                        }
                        <Col style={{marginTop: 24}}>
                            <span>Dados bancários</span>
                            <FormInputText label={'Chave PIX'} name="bankInfo.pixKey" value={values.bankInfo?.pixKey}/>
                            <FormInputText label={'Nome do banco'} name="bankInfo.bankName" value={values.bankInfo?.bankName}/>
                            <FormInputText label={'Agência'} name="bankInfo.agency" value={values.bankInfo?.agency}/>
                            <FormInputText label={'Número da conta'} name="bankInfo.accountNumber" value={values.bankInfo?.accountNumber}/>
                            <FormInputText label={'Nome do titular'} name="bankInfo.ownerName" value={values.bankInfo?.ownerName}/>
                            <FormInputText label={'Documento do titular'} name="bankInfo.ownerDocument" value={values.bankInfo?.ownerDocument}/>
                        </Col>
                        <Row responsive style={styles.btnRow}>
                            <Btn
                                onClick={goBack}
                                style={styles.btn}>Voltar</Btn>
                            <Whitespace height={Metrics.spacingMD}/>
                            <Btn
                                onClick={() => handleSubmit()}
                                disabled={isFetching}
                                type='primary'
                                style={styles.btn}>
                                Salvar
                            </Btn>
                        </Row>
                    </Form>
                    )}
            </Formik>
        </div>
);
}

export default UserForm;
