import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .nullable()
        .required('O campo nome é obrigatório.'),
    crm: Yup.string()
        .nullable()
        .required('O campo Crm é obrigatório.'),
    specialty: Yup.string()
        .nullable()
        .required('O campo Especialidade é obrigatório.'),
    phone: Yup.string()
        .nullable()
        .required('O campo Telefone é obrigatório.'),
    address: Yup.object().shape({
        street: Yup.string()
            .nullable()
            .required('O Campo Endereço é obrigatório.'),
        state: Yup.string()
            .nullable()
            .required('O Campo Estado é obrigatório.'),
        district: Yup.string()
            .nullable()
            .required('O Campo Bairro é obrigatório.'),
        postalCode: Yup.string()
            .trim()
            .required('O Campo CEP é obrigatório.'),
        city: Yup.string()
            .nullable()
            .required('O Campo Cidade é obrigatório.'),
        number: Yup.string()
            .nullable()
            .required('O Campo Número é obrigatório.')
    })
});

// name: '',
// crm: '',
// specialty: '',
// whatsapp: '',
// phone: '',
// linkedin: '',
// instagram: '',
// facebook: '',
// address: {}

export default ValidationSchema;
