import React from 'react';
import {Row} from "../../common";
import {Colors, Metrics} from "../../config";
import {showSuccessToast} from "../../../configs/toast";


const MyLink = ({user}: any) => {
  return (
      <Row style={{
          marginTop: Metrics.spacingLG,
          alignItems: 'center'
      }}>
                <span
                    style={{
                        fontSize: Metrics.fontSize.xxsm,
                        color: Colors.primaryDark
                    }}
                >
                    Meu link de referência:
                </span>
          <code
              style={{
                  marginLeft: Metrics.spacingSM,
                  cursor: 'pointer'
              }}
              onClick={() => {
                  navigator.clipboard.writeText(`https://silicred.com.br/#/simulation?referenceSeller=${user.id}`);
                  showSuccessToast('Link copiado para a área de transferência!')
              }}>
              {`https://silicred.com.br/#/simulation?referenceSeller=${user.id}`}
          </code>
      </Row>
  );
}

export default MyLink;
