/* eslint-disable jsx-a11y/label-has-associated-control */
import { useFormikContext } from 'formik';
import { FormItem, Input } from 'formik-antd';
import React from 'react';
import {Metrics} from "../../../config";

const FormInputText = ({
  label,
  name,
  value,
  type,
  onChange: onChangeParam,
  required,
  showSpinner,
  button,
  icon,
  iconColor,
  disabled,
  maxLength,
  placeholder,
  fullWidth,
  showValidateSuccess,
  autoFocus,
  forcePlaceholder,
  ...rest
}: any) => {
  const { getFieldMeta } = useFormikContext();
  const { error, touched } = getFieldMeta(name);

  const onChange = (newValue: any) => {
    if (!disabled) {
      if (onChangeParam) {
        onChangeParam(newValue);
      }
    }
  };

  return (
    <FormItem
      style={fullWidth ? { width: '100%', marginTop: Metrics.spacingSM } : {marginTop: Metrics.spacingSM}}
      name={name}
      required={required}
      showValidateSuccess={showValidateSuccess}>
      <label style={{ color: error && touched ? '#ff696b' : 'unset', fontSize: Metrics.fontSize.xxsm}}>
        {label && (required ? `${label} *` : label)}
      </label>
      <Input
        type={type}
        mode="outlined"
        value={value}
        onChange={e => onChange(e.target.value)}
        autoFocus={autoFocus}
        placeholder={forcePlaceholder || !label ? placeholder : ''}
        maxLength={maxLength}
        name={name}
        required={required}
        disabled={disabled}
        {...rest}
      />
    </FormItem>
  );
};

export default FormInputText;
