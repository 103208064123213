import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react'; // import as default
import { ImageDrop } from 'quill-image-drop-module';
import { useFormikContext } from 'formik';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);
const Editor = ({ value, onChange, height = '400px' }: any) => {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ size: ['small', false, 'large'] }], // custom dropdown
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      // ['link', 'image'],
    ],
    // imageDrop: true,
    imageResize: {
      handleStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  };

  return (
      <ReactQuill
          style={{
            flex: 1,
            borderRadius: '8px',
            height,
          }}
          value={value || ''}
          modules={modules}
          onChange={onChange}
      />
  );
};


export default ({
  label,
  name,
  value,
                  height,
  required,
  disabled,
  onChange: onChangeParam,
}: any) => {
  const { setFieldValue } = useFormikContext();

  const onChange = (newValue: any) => {
    setFieldValue(name, newValue);
    if (!disabled && onChangeParam) {
      onChangeParam(newValue);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <span>{required ? `${label} *` : label}</span>
      <Editor onChange={onChange} value={value} height={height} />
    </div>
  );
};
