import React from "react";
import {Col} from "../../common";
import {SimulationForm} from "../../components";
import {Metrics} from "../../config";
import useBreakpoint from "../../hooks/useBreakpoint";

const styles = {
    container: {alignItems: 'center'},
    btn: {marginTop: Metrics.spacingMD},
}

const Simulation = () => {
    const { isMobile } = useBreakpoint()
    return (
        <Col style={{...styles.container, padding: isMobile ? Metrics.spacingMinimun : Metrics.spacingXLG}}>
            <SimulationForm />
        </Col>
    );
};

export default Simulation;
