import React from "react";
import {Col} from "../../common";
import {Colors, Metrics} from "../../config";
import registrationImg from "../../../assets/images/Cadastro.svg";
import {RegistrationForm} from "../../components";
import useFetch from "../../hooks/useFetch";
import {registerUserRequest} from "../../services/user";
import {showSuccessToast} from "../../../configs/toast";
import {useHistory} from "react-router";

const styles = {
    container: {alignItems: 'center', padding: `75px ${Metrics.spacingXLG}`},
    title: {
        color: Colors.primary,
    },
    img: {width: '90px'},
}

const Registration = () => {
    const { goBack } = useHistory();
    const [{isFetching}, create,] = useFetch(
    {
        provider: registerUserRequest,
        param: '',
        requestOnMount: false,
        initialData: null,
        resultHandler: {
            success: () => {
                goBack();
                showSuccessToast('Cadastro efetuado! Verifique sua caixa de e-mail.')
            },
        }
        }
    );
    return (
        <Col style={styles.container}>
            <img style={styles.img} src={registrationImg} alt=""/>
            <h2 style={styles.title}>Cadastro</h2>
            <RegistrationForm onSubmit={create} isFetching={isFetching} isNewUser />
        </Col>
    );
};

export default Registration;
