import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    password: Yup.string()
        .trim()
        .required('O campo Senha é obrigatório.')
        .min(4, 'A senha precisa ter no mínimo 4 caracteres.'),
    passwordConfirmation: Yup.string()
        .trim()
        .required('O campo Confirmação de Senha é obrigatório.')
        .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais.'),
});

export default ValidationSchema;
