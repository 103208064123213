import React from "react";
import {GenericList} from "../../../components";
import {deleteInstallmentRequest, findInstallmentsRequest} from "../../../services/installment";
import {INSTALLMENT_TYPES} from "./util/INSTALLMENT_TYPES";

export const Installment = () => {
    const columns: any = [
        {
            title: 'Valor',
            key: 'value'
        },
        {
            title: 'Taxa Administrativa',
            key: 'taxAdm'
        },
        {
            title: 'Tipo contrato',
            key: 'type',
            render: (r: string) => <span>{INSTALLMENT_TYPES[r]}</span>
        },
    ]
  return (
      <GenericList
          deletable
          title={'Parcelamentos'}
          newPath={'installments/create'}
          editPath={'installments/edit'}
          providerFind={findInstallmentsRequest}
          providerDelete={deleteInstallmentRequest}
          columns={columns}
      />
  );
};
