import api from "./api";

const transformToOpenCoAddress = (address: any) => {
    return {
        ...address,
        streetAddress: address.street,
        neighborhood: address.district,
        zipCode: parseInt(address.postalCode.replace(/[^0-9]/g, '')),
    };
}

export const sendFinancingDataRequest = async (param: any, data: any) => {
    return api.post(`/api/financing/send-data`, data);
};

export const updateInfoFinancingRequest = async (param: any, data: any) => {
    const requestBody = {
      ...data,
        employmentInfo: {
            ...data.employmentInfo,
            income: data.request.income
        },
        homeInfo: {
            ...transformToOpenCoAddress(data.homeInfo),
        }
    };
    return api.post(`/api/financing/update-application`, requestBody);
};

export const getFinancingOffersRequest = async (param: any, data: any) => {
    return api.post(`/api/financing/get-offers`, data);
};
