import {Col, FormInputText} from "../../../common";
import React from "react";
import {useFormikContext} from "formik";
import {MaskUtils} from "../../../../util/MasksUtil";


const ClientInfo = () => {
    const { values }: any = useFormikContext();

    return (
        <Col>
            <FormInputText label="Valor Desejado" name='request.amount' value={values.request.amount} placeholder='Nome' />
            <FormInputText label="E-mail" name='person.email' value={values.person.email} placeholder='E-mail' />
            <FormInputText label="Nome" name='person.name' value={values.person.name} placeholder='Nome Completo' />
            <FormInputText label="Cpf" name='person.cpfStr' value={MaskUtils.formatCpf(values.person.cpfStr)} placeholder='CPF' />
        </Col>
    );
};

export default ClientInfo;
