import { Col, Row } from "new/common";
import React from "react";
import {DreamContent, MyLink, TitledContainer} from "../../../components";
import {useAuthentication} from "../../../context/AuthContext";
import {showSuccessToast} from "../../../../configs/toast";
import {Colors, Metrics} from "../../../config";


export const Supervisor = () => {
    const { user } = useAuthentication();

    return <TitledContainer title={'Vendas'}>
        <Col>
            <MyLink user={user} />
            <span
                style={{
                    fontSize: Metrics.fontSize.xxxsm,
                    color: 'gray',
                    marginBottom: Metrics.spacingMD
                }}
            >
                    (Clique para copiar)
                </span>
            <DreamContent supervisor={user?.id} seller={user?.id} />
        </Col>
    </TitledContainer>
}
