import React, {useState} from 'react';
import CookiesDialog from "../CookiesDialog";
import useBreakpoint from "../../hooks/useBreakpoint";

const styles: any = {
    container: {
        position: 'fixed',
        margin: '10px',
        bottom: 0,
        backgroundColor: 'white',
        zIndex: 10,
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px 25px 10px',
        boxShadow: '1px 1px 5px #cacaca'
    },
    text: {
        fontSize: '13px',
        fontFamily: 'montserrat-regular'
    },
    btnsContainer: {
        display: 'flex',
        margin: '10px',
    },
    btn: {
        backgroundColor: '#BD997F',
        color: 'white',
        border: 'none',
        borderRadius: '100px',
        padding: '10px 25px',
        cursor: 'pointer',
        margin: '0 10px',
    }
}

const LgpdWarn = ({setLgpd}: any) => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const { isMobile } = useBreakpoint();
    return (
      <div style={styles.container}>
        <label style={styles.text}>
            Usamos cookies para armazenar informações sobre como você usa o nosso site e as páginas que visita. Tudo para tornar sua experiência a mais agradável possível. Para entender os tipos de cookies que utilizamos, clique em <b>Definições dos cookies</b>. Ao clicar em <b>Eu concordo</b>, você consente com a utilização de cookies.
        </label>
        <div style={styles.btnsContainer}>
            <button style={styles.btn} onClick={ () => setDialogVisible(true)}>Definições dos cookies</button>
            <button style={styles.btn} onClick={ () => {
                setLgpd('true');
                localStorage.setItem('lgpd', 'true');
            }}>Eu concordo</button>
        </div>
          {
              dialogVisible && <CookiesDialog setLgpd={setLgpd} dialogVisible={dialogVisible} setDialogVisible={setDialogVisible} />
          }
      </div>
    );
};

export default LgpdWarn;
