import React from "react";
import {Table as AntdTable} from "antd";
import {Colors, Metrics} from "../../../config";
import {PaymentStatusEnum} from "../../../../constants";
import useBreakpoint from "../../../hooks/useBreakpoint";
import {ActionIcons, Col, Row, Table} from "../../../common";
import Filters from "../../../../configs/Filters";
import {getLabelBankSlipSituacion} from "../../../../util";
import moment from "moment";


const {Column} = AntdTable;
const styles = {
    title: {color: Colors.primary},
    fieldName: {color: 'rgb(133 133 133)', marginRight: Metrics.spacingSM},
    seller: {color: Colors.primary, marginRight: Metrics.spacingSM},
    sellerName: {color: 'rgb(133 133 133)'}
}

const getColorFromPayment = (situacao: PaymentStatusEnum | undefined) => {
    switch (situacao) {
        case PaymentStatusEnum.OVERDUE:
            return '#ba4142';
        case PaymentStatusEnum.PAID:
            return '#6dbd79';
        case PaymentStatusEnum.PENDING:
            return '#CCCCCC';
    }
};

export const DetailProcedureTable = ({
         data,
         fee,
         isFetchingDownload,
         isFetchingMarkPaid,
         handleDownloadBankslip,
         setSelectedInstallmentLine,
         seller,
         supervisor}: any) => {
    const {isMobile} = useBreakpoint();

    return (
        <Col style={{marginBottom: Metrics.spacingMD}}>
            <p style={styles.title}><b>{fee ? 'Taxa de adesão' : 'Parcelas'}</b></p>
            <Table
                indentSize={2}
                style={isMobile ? {width: '200px'} : {}}
                showHeader={false}
                data={data?.userPayments.filter((up: any) => fee ? up.fee : up.parcelNumber)
                    .sort((a: any, b: any) => {
                        // @ts-ignore
                        return a.parcelNumber > b.parcelNumber ? 1 : -1;
                    })}>
                {!isMobile &&
                    <Column width={'156px'} title={''} dataIndex="parcelNumber" key="parcelNumber" render={(_, rowData: any) => <span>{rowData?.parcelNumber || 1}</span>}  />
                }
                <Column title={''} dataIndex="dueDate" key="dueDate"
                        render={(_, rowData: any) => <span>{moment(rowData?.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>}  />
                <Column title={''} dataIndex="value" key="value" render={(_, rowData: any) => <span>{Filters.convertMoneyTextMask(rowData?.value)}</span>}  />
                <Column title={''} dataIndex="status" key="status"
                        render={(_, rowData: any) =>
                            <Row>
                                    <span style={{color: getColorFromPayment(rowData?.status)}}>
                                        {getLabelBankSlipSituacion(rowData?.status, 'md')}
                                    </span>
                                {
                                    rowData?.paymentDate ?
                                        <span style={{marginLeft: Metrics.spacingMinimun, fontSize: Metrics.fontSize.xxxsm}}>
                                                    ({moment(rowData?.paymentDate).format('DD/MM/YYYY')})
                                        </span> : ''
                                }
                            </Row>
                        }
                />
                <Column
                    title={''}
                    dataIndex="action"
                    key="action"
                    render={(i, record: any) => (
                        <Row>
                            {
                                record?.status !== PaymentStatusEnum.PAID &&
                                <Row>
                                    <ActionIcons
                                        type={"download"}
                                        actionFn={() => !isFetchingDownload ? handleDownloadBankslip(record) : () => {}}
                                    />
                                    {
                                        !seller && !supervisor &&
                                        <ActionIcons
                                            type={"check"}
                                            actionFn={() => !isFetchingMarkPaid ? setSelectedInstallmentLine(record.id) : () => {}}
                                        />
                                    }
                                </Row>
                            }
                        </Row>
                    )}
                />
            </Table>
        </Col>
    );
};
