import api from "./api";

export const findBanksRequest = async () => {
    return api.get(`/api/company/banks`);
};
export const findCompanyRequest = async () => {
    return api.get(`/api/company`);
};

export const updateCompanyRequest = (param: any, data: any) => {
    return api.put(`/api/company`, data);
};
