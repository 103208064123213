import React from "react";
import {Col} from "../../common";
import aboutUsImg from "../../../assets/images/sobreNos.svg";
import {Colors, Metrics} from "../../config";

const styles = {
    container: {alignItems: 'center', padding: `75px ${Metrics.spacingXLG}`},
    title: {
        color: Colors.primary,
    },
    text: {
        fontSize: Metrics.fontSize.xxsm,
        whiteSpace: 'pre-line' as const,
        width: '1100px',
        fontFamily: 'montserrat-medium',
        color: Colors.text,
        textAlign: 'justify' as const
    },
    text2: {
        fontSize: Metrics.fontSize.xxsm,
        whiteSpace: 'pre-line' as const,
        fontFamily: 'montserrat-bold',
        width: '1100px',
        color: Colors.text,
        textAlign: 'justify' as const
    },
    text3: {
        fontSize: Metrics.fontSize.xsm,
        whiteSpace: 'pre-line' as const,
        maxWidth: '1100px',
        fontWeight: 'bolder',
        color: Colors.text,
        textAlign: 'justify' as const
    },
    img: {width: '120px'}
}
const Terms = () => {
    return (
        <Col style={styles.container}>
            <img style={styles.img} src={aboutUsImg} alt=""/>
            <h2 style={styles.title}>Termos de uso</h2>
            <p style={styles.text}>
                Sejam bem vindos a SILICRED ADMINISTRAÇÃO EM CRÉDITO LTDA, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 35.237.854/0001-70, localizada à Rua Fúlvio Aducci 627 – sala 908 , bairro Estreito - Florianópolis, Estado de Santa Catarina, CEP: 88075-000, representada pelos seus sócios administradores, empresa privada cujas atividades sociais são a prestação de serviços a seus usuários que desejam realizar cirurgia plástica, cirurgia geral, implante capitar, procedimentos odontológicos diferenciados, entre outros, em condições financeiras baseadas nas facilidades creditícias, aproximando-os de profissionais devidamente capacitados, nos termos do Ordenamento Ético Jurídico vigente.
            </p>
            <p style={styles.text}>
                Doravante, a SILICRED ADMINISTRAÇÃO EM CRÉDITO LTDA, será referenciado pela sigla SILICRED.
            </p>
            <p style={styles.text}>
                Os presentes Termos de Uso, poderão ser modificados, alterados com acréscimos ou supressões, baseados em critérios operacionais da SILICRED, a qualquer momento, objetivando aperfeiçoar o relacionamento com os usuários do nosso website, a partir de alterações legislativas ou técnicas, relacionadas com novas versões do sistema operacional da plataforma que hospeda o referido website, entrando em vigor imediatamente após a publicação no sítio eletrônico. Você, usuário/paciente, terá acesso, periodicamente, a mais atualizada versão destes Termos de Uso e será responsável por acessá-los.
            </p>
            <p style={styles.text2}>
                Os Termos de Uso e a Política de Privacidade, são documentos válidos para todos os usuários de nossa Plataforma, nas formas consignadas pela Lei Geral de Proteção de Dados - LGPD, Lei Federal n°13.709/2018, pelas disposições consumeristas da Lei Federal n° 8078/1990 e pelas demais normas pertencentes ao Ordenamento Jurídico brasileiro aplicáveis.
            </p>
            <p style={styles.text}>
                O acesso as informações a aos serviços contidos no website, precede de aceitação expressa de quaisquer alterações ou modificações feitas nos Termos de Uso.
            </p>
            <p style={styles.text2}>
                1. Aceitação aos Termos de Uso
            </p>
            <p style={styles.text}>
                1.1 Ao acessar as informações e/ou os serviços da SILICRED, você manifestará CONCORDÂNCIA EXPRESSA com os Termos de Uso, suas cláusulas e condições, conforme abaixo detalhadas e consignadas.
            </p>
            <p style={styles.text}>
                1.2 Você só será autorizado a acessar as informações contidas no website com relação aos ser viços prestados pela empresa, se CONCORDAR com todas regras e condições estabelecidas nos presentes Termos de Uso.
            </p>
            <p style={styles.text}>
                1.3 Se você não concordar com os presentes Termos de Uso, você não estará autorizado a acessar as informações sobre contidas no website sobre os Serviços oferecidos. O acesso às informações sobre a utilização dos serviços oferecidos pela SILICRED está expressamente condicionado ao seu consentimento às regras dos presentes Termos de Uso.
            </p>
            <p style={styles.text2}>
                2. Usuários/pacientes
            </p>
            <p style={styles.text}>
                2.1 Usuários: O uso das áreas públicas do website da SILICRED está disponível para qualquer pessoa, sem necessidade de registro, bastando a concordância expressa com estes Termos de Uso. Para poder usufruir do acesso a informações sobre serviços privados e poder usufruir destes, o usuário deverá se registrar de forma específica, a partir dos requisitos e na forma que o sistema  apresentará.
            </p>
            <p style={styles.text}>
                2.2 Pacientes: Qualquer pessoa maior de idade, representada ou assistida juridicamente, em qualquer parte do país, poderá se tornar usuária da SILICRED, desde que manifeste a concordância expressa com estes Termos de Uso e as devidas autorizações conforme o estabelecido no Politica de Privacidade, a partir dos requisitos e na forma que o sistema apresentará.
            </p>
            <p style={styles.text}>
                2.3 Senha e Segurança: Quando você completar o seu processo de registro, você criará uma senha que habilitará seu acesso à nossa Plataforma. Você assumirá expressamente o compromisso formal de manter a confidencialidade da sua senha, sendo inteiramente responsável por qualquer dano resultante da não manutenção dessa confidencialidade e de todas as atividades que ocorrerem através do uso de sua senha. Você concorda expressamente em nos notificar imediatamente sobre a ocorrência de qualquer acesso não autorizado em sua área privativa, assim como também sobre qualquer utilização não autorizada de sua senha. Você compreende e manifesta esta compreensão expressamente, sobre o fato da SILICRED não vir a ser responsabilizado por qualquer perda ou dano que eventualmente vier a ocorrer, decorrente do descumprimento destas disposições.
            </p>
            <p style={styles.text2}>
                3. Informações Públicas e Privadas
            </p>
            <p style={styles.text}>
                3.1 “Suas Informações”: são definidas como qualquer informação voluntariamente postada por você ou que você nos dê (direta ou indiretamente), desde que manifeste a concordância expressa com estes Termos de Uso, inclusive através do seu processo de registro ou do seu uso de nossa Plataforma, comentários, mensagens enviadas dentro de nossa Plataforma, ou e-mail, tudo na forma preconizada na Política de Privacidade. Você é o único responsável pelas informações que prestar, nos termos da Politica de Privacidade e destes Termos de Uso, sendo que, desde que autorizado expressamente por você, com  ciência prévia da(s) finalidade(s), tais informações poderão ser divulgadas, agindo a SILICRED como um canal passivo para a distribuição e publicação dessas informações, conforme definições abaixo:
            </p>
            <p style={styles.text}>
                3.1.1 “Informações Públicas”: são todos aqueles dados, que você, cumpridos os requisitos autorizatórios e de finalidade expressos no item anterior, através do uso de nossa Plataforma ou de outra maneira, envie ou disponibilize para a inclusão em áreas públicas de nosso website.
            </p>
            <p style={styles.text}>
                3.1.2 “Informação Privada”: são todos aqueles dados, que você, cumpridos os requisitos autorizatórios e de finalidade expressos no item anterior, através do uso de nossa Plataforma ou de outra maneira, não envie ou não disponibilize para a inclusão em áreas públicas de nosso website.
            </p>
            <p style={styles.text}>
                3.2 “Áreas públicas”: são aquelas áreas do nosso website que são acessíveis para alguns ou todos os nossos usuários ou pacientes (áreas não restritas à sua visualização apenas) ou para o público geral.
            </p>
            <p style={styles.text}>
                3.3 “Áreas Privadas”: são aquelas áreas do nosso website que são acessíveis somente para o usuário ou paciente cadastrado, na forma da Política de Privacidade e destes Termos de Uso (áreas restritas à sua visualização apenas).
            </p>
            <p style={styles.text}>
                3.4 Acessibilidade das Informações Pública: As suas Informações Públicas podem ser acessíveis e publicadas por programas de publicação automática e por ferramentas de busca, ferramentas de metabusca, crawlers, metacrawlers e outros similares, na forma da Política de Privacidade e destes Termos de Uso.
            </p>
            <p style={styles.text}>
                3.5 Restrições: Ao considerar o uso de nossa Plataforma, você concorda expressamente que as Suas Informações: (a) não devem ser fraudulentas; (b) não devem infringir nenhum direito autoral de terceiros, patente, marca registrada, direito de distribuição ou outro direito de propriedade intelectual, de publicação ou privacidade; (c) não devem violar nenhuma lei, estatuto, ordenação ou regulamento contido no Ordenamento Jurídico brasileiro; (d) não devem ser difamatórias, caluniosas, ameaçadoras, discriminadoras ou abusivas; (e) não devem ser obscenas ou conter pornografia de qualquer espécie, especialmente pornografia infantil, ou fotos de pessoas despidas, em poses indecorosas ou constrangedoras; (f) não devem conter vírus, cavalos de tróia, worms, time bombs, cancelbots, easter eggs ou outras rotinas de programação que possam danificar, interferir, interceptar ou desapropriar qualquer sistema, dado ou informação pessoal; (g) não devem nos responsabilizar ou fazer com que percamos (total ou parcialmente) o serviço do nosso Provedor de Internet ou outros fornecedores semelhantes ou correlatos; (h) não devem criar links direta ou indiretamente a qualquer material pertencente, autorizado ou relacionado a você,  tenha ou não direito de incluir ou linkar.
            </p>
            <p style={styles.text}>
                3.6 Obrigações: Você concorda expressamente também, que deverá nos informar um endereço válido de e-mail, tanto na hora de seu registro conosco quanto a cada vez que o seu e-mail mudar. Você concorda expressamente também, que o seu perfil de usuário da SILICRED, postagens de comentários em blogs, uploads de fotos ou quaisquer porções do website reservadas apenas a uso privativo, não devem ser usadas por você para atividades de relacionamento, comerciais, vendas de bens e/ou serviços, promoções de quaisquer tipos de bem ou serviços. Você será exclusivamente responsável pelas informações que vier a postar nas áreas publicamente acessíveis da Plataforma, independente de sua intenção ou não de fazê-lo.
            </p>
            <p style={styles.text}>
                3.7 Privacidade: Todas as informações pessoais relacionadas ao uso dos Serviços ou ao uso do website da SILICRED, estão vinculadas e sujeitas à nossa Política de Privacidade e aos Termos de Uso e que podem ser encontradas em https://silicred.com.br/termosdeuso.
            </p>
            <p style={styles.text}>
                3.8 Licença/Consentimento/Autorização: Com fundamento em nossa Política de Privacidade e conforme as disposições contidas nestes Termos de Uso, nós usaremos as suas Informações Pessoais Identificáveis, fornecidas a partir de sua Licença/Consentimento/Autorização expressa, apenas para as finalidades declinadas, no momento da coleta, e necessárias às finalidades sociais próprias da SILICRED. Entretanto, para nos autorizar a usar suas Informações Públicas e para nos assegurar de que não violamos nenhum direito que você possa ter sobre tais Informações, você garante e assegura a SILICRED o direito de uso motivado, expresso e consentido/autorizado/licenciado, e não-exclusivo, universal, sem pagamento de royalties, em caráter total, definitivo, irrevogável e irretratável, de utilizar, a qualquer tempo, no Brasil, das informações e dados fornecidos.
            </p>
            <p style={styles.text}>
                3.9 Restrições no Nosso Uso de Suas Informações: Exceto se devida, expressa  e motivadamente autorizado e desde que não contrarie os conteúdos de nossa Política de Privacidade e Termos de Uso, nós não venderemos, alugaremos, cederemos ou divulgaremos, no todo ou em parte, nenhuma de suas Informações Pessoais Identificáveis a nós transferidas, para terceiros.
            </p>
            <p style={styles.text}>
                3.10 Consentimento de Divulgação: Você compreende e concorda expressamente que a SILICRED pode divulgar as Informações do qual você é titular se as mesmas forem requeridas em face de mandamento legal ou em razão de legitimo interesse, de boa fé, tendo em vista que essa divulgação é razoável e necessária para: (a) cooperar com um procedimento judicial, uma ordem judicial ou processo legal sobre nós ou nosso website; (b) reforçar os Termos de Uso; (c) contestar infração a direito de terceiros em face de suas Informações; (d) proteger os direitos, propriedades ou a segurança pessoal do SILICRED, seus empregados, usuários e  pacientes.
            </p>
            <p style={styles.text}>
                3.11 Consentimento de divulgação: A SILICRED reserva-se ao direito, de suspender ou por fim ao seu acesso ou uso de nossa Plataforma e de ter acesso a informações sobre nossos Serviços, de recusar todo e qualquer uso corrente ou futuro de todas ou algumas partes de nossa Plataforma e/ou Serviços, fundamentado em violação aos critérios estabelecidos em sua Política de Privacidade e no conteúdo destes Termos de uso.
            </p>
            <p style={styles.text2}>
                4. Condições Gerais de Uso dos Serviços
            </p>
            <p style={styles.text}>
                4.1 Ao manifestar aceitação a estes Termos de Uso, você tem o direito não exclusivo, intransferível, não sub-licenciável e limitado de entrar, acessar e usar as informações sobre os nossos serviços, unicamente para uso pessoal e não comercial.
            </p>
            <p style={styles.text}>
                4.2 Todos os direitos de uso de nossa plataforma estão previstos expressamente em nosso Política e Privacidade e nestes Termos de Uso, que pertencem a SILICRED.
            </p>
            <p style={styles.text}>
                4.3 Você concorda expressamente que o acesso a informações sobre os serviços prestados pela SILICRED são para o seu uso pessoal e não comercial.
            </p>
            <p style={styles.text}>
                4.4 Você concorda expressamente que o acesso a áreas privativas de nossa plataforma, onde eventualmente estejam hospedados dados privativos de sua titularidade, estão protegidos e limitados ao uso por senha de acesso, pessoal e intransferível, que você recebeu tendo em visto cadastro prévio, consentido e devidamente motivado.
            </p>
            <p style={styles.text}>
                4.5 Além da licença limitada de uso estabelecida nestes Termos de Uso, você não possui qualquer outro direito, título ou propriedade sobre as informações sobre os serviços. Você compreende, reconhece expressamente e anui que, em quaisquer circunstâncias, os seus direitos com relação a obtenção de informações sobre os serviços oferecidos pela SILICRED estarão limitados por direitos autorais e/ou leis de propriedade intelectual aplicáveis e ainda por disposições constantes nestes Termos de Uso.
            </p>
            <p style={styles.text}>
                4.6 As informações sobre os serviços prestados pela SILICRED permitem que os usuários e pacientes sejam conscientizados sobre o conteúdo, a natureza, as dimensões, as espécies e formas de prestação desses serviços à comunidade assistida, dando efetividade ao direito a informação que possuem.
            </p>
            <p style={styles.text}>
                4.7 A SILICRED poderá modificar as informações sobre os serviços prestados e/ou o conteúdo de seu website, com fundamento em sua Política de Privacidade e em critérios estabelecidos nestes Termos de Uso, ou descontinuar a sua disponibilidade a qualquer momento.
            </p>
            <p style={styles.text}>
                4.8 Você compreende que é o único responsável pela obtenção, pagamento e manutenção de todos os serviços de telefonia que implicam em acesso à internet, plano de dados, tarifas e/ou outras taxas, mensalidades e custos associados ao seu acesso e uso das informações sobre os serviços prestados pela SILICRED, bem como pelo software, hardware de seu computador e outros equipamentos necessários para o uso e acesso às informações sobre os serviços.
            </p>
            <p style={styles.text}>
                4.9 Não obstante outras disposições contidas na nossa Politica de Privacidade e nestes Termos de Uso, as informações sobre os serviços estarão disponíveis para seu uso pessoal que começa com a anuência expressa, que implica em concordância, com estes Termos de Uso.
            </p>
            <p style={styles.text}>
                4.10 Você não deverá apoiar, de nenhuma forma e sob qualquer circunstância, tentativas de terceiros para driblar, reverter a engenharia, decodificar, decompilar, desmontar, fraudar ou interferir, de qualquer forma e por qualquer meio, físico, telemático ou virtual, a dimensão, tipos, natureza, forma e outros com aspectos, relacionados ao serviços prestados pela SILICRED.
            </p>
            <p style={styles.text}>
                4.11 Você não deve distribuir, intercambiar, transferir, modificar, vender, revender ou retransmitir a qualquer pessoa, qualquer parte das informações relacionadas aos serviços prestados pela SILICRED, incluindo, mas não se limitando, a qualquer texto, imagem ou áudio, para qualquer finalidade empresarial comercial, pública ou privada.
            </p>
            <p style={styles.text2}>
                5. Pagamentos pelos Serviços
            </p>
            <p style={styles.text}>
                5.1 Através da área privada, você receberá informações sobre os custos dos serviços que serão prestados pela SILICRED.
            </p>
            <p style={styles.text}>
                5.2 Você concorda expressamente em fornecer seus dados pessoais: nome, profissão, estado civil, endereço, filiação, endereço de e-mail e de rede social e a manter e atualizar tais informações, com veracidade, exatidão e atualização, para a realização do seu cadastro pessoal, objetivando proporcionar contato personalizado e a viabilizar o envio de orientações complementares sobre os serviços que serão prestados.
            </p>
            <p style={styles.text}>
                5.3 Você compreende e anui expressamente, que não poderá se fazer passar por outra pessoa física ou representante de pessoa jurídica ou ainda, adulterar sua identidade ou sua afiliação a qualquer pessoa física ou jurídica, incluindo o uso do nome de usuário, senha ou de outra informação de conta de outra pessoa física ou jurídica ou o nome, a semelhança, voz, imagem ou fotografia de outra pessoa.
            </p>
            <p style={styles.text}>
                5.4 Transações bancárias e através de cartão de crédito: Durante o fornecimento de informações e em face de eventual pré contratação de serviços, com o objetivo de garantir agenda profissional, a solicitação de informações do seu cartão de crédito ou de sua conta bancária podem ser solicitadas, solicitação esta a que você anuirá expressamente.
            </p>
            <p style={styles.text}>
                5.5 Direito de alterar os preços: A SILICRED reserva-se ao direito de alterar sua tabela de preços de serviços sempre que isso se fizer necessário para fazer frente aos custos e a remuneração adequada e razoável de seus serviços, a qualquer momento, sem aviso prévio.
            </p>
            <p style={styles.text2}>
                6. Uso da Plataforma
            </p>
            <p style={styles.text}>
                6.1 Responsabilidade e Controle: Você é inteiramente responsável pelas informações que fornecer, em conformidade com a Política de Privacidade e em consonância com estes Termos de Uso, que faça upload, poste, distribua, envie por e-mail ou de alguma outra forma torne disponível no âmbito de nossa plataforma.
            </p>
            <p style={styles.text}>
                6.2 Condições para Suspensão ou Término dos Serviços:
            </p>
            <p style={styles.text}>
                6.2.1 Os seguintes tipos de ação são vedados no website e na Plataforma da SILICRED, sendo passíveis de suspensão e/ou término imediato do direito ao acesso:
            </p>
            <p style={styles.text}>
                (a) Uso de nossa Plataforma para: (i) ameaçar ou intimidar outra pessoa de qualquer forma, incluindo a restrição ou inibição do uso de nossa Plataforma; (ii) personificar qualquer pessoa (incluindo os Parceiros), ou atestar falsamente afiliação ou representação de qualquer pessoa ou companhia, através do uso de endereços de e-mail similares, apelidos, ou a criação de contas falsas ou qualquer outro método ou procedimento; (iii) disfarçar a origem de quaisquer Informações Públicas que sejam transmitidas; (iv) perseguir ou perturbar outrem; (v) coletar ou armazenar dados pessoais de outros usuários situados na área pública;
            </p>
            <p style={styles.text}>
                (b) Postar quaisquer Informações Públicas ou outro material: (i) que seja ilegal, ofensivo, racista, preconceituoso, ameaçador, abusivo, perturbador, difamatório, intimidador, vulgar, obsceno, acusatório, que invada a privacidade de outrem (inclusive a postagem de e-mails privados ou informações de contato de outros usuários), odioso, racial, eticamente ou de qualquer outra forma contestável, incluindo quaisquer Informações Públicas ou outro material que possa ser considerado um discurso de ódio; (ii) que seja obsceno, pornográfico ou indecente por natureza; (iii) que você não tenha o direito de disponibilizar por qualquer lei, regulamente ou contrato; (iv) que infrinja qualquer patente, marca registrada, segredo comercial, direitos autorais ou quaisquer outros direitos de propriedade intelectual ou direitos de terceiros; (v) que de caracterize como qualquer tipo de propaganda ou material promocional não solicitado, ou qualquer outra forma de solicitação (incluindo, mas não se limitando a “spam”, “junk mail”, e correntes de e-mail); ou (vi) que seja de qualquer outra forma inapropriado ou postado de má fé;
            </p>
            <p style={styles.text}>
                (c) Encorajar outrem a violar estes Termos de Uso ou se recusar a seguir instruções da SILICRED ou dos profissionais parceiros;
            </p>
            <p style={styles.text}>
                (d) Violar (intencional ou não intencionalmente) estes Termos de Uso, ou qualquer lei, regulamente, estatuto ou norma disciplinar ou regulamentar, municipal, estadual ou nacional aplicável.
            </p>
            <p style={styles.text}>
                6.2.2 Não limitado ao disposto acima, ao SILICRED tem o direito de remover, sem aviso prévio, quaisquer Informações Públicas ou Privadas, ou ainda outro material que viole a política de Privacidade ou estes Termos de Uso.
            </p>
            <p style={styles.text}>
                6.3 Não-Interferência com a Plataforma. Você concorda que não irá: (a) fazer upload, postar, publicar, distribuir, enviar por e-mail ou transmitir de qualquer outra forma rotinas de programação, arquivos ou programas com a intenção de interromper, destruir ou limitar as funcionalidades de qualquer software ou hardware ou equipamento de telecomunicação; (b) interferir com ou perturbar nossa Plataforma ou com as redes conectadas ao nosso website através do uso de nossa Plataforma, ou desobedecer quaisquer requerimentos, procedimentos, políticas ou regulamentos das redes conectadas ao nosso website, ou de alguma outra maneira interferir com a nossa Plataforma em qualquer sentido, incluindo através do uso de JavaScript, ou outros códigos; (c) agir de qualquer maneira que imponha uma carga excessivamente pesada, que seja desproporcional ou não razoável, em nossa infraestrutura; (d) copiar, reproduzir, alterar, modificar ou exibir publicamente qualquer informação que esteja disponível em nosso website (exceto as Informações de sua titularidade), ou criar trabalhos derivados do nosso website, com o entendimento de que tais ações constituiriam infração de direitos autorais ou outro tipo de violação à propriedade intelectual e/ou industrial da SILICRED ou de quaisquer terceiros.
            </p>
            <p style={styles.text}>
                6.4 Práticas Gerais de Uso da Plataforma: Você declara formal e expressamente, ter conhecimento e compreender que a SILICRED possui a prerrogativa de estabelecer práticas e limites gerais no que diz respeito ao uso de nossa Plataforma. Você declara expressamente e concorda, em face disso, que nós não nos responsabilizamos nem podemos ser responsabilizados pelo armazenamento ou supressão de material, falha em armazenar ou apagar, qualquer uma das informações de que você seja titular, a nós repassada na forma da Política de Privacidade e nestes Termos de Uso.
            </p>
            <p style={styles.text2}>
                7. Senha
            </p>
            <p style={styles.text}>
                7.1 Nosso website poderá apresentar a você a possibilidade de registro cadastramento de uma conta de usuário. Ao abrir uma conta junto ao website, você poderá receber uma identificação de conta (login) e uma senha inicial. É de sua responsabilidade (a) manter o sigilo de sua identificação de conta e senha; (b) atualizar e revisar sua conta frequentemente; e (c) avisar prontamente a SILICRED caso ocorra qualquer utilização não autorizada de sua conta ou qualquer quebra de segurança no sigilo da senha. Você é responsável também por todas as atividades que forem realizadas através de sua conta e a SILICRED as regras estabelecidas nestes Termos de Uso.
            </p>
            <p style={styles.text2}>
                8. Comunicados da SILICRED
            </p>
            <p style={styles.text}>
                8.1 Você compreende, concorda expressamente e AUTORIZA EXPRESSAMENTE, que nós podemos lhe enviar comunicados de serviços oferecidos pela SILICRED e newsletters informativos sobre a natureza dos serviços oferecidos, objetivando lhe beneficiar e esclarecer dúvidas, inseguranças e a fornecer-lhe informações relevantes.
            </p>
            <p style={styles.text2}>
                9. Links
            </p>
            <p style={styles.text}>
                9.1 Nós podemos disponibilizar o acesso a links para outros websites ou recursos digitais que possuem o objetivo de ampliar, quantitativamente e qualitativamente, as informações relacionadas aos serviços prestados pela SILICRED. Por não termos controle sobre tais websites e não possuirmos controle sobre os recursos, você compreende e concorda expressamente que nós não somos responsáveis pelo conteúdo de tais websites e recursos. De igual forma, nós não endossamos e não nos responsabilizamos nem somos passíveis de ser responsabilizados, por qualquer conteúdo, publicidade, produto, ou outro material disponível nesses websites ou recursos digitais.
            </p>
            <p style={styles.text}>
                9.2 Você também compreende e concorda expressamente, que a SILICRED não será responsável nem poderá ser responsabilizado, direta ou indiretamente, por quaisquer perdas e danos causados ou alegados de terem sido causadas ou relacionadas ao uso de qualquer conteúdo, bem ou serviço disponível na Plataforma, através do uso de qualquer um dos websites e/ou recursos digitais disponibilizados.
            </p>
            <p style={styles.text2}>
                10. Cookies
            </p>
            <p style={styles.text}>
                10.1 A SILICRED faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que contém informações relacionadas à navegação do site. Em suma, os Cookies são utilizados para aprimorar a experiência de uso.
            </p>
            <p style={styles.text}>
                10.2 Você compreende e CONCORDA EXPRESSAMENTE que, ao acessar nosso website e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.
            </p>
            <p style={styles.text}>
                10.3 A SILICRED utiliza os seguintes Cookies:
            </p>
            <p style={styles.text}>
                Alguns cookies serão colocados em seu dispositivo diretamente pelo nosso site - são conhecidos como cookies primários. Eles são essenciais para você navegar no site e usar seus recursos.
            </p>
            <p style={styles.text}>
                Temporários: Nós utilizamos cookies de sessão. Eles são temporários e expiram quando você fecha o navegador ou quando a sessão termina. Finalidade: Estabelecer controle de idioma e segurança ao tempo da sessão.
            </p>
            <p style={styles.text}>
                Outros cookies são colocados no seu dispositivo não pelo site que você está visitando, mas por terceiros, como, por exemplo, os sistemas analíticos.
            </p>
            <p style={styles.text}>
                Temporários: Nós utilizamos cookies de sessão. Eles são temporários e expiram quando você fecha o navegador ou quando a sessão termina. Finalidade: Coletam informações sobre como você usa o site, como as páginas que você visitou e os links em que clicou. Nenhuma dessas informações pode ser usada para identificá-lo. Seu único objetivo é possibilitar análises e melhorar as funções do site.
            </p>
            <p style={styles.text}>
                10.4 Você possui o direito de, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
            </p>
            <p style={styles.text}>
                10.5 Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies.
            </p>
            <p style={styles.text}>
                Você pode ter maiores informações sobre os Cookies que utilizamos e como eles funcionam na nossa Política de Cookies, disponível em nosso site.
            </p>
            <p style={styles.text2}>
                11. Garantias e Responsabilidades
            </p>
            <p style={styles.text}>
                11.1 Isenção de Garantias: Você usa a nossa Plataforma a seu próprio e exclusivo risco. Nossa Plataforma é oferecida a você “como é” e “como está disponível”. Nós nos isentamos de garantias e condições de quaisquer tipos, sejam expressas, implícitas ou instituídas, incluindo mas não se limitando, a garantias relacionadas à segurança, confiabilidade, conveniência e performance de nossa Plataforma. Nós nos isentamos ainda de quaisquer garantias de resultado sobre informações ou conselhos obtidos através de nossa Plataforma. Além disso, nenhum conselho ou informação, seja oral ou escrita, obtida de você por nós, deve criar nenhum tipo de garantia ou promessa de resultado.
            </p>
            <p style={styles.text}>
                11.2 Suposição de Risco: Você compreende e concorda expressamente que ao fazer download ou obter qualquer outra forma de acesso ao nosso material ou dados através do uso de nossa Plataforma ao seu próprio critério e risco, você será o único responsável por quaisquer danos ao seu computador ou perda de dados que resulte do download deste material ou dados.
            </p>
            <p style={styles.text}>
                11.3 Limitação de Responsabilidade: Você concorda expressamente que, em nenhuma circunstância, a SILICRED poderá ser responsabilizado por qualquer dano direto, indireto, incidental, especial, consequencial ou punitivo, incluindo mas não se limitando, a perdas e danos, lucros cessantes, perda de uma chance, outras perdas e danos intangíveis, incluindo as modalidades de culpa, relacionados ao uso das informações sobre os Serviços prestados pela SILICRED através de nossa Plataforma.
            </p>
            <p style={styles.text2}>
                12. Indenização
            </p>
            <p style={styles.text}>
                12.1 Você compreende e concorda expressamente que poderá ter de incorrer em obrigação indenizatória, em benefício da SILICRED, seus dirigentes, sócios, empregados,  consultores parceiros, em face de reclamação comprovada, praticada em descumprimento da Política de Privacidade  e em detrimento destes Termos de Uso. Essa responsabilização poderá implicar em recomposição pecuniária do dano, multas, custas judiciais, honorários advocatícios além de outras despesas e onerações relacionadas as infrações praticadas. Essa responsabilização também será possível quando você, utilizando-se de nossa plataforma, violar qualquer lei, estatuto, ordem ou regulamento ou direitos de terceiros.
            </p>
            <p style={styles.text2}>
                13. Rescisão
            </p>
            <p style={styles.text}>
                13.1 Você concorda expressamente que nós, conforme critérios estabelecidos na Politica de Privacidade e nos critérios fixados nestes Termos de Uso, poderemos disparar um alerta, suspender temporariamente, indefinidamente ou remover completamente qualquer conteúdo ou informação postada por você, ou encerrar sua conta em  nossa Plataforma, incluindo mas não se limitando a: (a) falta de uso; (b) violação das regras estabelecidas na Politica de Privacidade e nestes Termos de Uso, sendo a ação dolosa ou culposa; (c) se não formos capazes de verificar ou autenticar qualquer informação que você nos forneça; (d) se houverem razões objetivas para se acreditar que suas ações irão gerar responsabilidade civil a você, a terceiros ou a SILICRED.
            </p>
            <p style={styles.text}>
                13.2 Você se declara ciente de que o encerramento de conta da qual seja titular ou do acesso total ou parcial à Plataforma pelos motivos esclarecidos na Politica de Privacidade e nestes Termos de Uso, poderá ser efetuado sem aviso prévio, e compreende e concorda expressamente que poderemos imediatamente desativar ou apagar a sua conta e todas as informações e arquivos relativos a ela e/ou barrar seu acesso futuro à nossa plataforma, não nos podendo responsabilizar por essas ações.
            </p>
            <p style={styles.text}>
                13.3 A SILICRED reserva-se a exercer o direito de rescindir eventual contrato de prestação de serviços firmado com você ou cancelar seu acesso a nossa plataforma, por violação a Política de Privacidade e a estes Termos de Uso.
            </p>
            <p style={styles.text2}>
                14. Propriedade Intelectual
            </p>
            <p style={styles.text}>
                14.1 Direitos autorais: O conteúdo publicado no website da SILICRED e as informações e conteúdos encontrados em nossa plataforma  estão protegidos pelas leis e tratados de direitos autorais nacionais e internacionais, assim como outras leis e tratados de propriedade intelectual. Você não pode fazer cópias não autorizadas ou usar algum conteúdo, exceto como especificado aqui e de acordo com as leis aplicáveis. Todos os direitos autorais do Conteúdo e dos Serviços (incluindo, mas não se limitando a imagens, fotografias, animações, vídeos, áudio, música, texto, layout) são de propriedade ou autorizados expressamente ao uso da SILICRED. Você concorda expressamente em cumprir com todas as leis de proteção dos direitos autorais relacionadas ao uso dos serviços apresentados e dos conteúdos veiculados. A SILICRED reserva-se ao direito de adotar todas as medidas, judiciais e extra judiciais, que julgar apropriadas, a seu critério exclusivo, objetivando proteger os eventuais direitos autorais violados.
            </p>
            <p style={styles.text}>
                14.2 Não é permitido aos Usuários e Pacientes tentar reconfigurar, desmontar ou fazer engenharia reversa no website, nas informações relativas aos serviços prestados pela SILICRED e/ou no conteúdo da plataforma.
            </p>
            <p style={styles.text}>
                14.3 Marcas: Você não está autorizado a utilizar nenhum marca e/ou sinais distintivos encontrados no website e/ou no conteúdo das informações. Você não pode copiar, exibir ou usar nenhuma das marcas comerciais sem consentimento prévio por escrito dos respectivos proprietários. Qualquer uso não autorizado poderá violar as leis de propriedade industrial, leis de privacidade, de propriedade intelectual e ainda estatutos civis e/ou criminais.
            </p>
            <p style={styles.text}>
                14.4 Todos os direitos e licenças não concedidos ou não mencionados ,expressamente nestes Termos de Uso são reservados aos proprietários dos conteúdos. Estes Termos de Uso não concedem quaisquer licenças implícitas.
            </p>
            <p style={styles.text2}>
                15. Disposições Gerais
            </p>
            <p style={styles.text}>
                15.1 Estes Termos de Uso, juntamente com a Política de Privacidade publicada no nosso website, constituem o acordo integral entre as Partes com relação ao objeto em questão, e substituem todos os acordos anteriores, escritos ou verbais.
            </p>
            <p style={styles.text}>
                15.2 Os usuários e/ou pacientes são potenciais contratantes independentes com relação as informações sobre os serviços prestados pela SILICRED, não resultando este instrumento na criação de qualquer espécie de promessa de contratação, de qualquer sociedade, franquia, representação de vendas ou relações que não as expressamente previstas nestes Termos de Uso.
            </p>
            <p style={styles.text}>
                15.3 Caso qualquer uma das cláusulas e condições contidas nestes Termos de Uso venha a ser declarada nula, no todo ou em parte, por qualquer motivo legal, contratual ou regulamentar, as demais cláusulas continuarão em pleno vigor e efeito.
            </p>
            <p style={styles.text}>
                15.4 A tolerância ou o não exercício, por qualquer dos usuários e/ou pacientes, de quaisquer direitos a eles assegurados nestes Termos de Uso, na Política de Privacidade ou no Ordenamento jurídico legal em geral não importará em novação ou em renúncia a quaisquer desses direitos, podendo a referida parte exercê-los durante a vigência destes Termos de Uso.
            </p>
            <p style={styles.text}>
                15.5 Notificações, avisos ou comunicações relativas aos presentes Termos de Uso serão enviados por escrito, por meio forma de comunicação, desde que recebida comprovadamente, em especial a eletrônica, por qualquer uma das partes à outra.
            </p>
            <p style={styles.text}>
                15.6 Fica eleito o foro da Comarca da Capital, Florianópolis, Estado de Santa Catarina, com exclusão de qualquer outro, por mais privilegiado que seja, para dirimir quaisquer litígios ou controvérsias oriundos dos presentes Termos de Uso.
            </p>
        </Col>
    );
};

export default Terms;
