import React from "react";
import {GenericList} from "../../../components";
import {deleteProcedureRequest, findProceduresRequest} from "../../../services/procedure";
import {PROCEDURE_TYPES} from "./util/PROCEDURE_TYPES";


export const Procedure = () => {

    const columns: any = [
        {
            title: 'Nome',
            key: 'name'
        },
        {
            title: 'Tipo',
            key: 'type',
            render: (item: any, rowData: any) => (<span>{PROCEDURE_TYPES[rowData.type]}</span>)
        }
    ]

    return (
      <GenericList
          deletable
          title={'Procedimentos'}
          newPath={'procedures/create'}
          editPath={'procedures/edit'}
          providerFind={findProceduresRequest}
          providerDelete={deleteProcedureRequest}
          columns={columns}
      />
  );
};
