import {toast, ToastOptions} from 'react-toastify';

const globalConfigs: ToastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
};

export const showInfoToast = (message: string) => {
    toast.info(message, globalConfigs);
};

export const showSuccessToast = (message: string) => {
    toast.success(message, globalConfigs);
};

export const showWarnToast = (message: string) => {
    toast.warn(message, globalConfigs);
};

export const showErrorToast = (message: string) => {
    toast.error(message, globalConfigs);
};
